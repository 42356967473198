/*------------------------------------------------------------------
.______    __  ___   ___  __   _______  ___________    ____
|   _  \  |  | \  \ /  / |  | |   ____||   ____\   \  /   /
|  |_)  | |  |  \  V  /  |  | |  |__   |  |__   \   \/   /
|   ___/  |  |   >   <   |  | |   __|  |   __|   \_    _/
|  |      |  |  /  .  \  |  | |  |____ |  |        |  |
| _|      |__| /__/ \__\ |__| |_______||__|        |__|


[Main Stylesheet]

Project:    	SassTrend
Version:    	0.1
Last change:    05.10.2018
Primary use:    Bootstrap SaaS, Startup, Software & WebApp Template


[Typography]

Body: 16px "Poppins", sans-serif;
Title: 34px "Oxygen", "Poppins", sans-serif;

/*==============================
[Table of contents]

1. Reset Setup
2. Button Styles
3. Header Styles
4. Footer Styles
5. Heading / Section Heading Styles
6. Hero Header/Banners Styles
7. Promos Styles
8. Video Elements Styles
9. Counter Styles
10. Feature Elements Styles
11. About Block Styles
12. Content Elements Styles
13. Call To Actions Styles
14. Team Elements Styles
15. Testimonial Styles
16. Screenshot Slider Styles
17. Subscriptions Styles
18. Pricing Styles
19. Client Slider Styles
20. Tab Features Styles
21. Download Styles
22. Contact Block Styles
23. Simple Content Styles
24. Showcase Styles
25. Map Block Styles
26. List Elements Styles
27. Special Elements Styles
28. Clients Elements Styles
29. Work process Styles
30. Faqs Styles
31. Login Block Styles
32. Info Box Elements Styles
33. Image Mapping Styles

-------------------------------------------------------------------*/
/* Webfont: Lato-Hairline */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-Hairline.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-Hairline.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-Hairline.woff2") format("woff2"), url("../fonts/lato/Lato-Hairline.woff") format("woff"), url("../fonts/lato/Lato-Hairline.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-HairlineItalic */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-HairlineItalic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-HairlineItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-HairlineItalic.woff2") format("woff2"), url("../fonts/lato/Lato-HairlineItalic.woff") format("woff"), url("../fonts/lato/Lato-HairlineItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 100;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Thin */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-Thin.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-Thin.woff2") format("woff2"), url("../fonts/lato/Lato-Thin.woff") format("woff"), url("../fonts/lato/Lato-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-ThinItalic */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-ThinItalic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-ThinItalic.woff2") format("woff2"), url("../fonts/lato/Lato-ThinItalic.woff") format("woff"), url("../fonts/lato/Lato-ThinItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 200;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Light */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-Light.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-Light.woff2") format("woff2"), url("../fonts/lato/Lato-Light.woff") format("woff"), url("../fonts/lato/Lato-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-LightItalic */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-LightItalic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-LightItalic.woff2") format("woff2"), url("../fonts/lato/Lato-LightItalic.woff") format("woff"), url("../fonts/lato/Lato-LightItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-Regular.woff2") format("woff2"), url("../fonts/lato/Lato-Regular.woff") format("woff"), url("../fonts/lato/Lato-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Italic */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-Italic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-Italic.woff2") format("woff2"), url("../fonts/lato/Lato-Italic.woff") format("woff"), url("../fonts/lato/Lato-Italic.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-Medium.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-Medium.woff2") format("woff2"), url("../fonts/lato/Lato-Medium.woff") format("woff"), url("../fonts/lato/Lato-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-MediumItalic */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-MediumItalic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-MediumItalic.woff2") format("woff2"), url("../fonts/lato/Lato-MediumItalic.woff") format("woff"), url("../fonts/lato/Lato-MediumItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Semibold */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-Semibold.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-Semibold.woff2") format("woff2"), url("../fonts/lato/Lato-Semibold.woff") format("woff"), url("../fonts/lato/Lato-Semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-SemiboldItalic */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-SemiboldItalic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-SemiboldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-SemiboldItalic.woff2") format("woff2"), url("../fonts/lato/Lato-SemiboldItalic.woff") format("woff"), url("../fonts/lato/Lato-SemiboldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-Bold.woff2") format("woff2"), url("../fonts/lato/Lato-Bold.woff") format("woff"), url("../fonts/lato/Lato-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BoldItalic */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-BoldItalic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-BoldItalic.woff2") format("woff2"), url("../fonts/lato/Lato-BoldItalic.woff") format("woff"), url("../fonts/lato/Lato-BoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Heavy */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-Heavy.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-Heavy.woff2") format("woff2"), url("../fonts/lato/Lato-Heavy.woff") format("woff"), url("../fonts/lato/Lato-Heavy.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-HeavyItalic */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-HeavyItalic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-HeavyItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-HeavyItalic.woff2") format("woff2"), url("../fonts/lato/Lato-HeavyItalic.woff") format("woff"), url("../fonts/lato/Lato-HeavyItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Black */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-Black.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-Black.woff2") format("woff2"), url("../fonts/lato/Lato-Black.woff") format("woff"), url("../fonts/lato/Lato-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BlackItalic */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/Lato-BlackItalic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-BlackItalic.woff2") format("woff2"), url("../fonts/lato/Lato-BlackItalic.woff") format("woff"), url("../fonts/lato/Lato-BlackItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}

/**
* = 1. Reset Setup
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
body {
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lato", sans-serif;
  color: #4A516A;
  font-weight: 400;
  overflow-x: hidden;
}

input {
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

a:hover,
a:focus,
input:focus,
input:hover,
select:focus,
select:hover,
select:active,
textarea:focus,
textarea:hover,
button:focus {
  outline: none;
}

a {
  color: #2bbcff;
  font-weight: bold;
  -webkit-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

a:hover, a:focus {
  color: #4A516A;
  text-decoration: none;
  outline: none;
}

button, input, optgroup, select, textarea {
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 20px;
  color: #4A516A;
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
.h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small {
  font-size: 65%;
}

h1 strong,
h1 b, h2 strong,
h2 b, h3 strong,
h3 b, h4 strong,
h4 b, h5 strong,
h5 b, h6 strong,
h6 b,
.h1 strong,
.h1 b, .h2 strong,
.h2 b, .h3 strong,
.h3 b, .h4 strong,
.h4 b, .h5 strong,
.h5 b, .h6 strong,
.h6 b {
  font-weight: 700;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
.h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
  color: #4A516A;
}

h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover,
.h1 a:hover, .h2 a:hover, .h3 a:hover, .h4 a:hover, .h5 a:hover, .h6 a:hover {
  color: #4A516A;
}

code {
  background-color: #f5f2f0;
  padding: .2rem .4rem;
}

blockquote {
  border-left-width: 2px;
  margin-bottom: 25px;
  margin-left: 45px;
  margin-top: 25px;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
}

dl {
  margin-bottom: 1.6em;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1.6em;
}

table,
th,
td {
  border: 1px solid #eaeaea;
  border: 1px solid rgba(51, 51, 51, 0.1);
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 1px 0 0 1px;
  margin: 0 0 1.6em;
  table-layout: fixed;
  /* Prevents HTML tables from becoming too wide */
  width: 100%;
}

caption,
th,
td {
  font-weight: normal;
  text-align: left;
}

th {
  border-width: 0 1px 1px 0;
  font-weight: 700;
}

td {
  border-width: 0 1px 1px 0;
}

th, td {
  padding: 0.4em;
}

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

iframe {
  width: 100%;
}

h1,
.h1 {
  font-size: 2.5em;
}

h2,
.h2 {
  font-size: 2.5em;
}

h3,
.h3 {
  font-size: 1.625em;
  color: rgba(74, 81, 106, 0.6);
}

h4,
.h4 {
  font-size: 1.25em;
}

h5,
.h5 {
  font-size: 16px;
  font-size: 1rem;
}

h6, .h6 {
  font-size: 15px;
  font-size: 0.9375rem;
}

.text-strong {
  font-weight: 700;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="time"],
input[type="date"],
input[type="search"],
textarea {
  background-color: #F5F9FD;
  width: 100%;
  border: none;
  border: 1px solid rgba(30, 67, 221, 0.09);
  padding: 14px 25px;
  font-size: 14px;
  font-size: 0.875rem;
  border-radius: 3px;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.02);
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

input[type="submit"] {
  background-color: #00E3F6;
  color: #FFFFFF;
  font-weight: 600;
  border: none;
  padding: 14px 25px;
  margin: 0;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.16);
  display: inline-block;
}

.st-body-font {
  font-family: "Lato", sans-serif;
}

.st-title-font {
  font-family: "Lato", sans-serif;
}

.st-position-wrap {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.min-height-mobile {
  min-height: 750px !important;
}

.st-fonts-poppins {
  font-family: "Lato", sans-serif;
}

.st-fonts-oxygen {
  font-family: "Lato", sans-serif;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semi-bold {
  font-weight: 600;
}

.font-weight-extra-bold {
  font-weight: 800;
}

.st_border_bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.st_before_none::before {
  display: none !important;
}

.st_after_none::after {
  display: none !important;
}

.bg-none {
  background: none !important;
}

.bgc-none {
  background-color: transparent !important;
}

.bgi-none {
  background-image: none !important;
}

.br-none br {
  display: none !important;
}

.bg-size-0 {
  background-size: 100% 100% !important;
}

.bg-size-100-auto {
  background-size: 100% auto !important;
}

.bg-size-auto-100 {
  background-size: auto 100% !important;
}

.bg-size-auto {
  background-size: auto !important;
}

.bg-size-cover {
  background-size: cover !important;
}

.bg-size-contain {
  background-size: contain !important;
}

.bg-size-initial {
  background-size: initial !important;
}

.bg-size-inherit {
  background-size: inherit !important;
}

.bg-attachment-scroll {
  background-attachment: scroll !important;
}

.bg-attachment-fixed {
  background-attachment: fixed !important;
}

.bg-attachment-local {
  background-attachment: local !important;
}

.bg-attachment-initial {
  background-attachment: initial !important;
}

.bg-attachment-inherit {
  background-attachment: inherit !important;
}

.bg-repeat-repeat {
  background-repeat: repeat !important;
}

.bg-repeat-repeat-x {
  background-repeat: repeat-x !important;
}

.bg-repeat-repeat-y {
  background-repeat: repeat-y !important;
}

.bg-repeat-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-repeat-initial {
  background-repeat: initial !important;
}

.bg-repeat-inherit {
  background-repeat: inherit !important;
}

.bg-position-left-top {
  background-position: left top !important;
}

.bg-position-left-center {
  background-position: left center !important;
}

.bg-position-left-bottom {
  background-position: left bottom !important;
}

.bg-position-right-top {
  background-position: right top !important;
}

.bg-position-right-center {
  background-position: right center !important;
}

.bg-position-right-bottom {
  background-position: right bottom !important;
}

.bg-position-center-top {
  background-position: center top !important;
}

.bg-position-center-center {
  background-position: center center !important;
}

.bg-position-center-bottom {
  background-position: center bottom !important;
}

.bg-position-initial {
  background-position: initial !important;
}

.bg-position-inherit {
  background-position: inherit !important;
}

.st-overflow-visible {
  overflow: visible !important;
}

.st-overflow-x-visible {
  overflow-x: visible !important;
}

.st-overflow-y-visible {
  overflow-y: visible !important;
}

.st-overflow-hidden {
  overflow: hidden !important;
}

.st-overflow-x-hidden {
  overflow-x: hidden !important;
}

.st-overflow-y-hidden {
  overflow-y: hidden !important;
}

.st-overflow-scroll {
  overflow: scroll !important;
}

.st-overflow-x-scroll {
  overflow-x: scroll !important;
}

.st-overflow-y-scroll {
  overflow-y: scroll !important;
}

.st-overflow-auto {
  overflow: auto !important;
}

.st-overflow-x-auto {
  overflow-x: auto !important;
}

.st-overflow-y-auto {
  overflow-y: auto !important;
}

.st-overflow-initial {
  overflow: initial !important;
}

.st-overflow-x-initial {
  overflow-x: initial !important;
}

.st-overflow-y-initial {
  overflow-y: initial !important;
}

.st-overflow-inherit {
  overflow: inherit !important;
}

.st-overflow-x-inherit {
  overflow-x: inherit !important;
}

.st-overflow-y-inherit {
  overflow-y: inherit !important;
}

.st-line-height-1 {
  line-height: 1 !important;
}

.st-line-height-1-1 {
  line-height: 1.1 !important;
}

.st-line-height-1-2 {
  line-height: 1.2 !important;
}

.st-line-height-1-3 {
  line-height: 1.3 !important;
}

.st-line-height-1-4 {
  line-height: 1.4 !important;
}

.st-line-height-1-5 {
  line-height: 1.5 !important;
}

.st-line-height-1-6 {
  line-height: 1.6 !important;
}

.st-line-height-1-7 {
  line-height: 1.7 !important;
}

.st-line-height-1-8 {
  line-height: 1.8 !important;
}

.st-line-height-1-9 {
  line-height: 1.9 !important;
}

.st-line-height-2 {
  line-height: 2 !important;
}

.st-line-height-2-1 {
  line-height: 2.1 !important;
}

.st-line-height-2-2 {
  line-height: 2.2 !important;
}

.st-line-height-2-3 {
  line-height: 2.3 !important;
}

.st-line-height-2-5 {
  line-height: 2.5 !important;
}

.st-line-height-3 {
  line-height: 3 !important;
}

.st-line-height-4 {
  line-height: 4 !important;
}

.st-width-full {
  width: 100% !important;
}

.word-break-normal {
  -ms-word-break: normal !important;
  word-break: normal !important;
}

@media only screen and (max-width: 575.98px) {
  .col-xs-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xs-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xs-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xs-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xs-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xs-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xs-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xs-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xs-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .d-xs-none {
    display: none !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-table {
    display: table !important;
  }
  .d-xs-table-row {
    display: table-row !important;
  }
  .d-xs-table-cell {
    display: table-cell !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}

.st-font-10 {
  font-size: 10px;
  font-size: 0.625rem;
}

.st-font-12 {
  font-size: 12px;
  font-size: 0.75rem;
}

.st-font-13 {
  font-size: 13px;
  font-size: 0.8125rem;
}

.st-font-14 {
  font-size: 14px;
  font-size: 0.875rem;
}

.st-font-15 {
  font-size: 15px;
  font-size: 0.9375rem;
}

.st-font-16 {
  font-size: 16px;
  font-size: 1rem;
}

.st-font-18 {
  font-size: 18px;
  font-size: 1.125rem;
}

.st-font-20 {
  font-size: 20px;
  font-size: 1.25rem;
}

.st-font-22 {
  font-size: 22px;
  font-size: 1.375rem;
}

.st-font-24 {
  font-size: 24px;
  font-size: 1.5rem;
}

.st-font-25 {
  font-size: 25px;
  font-size: 1.5625rem;
}

.st-font-28 {
  font-size: 28px;
  font-size: 1.75rem;
}

.st-font-30 {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.7;
}

.st-font-34 {
  font-size: 34px;
  font-size: 2.125rem;
  line-height: 1.6;
}

.st-font-38 {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.5;
}

.st-font-40 {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 1.4;
}

.st-font-44 {
  font-size: 44px;
  font-size: 2.75rem;
  line-height: 1.2;
}

.st-font-48 {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1;
}

.st-font-52 {
  font-size: 52px;
  font-size: 3.25rem;
  line-height: 1;
}

.st-font-55 {
  font-size: 55px;
  font-size: 3.4375rem;
  line-height: 1;
}

.st-font-60 {
  font-size: 60px;
  font-size: 3.75rem;
  line-height: 1;
}

.st-font-78 {
  font-size: 78px;
  font-size: 4.875rem;
  line-height: 0.9;
}

[data-overlay] {
  position: relative;
}

[data-overlay]:before {
  position: absolute;
  content: '';
  background: #1f1e1e;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

[data-overlay="0"]::before {
  opacity: 0;
}

.st-opacity-0 {
  opacity: 0;
}

[data-overlay="1"]::before {
  opacity: 0.1;
}

.st-opacity-1 {
  opacity: 0.1;
}

[data-overlay="2"]::before {
  opacity: 0.2;
}

.st-opacity-2 {
  opacity: 0.2;
}

[data-overlay="3"]::before {
  opacity: 0.3;
}

.st-opacity-3 {
  opacity: 0.3;
}

[data-overlay="4"]::before {
  opacity: 0.4;
}

.st-opacity-4 {
  opacity: 0.4;
}

[data-overlay="5"]::before {
  opacity: 0.5;
}

.st-opacity-5 {
  opacity: 0.5;
}

[data-overlay="6"]::before {
  opacity: 0.6;
}

.st-opacity-6 {
  opacity: 0.6;
}

[data-overlay="7"]::before {
  opacity: 0.7;
}

.st-opacity-7 {
  opacity: 0.7;
}

[data-overlay="8"]::before {
  opacity: 0.8;
}

.st-opacity-8 {
  opacity: 0.8;
}

[data-overlay="9"]::before {
  opacity: 0.9;
}

.st-opacity-9 {
  opacity: 0.9;
}

[data-overlay="10"]::before {
  opacity: 1;
}

.st-opacity-10 {
  opacity: 1;
}

.st-width-10 {
  width: 10% !important;
}

.st-width-20 {
  width: 20% !important;
}

.st-width-25 {
  width: 25% !important;
}

.st-width-30 {
  width: 30% !important;
}

.st-width-35 {
  width: 35% !important;
}

.st-width-40 {
  width: 40% !important;
}

.st-width-45 {
  width: 45% !important;
}

.st-width-50 {
  width: 50% !important;
}

.st-width-55 {
  width: 55% !important;
}

.st-width-60 {
  width: 60% !important;
}

.st-width-70 {
  width: 70% !important;
}

.st-width-75 {
  width: 75% !important;
}

.st-width-80 {
  width: 80% !important;
}

.st-width-85 {
  width: 85% !important;
}

.st-width-90 {
  width: 90% !important;
}

.st-width-95 {
  width: 95% !important;
}

.st-width-100 {
  width: 100% !important;
}

.st-side-auto {
  margin-right: auto;
  margin-left: auto;
}

.st-min-1150 {
  min-height: 1150px !important;
}

.st-min-1000 {
  min-height: 1000px !important;
}

.st-min-900 {
  min-height: 900px !important;
}

.st-min-850 {
  min-height: 850px !important;
}

.st-min-650 {
  min-height: 650px !important;
}

@media only screen and (max-width: 1499.98px) {
  .st-font-xl-10 {
    font-size: 10px;
    font-size: 0.625rem;
  }
  .st-lineheight-xl-10 {
    line-height: 10px;
  }
  .st-font-xl-12 {
    font-size: 12px;
    font-size: 0.75rem;
  }
  .st-lineheight-xl-12 {
    line-height: 12px;
  }
  .st-font-xl-13 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
  .st-lineheight-xl-13 {
    line-height: 13px;
  }
  .st-font-xl-14 {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .st-lineheight-xl-14 {
    line-height: 14px;
  }
  .st-font-xl-15 {
    font-size: 15px;
    font-size: 0.9375rem;
  }
  .st-lineheight-xl-15 {
    line-height: 15px;
  }
  .st-font-xl-16 {
    font-size: 16px;
    font-size: 1rem;
  }
  .st-lineheight-xl-16 {
    line-height: 16px;
  }
  .st-font-xl-18 {
    font-size: 18px;
    font-size: 1.125rem;
  }
  .st-lineheight-xl-18 {
    line-height: 18px;
  }
  .st-font-xl-20 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .st-lineheight-xl-20 {
    line-height: 20px;
  }
  .st-font-xl-22 {
    font-size: 22px;
    font-size: 1.375rem;
  }
  .st-lineheight-xl-22 {
    line-height: 22px;
  }
  .st-font-xl-24 {
    font-size: 24px;
    font-size: 1.5rem;
  }
  .st-lineheight-xl-24 {
    line-height: 24px;
  }
  .st-font-xl-25 {
    font-size: 25px;
    font-size: 1.5625rem;
  }
  .st-lineheight-xl-25 {
    line-height: 25px;
  }
  .st-font-xl-28 {
    font-size: 28px;
    font-size: 1.75rem;
  }
  .st-lineheight-xl-28 {
    line-height: 28px;
  }
  .st-font-xl-30 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.7;
  }
  .st-lineheight-xl-30 {
    line-height: 30px;
  }
  .st-font-xl-34 {
    font-size: 34px;
    font-size: 2.125rem;
    line-height: 1.6;
  }
  .st-lineheight-xl-34 {
    line-height: 34px;
  }
  .st-font-xl-38 {
    font-size: 38px;
    font-size: 2.375rem;
    line-height: 1.5;
  }
  .st-lineheight-xl-38 {
    line-height: 38px;
  }
  .st-font-xl-40 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.4;
  }
  .st-lineheight-xl-40 {
    line-height: 40px;
  }
  .st-font-xl-44 {
    font-size: 44px;
    font-size: 2.75rem;
    line-height: 1.2;
  }
  .st-lineheight-xl-44 {
    line-height: 44px;
  }
  .st-font-xl-48 {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1;
  }
  .st-lineheight-xl-48 {
    line-height: 48px;
  }
  .st-font-xl-52 {
    font-size: 52px;
    font-size: 3.25rem;
    line-height: 1;
  }
  .st-lineheight-xl-52 {
    line-height: 52px;
  }
  .st-font-xl-55 {
    font-size: 55px;
    font-size: 3.4375rem;
    line-height: 1;
  }
  .st-lineheight-xl-55 {
    line-height: 55px;
  }
  .st-font-xl-60 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1;
  }
  .st-lineheight-xl-60 {
    line-height: 60px;
  }
  .st-font-xl-78 {
    font-size: 78px;
    font-size: 4.875rem;
    line-height: 0.9;
  }
  .st-lineheight-xl-78 {
    line-height: 78px;
  }
  .st-text-xl-center {
    text-align: center !important;
  }
  .st-text-xl-right {
    text-align: right !important;
  }
  .st-text-xl-left {
    text-align: left !important;
  }
  .st-min-xl-1150 {
    min-height: 1150px !important;
  }
  .st-min-xl-1000 {
    min-height: 1000px !important;
  }
  .st-min-xl-900 {
    min-height: 900px !important;
  }
  .st-min-xl-850 {
    min-height: 850px !important;
  }
  .st-min-xl-650 {
    min-height: 650px !important;
  }
  .st-min-xl-auto {
    min-height: auto !important;
    height: auto !important;
  }
  .st-height-xl-inherit {
    height: auto !important;
    min-height: inherit !important;
    max-height: inherit !important;
  }
  .st-d-xl-none {
    display: none !important;
  }
  .st-d-xl-block {
    display: block !important;
  }
  .st-width-xl-10 {
    width: 10% !important;
  }
  .st-width-xl-20 {
    width: 20% !important;
  }
  .st-width-xl-25 {
    width: 25% !important;
  }
  .st-width-xl-30 {
    width: 30% !important;
  }
  .st-width-xl-35 {
    width: 35% !important;
  }
  .st-width-xl-40 {
    width: 40% !important;
  }
  .st-width-xl-45 {
    width: 45% !important;
  }
  .st-width-xl-50 {
    width: 50% !important;
  }
  .st-width-xl-55 {
    width: 55% !important;
  }
  .st-width-xl-60 {
    width: 60% !important;
  }
  .st-width-xl-70 {
    width: 70% !important;
  }
  .st-width-xl-75 {
    width: 75% !important;
  }
  .st-width-xl-80 {
    width: 80% !important;
  }
  .st-width-xl-85 {
    width: 85% !important;
  }
  .st-width-xl-90 {
    width: 90% !important;
  }
  .st-width-xl-95 {
    width: 95% !important;
  }
  .st-width-xl-100 {
    width: 100% !important;
  }
  .st-align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: start !important;
  }
  .st-justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: start !important;
  }
  .st-align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: end !important;
  }
  .st-justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: end !important;
  }
  .st-align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .st-justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .bg-size-xl-0 {
    background-size: 100% 100% !important;
  }
  .bg-size-xl-100-auto {
    background-size: 100% auto !important;
  }
  .bg-size-xl-auto-100 {
    background-size: auto 100% !important;
  }
  .bg-size-xl-auto {
    background-size: auto !important;
  }
  .bg-size-xl-cover {
    background-size: cover !important;
  }
  .bg-size-xl-contain {
    background-size: contain !important;
  }
  .bg-size-xl-initial {
    background-size: initial !important;
  }
  .bg-size-xl-inherit {
    background-size: inherit !important;
  }
  .bg-attachment-xl-scroll {
    background-attachment: scroll !important;
  }
  .bg-attachment-xl-fixed {
    background-attachment: fixed !important;
  }
  .bg-attachment-xl-local {
    background-attachment: local !important;
  }
  .bg-attachment-xl-initial {
    background-attachment: initial !important;
  }
  .bg-attachment-xl-inherit {
    background-attachment: inherit !important;
  }
  .bg-repeat-xl-repeat {
    background-repeat: repeat !important;
  }
  .bg-repeat-xl-repeat-x {
    background-repeat: repeat-x !important;
  }
  .bg-repeat-xl-repeat-y {
    background-repeat: repeat-y !important;
  }
  .bg-repeat-xl-no-repeat {
    background-repeat: no-repeat !important;
  }
  .bg-repeat-xl-initial {
    background-repeat: initial !important;
  }
  .bg-repeat-xl-inherit {
    background-repeat: inherit !important;
  }
  .bg-position-xl-left-top {
    background-position: left top !important;
  }
  .bg-position-xl-left-center {
    background-position: left center !important;
  }
  .bg-position-xl-left-bottom {
    background-position: left bottom !important;
  }
  .bg-position-xl-right-top {
    background-position: right top !important;
  }
  .bg-position-xl-right-center {
    background-position: right center !important;
  }
  .bg-position-xl-right-bottom {
    background-position: right bottom !important;
  }
  .bg-position-xl-center-top {
    background-position: center top !important;
  }
  .bg-position-xl-center-center {
    background-position: center center !important;
  }
  .bg-position-xl-center-bottom {
    background-position: center bottom !important;
  }
  .bg-position-xl-initial {
    background-position: initial !important;
  }
  .bg-position-xl-inherit {
    background-position: inherit !important;
  }
  .st-overflow-xl-visible {
    overflow: visible !important;
  }
  .st-overflow-x-xl-visible {
    overflow-x: visible !important;
  }
  .st-overflow-y-xl-visible {
    overflow-y: visible !important;
  }
  .st-overflow-xl-hidden {
    overflow: hidden !important;
  }
  .st-overflow-x-xl-hidden {
    overflow-x: hidden !important;
  }
  .st-overflow-y-xl-hidden {
    overflow-y: hidden !important;
  }
  .st-overflow-xl-scroll {
    overflow: scroll !important;
  }
  .st-overflow-x-xl-scroll {
    overflow-x: scroll !important;
  }
  .st-overflow-y-xl-scroll {
    overflow-y: scroll !important;
  }
  .st-overflow-xl-auto {
    overflow: auto !important;
  }
  .st-overflow-x-xl-auto {
    overflow-x: auto !important;
  }
  .st-overflow-y-xl-auto {
    overflow-y: auto !important;
  }
  .st-overflow-xl-initial {
    overflow: initial !important;
  }
  .st-overflow-x-xl-initial {
    overflow-x: initial !important;
  }
  .st-overflow-y-xl-initial {
    overflow-y: initial !important;
  }
  .st-overflow-xl-inherit {
    overflow: inherit !important;
  }
  .st-overflow-x-xl-inherit {
    overflow-x: inherit !important;
  }
  .st-overflow-y-xl-inherit {
    overflow-y: inherit !important;
  }
  .st-line-height-xl-1 {
    line-height: 1 !important;
  }
  .st-line-height-xl-1-1 {
    line-height: 1.1 !important;
  }
  .st-line-height-xl-1-2 {
    line-height: 1.2 !important;
  }
  .st-line-height-xl-1-3 {
    line-height: 1.3 !important;
  }
  .st-line-height-xl-1-4 {
    line-height: 1.4 !important;
  }
  .st-line-height-xl-1-5 {
    line-height: 1.5 !important;
  }
  .st-line-height-xl-1-6 {
    line-height: 1.6 !important;
  }
  .st-line-height-xl-1-7 {
    line-height: 1.7 !important;
  }
  .st-line-height-xl-1-8 {
    line-height: 1.8 !important;
  }
  .st-line-height-xl-1-9 {
    line-height: 1.9 !important;
  }
  .st-line-height-xl-2 {
    line-height: 2 !important;
  }
  .st-line-height-xl-2-1 {
    line-height: 2.1 !important;
  }
  .st-line-height-xl-2-2 {
    line-height: 2.2 !important;
  }
  .st-line-height-xl-2-3 {
    line-height: 2.3 !important;
  }
  .st-line-height-xl-2-5 {
    line-height: 2.5 !important;
  }
  .st-line-height-xl-3 {
    line-height: 3 !important;
  }
  .st-line-height-xl-4 {
    line-height: 4 !important;
  }
  .st-side-xl-auto {
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 1199.98px) {
  .st-font-lg-10 {
    font-size: 10px;
    font-size: 0.625rem;
  }
  .st-lineheight-lg-10 {
    line-height: 10px;
  }
  .st-font-lg-12 {
    font-size: 12px;
    font-size: 0.75rem;
  }
  .st-lineheight-lg-12 {
    line-height: 12px;
  }
  .st-font-lg-13 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
  .st-lineheight-lg-13 {
    line-height: 13px;
  }
  .st-font-lg-14 {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .st-lineheight-lg-14 {
    line-height: 14px;
  }
  .st-font-lg-15 {
    font-size: 15px;
    font-size: 0.9375rem;
  }
  .st-lineheight-lg-15 {
    line-height: 15px;
  }
  .st-font-lg-16 {
    font-size: 16px;
    font-size: 1rem;
  }
  .st-lineheight-lg-16 {
    line-height: 16px;
  }
  .st-font-lg-18 {
    font-size: 18px;
    font-size: 1.125rem;
  }
  .st-lineheight-lg-18 {
    line-height: 18px;
  }
  .st-font-lg-20 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .st-lineheight-lg-20 {
    line-height: 20px;
  }
  .st-font-lg-22 {
    font-size: 22px;
    font-size: 1.375rem;
  }
  .st-lineheight-lg-22 {
    line-height: 22px;
  }
  .st-font-lg-24 {
    font-size: 24px;
    font-size: 1.5rem;
  }
  .st-lineheight-lg-24 {
    line-height: 24px;
  }
  .st-font-lg-25 {
    font-size: 25px;
    font-size: 1.5625rem;
  }
  .st-lineheight-lg-25 {
    line-height: 25px;
  }
  .st-font-lg-28 {
    font-size: 28px;
    font-size: 1.75rem;
  }
  .st-lineheight-lg-28 {
    line-height: 28px;
  }
  .st-font-lg-30 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.7;
  }
  .st-lineheight-lg-30 {
    line-height: 30px;
  }
  .st-font-lg-34 {
    font-size: 34px;
    font-size: 2.125rem;
    line-height: 1.6;
  }
  .st-lineheight-lg-34 {
    line-height: 34px;
  }
  .st-font-lg-38 {
    font-size: 38px;
    font-size: 2.375rem;
    line-height: 1.5;
  }
  .st-lineheight-lg-38 {
    line-height: 38px;
  }
  .st-font-lg-40 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.4;
  }
  .st-lineheight-lg-40 {
    line-height: 40px;
  }
  .st-font-lg-44 {
    font-size: 44px;
    font-size: 2.75rem;
    line-height: 1.2;
  }
  .st-lineheight-lg-44 {
    line-height: 44px;
  }
  .st-font-lg-48 {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1;
  }
  .st-lineheight-lg-48 {
    line-height: 48px;
  }
  .st-font-lg-52 {
    font-size: 52px;
    font-size: 3.25rem;
    line-height: 1;
  }
  .st-lineheight-lg-52 {
    line-height: 52px;
  }
  .st-font-lg-55 {
    font-size: 55px;
    font-size: 3.4375rem;
    line-height: 1;
  }
  .st-lineheight-lg-55 {
    line-height: 55px;
  }
  .st-font-lg-60 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1;
  }
  .st-lineheight-lg-60 {
    line-height: 60px;
  }
  .st-font-lg-78 {
    font-size: 78px;
    font-size: 4.875rem;
    line-height: 0.9;
  }
  .st-lineheight-lg-78 {
    line-height: 78px;
  }
  .st-text-lg-center {
    text-align: center !important;
  }
  .st-text-lg-right {
    text-align: right !important;
  }
  .st-text-lg-left {
    text-align: left !important;
  }
  .st-min-lg-1150 {
    min-height: 1150px !important;
  }
  .st-min-lg-1000 {
    min-height: 1000px !important;
  }
  .st-min-lg-900 {
    min-height: 900px !important;
  }
  .st-min-lg-850 {
    min-height: 850px !important;
  }
  .st-min-lg-650 {
    min-height: 650px !important;
  }
  .st-min-lg-auto {
    min-height: auto !important;
    height: auto !important;
  }
  .st-height-lg-inherit {
    height: auto !important;
    min-height: inherit !important;
    max-height: inherit !important;
  }
  .st-d-lg-none {
    display: none !important;
  }
  .st-d-lg-block {
    display: block !important;
  }
  .st-width-lg-10 {
    width: 10% !important;
  }
  .st-width-lg-20 {
    width: 20% !important;
  }
  .st-width-lg-25 {
    width: 25% !important;
  }
  .st-width-lg-30 {
    width: 30% !important;
  }
  .st-width-lg-35 {
    width: 35% !important;
  }
  .st-width-lg-40 {
    width: 40% !important;
  }
  .st-width-lg-45 {
    width: 45% !important;
  }
  .st-width-lg-50 {
    width: 50% !important;
  }
  .st-width-lg-55 {
    width: 55% !important;
  }
  .st-width-lg-60 {
    width: 60% !important;
  }
  .st-width-lg-70 {
    width: 70% !important;
  }
  .st-width-lg-75 {
    width: 75% !important;
  }
  .st-width-lg-80 {
    width: 80% !important;
  }
  .st-width-lg-85 {
    width: 85% !important;
  }
  .st-width-lg-90 {
    width: 90% !important;
  }
  .st-width-lg-95 {
    width: 95% !important;
  }
  .st-width-lg-100 {
    width: 100% !important;
  }
  .st-align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: start !important;
  }
  .st-justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: start !important;
  }
  .st-align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: end !important;
  }
  .st-justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: end !important;
  }
  .st-align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .st-justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .bg-size-lg-0 {
    background-size: 100% 100% !important;
  }
  .bg-size-lg-100-auto {
    background-size: 100% auto !important;
  }
  .bg-size-lg-auto-100 {
    background-size: auto 100% !important;
  }
  .bg-size-lg-auto {
    background-size: auto !important;
  }
  .bg-size-lg-cover {
    background-size: cover !important;
  }
  .bg-size-lg-contain {
    background-size: contain !important;
  }
  .bg-size-lg-initial {
    background-size: initial !important;
  }
  .bg-size-lg-inherit {
    background-size: inherit !important;
  }
  .bg-attachment-lg-scroll {
    background-attachment: scroll !important;
  }
  .bg-attachment-lg-fixed {
    background-attachment: fixed !important;
  }
  .bg-attachment-lg-local {
    background-attachment: local !important;
  }
  .bg-attachment-lg-initial {
    background-attachment: initial !important;
  }
  .bg-attachment-lg-inherit {
    background-attachment: inherit !important;
  }
  .bg-repeat-lg-repeat {
    background-repeat: repeat !important;
  }
  .bg-repeat-lg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .bg-repeat-lg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .bg-repeat-lg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .bg-repeat-lg-initial {
    background-repeat: initial !important;
  }
  .bg-repeat-lg-inherit {
    background-repeat: inherit !important;
  }
  .bg-position-lg-left-top {
    background-position: left top !important;
  }
  .bg-position-lg-left-center {
    background-position: left center !important;
  }
  .bg-position-lg-left-bottom {
    background-position: left bottom !important;
  }
  .bg-position-lg-right-top {
    background-position: right top !important;
  }
  .bg-position-lg-right-center {
    background-position: right center !important;
  }
  .bg-position-lg-right-bottom {
    background-position: right bottom !important;
  }
  .bg-position-lg-center-top {
    background-position: center top !important;
  }
  .bg-position-lg-center-center {
    background-position: center center !important;
  }
  .bg-position-lg-center-bottom {
    background-position: center bottom !important;
  }
  .bg-position-lg-initial {
    background-position: initial !important;
  }
  .bg-position-lg-inherit {
    background-position: inherit !important;
  }
  .st-overflow-lg-visible {
    overflow: visible !important;
  }
  .st-overflow-x-lg-visible {
    overflow-x: visible !important;
  }
  .st-overflow-y-lg-visible {
    overflow-y: visible !important;
  }
  .st-overflow-lg-hidden {
    overflow: hidden !important;
  }
  .st-overflow-x-lg-hidden {
    overflow-x: hidden !important;
  }
  .st-overflow-y-lg-hidden {
    overflow-y: hidden !important;
  }
  .st-overflow-lg-scroll {
    overflow: scroll !important;
  }
  .st-overflow-x-lg-scroll {
    overflow-x: scroll !important;
  }
  .st-overflow-y-lg-scroll {
    overflow-y: scroll !important;
  }
  .st-overflow-lg-auto {
    overflow: auto !important;
  }
  .st-overflow-x-lg-auto {
    overflow-x: auto !important;
  }
  .st-overflow-y-lg-auto {
    overflow-y: auto !important;
  }
  .st-overflow-lg-initial {
    overflow: initial !important;
  }
  .st-overflow-x-lg-initial {
    overflow-x: initial !important;
  }
  .st-overflow-y-lg-initial {
    overflow-y: initial !important;
  }
  .st-overflow-lg-inherit {
    overflow: inherit !important;
  }
  .st-overflow-x-lg-inherit {
    overflow-x: inherit !important;
  }
  .st-overflow-y-lg-inherit {
    overflow-y: inherit !important;
  }
  .st-line-height-lg-1 {
    line-height: 1 !important;
  }
  .st-line-height-lg-1-1 {
    line-height: 1.1 !important;
  }
  .st-line-height-lg-1-2 {
    line-height: 1.2 !important;
  }
  .st-line-height-lg-1-3 {
    line-height: 1.3 !important;
  }
  .st-line-height-lg-1-4 {
    line-height: 1.4 !important;
  }
  .st-line-height-lg-1-5 {
    line-height: 1.5 !important;
  }
  .st-line-height-lg-1-6 {
    line-height: 1.6 !important;
  }
  .st-line-height-lg-1-7 {
    line-height: 1.7 !important;
  }
  .st-line-height-lg-1-8 {
    line-height: 1.8 !important;
  }
  .st-line-height-lg-1-9 {
    line-height: 1.9 !important;
  }
  .st-line-height-lg-2 {
    line-height: 2 !important;
  }
  .st-line-height-lg-2-1 {
    line-height: 2.1 !important;
  }
  .st-line-height-lg-2-2 {
    line-height: 2.2 !important;
  }
  .st-line-height-lg-2-3 {
    line-height: 2.3 !important;
  }
  .st-line-height-lg-2-5 {
    line-height: 2.5 !important;
  }
  .st-line-height-lg-3 {
    line-height: 3 !important;
  }
  .st-line-height-lg-4 {
    line-height: 4 !important;
  }
  .st-side-lg-auto {
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 991.98px) {
  .st-font-md-10 {
    font-size: 10px;
    font-size: 0.625rem;
  }
  .st-lineheight-md-10 {
    line-height: 10px;
  }
  .st-font-md-12 {
    font-size: 12px;
    font-size: 0.75rem;
  }
  .st-lineheight-md-12 {
    line-height: 12px;
  }
  .st-font-md-13 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
  .st-lineheight-md-13 {
    line-height: 13px;
  }
  .st-font-md-14 {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .st-lineheight-md-14 {
    line-height: 14px;
  }
  .st-font-md-15 {
    font-size: 15px;
    font-size: 0.9375rem;
  }
  .st-lineheight-md-15 {
    line-height: 15px;
  }
  .st-font-md-16 {
    font-size: 16px;
    font-size: 1rem;
  }
  .st-lineheight-md-16 {
    line-height: 16px;
  }
  .st-font-md-18 {
    font-size: 18px;
    font-size: 1.125rem;
  }
  .st-lineheight-md-18 {
    line-height: 18px;
  }
  .st-font-md-20 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .st-lineheight-md-20 {
    line-height: 20px;
  }
  .st-font-md-22 {
    font-size: 22px;
    font-size: 1.375rem;
  }
  .st-lineheight-md-22 {
    line-height: 22px;
  }
  .st-font-md-24 {
    font-size: 24px;
    font-size: 1.5rem;
  }
  .st-lineheight-md-24 {
    line-height: 24px;
  }
  .st-font-md-25 {
    font-size: 25px;
    font-size: 1.5625rem;
  }
  .st-lineheight-md-25 {
    line-height: 25px;
  }
  .st-font-md-28 {
    font-size: 28px;
    font-size: 1.75rem;
  }
  .st-lineheight-md-28 {
    line-height: 28px;
  }
  .st-font-md-30 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.7;
  }
  .st-lineheight-md-30 {
    line-height: 30px;
  }
  .st-font-md-34 {
    font-size: 34px;
    font-size: 2.125rem;
    line-height: 1.6;
  }
  .st-lineheight-md-34 {
    line-height: 34px;
  }
  .st-font-md-38 {
    font-size: 38px;
    font-size: 2.375rem;
    line-height: 1.5;
  }
  .st-lineheight-md-38 {
    line-height: 38px;
  }
  .st-font-md-40 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.4;
  }
  .st-lineheight-md-40 {
    line-height: 40px;
  }
  .st-font-md-44 {
    font-size: 44px;
    font-size: 2.75rem;
    line-height: 1.2;
  }
  .st-lineheight-md-44 {
    line-height: 44px;
  }
  .st-font-md-48 {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1;
  }
  .st-lineheight-md-48 {
    line-height: 48px;
  }
  .st-font-md-52 {
    font-size: 52px;
    font-size: 3.25rem;
    line-height: 1;
  }
  .st-lineheight-md-52 {
    line-height: 52px;
  }
  .st-font-md-55 {
    font-size: 55px;
    font-size: 3.4375rem;
    line-height: 1;
  }
  .st-lineheight-md-55 {
    line-height: 55px;
  }
  .st-font-md-60 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1;
  }
  .st-lineheight-md-60 {
    line-height: 60px;
  }
  .st-font-md-78 {
    font-size: 78px;
    font-size: 4.875rem;
    line-height: 0.9;
  }
  .st-lineheight-md-78 {
    line-height: 78px;
  }
  .st-text-md-center {
    text-align: center !important;
  }
  .st-text-md-right {
    text-align: right !important;
  }
  .st-text-md-left {
    text-align: left !important;
  }
  .st-min-md-1150 {
    min-height: 1150px !important;
  }
  .st-min-md-1000 {
    min-height: 1000px !important;
  }
  .st-min-md-900 {
    min-height: 900px !important;
  }
  .st-min-md-850 {
    min-height: 850px !important;
  }
  .st-min-md-650 {
    min-height: 650px !important;
  }
  .st-min-md-auto {
    min-height: auto !important;
    height: auto !important;
  }
  .st-height-md-inherit {
    height: auto !important;
    min-height: inherit !important;
    max-height: inherit !important;
  }
  .st-d-md-none {
    display: none !important;
  }
  .st-d-md-block {
    display: block !important;
  }
  .st-width-md-10 {
    width: 10% !important;
  }
  .st-width-md-20 {
    width: 20% !important;
  }
  .st-width-md-25 {
    width: 25% !important;
  }
  .st-width-md-30 {
    width: 30% !important;
  }
  .st-width-md-35 {
    width: 35% !important;
  }
  .st-width-md-40 {
    width: 40% !important;
  }
  .st-width-md-45 {
    width: 45% !important;
  }
  .st-width-md-50 {
    width: 50% !important;
  }
  .st-width-md-55 {
    width: 55% !important;
  }
  .st-width-md-60 {
    width: 60% !important;
  }
  .st-width-md-70 {
    width: 70% !important;
  }
  .st-width-md-75 {
    width: 75% !important;
  }
  .st-width-md-80 {
    width: 80% !important;
  }
  .st-width-md-85 {
    width: 85% !important;
  }
  .st-width-md-90 {
    width: 90% !important;
  }
  .st-width-md-95 {
    width: 95% !important;
  }
  .st-width-md-100 {
    width: 100% !important;
  }
  .st-align-items-md-start {
    -ms-flex-align: start !important;
    align-items: start !important;
  }
  .st-justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: start !important;
  }
  .st-align-items-md-end {
    -ms-flex-align: end !important;
    align-items: end !important;
  }
  .st-justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: end !important;
  }
  .st-align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .st-justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .bg-size-md-0 {
    background-size: 100% 100% !important;
  }
  .bg-size-md-100-auto {
    background-size: 100% auto !important;
  }
  .bg-size-md-auto-100 {
    background-size: auto 100% !important;
  }
  .bg-size-md-auto {
    background-size: auto !important;
  }
  .bg-size-md-cover {
    background-size: cover !important;
  }
  .bg-size-md-contain {
    background-size: contain !important;
  }
  .bg-size-md-initial {
    background-size: initial !important;
  }
  .bg-size-md-inherit {
    background-size: inherit !important;
  }
  .bg-attachment-md-scroll {
    background-attachment: scroll !important;
  }
  .bg-attachment-md-fixed {
    background-attachment: fixed !important;
  }
  .bg-attachment-md-local {
    background-attachment: local !important;
  }
  .bg-attachment-md-initial {
    background-attachment: initial !important;
  }
  .bg-attachment-md-inherit {
    background-attachment: inherit !important;
  }
  .bg-repeat-md-repeat {
    background-repeat: repeat !important;
  }
  .bg-repeat-md-repeat-x {
    background-repeat: repeat-x !important;
  }
  .bg-repeat-md-repeat-y {
    background-repeat: repeat-y !important;
  }
  .bg-repeat-md-no-repeat {
    background-repeat: no-repeat !important;
  }
  .bg-repeat-md-initial {
    background-repeat: initial !important;
  }
  .bg-repeat-md-inherit {
    background-repeat: inherit !important;
  }
  .bg-position-md-left-top {
    background-position: left top !important;
  }
  .bg-position-md-left-center {
    background-position: left center !important;
  }
  .bg-position-md-left-bottom {
    background-position: left bottom !important;
  }
  .bg-position-md-right-top {
    background-position: right top !important;
  }
  .bg-position-md-right-center {
    background-position: right center !important;
  }
  .bg-position-md-right-bottom {
    background-position: right bottom !important;
  }
  .bg-position-md-center-top {
    background-position: center top !important;
  }
  .bg-position-md-center-center {
    background-position: center center !important;
  }
  .bg-position-md-center-bottom {
    background-position: center bottom !important;
  }
  .bg-position-md-initial {
    background-position: initial !important;
  }
  .bg-position-md-inherit {
    background-position: inherit !important;
  }
  .st-overflow-md-visible {
    overflow: visible !important;
  }
  .st-overflow-x-md-visible {
    overflow-x: visible !important;
  }
  .st-overflow-y-md-visible {
    overflow-y: visible !important;
  }
  .st-overflow-md-hidden {
    overflow: hidden !important;
  }
  .st-overflow-x-md-hidden {
    overflow-x: hidden !important;
  }
  .st-overflow-y-md-hidden {
    overflow-y: hidden !important;
  }
  .st-overflow-md-scroll {
    overflow: scroll !important;
  }
  .st-overflow-x-md-scroll {
    overflow-x: scroll !important;
  }
  .st-overflow-y-md-scroll {
    overflow-y: scroll !important;
  }
  .st-overflow-md-auto {
    overflow: auto !important;
  }
  .st-overflow-x-md-auto {
    overflow-x: auto !important;
  }
  .st-overflow-y-md-auto {
    overflow-y: auto !important;
  }
  .st-overflow-md-initial {
    overflow: initial !important;
  }
  .st-overflow-x-md-initial {
    overflow-x: initial !important;
  }
  .st-overflow-y-md-initial {
    overflow-y: initial !important;
  }
  .st-overflow-md-inherit {
    overflow: inherit !important;
  }
  .st-overflow-x-md-inherit {
    overflow-x: inherit !important;
  }
  .st-overflow-y-md-inherit {
    overflow-y: inherit !important;
  }
  .st-line-height-md-1 {
    line-height: 1 !important;
  }
  .st-line-height-md-1-1 {
    line-height: 1.1 !important;
  }
  .st-line-height-md-1-2 {
    line-height: 1.2 !important;
  }
  .st-line-height-md-1-3 {
    line-height: 1.3 !important;
  }
  .st-line-height-md-1-4 {
    line-height: 1.4 !important;
  }
  .st-line-height-md-1-5 {
    line-height: 1.5 !important;
  }
  .st-line-height-md-1-6 {
    line-height: 1.6 !important;
  }
  .st-line-height-md-1-7 {
    line-height: 1.7 !important;
  }
  .st-line-height-md-1-8 {
    line-height: 1.8 !important;
  }
  .st-line-height-md-1-9 {
    line-height: 1.9 !important;
  }
  .st-line-height-md-2 {
    line-height: 2 !important;
  }
  .st-line-height-md-2-1 {
    line-height: 2.1 !important;
  }
  .st-line-height-md-2-2 {
    line-height: 2.2 !important;
  }
  .st-line-height-md-2-3 {
    line-height: 2.3 !important;
  }
  .st-line-height-md-2-5 {
    line-height: 2.5 !important;
  }
  .st-line-height-md-3 {
    line-height: 3 !important;
  }
  .st-line-height-md-4 {
    line-height: 4 !important;
  }
  .st-side-md-auto {
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 767.98px) {
  .st-font-sm-10 {
    font-size: 10px;
    font-size: 0.625rem;
  }
  .st-lineheight-sm-10 {
    line-height: 10px;
  }
  .st-font-sm-12 {
    font-size: 12px;
    font-size: 0.75rem;
  }
  .st-lineheight-sm-12 {
    line-height: 12px;
  }
  .st-font-sm-13 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
  .st-lineheight-sm-13 {
    line-height: 13px;
  }
  .st-font-sm-14 {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .st-lineheight-sm-14 {
    line-height: 14px;
  }
  .st-font-sm-15 {
    font-size: 15px;
    font-size: 0.9375rem;
  }
  .st-lineheight-sm-15 {
    line-height: 15px;
  }
  .st-font-sm-16 {
    font-size: 16px;
    font-size: 1rem;
  }
  .st-lineheight-sm-16 {
    line-height: 16px;
  }
  .st-font-sm-18 {
    font-size: 18px;
    font-size: 1.125rem;
  }
  .st-lineheight-sm-18 {
    line-height: 18px;
  }
  .st-font-sm-20 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .st-lineheight-sm-20 {
    line-height: 20px;
  }
  .st-font-sm-22 {
    font-size: 22px;
    font-size: 1.375rem;
  }
  .st-lineheight-sm-22 {
    line-height: 22px;
  }
  .st-font-sm-24 {
    font-size: 24px;
    font-size: 1.5rem;
  }
  .st-lineheight-sm-24 {
    line-height: 24px;
  }
  .st-font-sm-25 {
    font-size: 25px;
    font-size: 1.5625rem;
  }
  .st-lineheight-sm-25 {
    line-height: 25px;
  }
  .st-font-sm-28 {
    font-size: 28px;
    font-size: 1.75rem;
  }
  .st-lineheight-sm-28 {
    line-height: 28px;
  }
  .st-font-sm-30 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.7;
  }
  .st-lineheight-sm-30 {
    line-height: 30px;
  }
  .st-font-sm-34 {
    font-size: 34px;
    font-size: 2.125rem;
    line-height: 1.6;
  }
  .st-lineheight-sm-34 {
    line-height: 34px;
  }
  .st-font-sm-38 {
    font-size: 38px;
    font-size: 2.375rem;
    line-height: 1.5;
  }
  .st-lineheight-sm-38 {
    line-height: 38px;
  }
  .st-font-sm-40 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.4;
  }
  .st-lineheight-sm-40 {
    line-height: 40px;
  }
  .st-font-sm-44 {
    font-size: 44px;
    font-size: 2.75rem;
    line-height: 1.2;
  }
  .st-lineheight-sm-44 {
    line-height: 44px;
  }
  .st-font-sm-48 {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1;
  }
  .st-lineheight-sm-48 {
    line-height: 48px;
  }
  .st-font-sm-52 {
    font-size: 52px;
    font-size: 3.25rem;
    line-height: 1;
  }
  .st-lineheight-sm-52 {
    line-height: 52px;
  }
  .st-font-sm-55 {
    font-size: 55px;
    font-size: 3.4375rem;
    line-height: 1;
  }
  .st-lineheight-sm-55 {
    line-height: 55px;
  }
  .st-font-sm-60 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1;
  }
  .st-lineheight-sm-60 {
    line-height: 60px;
  }
  .st-font-sm-78 {
    font-size: 78px;
    font-size: 4.875rem;
    line-height: 0.9;
  }
  .st-lineheight-sm-78 {
    line-height: 78px;
  }
  .st-text-sm-center {
    text-align: center !important;
  }
  .st-text-sm-right {
    text-align: right !important;
  }
  .st-text-sm-left {
    text-align: left !important;
  }
  .st-min-sm-1150 {
    min-height: 1150px !important;
  }
  .st-min-sm-1000 {
    min-height: 1000px !important;
  }
  .st-min-sm-900 {
    min-height: 900px !important;
  }
  .st-min-sm-850 {
    min-height: 850px !important;
  }
  .st-min-sm-650 {
    min-height: 650px !important;
  }
  .st-min-sm-auto {
    min-height: auto !important;
    height: auto !important;
  }
  .st-height-sm-inherit {
    height: auto !important;
    min-height: inherit !important;
    max-height: inherit !important;
  }
  .st-d-sm-none {
    display: none !important;
  }
  .st-d-sm-block {
    display: block !important;
  }
  .st-width-sm-10 {
    width: 10% !important;
  }
  .st-width-sm-20 {
    width: 20% !important;
  }
  .st-width-sm-25 {
    width: 25% !important;
  }
  .st-width-sm-30 {
    width: 30% !important;
  }
  .st-width-sm-35 {
    width: 35% !important;
  }
  .st-width-sm-40 {
    width: 40% !important;
  }
  .st-width-sm-45 {
    width: 45% !important;
  }
  .st-width-sm-50 {
    width: 50% !important;
  }
  .st-width-sm-55 {
    width: 55% !important;
  }
  .st-width-sm-60 {
    width: 60% !important;
  }
  .st-width-sm-70 {
    width: 70% !important;
  }
  .st-width-sm-75 {
    width: 75% !important;
  }
  .st-width-sm-80 {
    width: 80% !important;
  }
  .st-width-sm-85 {
    width: 85% !important;
  }
  .st-width-sm-90 {
    width: 90% !important;
  }
  .st-width-sm-95 {
    width: 95% !important;
  }
  .st-width-sm-100 {
    width: 100% !important;
  }
  .st-align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: start !important;
  }
  .st-justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: start !important;
  }
  .st-align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: end !important;
  }
  .st-justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: end !important;
  }
  .st-align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .st-justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .bg-size-sm-0 {
    background-size: 100% 100% !important;
  }
  .bg-size-sm-100-auto {
    background-size: 100% auto !important;
  }
  .bg-size-sm-auto-100 {
    background-size: auto 100% !important;
  }
  .bg-size-sm-auto {
    background-size: auto !important;
  }
  .bg-size-sm-cover {
    background-size: cover !important;
  }
  .bg-size-sm-contain {
    background-size: contain !important;
  }
  .bg-size-sm-initial {
    background-size: initial !important;
  }
  .bg-size-sm-inherit {
    background-size: inherit !important;
  }
  .bg-attachment-sm-scroll {
    background-attachment: scroll !important;
  }
  .bg-attachment-sm-fixed {
    background-attachment: fixed !important;
  }
  .bg-attachment-sm-local {
    background-attachment: local !important;
  }
  .bg-attachment-sm-initial {
    background-attachment: initial !important;
  }
  .bg-attachment-sm-inherit {
    background-attachment: inherit !important;
  }
  .bg-repeat-sm-repeat {
    background-repeat: repeat !important;
  }
  .bg-repeat-sm-repeat-x {
    background-repeat: repeat-x !important;
  }
  .bg-repeat-sm-repeat-y {
    background-repeat: repeat-y !important;
  }
  .bg-repeat-sm-no-repeat {
    background-repeat: no-repeat !important;
  }
  .bg-repeat-sm-initial {
    background-repeat: initial !important;
  }
  .bg-repeat-sm-inherit {
    background-repeat: inherit !important;
  }
  .bg-position-sm-left-top {
    background-position: left top !important;
  }
  .bg-position-sm-left-center {
    background-position: left center !important;
  }
  .bg-position-sm-left-bottom {
    background-position: left bottom !important;
  }
  .bg-position-sm-right-top {
    background-position: right top !important;
  }
  .bg-position-sm-right-center {
    background-position: right center !important;
  }
  .bg-position-sm-right-bottom {
    background-position: right bottom !important;
  }
  .bg-position-sm-center-top {
    background-position: center top !important;
  }
  .bg-position-sm-center-center {
    background-position: center center !important;
  }
  .bg-position-sm-center-bottom {
    background-position: center bottom !important;
  }
  .bg-position-sm-initial {
    background-position: initial !important;
  }
  .bg-position-sm-inherit {
    background-position: inherit !important;
  }
  .st-overflow-sm-visible {
    overflow: visible !important;
  }
  .st-overflow-x-sm-visible {
    overflow-x: visible !important;
  }
  .st-overflow-y-sm-visible {
    overflow-y: visible !important;
  }
  .st-overflow-sm-hidden {
    overflow: hidden !important;
  }
  .st-overflow-x-sm-hidden {
    overflow-x: hidden !important;
  }
  .st-overflow-y-sm-hidden {
    overflow-y: hidden !important;
  }
  .st-overflow-sm-scroll {
    overflow: scroll !important;
  }
  .st-overflow-x-sm-scroll {
    overflow-x: scroll !important;
  }
  .st-overflow-y-sm-scroll {
    overflow-y: scroll !important;
  }
  .st-overflow-sm-auto {
    overflow: auto !important;
  }
  .st-overflow-x-sm-auto {
    overflow-x: auto !important;
  }
  .st-overflow-y-sm-auto {
    overflow-y: auto !important;
  }
  .st-overflow-sm-initial {
    overflow: initial !important;
  }
  .st-overflow-x-sm-initial {
    overflow-x: initial !important;
  }
  .st-overflow-y-sm-initial {
    overflow-y: initial !important;
  }
  .st-overflow-sm-inherit {
    overflow: inherit !important;
  }
  .st-overflow-x-sm-inherit {
    overflow-x: inherit !important;
  }
  .st-overflow-y-sm-inherit {
    overflow-y: inherit !important;
  }
  .st-line-height-sm-1 {
    line-height: 1 !important;
  }
  .st-line-height-sm-1-1 {
    line-height: 1.1 !important;
  }
  .st-line-height-sm-1-2 {
    line-height: 1.2 !important;
  }
  .st-line-height-sm-1-3 {
    line-height: 1.3 !important;
  }
  .st-line-height-sm-1-4 {
    line-height: 1.4 !important;
  }
  .st-line-height-sm-1-5 {
    line-height: 1.5 !important;
  }
  .st-line-height-sm-1-6 {
    line-height: 1.6 !important;
  }
  .st-line-height-sm-1-7 {
    line-height: 1.7 !important;
  }
  .st-line-height-sm-1-8 {
    line-height: 1.8 !important;
  }
  .st-line-height-sm-1-9 {
    line-height: 1.9 !important;
  }
  .st-line-height-sm-2 {
    line-height: 2 !important;
  }
  .st-line-height-sm-2-1 {
    line-height: 2.1 !important;
  }
  .st-line-height-sm-2-2 {
    line-height: 2.2 !important;
  }
  .st-line-height-sm-2-3 {
    line-height: 2.3 !important;
  }
  .st-line-height-sm-2-5 {
    line-height: 2.5 !important;
  }
  .st-line-height-sm-3 {
    line-height: 3 !important;
  }
  .st-line-height-sm-4 {
    line-height: 4 !important;
  }
  .st-side-sm-auto {
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 575.98px) {
  .st-font-xs-10 {
    font-size: 10px;
    font-size: 0.625rem;
  }
  .st-lineheight-xs-10 {
    line-height: 10px;
  }
  .st-font-xs-12 {
    font-size: 12px;
    font-size: 0.75rem;
  }
  .st-lineheight-xs-12 {
    line-height: 12px;
  }
  .st-font-xs-13 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
  .st-lineheight-xs-13 {
    line-height: 13px;
  }
  .st-font-xs-14 {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .st-lineheight-xs-14 {
    line-height: 14px;
  }
  .st-font-xs-15 {
    font-size: 15px;
    font-size: 0.9375rem;
  }
  .st-lineheight-xs-15 {
    line-height: 15px;
  }
  .st-font-xs-16 {
    font-size: 16px;
    font-size: 1rem;
  }
  .st-lineheight-xs-16 {
    line-height: 16px;
  }
  .st-font-xs-18 {
    font-size: 18px;
    font-size: 1.125rem;
  }
  .st-lineheight-xs-18 {
    line-height: 18px;
  }
  .st-font-xs-20 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .st-lineheight-xs-20 {
    line-height: 20px;
  }
  .st-font-xs-22 {
    font-size: 22px;
    font-size: 1.375rem;
  }
  .st-lineheight-xs-22 {
    line-height: 22px;
  }
  .st-font-xs-24 {
    font-size: 24px;
    font-size: 1.5rem;
  }
  .st-lineheight-xs-24 {
    line-height: 24px;
  }
  .st-font-xs-25 {
    font-size: 25px;
    font-size: 1.5625rem;
  }
  .st-lineheight-xs-25 {
    line-height: 25px;
  }
  .st-font-xs-28 {
    font-size: 28px;
    font-size: 1.75rem;
  }
  .st-lineheight-xs-28 {
    line-height: 28px;
  }
  .st-font-xs-30 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.7;
  }
  .st-lineheight-xs-30 {
    line-height: 30px;
  }
  .st-font-xs-34 {
    font-size: 34px;
    font-size: 2.125rem;
    line-height: 1.6;
  }
  .st-lineheight-xs-34 {
    line-height: 34px;
  }
  .st-font-xs-38 {
    font-size: 38px;
    font-size: 2.375rem;
    line-height: 1.5;
  }
  .st-lineheight-xs-38 {
    line-height: 38px;
  }
  .st-font-xs-40 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.4;
  }
  .st-lineheight-xs-40 {
    line-height: 40px;
  }
  .st-font-xs-44 {
    font-size: 44px;
    font-size: 2.75rem;
    line-height: 1.2;
  }
  .st-lineheight-xs-44 {
    line-height: 44px;
  }
  .st-font-xs-48 {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1;
  }
  .st-lineheight-xs-48 {
    line-height: 48px;
  }
  .st-font-xs-52 {
    font-size: 52px;
    font-size: 3.25rem;
    line-height: 1;
  }
  .st-lineheight-xs-52 {
    line-height: 52px;
  }
  .st-font-xs-55 {
    font-size: 55px;
    font-size: 3.4375rem;
    line-height: 1;
  }
  .st-lineheight-xs-55 {
    line-height: 55px;
  }
  .st-font-xs-60 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1;
  }
  .st-lineheight-xs-60 {
    line-height: 60px;
  }
  .st-font-xs-78 {
    font-size: 78px;
    font-size: 4.875rem;
    line-height: 0.9;
  }
  .st-lineheight-xs-78 {
    line-height: 78px;
  }
  .st-text-xs-center {
    text-align: center !important;
  }
  .st-text-xs-right {
    text-align: right !important;
  }
  .st-text-xs-left {
    text-align: left !important;
  }
  .st-min-xs-1150 {
    min-height: 1150px !important;
  }
  .st-min-xs-1000 {
    min-height: 1000px !important;
  }
  .st-min-xs-900 {
    min-height: 900px !important;
  }
  .st-min-xs-850 {
    min-height: 850px !important;
  }
  .st-min-xs-650 {
    min-height: 650px !important;
  }
  .st-min-xs-auto {
    min-height: auto !important;
    height: auto !important;
  }
  .st-height-xs-inherit {
    height: auto !important;
    min-height: inherit !important;
    max-height: inherit !important;
  }
  .st-d-xs-none {
    display: none !important;
  }
  .st-d-xs-block {
    display: block !important;
  }
  .st-width-xs-10 {
    width: 10% !important;
  }
  .st-width-xs-20 {
    width: 20% !important;
  }
  .st-width-xs-25 {
    width: 25% !important;
  }
  .st-width-xs-30 {
    width: 30% !important;
  }
  .st-width-xs-35 {
    width: 35% !important;
  }
  .st-width-xs-40 {
    width: 40% !important;
  }
  .st-width-xs-45 {
    width: 45% !important;
  }
  .st-width-xs-50 {
    width: 50% !important;
  }
  .st-width-xs-55 {
    width: 55% !important;
  }
  .st-width-xs-60 {
    width: 60% !important;
  }
  .st-width-xs-70 {
    width: 70% !important;
  }
  .st-width-xs-75 {
    width: 75% !important;
  }
  .st-width-xs-80 {
    width: 80% !important;
  }
  .st-width-xs-85 {
    width: 85% !important;
  }
  .st-width-xs-90 {
    width: 90% !important;
  }
  .st-width-xs-95 {
    width: 95% !important;
  }
  .st-width-xs-100 {
    width: 100% !important;
  }
  .st-align-items-xs-start {
    -ms-flex-align: start !important;
    align-items: start !important;
  }
  .st-justify-content-xs-start {
    -ms-flex-pack: start !important;
    justify-content: start !important;
  }
  .st-align-items-xs-end {
    -ms-flex-align: end !important;
    align-items: end !important;
  }
  .st-justify-content-xs-end {
    -ms-flex-pack: end !important;
    justify-content: end !important;
  }
  .st-align-items-xs-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .st-justify-content-xs-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .bg-size-xs-0 {
    background-size: 100% 100% !important;
  }
  .bg-size-xs-100-auto {
    background-size: 100% auto !important;
  }
  .bg-size-xs-auto-100 {
    background-size: auto 100% !important;
  }
  .bg-size-xs-auto {
    background-size: auto !important;
  }
  .bg-size-xs-cover {
    background-size: cover !important;
  }
  .bg-size-xs-contain {
    background-size: contain !important;
  }
  .bg-size-xs-initial {
    background-size: initial !important;
  }
  .bg-size-xs-inherit {
    background-size: inherit !important;
  }
  .bg-attachment-xs-scroll {
    background-attachment: scroll !important;
  }
  .bg-attachment-xs-fixed {
    background-attachment: fixed !important;
  }
  .bg-attachment-xs-local {
    background-attachment: local !important;
  }
  .bg-attachment-xs-initial {
    background-attachment: initial !important;
  }
  .bg-attachment-xs-inherit {
    background-attachment: inherit !important;
  }
  .bg-repeat-xs-repeat {
    background-repeat: repeat !important;
  }
  .bg-repeat-xs-repeat-x {
    background-repeat: repeat-x !important;
  }
  .bg-repeat-xs-repeat-y {
    background-repeat: repeat-y !important;
  }
  .bg-repeat-xs-no-repeat {
    background-repeat: no-repeat !important;
  }
  .bg-repeat-xs-initial {
    background-repeat: initial !important;
  }
  .bg-repeat-xs-inherit {
    background-repeat: inherit !important;
  }
  .bg-position-xs-left-top {
    background-position: left top !important;
  }
  .bg-position-xs-left-center {
    background-position: left center !important;
  }
  .bg-position-xs-left-bottom {
    background-position: left bottom !important;
  }
  .bg-position-xs-right-top {
    background-position: right top !important;
  }
  .bg-position-xs-right-center {
    background-position: right center !important;
  }
  .bg-position-xs-right-bottom {
    background-position: right bottom !important;
  }
  .bg-position-xs-center-top {
    background-position: center top !important;
  }
  .bg-position-xs-center-center {
    background-position: center center !important;
  }
  .bg-position-xs-center-bottom {
    background-position: center bottom !important;
  }
  .bg-position-xs-initial {
    background-position: initial !important;
  }
  .bg-position-xs-inherit {
    background-position: inherit !important;
  }
  .st-overflow-xs-visible {
    overflow: visible !important;
  }
  .st-overflow-x-xs-visible {
    overflow-x: visible !important;
  }
  .st-overflow-y-xs-visible {
    overflow-y: visible !important;
  }
  .st-overflow-xs-hidden {
    overflow: hidden !important;
  }
  .st-overflow-x-xs-hidden {
    overflow-x: hidden !important;
  }
  .st-overflow-y-xs-hidden {
    overflow-y: hidden !important;
  }
  .st-overflow-xs-scroll {
    overflow: scroll !important;
  }
  .st-overflow-x-xs-scroll {
    overflow-x: scroll !important;
  }
  .st-overflow-y-xs-scroll {
    overflow-y: scroll !important;
  }
  .st-overflow-xs-auto {
    overflow: auto !important;
  }
  .st-overflow-x-xs-auto {
    overflow-x: auto !important;
  }
  .st-overflow-y-xs-auto {
    overflow-y: auto !important;
  }
  .st-overflow-xs-initial {
    overflow: initial !important;
  }
  .st-overflow-x-xs-initial {
    overflow-x: initial !important;
  }
  .st-overflow-y-xs-initial {
    overflow-y: initial !important;
  }
  .st-overflow-xs-inherit {
    overflow: inherit !important;
  }
  .st-overflow-x-xs-inherit {
    overflow-x: inherit !important;
  }
  .st-overflow-y-xs-inherit {
    overflow-y: inherit !important;
  }
  .st-line-height-xs-1 {
    line-height: 1 !important;
  }
  .st-line-height-xs-1-1 {
    line-height: 1.1 !important;
  }
  .st-line-height-xs-1-2 {
    line-height: 1.2 !important;
  }
  .st-line-height-xs-1-3 {
    line-height: 1.3 !important;
  }
  .st-line-height-xs-1-4 {
    line-height: 1.4 !important;
  }
  .st-line-height-xs-1-5 {
    line-height: 1.5 !important;
  }
  .st-line-height-xs-1-6 {
    line-height: 1.6 !important;
  }
  .st-line-height-xs-1-7 {
    line-height: 1.7 !important;
  }
  .st-line-height-xs-1-8 {
    line-height: 1.8 !important;
  }
  .st-line-height-xs-1-9 {
    line-height: 1.9 !important;
  }
  .st-line-height-xs-2 {
    line-height: 2 !important;
  }
  .st-line-height-xs-2-1 {
    line-height: 2.1 !important;
  }
  .st-line-height-xs-2-2 {
    line-height: 2.2 !important;
  }
  .st-line-height-xs-2-3 {
    line-height: 2.3 !important;
  }
  .st-line-height-xs-2-5 {
    line-height: 2.5 !important;
  }
  .st-line-height-xs-3 {
    line-height: 3 !important;
  }
  .st-line-height-xs-4 {
    line-height: 4 !important;
  }
  .st-side-xs-auto {
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .tilt-wrapper {
    -webkit-transform: translate3d(0, 0, 0);
  }
}

.st-icon-gradient.gigas-icon-gradient {
  background: #5040a0;
  background: -moz-linear-gradient(-45deg, #5040a0 0%, #d2caf9 100%);
  background: -webkit-linear-gradient(-45deg, #5040a0 0%, #d2caf9 100%);
  background: linear-gradient(135deg, #5040a0 0%, #d2caf9 100%);
  background: linear-gradient(135deg, #5040a0 0%, #d2caf9 51%, #5040a0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5040a0', endColorstr='#d2caf9',GradientType=0 );
  background-size: 230% auto;
}

.st-icon-gradient.orange-icon-gradient {
  background: #ff5d1c;
  background: -moz-linear-gradient(318deg, #ff5d1c 0%, #fe9545 100%);
  background: -webkit-linear-gradient(318deg, #ff5d1c 0%, #fe9545 100%);
  background: linear-gradient(318deg, #ff5d1c 0%, #fe9545 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5d1c', endColorstr='#fe9545',GradientType=1 );
}

.line-gradient {
  background: #5040a0;
  background: -moz-linear-gradient(left, #ede9ff 0%, #5040a0 50%, white 100%);
  background: -webkit-linear-gradient(left, #ede9ff 0%, #5040a0 50%, white 100%);
  background: linear-gradient(to right, #ede9ff 0%, #5040a0 50%, white 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ede9ff',GradientType=1 );
}

.aqua-line-gradient {
  background: #00e3f6;
  background: -moz-linear-gradient(left, #ede9ff 0%, rgba(0, 227, 246, 0.7) 50%, rgba(255, 255, 255, 0.4) 100%);
  background: -webkit-linear-gradient(left, #ede9ff 0%, rgba(0, 227, 246, 0.7) 50%, rgba(255, 255, 255, 0.4) 100%);
  background: linear-gradient(to right, #ede9ff 0%, rgba(0, 227, 246, 0.7) 50%, rgba(255, 255, 255, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ede9ff',GradientType=1 );
}

.bg-gigas-gradient, .st_banner_area.st_banner_radius_gradient:before {
  background: #8066dc;
  background: -moz-linear-gradient(top, #8066dc 0%, #5040a0 100%);
  background: -webkit-linear-gradient(top, #8066dc 0%, #5040a0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8066dc', endColorstr='#5040a0',GradientType=0 );
}

.bg-gigas-gradient-btn,
.st_default_btn.bg-gigas-gradient-btn, header.sticky-menu.sticky-gigas-gradient {
  background: -moz-linear-gradient(left, #5040a0 0%, #8066dc 100%);
  background: -webkit-linear-gradient(left, #5040a0 0%, #8066dc 100%);
  background: linear-gradient(to right, #5040a0 0%, #8066dc 51%, #5040a0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5040a0', endColorstr='#8066dc',GradientType=1 );
  background-size: 200% auto;
}

.bg-emerald-gradient,
.st_default_btn.bg-emerald-gradient, .st_default_btn.bg-emerald-gredient {
  background: #38d16a;
  background: -moz-linear-gradient(-45deg, #38d16a 0%, #40d1a7 100%);
  background: -webkit-linear-gradient(-45deg, #38d16a 0%, #40d1a7 100%);
  background: linear-gradient(135deg, #38d16a 0%, #40d1a7 100%);
  background: linear-gradient(to right, #38d16a 0%, #40d1a7 51%, #38d16a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#38d16a', endColorstr='#40d1a7',GradientType=1 );
  background-size: 200% auto;
}

.aqua-gredient-color, .st_banner_aqua_gradient:before, .st_banner_area.st_banner_aqua_gradient:before {
  background: #1e3bdd;
  background: -moz-linear-gradient(top, #1e3bdd 0%, #01e0f6 100%);
  background: -webkit-linear-gradient(top, #1e3bdd 0%, #01e0f6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e3bdd', endColorstr='#01e0f6',GradientType=0 );
}

.aqua-gredient-background, .st_default_btn.aqua-gredient-background, .aqua-gredient-bg-2, header.sticky-menu.sticky-aqua-gradient, .st_accordion_item .card {
  background: #1c48de;
  background: -moz-linear-gradient(left, #1c48de 0%, #00e3f6 100%);
  background: -webkit-linear-gradient(left, #1c48de 0%, #00e3f6 100%);
  background: linear-gradient(to right, #1c48de 0%, #00e3f6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1c48de', endColorstr='#00e3f6',GradientType=1 );
}

.st_custom-tab {
  background: #1e3bdd;
  background: -moz-linear-gradient(-45deg, #1e3bdd 0%, #01e0f6 100%);
  background: -webkit-linear-gradient(-45deg, #1e3bdd 0%, #01e0f6 100%);
  background: linear-gradient(135deg, #1e3bdd 0%, #01e0f6 100%);
  background: linear-gradient(to right, #1e3bdd 0%, #01e0f6 51%, #1e3bdd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e3bdd', endColorstr='#01e0f6',GradientType=1 );
}

.polar-gradient-background {
  background: #FFFFFF;
  background: -moz-linear-gradient(top, #FFFFFF 0%, #F5F9FD 50%, #e5f2ff 100%);
  background: -webkit-linear-gradient(top, #FFFFFF 0%, #F5F9FD 50%, #e5f2ff 100%);
  background: linear-gradient(to bottom, #FFFFFF 0%, #F5F9FD 50%, #e5f2ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-white', endColorstr='#e5f2ff',GradientType=0 );
}

header.sticky-menu.sticky-orange-gradient {
  background: #FF5D1C;
  background: -moz-linear-gradient(left, #FF5D1C 0%, #fe9545 100%);
  background: -webkit-linear-gradient(left, #FF5D1C 0%, #fe9545 100%);
  background: linear-gradient(to right, #FF5D1C 0%, #fe9545 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1c48de', endColorstr='#00e3f6',GradientType=1 );
}

.orange-gradient-banner, .st_video_bg.orange-gradient-banner {
  background: #FF5D1C;
  background: -moz-linear-gradient(top, #FF5D1C 0%, #fe9545 100%);
  background: -webkit-linear-gradient(top, #FF5D1C 0%, #fe9545 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-orange', endColorstr='#fe9545',GradientType=0 );
}

.st_screenshot_area .st_screenshot_warap.st_screenshot_warap_v3 .st_screenshot_tab .nav-pills > li a.active {
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0%, #fff8f8 50%, #fff1f1 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #fff8f8 50%, #fff1f1 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #fff8f8 50%, #fff1f1 100%);
}

.orange-deep-gradient {
  background: #FE9545;
  background: -moz-linear-gradient(left, #FE9545 0%, #FF5D1C 100%);
  background: -webkit-linear-gradient(left, #FE9545 0%, #FF5D1C 100%);
  background: linear-gradient(to right, #FE9545 0%, #FF5D1C 51%, #FE9545 100%);
  background-size: 200% auto;
}

.wave-curved-bg {
  background: #F5F9FD;
  background: -moz-linear-gradient(top, #F5F9FD 0%, #FFFFFF 100%);
  background: -webkit-linear-gradient(top, #F5F9FD 0%, #FFFFFF 100%);
}

.wave-curved-bg {
  margin-top: 200px;
  padding-top: 100px;
  position: relative;
}

.wave-curved-bg::before {
  width: 100%;
  height: 420px;
  position: absolute;
  left: 0;
  top: -420px;
  content: "";
  background-image: url(../images/v14/about-shape.png);
  background-position: top center;
  z-index: -1;
}

@media only screen and (max-width: 991.98px) {
  .wave-curved-bg {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767.98px) {
  .wave-curved-bg {
    padding-top: 50px;
  }
}

.aqua-price-gradient {
  background-image: -webkit-linear-gradient(to right, #1c48de, #00e3f6);
  background-image: -moz-linear-gradient(to right, #1c48de, #00e3f6);
  background-image: linear-gradient(to right, #1c48de, #00e3f6);
}

.st_default_btn.bg-emerald-gradient:hover,
.st_default_btn.bg-emerald-gradient:focus,
.st_default_btn.bg-emerald-gradient:active,
.st_default_btn.bg-gigas-gradient-btn:hover,
.st_default_btn.bg-gigas-gradient-btn:focus,
.st_default_btn.bg-gigas-gradient-btn:active {
  background-position: right center;
  color: #FFFFFF;
}

.st-s-gredient-bg {
  padding: 290px 0 150px;
  background: #FFFFFF;
  background: -moz-linear-gradient(top, #FFFFFF 0%, #eceeff 50%, #e8eaff 100%);
  background: -webkit-linear-gradient(top, #FFFFFF 0%, #eceeff 50%, #e8eaff 100%);
  background: linear-gradient(to bottom, #FFFFFF 0%, #eceeff 50%, #e8eaff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-white', endColorstr='#e8eaff',GradientType=0 );
  -webkit-mask-image: url(../images/footer-bg.png);
  mask-image: url(../images/footer-bg.png);
  mask-repeat: no-repeat;
  mask-size: 100% auto;
  mask-position: top center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 100% auto;
  -webkit-mask-position: top center;
}

@media only screen and (max-width: 1199.98px) {
  .st-s-gredient-bg {
    padding: 250px 0 110px;
    mask-size: 100% 100%;
    -webkit-mask-size: 100% 100%;
  }
  .st-s-gredient-bg .align-items-end {
    align-items: flex-start !important;
  }
}

@media only screen and (max-width: 767.98px) {
  .st-s-gredient-bg {
    padding: 110px 0 60px;
    -webkit-mask-image: none;
    mask-image: none;
  }
}

header.sticky-menu.sticky-ash-gradient {
  background: #F8FAFF;
  background: -moz-linear-gradient(left, #F8FAFF 0%, #F8FAFF 100%);
  background: -webkit-linear-gradient(left, #F8FAFF 0%, #F8FAFF 100%);
  background: linear-gradient(to right, #F8FAFF 0%, #F8FAFF 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-zircon', endColorstr='$color-zircon',GradientType=1 );
}

.black-alpha-bg {
  background: rgba(0, 0, 0, 0.37);
}

header.sticky-menu.sticky-black-alpha-bg {
  background: rgba(0, 0, 0, 0.37);
}

header.sticky-menu.white-alpha-bg {
  background: rgba(255, 255, 255, 0.37);
}

header.sticky-menu.sticky-white-alpha-bg {
  background: rgba(255, 255, 255, 0.37);
}

.bg-gigas {
  background-color: #5040A0 !important;
}

.rgba-gigas {
  background-color: rgba(80, 64, 160, 0.1) !important;
}

.text-gigas {
  color: #5040A0 !important;
}

.bg-perison-blue {
  background-color: #1E43DD !important;
}

.rgba-perison-blue {
  background-color: rgba(30, 67, 221, 0.1) !important;
}

.text-perison-blue {
  color: #1E43DD !important;
}

.bg-st-aqua {
  background-color: #00E3F6 !important;
}

.rgba-st-aqua {
  background-color: rgba(0, 227, 246, 0.1) !important;
}

.text-st-aqua {
  color: #00E3F6 !important;
}

.bg-shamrock {
  background-color: #3AD984 !important;
}

.rgba-shamrock {
  background-color: rgba(58, 217, 132, 0.1) !important;
}

.text-shamrock {
  color: #3AD984 !important;
}

.bg-yellow-orange {
  background-color: #FFAA3B !important;
}

.rgba-yellow-orange {
  background-color: rgba(255, 170, 59, 0.1) !important;
}

.text-yellow-orange {
  color: #FFAA3B !important;
}

.bg-st-pink {
  background-color: #F23F79 !important;
}

.rgba-st-pink {
  background-color: rgba(242, 63, 121, 0.1) !important;
}

.text-st-pink {
  color: #F23F79 !important;
}

.bg-st-orange {
  background-color: #FF5D1C !important;
}

.rgba-st-orange {
  background-color: rgba(255, 93, 28, 0.1) !important;
}

.text-st-orange {
  color: #FF5D1C !important;
}

.bg-science-blue {
  background-color: #0366D6 !important;
}

.rgba-science-blue {
  background-color: rgba(3, 102, 214, 0.1) !important;
}

.text-science-blue {
  color: #0366D6 !important;
}

.bg-emerald {
  background-color: #38D16A !important;
}

.rgba-emerald {
  background-color: rgba(56, 209, 106, 0.1) !important;
}

.text-emerald {
  color: #38D16A !important;
}

.bg-chambray {
  background-color: #3B3E99 !important;
}

.rgba-chambray {
  background-color: rgba(59, 62, 153, 0.1) !important;
}

.text-chambray {
  color: #3B3E99 !important;
}

.bg-medium-purple {
  background-color: #8066DC !important;
}

.rgba-medium-purple {
  background-color: rgba(128, 102, 220, 0.1) !important;
}

.text-medium-purple {
  color: #8066DC !important;
}

.bg-lochmara {
  background-color: #0079BF !important;
}

.rgba-lochmara {
  background-color: rgba(0, 121, 191, 0.1) !important;
}

.text-lochmara {
  color: #0079BF !important;
}

.bg-froly {
  background-color: #F46F6C !important;
}

.rgba-froly {
  background-color: rgba(244, 111, 108, 0.1) !important;
}

.text-froly {
  color: #F46F6C !important;
}

.bg-alpha-froly {
  background-color: rgba(244, 111, 108, 0.1) !important;
}

.rgba-alpha-froly {
  background-color: rgba(244, 111, 108, 0.1) !important;
}

.text-alpha-froly {
  color: rgba(244, 111, 108, 0.1) !important;
}

.bg-sweet-pink {
  background-color: #FFA3A1 !important;
}

.rgba-sweet-pink {
  background-color: rgba(255, 163, 161, 0.1) !important;
}

.text-sweet-pink {
  color: #FFA3A1 !important;
}

.bg-koamaru {
  background-color: #1D1068 !important;
}

.rgba-koamaru {
  background-color: rgba(29, 16, 104, 0.1) !important;
}

.text-koamaru {
  color: #1D1068 !important;
}

.bg-haiti {
  background-color: #140F30 !important;
}

.rgba-haiti {
  background-color: rgba(20, 15, 48, 0.1) !important;
}

.text-haiti {
  color: #140F30 !important;
}

.bg-magnolia {
  background-color: #F9F8FF !important;
}

.rgba-magnolia {
  background-color: rgba(249, 248, 255, 0.1) !important;
}

.text-magnolia {
  color: #F9F8FF !important;
}

.bg-alice-blue {
  background-color: #F5FBFF !important;
}

.rgba-alice-blue {
  background-color: rgba(245, 251, 255, 0.1) !important;
}

.text-alice-blue {
  color: #F5FBFF !important;
}

.bg-polar {
  background-color: #F5F9FD !important;
}

.rgba-polar {
  background-color: rgba(245, 249, 253, 0.1) !important;
}

.text-polar {
  color: #F5F9FD !important;
}

.bg-st-primary {
  background-color: #4A516A !important;
}

.rgba-st-primary {
  background-color: rgba(74, 81, 106, 0.1) !important;
}

.text-st-primary {
  color: #4A516A !important;
}

.bg-st-white {
  background-color: #FFFFFF !important;
}

.rgba-st-white {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.text-st-white {
  color: #FFFFFF !important;
}

.bg-st-black {
  background-color: #000000 !important;
}

.rgba-st-black {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.text-st-black {
  color: #000000 !important;
}

.bg-st-fog {
  background-color: #D6CCFF !important;
}

.rgba-st-fog {
  background-color: rgba(214, 204, 255, 0.1) !important;
}

.text-st-fog {
  color: #D6CCFF !important;
}

.bg-st-gray {
  background-color: #999999 !important;
}

.rgba-st-gray {
  background-color: rgba(153, 153, 153, 0.1) !important;
}

.text-st-gray {
  color: #999999 !important;
}

.bg-st-concrete {
  background-color: #F3F3F3 !important;
}

.rgba-st-concrete {
  background-color: rgba(243, 243, 243, 0.1) !important;
}

.text-st-concrete {
  color: #F3F3F3 !important;
}

.bg-st-violet {
  background-color: #D52EEF !important;
}

.rgba-st-violet {
  background-color: rgba(213, 46, 239, 0.1) !important;
}

.text-st-violet {
  color: #D52EEF !important;
}

.bg-sun-glow {
  background-color: #FCCC2C !important;
}

.rgba-sun-glow {
  background-color: rgba(252, 204, 44, 0.1) !important;
}

.text-sun-glow {
  color: #FCCC2C !important;
}

.bg-st-cabaret {
  background-color: #D1386C !important;
}

.rgba-st-cabaret {
  background-color: rgba(209, 56, 108, 0.1) !important;
}

.text-st-cabaret {
  color: #D1386C !important;
}

.bg-st-zircon {
  background-color: #F8FAFF !important;
}

.rgba-st-zircon {
  background-color: rgba(248, 250, 255, 0.1) !important;
}

.text-st-zircon {
  color: #F8FAFF !important;
}

.bg-clear-day {
  background-color: #F9FFFD !important;
}

.rgba-clear-day {
  background-color: rgba(249, 255, 253, 0.1) !important;
}

.text-clear-day {
  color: #F9FFFD !important;
}

.bg-st-shamrock {
  background-color: #3AD984 !important;
}

.rgba-st-shamrock {
  background-color: rgba(58, 217, 132, 0.1) !important;
}

.text-st-shamrock {
  color: #3AD984 !important;
}

.bg-blue-bell {
  background-color: #9D9ECE !important;
}

.rgba-blue-bell {
  background-color: rgba(157, 158, 206, 0.1) !important;
}

.text-blue-bell {
  color: #9D9ECE !important;
}

.bg-snuff {
  background-color: #DBD7E9 !important;
}

.rgba-snuff {
  background-color: rgba(219, 215, 233, 0.1) !important;
}

.text-snuff {
  color: #DBD7E9 !important;
}

.bg-french-grey {
  background-color: #C3C3C7 !important;
}

.rgba-french-grey {
  background-color: rgba(195, 195, 199, 0.1) !important;
}

.text-french-grey {
  color: #C3C3C7 !important;
}

.bg-ebony-clay {
  background-color: #1F2833 !important;
}

.rgba-ebony-clay {
  background-color: rgba(31, 40, 51, 0.1) !important;
}

.text-ebony-clay {
  color: #1F2833 !important;
}

.bg-black-rock {
  background-color: #080327 !important;
}

.rgba-black-rock {
  background-color: rgba(8, 3, 39, 0.1) !important;
}

.text-black-rock {
  color: #080327 !important;
}

.bg-st-lavender {
  background-color: #B4B5D5 !important;
}

.rgba-st-lavender {
  background-color: rgba(180, 181, 213, 0.1) !important;
}

.text-st-lavender {
  color: #B4B5D5 !important;
}

.bg-titan-white {
  background-color: #F5F5FF !important;
}

.rgba-titan-white {
  background-color: rgba(245, 245, 255, 0.1) !important;
}

.text-titan-white {
  color: #F5F5FF !important;
}

.bg-regent {
  background-color: #8D9BA4 !important;
}

.rgba-regent {
  background-color: rgba(141, 155, 164, 0.1) !important;
}

.text-regent {
  color: #8D9BA4 !important;
}

.bg-shhamrock {
  background-color: #40D1A7 !important;
}

.rgba-shhamrock {
  background-color: rgba(64, 209, 167, 0.1) !important;
}

.text-shhamrock {
  color: #40D1A7 !important;
}

.bg-gray-light {
  background-color: #f0f2f8 !important;
}

.rgba-gray-light {
  background-color: rgba(240, 242, 248, 0.1) !important;
}

.text-gray-light {
  color: #f0f2f8 !important;
}

.bg-gray {
  background-color: #b6beda !important;
}

.rgba-gray {
  background-color: rgba(182, 190, 218, 0.1) !important;
}

.text-gray {
  color: #b6beda !important;
}

.bg-gray-dark {
  background-color: #2f3447 !important;
}

.rgba-gray-dark {
  background-color: rgba(47, 52, 71, 0.1) !important;
}

.text-gray-dark {
  color: #2f3447 !important;
}

.bg-blue-darker {
  background-color: #3f455c !important;
}

.rgba-blue-darker {
  background-color: rgba(63, 69, 92, 0.1) !important;
}

.text-blue-darker {
  color: #3f455c !important;
}

.bg-blue-dark {
  background-color: #4a516a !important;
}

.rgba-blue-dark {
  background-color: rgba(74, 81, 106, 0.1) !important;
}

.text-blue-dark {
  color: #4a516a !important;
}

.bg-blue {
  background-color: #2bbcff !important;
}

.rgba-blue {
  background-color: rgba(43, 188, 255, 0.1) !important;
}

.text-blue {
  color: #2bbcff !important;
}

.bg-green {
  background-color: #2ad9d3 !important;
}

.rgba-green {
  background-color: rgba(42, 217, 211, 0.1) !important;
}

.text-green {
  color: #2ad9d3 !important;
}

.st_default_btn.bg-gigas,
.st_default_btn.st_transparent.bg-gigas,
.feature_item_icon.bg-gigas {
  background-color: #5040A0 !important;
}

.feature_item_icon.bg-gigas {
  box-shadow: 0 10px 30px rgba(80, 64, 160, 0.3);
}

.st_info_box.bg-gigas {
  background-color: #5040A0 !important;
}

.st_info_box.bg-shadow-gigas {
  box-shadow: 0 30px 60px rgba(80, 64, 160, 0.4);
}

.st-mean-gigas.mean-container a.meanmenu-reveal {
  color: #5040A0 !important;
}

.st-mean-gigas.mean-container a.meanmenu-reveal span {
  background-color: #5040A0 !important;
}

.st_default_btn.bg-perison-blue,
.st_default_btn.st_transparent.bg-perison-blue,
.feature_item_icon.bg-perison-blue {
  background-color: #1E43DD !important;
}

.feature_item_icon.bg-perison-blue {
  box-shadow: 0 10px 30px rgba(30, 67, 221, 0.3);
}

.st_info_box.bg-perison-blue {
  background-color: #1E43DD !important;
}

.st_info_box.bg-shadow-perison-blue {
  box-shadow: 0 30px 60px rgba(30, 67, 221, 0.4);
}

.st-mean-perison-blue.mean-container a.meanmenu-reveal {
  color: #1E43DD !important;
}

.st-mean-perison-blue.mean-container a.meanmenu-reveal span {
  background-color: #1E43DD !important;
}

.st_default_btn.bg-st-aqua,
.st_default_btn.st_transparent.bg-st-aqua,
.feature_item_icon.bg-st-aqua {
  background-color: #00E3F6 !important;
}

.feature_item_icon.bg-st-aqua {
  box-shadow: 0 10px 30px rgba(0, 227, 246, 0.3);
}

.st_info_box.bg-st-aqua {
  background-color: #00E3F6 !important;
}

.st_info_box.bg-shadow-st-aqua {
  box-shadow: 0 30px 60px rgba(0, 227, 246, 0.4);
}

.st-mean-st-aqua.mean-container a.meanmenu-reveal {
  color: #00E3F6 !important;
}

.st-mean-st-aqua.mean-container a.meanmenu-reveal span {
  background-color: #00E3F6 !important;
}

.st_default_btn.bg-shamrock,
.st_default_btn.st_transparent.bg-shamrock,
.feature_item_icon.bg-shamrock {
  background-color: #3AD984 !important;
}

.feature_item_icon.bg-shamrock {
  box-shadow: 0 10px 30px rgba(58, 217, 132, 0.3);
}

.st_info_box.bg-shamrock {
  background-color: #3AD984 !important;
}

.st_info_box.bg-shadow-shamrock {
  box-shadow: 0 30px 60px rgba(58, 217, 132, 0.4);
}

.st-mean-shamrock.mean-container a.meanmenu-reveal {
  color: #3AD984 !important;
}

.st-mean-shamrock.mean-container a.meanmenu-reveal span {
  background-color: #3AD984 !important;
}

.st_default_btn.bg-yellow-orange,
.st_default_btn.st_transparent.bg-yellow-orange,
.feature_item_icon.bg-yellow-orange {
  background-color: #FFAA3B !important;
}

.feature_item_icon.bg-yellow-orange {
  box-shadow: 0 10px 30px rgba(255, 170, 59, 0.3);
}

.st_info_box.bg-yellow-orange {
  background-color: #FFAA3B !important;
}

.st_info_box.bg-shadow-yellow-orange {
  box-shadow: 0 30px 60px rgba(255, 170, 59, 0.4);
}

.st-mean-yellow-orange.mean-container a.meanmenu-reveal {
  color: #FFAA3B !important;
}

.st-mean-yellow-orange.mean-container a.meanmenu-reveal span {
  background-color: #FFAA3B !important;
}

.st_default_btn.bg-st-pink,
.st_default_btn.st_transparent.bg-st-pink,
.feature_item_icon.bg-st-pink {
  background-color: #F23F79 !important;
}

.feature_item_icon.bg-st-pink {
  box-shadow: 0 10px 30px rgba(242, 63, 121, 0.3);
}

.st_info_box.bg-st-pink {
  background-color: #F23F79 !important;
}

.st_info_box.bg-shadow-st-pink {
  box-shadow: 0 30px 60px rgba(242, 63, 121, 0.4);
}

.st-mean-st-pink.mean-container a.meanmenu-reveal {
  color: #F23F79 !important;
}

.st-mean-st-pink.mean-container a.meanmenu-reveal span {
  background-color: #F23F79 !important;
}

.st_default_btn.bg-st-orange,
.st_default_btn.st_transparent.bg-st-orange,
.feature_item_icon.bg-st-orange {
  background-color: #FF5D1C !important;
}

.feature_item_icon.bg-st-orange {
  box-shadow: 0 10px 30px rgba(255, 93, 28, 0.3);
}

.st_info_box.bg-st-orange {
  background-color: #FF5D1C !important;
}

.st_info_box.bg-shadow-st-orange {
  box-shadow: 0 30px 60px rgba(255, 93, 28, 0.4);
}

.st-mean-st-orange.mean-container a.meanmenu-reveal {
  color: #FF5D1C !important;
}

.st-mean-st-orange.mean-container a.meanmenu-reveal span {
  background-color: #FF5D1C !important;
}

.st_default_btn.bg-science-blue,
.st_default_btn.st_transparent.bg-science-blue,
.feature_item_icon.bg-science-blue {
  background-color: #0366D6 !important;
}

.feature_item_icon.bg-science-blue {
  box-shadow: 0 10px 30px rgba(3, 102, 214, 0.3);
}

.st_info_box.bg-science-blue {
  background-color: #0366D6 !important;
}

.st_info_box.bg-shadow-science-blue {
  box-shadow: 0 30px 60px rgba(3, 102, 214, 0.4);
}

.st-mean-science-blue.mean-container a.meanmenu-reveal {
  color: #0366D6 !important;
}

.st-mean-science-blue.mean-container a.meanmenu-reveal span {
  background-color: #0366D6 !important;
}

.st_default_btn.bg-emerald,
.st_default_btn.st_transparent.bg-emerald,
.feature_item_icon.bg-emerald {
  background-color: #38D16A !important;
}

.feature_item_icon.bg-emerald {
  box-shadow: 0 10px 30px rgba(56, 209, 106, 0.3);
}

.st_info_box.bg-emerald {
  background-color: #38D16A !important;
}

.st_info_box.bg-shadow-emerald {
  box-shadow: 0 30px 60px rgba(56, 209, 106, 0.4);
}

.st-mean-emerald.mean-container a.meanmenu-reveal {
  color: #38D16A !important;
}

.st-mean-emerald.mean-container a.meanmenu-reveal span {
  background-color: #38D16A !important;
}

.st_default_btn.bg-chambray,
.st_default_btn.st_transparent.bg-chambray,
.feature_item_icon.bg-chambray {
  background-color: #3B3E99 !important;
}

.feature_item_icon.bg-chambray {
  box-shadow: 0 10px 30px rgba(59, 62, 153, 0.3);
}

.st_info_box.bg-chambray {
  background-color: #3B3E99 !important;
}

.st_info_box.bg-shadow-chambray {
  box-shadow: 0 30px 60px rgba(59, 62, 153, 0.4);
}

.st-mean-chambray.mean-container a.meanmenu-reveal {
  color: #3B3E99 !important;
}

.st-mean-chambray.mean-container a.meanmenu-reveal span {
  background-color: #3B3E99 !important;
}

.st_default_btn.bg-medium-purple,
.st_default_btn.st_transparent.bg-medium-purple,
.feature_item_icon.bg-medium-purple {
  background-color: #8066DC !important;
}

.feature_item_icon.bg-medium-purple {
  box-shadow: 0 10px 30px rgba(128, 102, 220, 0.3);
}

.st_info_box.bg-medium-purple {
  background-color: #8066DC !important;
}

.st_info_box.bg-shadow-medium-purple {
  box-shadow: 0 30px 60px rgba(128, 102, 220, 0.4);
}

.st-mean-medium-purple.mean-container a.meanmenu-reveal {
  color: #8066DC !important;
}

.st-mean-medium-purple.mean-container a.meanmenu-reveal span {
  background-color: #8066DC !important;
}

.st_default_btn.bg-lochmara,
.st_default_btn.st_transparent.bg-lochmara,
.feature_item_icon.bg-lochmara {
  background-color: #0079BF !important;
}

.feature_item_icon.bg-lochmara {
  box-shadow: 0 10px 30px rgba(0, 121, 191, 0.3);
}

.st_info_box.bg-lochmara {
  background-color: #0079BF !important;
}

.st_info_box.bg-shadow-lochmara {
  box-shadow: 0 30px 60px rgba(0, 121, 191, 0.4);
}

.st-mean-lochmara.mean-container a.meanmenu-reveal {
  color: #0079BF !important;
}

.st-mean-lochmara.mean-container a.meanmenu-reveal span {
  background-color: #0079BF !important;
}

.st_default_btn.bg-froly,
.st_default_btn.st_transparent.bg-froly,
.feature_item_icon.bg-froly {
  background-color: #F46F6C !important;
}

.feature_item_icon.bg-froly {
  box-shadow: 0 10px 30px rgba(244, 111, 108, 0.3);
}

.st_info_box.bg-froly {
  background-color: #F46F6C !important;
}

.st_info_box.bg-shadow-froly {
  box-shadow: 0 30px 60px rgba(244, 111, 108, 0.4);
}

.st-mean-froly.mean-container a.meanmenu-reveal {
  color: #F46F6C !important;
}

.st-mean-froly.mean-container a.meanmenu-reveal span {
  background-color: #F46F6C !important;
}

.st_default_btn.bg-alpha-froly,
.st_default_btn.st_transparent.bg-alpha-froly,
.feature_item_icon.bg-alpha-froly {
  background-color: rgba(244, 111, 108, 0.1) !important;
}

.feature_item_icon.bg-alpha-froly {
  box-shadow: 0 10px 30px rgba(244, 111, 108, 0.3);
}

.st_info_box.bg-alpha-froly {
  background-color: rgba(244, 111, 108, 0.1) !important;
}

.st_info_box.bg-shadow-alpha-froly {
  box-shadow: 0 30px 60px rgba(244, 111, 108, 0.4);
}

.st-mean-alpha-froly.mean-container a.meanmenu-reveal {
  color: rgba(244, 111, 108, 0.1) !important;
}

.st-mean-alpha-froly.mean-container a.meanmenu-reveal span {
  background-color: rgba(244, 111, 108, 0.1) !important;
}

.st_default_btn.bg-sweet-pink,
.st_default_btn.st_transparent.bg-sweet-pink,
.feature_item_icon.bg-sweet-pink {
  background-color: #FFA3A1 !important;
}

.feature_item_icon.bg-sweet-pink {
  box-shadow: 0 10px 30px rgba(255, 163, 161, 0.3);
}

.st_info_box.bg-sweet-pink {
  background-color: #FFA3A1 !important;
}

.st_info_box.bg-shadow-sweet-pink {
  box-shadow: 0 30px 60px rgba(255, 163, 161, 0.4);
}

.st-mean-sweet-pink.mean-container a.meanmenu-reveal {
  color: #FFA3A1 !important;
}

.st-mean-sweet-pink.mean-container a.meanmenu-reveal span {
  background-color: #FFA3A1 !important;
}

.st_default_btn.bg-koamaru,
.st_default_btn.st_transparent.bg-koamaru,
.feature_item_icon.bg-koamaru {
  background-color: #1D1068 !important;
}

.feature_item_icon.bg-koamaru {
  box-shadow: 0 10px 30px rgba(29, 16, 104, 0.3);
}

.st_info_box.bg-koamaru {
  background-color: #1D1068 !important;
}

.st_info_box.bg-shadow-koamaru {
  box-shadow: 0 30px 60px rgba(29, 16, 104, 0.4);
}

.st-mean-koamaru.mean-container a.meanmenu-reveal {
  color: #1D1068 !important;
}

.st-mean-koamaru.mean-container a.meanmenu-reveal span {
  background-color: #1D1068 !important;
}

.st_default_btn.bg-haiti,
.st_default_btn.st_transparent.bg-haiti,
.feature_item_icon.bg-haiti {
  background-color: #140F30 !important;
}

.feature_item_icon.bg-haiti {
  box-shadow: 0 10px 30px rgba(20, 15, 48, 0.3);
}

.st_info_box.bg-haiti {
  background-color: #140F30 !important;
}

.st_info_box.bg-shadow-haiti {
  box-shadow: 0 30px 60px rgba(20, 15, 48, 0.4);
}

.st-mean-haiti.mean-container a.meanmenu-reveal {
  color: #140F30 !important;
}

.st-mean-haiti.mean-container a.meanmenu-reveal span {
  background-color: #140F30 !important;
}

.st_default_btn.bg-magnolia,
.st_default_btn.st_transparent.bg-magnolia,
.feature_item_icon.bg-magnolia {
  background-color: #F9F8FF !important;
}

.feature_item_icon.bg-magnolia {
  box-shadow: 0 10px 30px rgba(249, 248, 255, 0.3);
}

.st_info_box.bg-magnolia {
  background-color: #F9F8FF !important;
}

.st_info_box.bg-shadow-magnolia {
  box-shadow: 0 30px 60px rgba(249, 248, 255, 0.4);
}

.st-mean-magnolia.mean-container a.meanmenu-reveal {
  color: #F9F8FF !important;
}

.st-mean-magnolia.mean-container a.meanmenu-reveal span {
  background-color: #F9F8FF !important;
}

.st_default_btn.bg-alice-blue,
.st_default_btn.st_transparent.bg-alice-blue,
.feature_item_icon.bg-alice-blue {
  background-color: #F5FBFF !important;
}

.feature_item_icon.bg-alice-blue {
  box-shadow: 0 10px 30px rgba(245, 251, 255, 0.3);
}

.st_info_box.bg-alice-blue {
  background-color: #F5FBFF !important;
}

.st_info_box.bg-shadow-alice-blue {
  box-shadow: 0 30px 60px rgba(245, 251, 255, 0.4);
}

.st-mean-alice-blue.mean-container a.meanmenu-reveal {
  color: #F5FBFF !important;
}

.st-mean-alice-blue.mean-container a.meanmenu-reveal span {
  background-color: #F5FBFF !important;
}

.st_default_btn.bg-polar,
.st_default_btn.st_transparent.bg-polar,
.feature_item_icon.bg-polar {
  background-color: #F5F9FD !important;
}

.feature_item_icon.bg-polar {
  box-shadow: 0 10px 30px rgba(245, 249, 253, 0.3);
}

.st_info_box.bg-polar {
  background-color: #F5F9FD !important;
}

.st_info_box.bg-shadow-polar {
  box-shadow: 0 30px 60px rgba(245, 249, 253, 0.4);
}

.st-mean-polar.mean-container a.meanmenu-reveal {
  color: #F5F9FD !important;
}

.st-mean-polar.mean-container a.meanmenu-reveal span {
  background-color: #F5F9FD !important;
}

.st_default_btn.bg-st-primary,
.st_default_btn.st_transparent.bg-st-primary,
.feature_item_icon.bg-st-primary {
  background-color: #4A516A !important;
}

.feature_item_icon.bg-st-primary {
  box-shadow: 0 10px 30px rgba(74, 81, 106, 0.3);
}

.st_info_box.bg-st-primary {
  background-color: #4A516A !important;
}

.st_info_box.bg-shadow-st-primary {
  box-shadow: 0 30px 60px rgba(74, 81, 106, 0.4);
}

.st-mean-st-primary.mean-container a.meanmenu-reveal {
  color: #4A516A !important;
}

.st-mean-st-primary.mean-container a.meanmenu-reveal span {
  background-color: #4A516A !important;
}

.st_default_btn.bg-st-white,
.st_default_btn.st_transparent.bg-st-white,
.feature_item_icon.bg-st-white {
  background-color: #FFFFFF !important;
}

.feature_item_icon.bg-st-white {
  box-shadow: 0 10px 30px rgba(255, 255, 255, 0.3);
}

.st_info_box.bg-st-white {
  background-color: #FFFFFF !important;
}

.st_info_box.bg-shadow-st-white {
  box-shadow: 0 30px 60px rgba(255, 255, 255, 0.4);
}

.st-mean-st-white.mean-container a.meanmenu-reveal {
  color: #FFFFFF !important;
}

.st-mean-st-white.mean-container a.meanmenu-reveal span {
  background-color: #FFFFFF !important;
}

.st_default_btn.bg-st-black,
.st_default_btn.st_transparent.bg-st-black,
.feature_item_icon.bg-st-black {
  background-color: #000000 !important;
}

.feature_item_icon.bg-st-black {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.st_info_box.bg-st-black {
  background-color: #000000 !important;
}

.st_info_box.bg-shadow-st-black {
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.4);
}

.st-mean-st-black.mean-container a.meanmenu-reveal {
  color: #000000 !important;
}

.st-mean-st-black.mean-container a.meanmenu-reveal span {
  background-color: #000000 !important;
}

.st_default_btn.bg-st-fog,
.st_default_btn.st_transparent.bg-st-fog,
.feature_item_icon.bg-st-fog {
  background-color: #D6CCFF !important;
}

.feature_item_icon.bg-st-fog {
  box-shadow: 0 10px 30px rgba(214, 204, 255, 0.3);
}

.st_info_box.bg-st-fog {
  background-color: #D6CCFF !important;
}

.st_info_box.bg-shadow-st-fog {
  box-shadow: 0 30px 60px rgba(214, 204, 255, 0.4);
}

.st-mean-st-fog.mean-container a.meanmenu-reveal {
  color: #D6CCFF !important;
}

.st-mean-st-fog.mean-container a.meanmenu-reveal span {
  background-color: #D6CCFF !important;
}

.st_default_btn.bg-st-gray,
.st_default_btn.st_transparent.bg-st-gray,
.feature_item_icon.bg-st-gray {
  background-color: #999999 !important;
}

.feature_item_icon.bg-st-gray {
  box-shadow: 0 10px 30px rgba(153, 153, 153, 0.3);
}

.st_info_box.bg-st-gray {
  background-color: #999999 !important;
}

.st_info_box.bg-shadow-st-gray {
  box-shadow: 0 30px 60px rgba(153, 153, 153, 0.4);
}

.st-mean-st-gray.mean-container a.meanmenu-reveal {
  color: #999999 !important;
}

.st-mean-st-gray.mean-container a.meanmenu-reveal span {
  background-color: #999999 !important;
}

.st_default_btn.bg-st-concrete,
.st_default_btn.st_transparent.bg-st-concrete,
.feature_item_icon.bg-st-concrete {
  background-color: #F3F3F3 !important;
}

.feature_item_icon.bg-st-concrete {
  box-shadow: 0 10px 30px rgba(243, 243, 243, 0.3);
}

.st_info_box.bg-st-concrete {
  background-color: #F3F3F3 !important;
}

.st_info_box.bg-shadow-st-concrete {
  box-shadow: 0 30px 60px rgba(243, 243, 243, 0.4);
}

.st-mean-st-concrete.mean-container a.meanmenu-reveal {
  color: #F3F3F3 !important;
}

.st-mean-st-concrete.mean-container a.meanmenu-reveal span {
  background-color: #F3F3F3 !important;
}

.st_default_btn.bg-st-violet,
.st_default_btn.st_transparent.bg-st-violet,
.feature_item_icon.bg-st-violet {
  background-color: #D52EEF !important;
}

.feature_item_icon.bg-st-violet {
  box-shadow: 0 10px 30px rgba(213, 46, 239, 0.3);
}

.st_info_box.bg-st-violet {
  background-color: #D52EEF !important;
}

.st_info_box.bg-shadow-st-violet {
  box-shadow: 0 30px 60px rgba(213, 46, 239, 0.4);
}

.st-mean-st-violet.mean-container a.meanmenu-reveal {
  color: #D52EEF !important;
}

.st-mean-st-violet.mean-container a.meanmenu-reveal span {
  background-color: #D52EEF !important;
}

.st_default_btn.bg-sun-glow,
.st_default_btn.st_transparent.bg-sun-glow,
.feature_item_icon.bg-sun-glow {
  background-color: #FCCC2C !important;
}

.feature_item_icon.bg-sun-glow {
  box-shadow: 0 10px 30px rgba(252, 204, 44, 0.3);
}

.st_info_box.bg-sun-glow {
  background-color: #FCCC2C !important;
}

.st_info_box.bg-shadow-sun-glow {
  box-shadow: 0 30px 60px rgba(252, 204, 44, 0.4);
}

.st-mean-sun-glow.mean-container a.meanmenu-reveal {
  color: #FCCC2C !important;
}

.st-mean-sun-glow.mean-container a.meanmenu-reveal span {
  background-color: #FCCC2C !important;
}

.st_default_btn.bg-st-cabaret,
.st_default_btn.st_transparent.bg-st-cabaret,
.feature_item_icon.bg-st-cabaret {
  background-color: #D1386C !important;
}

.feature_item_icon.bg-st-cabaret {
  box-shadow: 0 10px 30px rgba(209, 56, 108, 0.3);
}

.st_info_box.bg-st-cabaret {
  background-color: #D1386C !important;
}

.st_info_box.bg-shadow-st-cabaret {
  box-shadow: 0 30px 60px rgba(209, 56, 108, 0.4);
}

.st-mean-st-cabaret.mean-container a.meanmenu-reveal {
  color: #D1386C !important;
}

.st-mean-st-cabaret.mean-container a.meanmenu-reveal span {
  background-color: #D1386C !important;
}

.st_default_btn.bg-st-zircon,
.st_default_btn.st_transparent.bg-st-zircon,
.feature_item_icon.bg-st-zircon {
  background-color: #F8FAFF !important;
}

.feature_item_icon.bg-st-zircon {
  box-shadow: 0 10px 30px rgba(248, 250, 255, 0.3);
}

.st_info_box.bg-st-zircon {
  background-color: #F8FAFF !important;
}

.st_info_box.bg-shadow-st-zircon {
  box-shadow: 0 30px 60px rgba(248, 250, 255, 0.4);
}

.st-mean-st-zircon.mean-container a.meanmenu-reveal {
  color: #F8FAFF !important;
}

.st-mean-st-zircon.mean-container a.meanmenu-reveal span {
  background-color: #F8FAFF !important;
}

.st_default_btn.bg-clear-day,
.st_default_btn.st_transparent.bg-clear-day,
.feature_item_icon.bg-clear-day {
  background-color: #F9FFFD !important;
}

.feature_item_icon.bg-clear-day {
  box-shadow: 0 10px 30px rgba(249, 255, 253, 0.3);
}

.st_info_box.bg-clear-day {
  background-color: #F9FFFD !important;
}

.st_info_box.bg-shadow-clear-day {
  box-shadow: 0 30px 60px rgba(249, 255, 253, 0.4);
}

.st-mean-clear-day.mean-container a.meanmenu-reveal {
  color: #F9FFFD !important;
}

.st-mean-clear-day.mean-container a.meanmenu-reveal span {
  background-color: #F9FFFD !important;
}

.st_default_btn.bg-st-shamrock,
.st_default_btn.st_transparent.bg-st-shamrock,
.feature_item_icon.bg-st-shamrock {
  background-color: #3AD984 !important;
}

.feature_item_icon.bg-st-shamrock {
  box-shadow: 0 10px 30px rgba(58, 217, 132, 0.3);
}

.st_info_box.bg-st-shamrock {
  background-color: #3AD984 !important;
}

.st_info_box.bg-shadow-st-shamrock {
  box-shadow: 0 30px 60px rgba(58, 217, 132, 0.4);
}

.st-mean-st-shamrock.mean-container a.meanmenu-reveal {
  color: #3AD984 !important;
}

.st-mean-st-shamrock.mean-container a.meanmenu-reveal span {
  background-color: #3AD984 !important;
}

.st_default_btn.bg-blue-bell,
.st_default_btn.st_transparent.bg-blue-bell,
.feature_item_icon.bg-blue-bell {
  background-color: #9D9ECE !important;
}

.feature_item_icon.bg-blue-bell {
  box-shadow: 0 10px 30px rgba(157, 158, 206, 0.3);
}

.st_info_box.bg-blue-bell {
  background-color: #9D9ECE !important;
}

.st_info_box.bg-shadow-blue-bell {
  box-shadow: 0 30px 60px rgba(157, 158, 206, 0.4);
}

.st-mean-blue-bell.mean-container a.meanmenu-reveal {
  color: #9D9ECE !important;
}

.st-mean-blue-bell.mean-container a.meanmenu-reveal span {
  background-color: #9D9ECE !important;
}

.st_default_btn.bg-snuff,
.st_default_btn.st_transparent.bg-snuff,
.feature_item_icon.bg-snuff {
  background-color: #DBD7E9 !important;
}

.feature_item_icon.bg-snuff {
  box-shadow: 0 10px 30px rgba(219, 215, 233, 0.3);
}

.st_info_box.bg-snuff {
  background-color: #DBD7E9 !important;
}

.st_info_box.bg-shadow-snuff {
  box-shadow: 0 30px 60px rgba(219, 215, 233, 0.4);
}

.st-mean-snuff.mean-container a.meanmenu-reveal {
  color: #DBD7E9 !important;
}

.st-mean-snuff.mean-container a.meanmenu-reveal span {
  background-color: #DBD7E9 !important;
}

.st_default_btn.bg-french-grey,
.st_default_btn.st_transparent.bg-french-grey,
.feature_item_icon.bg-french-grey {
  background-color: #C3C3C7 !important;
}

.feature_item_icon.bg-french-grey {
  box-shadow: 0 10px 30px rgba(195, 195, 199, 0.3);
}

.st_info_box.bg-french-grey {
  background-color: #C3C3C7 !important;
}

.st_info_box.bg-shadow-french-grey {
  box-shadow: 0 30px 60px rgba(195, 195, 199, 0.4);
}

.st-mean-french-grey.mean-container a.meanmenu-reveal {
  color: #C3C3C7 !important;
}

.st-mean-french-grey.mean-container a.meanmenu-reveal span {
  background-color: #C3C3C7 !important;
}

.st_default_btn.bg-ebony-clay,
.st_default_btn.st_transparent.bg-ebony-clay,
.feature_item_icon.bg-ebony-clay {
  background-color: #1F2833 !important;
}

.feature_item_icon.bg-ebony-clay {
  box-shadow: 0 10px 30px rgba(31, 40, 51, 0.3);
}

.st_info_box.bg-ebony-clay {
  background-color: #1F2833 !important;
}

.st_info_box.bg-shadow-ebony-clay {
  box-shadow: 0 30px 60px rgba(31, 40, 51, 0.4);
}

.st-mean-ebony-clay.mean-container a.meanmenu-reveal {
  color: #1F2833 !important;
}

.st-mean-ebony-clay.mean-container a.meanmenu-reveal span {
  background-color: #1F2833 !important;
}

.st_default_btn.bg-black-rock,
.st_default_btn.st_transparent.bg-black-rock,
.feature_item_icon.bg-black-rock {
  background-color: #080327 !important;
}

.feature_item_icon.bg-black-rock {
  box-shadow: 0 10px 30px rgba(8, 3, 39, 0.3);
}

.st_info_box.bg-black-rock {
  background-color: #080327 !important;
}

.st_info_box.bg-shadow-black-rock {
  box-shadow: 0 30px 60px rgba(8, 3, 39, 0.4);
}

.st-mean-black-rock.mean-container a.meanmenu-reveal {
  color: #080327 !important;
}

.st-mean-black-rock.mean-container a.meanmenu-reveal span {
  background-color: #080327 !important;
}

.st_default_btn.bg-st-lavender,
.st_default_btn.st_transparent.bg-st-lavender,
.feature_item_icon.bg-st-lavender {
  background-color: #B4B5D5 !important;
}

.feature_item_icon.bg-st-lavender {
  box-shadow: 0 10px 30px rgba(180, 181, 213, 0.3);
}

.st_info_box.bg-st-lavender {
  background-color: #B4B5D5 !important;
}

.st_info_box.bg-shadow-st-lavender {
  box-shadow: 0 30px 60px rgba(180, 181, 213, 0.4);
}

.st-mean-st-lavender.mean-container a.meanmenu-reveal {
  color: #B4B5D5 !important;
}

.st-mean-st-lavender.mean-container a.meanmenu-reveal span {
  background-color: #B4B5D5 !important;
}

.st_default_btn.bg-titan-white,
.st_default_btn.st_transparent.bg-titan-white,
.feature_item_icon.bg-titan-white {
  background-color: #F5F5FF !important;
}

.feature_item_icon.bg-titan-white {
  box-shadow: 0 10px 30px rgba(245, 245, 255, 0.3);
}

.st_info_box.bg-titan-white {
  background-color: #F5F5FF !important;
}

.st_info_box.bg-shadow-titan-white {
  box-shadow: 0 30px 60px rgba(245, 245, 255, 0.4);
}

.st-mean-titan-white.mean-container a.meanmenu-reveal {
  color: #F5F5FF !important;
}

.st-mean-titan-white.mean-container a.meanmenu-reveal span {
  background-color: #F5F5FF !important;
}

.st_default_btn.bg-regent,
.st_default_btn.st_transparent.bg-regent,
.feature_item_icon.bg-regent {
  background-color: #8D9BA4 !important;
}

.feature_item_icon.bg-regent {
  box-shadow: 0 10px 30px rgba(141, 155, 164, 0.3);
}

.st_info_box.bg-regent {
  background-color: #8D9BA4 !important;
}

.st_info_box.bg-shadow-regent {
  box-shadow: 0 30px 60px rgba(141, 155, 164, 0.4);
}

.st-mean-regent.mean-container a.meanmenu-reveal {
  color: #8D9BA4 !important;
}

.st-mean-regent.mean-container a.meanmenu-reveal span {
  background-color: #8D9BA4 !important;
}

.st_default_btn.bg-shhamrock,
.st_default_btn.st_transparent.bg-shhamrock,
.feature_item_icon.bg-shhamrock {
  background-color: #40D1A7 !important;
}

.feature_item_icon.bg-shhamrock {
  box-shadow: 0 10px 30px rgba(64, 209, 167, 0.3);
}

.st_info_box.bg-shhamrock {
  background-color: #40D1A7 !important;
}

.st_info_box.bg-shadow-shhamrock {
  box-shadow: 0 30px 60px rgba(64, 209, 167, 0.4);
}

.st-mean-shhamrock.mean-container a.meanmenu-reveal {
  color: #40D1A7 !important;
}

.st-mean-shhamrock.mean-container a.meanmenu-reveal span {
  background-color: #40D1A7 !important;
}

.st_default_btn.bg-gray-light,
.st_default_btn.st_transparent.bg-gray-light,
.feature_item_icon.bg-gray-light {
  background-color: #f0f2f8 !important;
}

.feature_item_icon.bg-gray-light {
  box-shadow: 0 10px 30px rgba(240, 242, 248, 0.3);
}

.st_info_box.bg-gray-light {
  background-color: #f0f2f8 !important;
}

.st_info_box.bg-shadow-gray-light {
  box-shadow: 0 30px 60px rgba(240, 242, 248, 0.4);
}

.st-mean-gray-light.mean-container a.meanmenu-reveal {
  color: #f0f2f8 !important;
}

.st-mean-gray-light.mean-container a.meanmenu-reveal span {
  background-color: #f0f2f8 !important;
}

.st_default_btn.bg-gray,
.st_default_btn.st_transparent.bg-gray,
.feature_item_icon.bg-gray {
  background-color: #b6beda !important;
}

.feature_item_icon.bg-gray {
  box-shadow: 0 10px 30px rgba(182, 190, 218, 0.3);
}

.st_info_box.bg-gray {
  background-color: #b6beda !important;
}

.st_info_box.bg-shadow-gray {
  box-shadow: 0 30px 60px rgba(182, 190, 218, 0.4);
}

.st-mean-gray.mean-container a.meanmenu-reveal {
  color: #b6beda !important;
}

.st-mean-gray.mean-container a.meanmenu-reveal span {
  background-color: #b6beda !important;
}

.st_default_btn.bg-gray-dark,
.st_default_btn.st_transparent.bg-gray-dark,
.feature_item_icon.bg-gray-dark {
  background-color: #2f3447 !important;
}

.feature_item_icon.bg-gray-dark {
  box-shadow: 0 10px 30px rgba(47, 52, 71, 0.3);
}

.st_info_box.bg-gray-dark {
  background-color: #2f3447 !important;
}

.st_info_box.bg-shadow-gray-dark {
  box-shadow: 0 30px 60px rgba(47, 52, 71, 0.4);
}

.st-mean-gray-dark.mean-container a.meanmenu-reveal {
  color: #2f3447 !important;
}

.st-mean-gray-dark.mean-container a.meanmenu-reveal span {
  background-color: #2f3447 !important;
}

.st_default_btn.bg-blue-darker,
.st_default_btn.st_transparent.bg-blue-darker,
.feature_item_icon.bg-blue-darker {
  background-color: #3f455c !important;
}

.feature_item_icon.bg-blue-darker {
  box-shadow: 0 10px 30px rgba(63, 69, 92, 0.3);
}

.st_info_box.bg-blue-darker {
  background-color: #3f455c !important;
}

.st_info_box.bg-shadow-blue-darker {
  box-shadow: 0 30px 60px rgba(63, 69, 92, 0.4);
}

.st-mean-blue-darker.mean-container a.meanmenu-reveal {
  color: #3f455c !important;
}

.st-mean-blue-darker.mean-container a.meanmenu-reveal span {
  background-color: #3f455c !important;
}

.st_default_btn.bg-blue-dark,
.st_default_btn.st_transparent.bg-blue-dark,
.feature_item_icon.bg-blue-dark {
  background-color: #4a516a !important;
}

.feature_item_icon.bg-blue-dark {
  box-shadow: 0 10px 30px rgba(74, 81, 106, 0.3);
}

.st_info_box.bg-blue-dark {
  background-color: #4a516a !important;
}

.st_info_box.bg-shadow-blue-dark {
  box-shadow: 0 30px 60px rgba(74, 81, 106, 0.4);
}

.st-mean-blue-dark.mean-container a.meanmenu-reveal {
  color: #4a516a !important;
}

.st-mean-blue-dark.mean-container a.meanmenu-reveal span {
  background-color: #4a516a !important;
}

.st_default_btn.bg-blue,
.st_default_btn.st_transparent.bg-blue,
.feature_item_icon.bg-blue {
  background-color: #2bbcff !important;
}

.feature_item_icon.bg-blue {
  box-shadow: 0 10px 30px rgba(43, 188, 255, 0.3);
}

.st_info_box.bg-blue {
  background-color: #2bbcff !important;
}

.st_info_box.bg-shadow-blue {
  box-shadow: 0 30px 60px rgba(43, 188, 255, 0.4);
}

.st-mean-blue.mean-container a.meanmenu-reveal {
  color: #2bbcff !important;
}

.st-mean-blue.mean-container a.meanmenu-reveal span {
  background-color: #2bbcff !important;
}

.st_default_btn.bg-green,
.st_default_btn.st_transparent.bg-green,
.feature_item_icon.bg-green {
  background-color: #2ad9d3 !important;
}

.feature_item_icon.bg-green {
  box-shadow: 0 10px 30px rgba(42, 217, 211, 0.3);
}

.st_info_box.bg-green {
  background-color: #2ad9d3 !important;
}

.st_info_box.bg-shadow-green {
  box-shadow: 0 30px 60px rgba(42, 217, 211, 0.4);
}

.st-mean-green.mean-container a.meanmenu-reveal {
  color: #2ad9d3 !important;
}

.st-mean-green.mean-container a.meanmenu-reveal span {
  background-color: #2ad9d3 !important;
}

.st_default_btn.text-gigas,
.st_default_btn.st_transparent.text-gigas {
  color: #5040A0 !important;
}

.st_default_btn.st_transparent.text-gigas:hover,
.st_default_btn.st_transparent.text-gigas:focus,
.st_default_btn.st_transparent.text-gigas:active {
  background-color: #5040A0 !important;
  border-color: #5040A0 !important;
}

.border-gigas {
  border-color: #5040A0 !important;
}

.st_default_btn.text-perison-blue,
.st_default_btn.st_transparent.text-perison-blue {
  color: #1E43DD !important;
}

.st_default_btn.st_transparent.text-perison-blue:hover,
.st_default_btn.st_transparent.text-perison-blue:focus,
.st_default_btn.st_transparent.text-perison-blue:active {
  background-color: #1E43DD !important;
  border-color: #1E43DD !important;
}

.border-perison-blue {
  border-color: #1E43DD !important;
}

.st_default_btn.text-st-aqua,
.st_default_btn.st_transparent.text-st-aqua {
  color: #00E3F6 !important;
}

.st_default_btn.st_transparent.text-st-aqua:hover,
.st_default_btn.st_transparent.text-st-aqua:focus,
.st_default_btn.st_transparent.text-st-aqua:active {
  background-color: #00E3F6 !important;
  border-color: #00E3F6 !important;
}

.border-st-aqua {
  border-color: #00E3F6 !important;
}

.st_default_btn.text-shamrock,
.st_default_btn.st_transparent.text-shamrock {
  color: #3AD984 !important;
}

.st_default_btn.st_transparent.text-shamrock:hover,
.st_default_btn.st_transparent.text-shamrock:focus,
.st_default_btn.st_transparent.text-shamrock:active {
  background-color: #3AD984 !important;
  border-color: #3AD984 !important;
}

.border-shamrock {
  border-color: #3AD984 !important;
}

.st_default_btn.text-yellow-orange,
.st_default_btn.st_transparent.text-yellow-orange {
  color: #FFAA3B !important;
}

.st_default_btn.st_transparent.text-yellow-orange:hover,
.st_default_btn.st_transparent.text-yellow-orange:focus,
.st_default_btn.st_transparent.text-yellow-orange:active {
  background-color: #FFAA3B !important;
  border-color: #FFAA3B !important;
}

.border-yellow-orange {
  border-color: #FFAA3B !important;
}

.st_default_btn.text-st-pink,
.st_default_btn.st_transparent.text-st-pink {
  color: #F23F79 !important;
}

.st_default_btn.st_transparent.text-st-pink:hover,
.st_default_btn.st_transparent.text-st-pink:focus,
.st_default_btn.st_transparent.text-st-pink:active {
  background-color: #F23F79 !important;
  border-color: #F23F79 !important;
}

.border-st-pink {
  border-color: #F23F79 !important;
}

.st_default_btn.text-st-orange,
.st_default_btn.st_transparent.text-st-orange {
  color: #FF5D1C !important;
}

.st_default_btn.st_transparent.text-st-orange:hover,
.st_default_btn.st_transparent.text-st-orange:focus,
.st_default_btn.st_transparent.text-st-orange:active {
  background-color: #FF5D1C !important;
  border-color: #FF5D1C !important;
}

.border-st-orange {
  border-color: #FF5D1C !important;
}

.st_default_btn.text-science-blue,
.st_default_btn.st_transparent.text-science-blue {
  color: #0366D6 !important;
}

.st_default_btn.st_transparent.text-science-blue:hover,
.st_default_btn.st_transparent.text-science-blue:focus,
.st_default_btn.st_transparent.text-science-blue:active {
  background-color: #0366D6 !important;
  border-color: #0366D6 !important;
}

.border-science-blue {
  border-color: #0366D6 !important;
}

.st_default_btn.text-emerald,
.st_default_btn.st_transparent.text-emerald {
  color: #38D16A !important;
}

.st_default_btn.st_transparent.text-emerald:hover,
.st_default_btn.st_transparent.text-emerald:focus,
.st_default_btn.st_transparent.text-emerald:active {
  background-color: #38D16A !important;
  border-color: #38D16A !important;
}

.border-emerald {
  border-color: #38D16A !important;
}

.st_default_btn.text-chambray,
.st_default_btn.st_transparent.text-chambray {
  color: #3B3E99 !important;
}

.st_default_btn.st_transparent.text-chambray:hover,
.st_default_btn.st_transparent.text-chambray:focus,
.st_default_btn.st_transparent.text-chambray:active {
  background-color: #3B3E99 !important;
  border-color: #3B3E99 !important;
}

.border-chambray {
  border-color: #3B3E99 !important;
}

.st_default_btn.text-medium-purple,
.st_default_btn.st_transparent.text-medium-purple {
  color: #8066DC !important;
}

.st_default_btn.st_transparent.text-medium-purple:hover,
.st_default_btn.st_transparent.text-medium-purple:focus,
.st_default_btn.st_transparent.text-medium-purple:active {
  background-color: #8066DC !important;
  border-color: #8066DC !important;
}

.border-medium-purple {
  border-color: #8066DC !important;
}

.st_default_btn.text-lochmara,
.st_default_btn.st_transparent.text-lochmara {
  color: #0079BF !important;
}

.st_default_btn.st_transparent.text-lochmara:hover,
.st_default_btn.st_transparent.text-lochmara:focus,
.st_default_btn.st_transparent.text-lochmara:active {
  background-color: #0079BF !important;
  border-color: #0079BF !important;
}

.border-lochmara {
  border-color: #0079BF !important;
}

.st_default_btn.text-froly,
.st_default_btn.st_transparent.text-froly {
  color: #F46F6C !important;
}

.st_default_btn.st_transparent.text-froly:hover,
.st_default_btn.st_transparent.text-froly:focus,
.st_default_btn.st_transparent.text-froly:active {
  background-color: #F46F6C !important;
  border-color: #F46F6C !important;
}

.border-froly {
  border-color: #F46F6C !important;
}

.st_default_btn.text-alpha-froly,
.st_default_btn.st_transparent.text-alpha-froly {
  color: rgba(244, 111, 108, 0.1) !important;
}

.st_default_btn.st_transparent.text-alpha-froly:hover,
.st_default_btn.st_transparent.text-alpha-froly:focus,
.st_default_btn.st_transparent.text-alpha-froly:active {
  background-color: rgba(244, 111, 108, 0.1) !important;
  border-color: rgba(244, 111, 108, 0.1) !important;
}

.border-alpha-froly {
  border-color: rgba(244, 111, 108, 0.1) !important;
}

.st_default_btn.text-sweet-pink,
.st_default_btn.st_transparent.text-sweet-pink {
  color: #FFA3A1 !important;
}

.st_default_btn.st_transparent.text-sweet-pink:hover,
.st_default_btn.st_transparent.text-sweet-pink:focus,
.st_default_btn.st_transparent.text-sweet-pink:active {
  background-color: #FFA3A1 !important;
  border-color: #FFA3A1 !important;
}

.border-sweet-pink {
  border-color: #FFA3A1 !important;
}

.st_default_btn.text-koamaru,
.st_default_btn.st_transparent.text-koamaru {
  color: #1D1068 !important;
}

.st_default_btn.st_transparent.text-koamaru:hover,
.st_default_btn.st_transparent.text-koamaru:focus,
.st_default_btn.st_transparent.text-koamaru:active {
  background-color: #1D1068 !important;
  border-color: #1D1068 !important;
}

.border-koamaru {
  border-color: #1D1068 !important;
}

.st_default_btn.text-haiti,
.st_default_btn.st_transparent.text-haiti {
  color: #140F30 !important;
}

.st_default_btn.st_transparent.text-haiti:hover,
.st_default_btn.st_transparent.text-haiti:focus,
.st_default_btn.st_transparent.text-haiti:active {
  background-color: #140F30 !important;
  border-color: #140F30 !important;
}

.border-haiti {
  border-color: #140F30 !important;
}

.st_default_btn.text-magnolia,
.st_default_btn.st_transparent.text-magnolia {
  color: #F9F8FF !important;
}

.st_default_btn.st_transparent.text-magnolia:hover,
.st_default_btn.st_transparent.text-magnolia:focus,
.st_default_btn.st_transparent.text-magnolia:active {
  background-color: #F9F8FF !important;
  border-color: #F9F8FF !important;
}

.border-magnolia {
  border-color: #F9F8FF !important;
}

.st_default_btn.text-alice-blue,
.st_default_btn.st_transparent.text-alice-blue {
  color: #F5FBFF !important;
}

.st_default_btn.st_transparent.text-alice-blue:hover,
.st_default_btn.st_transparent.text-alice-blue:focus,
.st_default_btn.st_transparent.text-alice-blue:active {
  background-color: #F5FBFF !important;
  border-color: #F5FBFF !important;
}

.border-alice-blue {
  border-color: #F5FBFF !important;
}

.st_default_btn.text-polar,
.st_default_btn.st_transparent.text-polar {
  color: #F5F9FD !important;
}

.st_default_btn.st_transparent.text-polar:hover,
.st_default_btn.st_transparent.text-polar:focus,
.st_default_btn.st_transparent.text-polar:active {
  background-color: #F5F9FD !important;
  border-color: #F5F9FD !important;
}

.border-polar {
  border-color: #F5F9FD !important;
}

.st_default_btn.text-st-primary,
.st_default_btn.st_transparent.text-st-primary {
  color: #4A516A !important;
}

.st_default_btn.st_transparent.text-st-primary:hover,
.st_default_btn.st_transparent.text-st-primary:focus,
.st_default_btn.st_transparent.text-st-primary:active {
  background-color: #4A516A !important;
  border-color: #4A516A !important;
}

.border-st-primary {
  border-color: #4A516A !important;
}

.st_default_btn.text-st-white,
.st_default_btn.st_transparent.text-st-white {
  color: #FFFFFF !important;
}

.st_default_btn.st_transparent.text-st-white:hover,
.st_default_btn.st_transparent.text-st-white:focus,
.st_default_btn.st_transparent.text-st-white:active {
  background-color: #FFFFFF !important;
  border-color: #FFFFFF !important;
}

.border-st-white {
  border-color: #FFFFFF !important;
}

.st_default_btn.text-st-black,
.st_default_btn.st_transparent.text-st-black {
  color: #000000 !important;
}

.st_default_btn.st_transparent.text-st-black:hover,
.st_default_btn.st_transparent.text-st-black:focus,
.st_default_btn.st_transparent.text-st-black:active {
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.border-st-black {
  border-color: #000000 !important;
}

.st_default_btn.text-st-fog,
.st_default_btn.st_transparent.text-st-fog {
  color: #D6CCFF !important;
}

.st_default_btn.st_transparent.text-st-fog:hover,
.st_default_btn.st_transparent.text-st-fog:focus,
.st_default_btn.st_transparent.text-st-fog:active {
  background-color: #D6CCFF !important;
  border-color: #D6CCFF !important;
}

.border-st-fog {
  border-color: #D6CCFF !important;
}

.st_default_btn.text-st-gray,
.st_default_btn.st_transparent.text-st-gray {
  color: #999999 !important;
}

.st_default_btn.st_transparent.text-st-gray:hover,
.st_default_btn.st_transparent.text-st-gray:focus,
.st_default_btn.st_transparent.text-st-gray:active {
  background-color: #999999 !important;
  border-color: #999999 !important;
}

.border-st-gray {
  border-color: #999999 !important;
}

.st_default_btn.text-st-concrete,
.st_default_btn.st_transparent.text-st-concrete {
  color: #F3F3F3 !important;
}

.st_default_btn.st_transparent.text-st-concrete:hover,
.st_default_btn.st_transparent.text-st-concrete:focus,
.st_default_btn.st_transparent.text-st-concrete:active {
  background-color: #F3F3F3 !important;
  border-color: #F3F3F3 !important;
}

.border-st-concrete {
  border-color: #F3F3F3 !important;
}

.st_default_btn.text-st-violet,
.st_default_btn.st_transparent.text-st-violet {
  color: #D52EEF !important;
}

.st_default_btn.st_transparent.text-st-violet:hover,
.st_default_btn.st_transparent.text-st-violet:focus,
.st_default_btn.st_transparent.text-st-violet:active {
  background-color: #D52EEF !important;
  border-color: #D52EEF !important;
}

.border-st-violet {
  border-color: #D52EEF !important;
}

.st_default_btn.text-sun-glow,
.st_default_btn.st_transparent.text-sun-glow {
  color: #FCCC2C !important;
}

.st_default_btn.st_transparent.text-sun-glow:hover,
.st_default_btn.st_transparent.text-sun-glow:focus,
.st_default_btn.st_transparent.text-sun-glow:active {
  background-color: #FCCC2C !important;
  border-color: #FCCC2C !important;
}

.border-sun-glow {
  border-color: #FCCC2C !important;
}

.st_default_btn.text-st-cabaret,
.st_default_btn.st_transparent.text-st-cabaret {
  color: #D1386C !important;
}

.st_default_btn.st_transparent.text-st-cabaret:hover,
.st_default_btn.st_transparent.text-st-cabaret:focus,
.st_default_btn.st_transparent.text-st-cabaret:active {
  background-color: #D1386C !important;
  border-color: #D1386C !important;
}

.border-st-cabaret {
  border-color: #D1386C !important;
}

.st_default_btn.text-st-zircon,
.st_default_btn.st_transparent.text-st-zircon {
  color: #F8FAFF !important;
}

.st_default_btn.st_transparent.text-st-zircon:hover,
.st_default_btn.st_transparent.text-st-zircon:focus,
.st_default_btn.st_transparent.text-st-zircon:active {
  background-color: #F8FAFF !important;
  border-color: #F8FAFF !important;
}

.border-st-zircon {
  border-color: #F8FAFF !important;
}

.st_default_btn.text-clear-day,
.st_default_btn.st_transparent.text-clear-day {
  color: #F9FFFD !important;
}

.st_default_btn.st_transparent.text-clear-day:hover,
.st_default_btn.st_transparent.text-clear-day:focus,
.st_default_btn.st_transparent.text-clear-day:active {
  background-color: #F9FFFD !important;
  border-color: #F9FFFD !important;
}

.border-clear-day {
  border-color: #F9FFFD !important;
}

.st_default_btn.text-st-shamrock,
.st_default_btn.st_transparent.text-st-shamrock {
  color: #3AD984 !important;
}

.st_default_btn.st_transparent.text-st-shamrock:hover,
.st_default_btn.st_transparent.text-st-shamrock:focus,
.st_default_btn.st_transparent.text-st-shamrock:active {
  background-color: #3AD984 !important;
  border-color: #3AD984 !important;
}

.border-st-shamrock {
  border-color: #3AD984 !important;
}

.st_default_btn.text-blue-bell,
.st_default_btn.st_transparent.text-blue-bell {
  color: #9D9ECE !important;
}

.st_default_btn.st_transparent.text-blue-bell:hover,
.st_default_btn.st_transparent.text-blue-bell:focus,
.st_default_btn.st_transparent.text-blue-bell:active {
  background-color: #9D9ECE !important;
  border-color: #9D9ECE !important;
}

.border-blue-bell {
  border-color: #9D9ECE !important;
}

.st_default_btn.text-snuff,
.st_default_btn.st_transparent.text-snuff {
  color: #DBD7E9 !important;
}

.st_default_btn.st_transparent.text-snuff:hover,
.st_default_btn.st_transparent.text-snuff:focus,
.st_default_btn.st_transparent.text-snuff:active {
  background-color: #DBD7E9 !important;
  border-color: #DBD7E9 !important;
}

.border-snuff {
  border-color: #DBD7E9 !important;
}

.st_default_btn.text-french-grey,
.st_default_btn.st_transparent.text-french-grey {
  color: #C3C3C7 !important;
}

.st_default_btn.st_transparent.text-french-grey:hover,
.st_default_btn.st_transparent.text-french-grey:focus,
.st_default_btn.st_transparent.text-french-grey:active {
  background-color: #C3C3C7 !important;
  border-color: #C3C3C7 !important;
}

.border-french-grey {
  border-color: #C3C3C7 !important;
}

.st_default_btn.text-ebony-clay,
.st_default_btn.st_transparent.text-ebony-clay {
  color: #1F2833 !important;
}

.st_default_btn.st_transparent.text-ebony-clay:hover,
.st_default_btn.st_transparent.text-ebony-clay:focus,
.st_default_btn.st_transparent.text-ebony-clay:active {
  background-color: #1F2833 !important;
  border-color: #1F2833 !important;
}

.border-ebony-clay {
  border-color: #1F2833 !important;
}

.st_default_btn.text-black-rock,
.st_default_btn.st_transparent.text-black-rock {
  color: #080327 !important;
}

.st_default_btn.st_transparent.text-black-rock:hover,
.st_default_btn.st_transparent.text-black-rock:focus,
.st_default_btn.st_transparent.text-black-rock:active {
  background-color: #080327 !important;
  border-color: #080327 !important;
}

.border-black-rock {
  border-color: #080327 !important;
}

.st_default_btn.text-st-lavender,
.st_default_btn.st_transparent.text-st-lavender {
  color: #B4B5D5 !important;
}

.st_default_btn.st_transparent.text-st-lavender:hover,
.st_default_btn.st_transparent.text-st-lavender:focus,
.st_default_btn.st_transparent.text-st-lavender:active {
  background-color: #B4B5D5 !important;
  border-color: #B4B5D5 !important;
}

.border-st-lavender {
  border-color: #B4B5D5 !important;
}

.st_default_btn.text-titan-white,
.st_default_btn.st_transparent.text-titan-white {
  color: #F5F5FF !important;
}

.st_default_btn.st_transparent.text-titan-white:hover,
.st_default_btn.st_transparent.text-titan-white:focus,
.st_default_btn.st_transparent.text-titan-white:active {
  background-color: #F5F5FF !important;
  border-color: #F5F5FF !important;
}

.border-titan-white {
  border-color: #F5F5FF !important;
}

.st_default_btn.text-regent,
.st_default_btn.st_transparent.text-regent {
  color: #8D9BA4 !important;
}

.st_default_btn.st_transparent.text-regent:hover,
.st_default_btn.st_transparent.text-regent:focus,
.st_default_btn.st_transparent.text-regent:active {
  background-color: #8D9BA4 !important;
  border-color: #8D9BA4 !important;
}

.border-regent {
  border-color: #8D9BA4 !important;
}

.st_default_btn.text-shhamrock,
.st_default_btn.st_transparent.text-shhamrock {
  color: #40D1A7 !important;
}

.st_default_btn.st_transparent.text-shhamrock:hover,
.st_default_btn.st_transparent.text-shhamrock:focus,
.st_default_btn.st_transparent.text-shhamrock:active {
  background-color: #40D1A7 !important;
  border-color: #40D1A7 !important;
}

.border-shhamrock {
  border-color: #40D1A7 !important;
}

.st_default_btn.text-gray-light,
.st_default_btn.st_transparent.text-gray-light {
  color: #f0f2f8 !important;
}

.st_default_btn.st_transparent.text-gray-light:hover,
.st_default_btn.st_transparent.text-gray-light:focus,
.st_default_btn.st_transparent.text-gray-light:active {
  background-color: #f0f2f8 !important;
  border-color: #f0f2f8 !important;
}

.border-gray-light {
  border-color: #f0f2f8 !important;
}

.st_default_btn.text-gray,
.st_default_btn.st_transparent.text-gray {
  color: #b6beda !important;
}

.st_default_btn.st_transparent.text-gray:hover,
.st_default_btn.st_transparent.text-gray:focus,
.st_default_btn.st_transparent.text-gray:active {
  background-color: #b6beda !important;
  border-color: #b6beda !important;
}

.border-gray {
  border-color: #b6beda !important;
}

.st_default_btn.text-gray-dark,
.st_default_btn.st_transparent.text-gray-dark {
  color: #2f3447 !important;
}

.st_default_btn.st_transparent.text-gray-dark:hover,
.st_default_btn.st_transparent.text-gray-dark:focus,
.st_default_btn.st_transparent.text-gray-dark:active {
  background-color: #2f3447 !important;
  border-color: #2f3447 !important;
}

.border-gray-dark {
  border-color: #2f3447 !important;
}

.st_default_btn.text-blue-darker,
.st_default_btn.st_transparent.text-blue-darker {
  color: #3f455c !important;
}

.st_default_btn.st_transparent.text-blue-darker:hover,
.st_default_btn.st_transparent.text-blue-darker:focus,
.st_default_btn.st_transparent.text-blue-darker:active {
  background-color: #3f455c !important;
  border-color: #3f455c !important;
}

.border-blue-darker {
  border-color: #3f455c !important;
}

.st_default_btn.text-blue-dark,
.st_default_btn.st_transparent.text-blue-dark {
  color: #4a516a !important;
}

.st_default_btn.st_transparent.text-blue-dark:hover,
.st_default_btn.st_transparent.text-blue-dark:focus,
.st_default_btn.st_transparent.text-blue-dark:active {
  background-color: #4a516a !important;
  border-color: #4a516a !important;
}

.border-blue-dark {
  border-color: #4a516a !important;
}

.st_default_btn.text-blue,
.st_default_btn.st_transparent.text-blue {
  color: #2bbcff !important;
}

.st_default_btn.st_transparent.text-blue:hover,
.st_default_btn.st_transparent.text-blue:focus,
.st_default_btn.st_transparent.text-blue:active {
  background-color: #2bbcff !important;
  border-color: #2bbcff !important;
}

.border-blue {
  border-color: #2bbcff !important;
}

.st_default_btn.text-green,
.st_default_btn.st_transparent.text-green {
  color: #2ad9d3 !important;
}

.st_default_btn.st_transparent.text-green:hover,
.st_default_btn.st_transparent.text-green:focus,
.st_default_btn.st_transparent.text-green:active {
  background-color: #2ad9d3 !important;
  border-color: #2ad9d3 !important;
}

.border-green {
  border-color: #2ad9d3 !important;
}

.bg-hover-gigas:hover,
.bg-hover-gigas:focus,
.bg-hover-gigas:active,
.st_default_btn.bg-hover-gigas:hover,
.st_default_btn.bg-hover-gigas:focus,
.st_default_btn.bg-hover-gigas:active {
  background-color: #5040A0 !important;
}

header.sticky-menu.sticky-bg-gigas {
  background: #5040A0 !important;
}

.text-hover-gigas:hover,
.text-hover-gigas:focus,
.text-hover-gigas:active,
.st_default_btn.st_transparent.text-hover-gigas:hover,
.st_default_btn.st_transparent.text-hover-gigas:focus,
.st_default_btn.st_transparent.text-hover-gigas:active {
  color: #5040A0 !important;
}

.fieldset-active-gigas input:checked + label {
  color: #5040A0 !important;
}

.fieldset-active-gigas .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(80, 64, 160, 0.1) 20%, rgba(80, 64, 160, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(80, 64, 160, 0.1) 20%, rgba(80, 64, 160, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(80, 64, 160, 0.1) 20%, rgba(80, 64, 160, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-gigas .st-pricing-underlines .st-pricing-underlines-2 {
  background: #5040A0;
  background: -moz-linear-gradient(left, rgba(80, 64, 160, 0.04) 0%, #5040A0 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(80, 64, 160, 0.04) 0%, #5040A0 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(80, 64, 160, 0.04) 0%, #5040A0 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-perison-blue:hover,
.bg-hover-perison-blue:focus,
.bg-hover-perison-blue:active,
.st_default_btn.bg-hover-perison-blue:hover,
.st_default_btn.bg-hover-perison-blue:focus,
.st_default_btn.bg-hover-perison-blue:active {
  background-color: #1E43DD !important;
}

header.sticky-menu.sticky-bg-perison-blue {
  background: #1E43DD !important;
}

.text-hover-perison-blue:hover,
.text-hover-perison-blue:focus,
.text-hover-perison-blue:active,
.st_default_btn.st_transparent.text-hover-perison-blue:hover,
.st_default_btn.st_transparent.text-hover-perison-blue:focus,
.st_default_btn.st_transparent.text-hover-perison-blue:active {
  color: #1E43DD !important;
}

.fieldset-active-perison-blue input:checked + label {
  color: #1E43DD !important;
}

.fieldset-active-perison-blue .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(30, 67, 221, 0.1) 20%, rgba(30, 67, 221, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(30, 67, 221, 0.1) 20%, rgba(30, 67, 221, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(30, 67, 221, 0.1) 20%, rgba(30, 67, 221, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-perison-blue .st-pricing-underlines .st-pricing-underlines-2 {
  background: #1E43DD;
  background: -moz-linear-gradient(left, rgba(30, 67, 221, 0.04) 0%, #1E43DD 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(30, 67, 221, 0.04) 0%, #1E43DD 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(30, 67, 221, 0.04) 0%, #1E43DD 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-st-aqua:hover,
.bg-hover-st-aqua:focus,
.bg-hover-st-aqua:active,
.st_default_btn.bg-hover-st-aqua:hover,
.st_default_btn.bg-hover-st-aqua:focus,
.st_default_btn.bg-hover-st-aqua:active {
  background-color: #00E3F6 !important;
}

header.sticky-menu.sticky-bg-st-aqua {
  background: #00E3F6 !important;
}

.text-hover-st-aqua:hover,
.text-hover-st-aqua:focus,
.text-hover-st-aqua:active,
.st_default_btn.st_transparent.text-hover-st-aqua:hover,
.st_default_btn.st_transparent.text-hover-st-aqua:focus,
.st_default_btn.st_transparent.text-hover-st-aqua:active {
  color: #00E3F6 !important;
}

.fieldset-active-st-aqua input:checked + label {
  color: #00E3F6 !important;
}

.fieldset-active-st-aqua .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(0, 227, 246, 0.1) 20%, rgba(0, 227, 246, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(0, 227, 246, 0.1) 20%, rgba(0, 227, 246, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(0, 227, 246, 0.1) 20%, rgba(0, 227, 246, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-st-aqua .st-pricing-underlines .st-pricing-underlines-2 {
  background: #00E3F6;
  background: -moz-linear-gradient(left, rgba(0, 227, 246, 0.04) 0%, #00E3F6 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 227, 246, 0.04) 0%, #00E3F6 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(0, 227, 246, 0.04) 0%, #00E3F6 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-shamrock:hover,
.bg-hover-shamrock:focus,
.bg-hover-shamrock:active,
.st_default_btn.bg-hover-shamrock:hover,
.st_default_btn.bg-hover-shamrock:focus,
.st_default_btn.bg-hover-shamrock:active {
  background-color: #3AD984 !important;
}

header.sticky-menu.sticky-bg-shamrock {
  background: #3AD984 !important;
}

.text-hover-shamrock:hover,
.text-hover-shamrock:focus,
.text-hover-shamrock:active,
.st_default_btn.st_transparent.text-hover-shamrock:hover,
.st_default_btn.st_transparent.text-hover-shamrock:focus,
.st_default_btn.st_transparent.text-hover-shamrock:active {
  color: #3AD984 !important;
}

.fieldset-active-shamrock input:checked + label {
  color: #3AD984 !important;
}

.fieldset-active-shamrock .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(58, 217, 132, 0.1) 20%, rgba(58, 217, 132, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(58, 217, 132, 0.1) 20%, rgba(58, 217, 132, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(58, 217, 132, 0.1) 20%, rgba(58, 217, 132, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-shamrock .st-pricing-underlines .st-pricing-underlines-2 {
  background: #3AD984;
  background: -moz-linear-gradient(left, rgba(58, 217, 132, 0.04) 0%, #3AD984 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(58, 217, 132, 0.04) 0%, #3AD984 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(58, 217, 132, 0.04) 0%, #3AD984 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-yellow-orange:hover,
.bg-hover-yellow-orange:focus,
.bg-hover-yellow-orange:active,
.st_default_btn.bg-hover-yellow-orange:hover,
.st_default_btn.bg-hover-yellow-orange:focus,
.st_default_btn.bg-hover-yellow-orange:active {
  background-color: #FFAA3B !important;
}

header.sticky-menu.sticky-bg-yellow-orange {
  background: #FFAA3B !important;
}

.text-hover-yellow-orange:hover,
.text-hover-yellow-orange:focus,
.text-hover-yellow-orange:active,
.st_default_btn.st_transparent.text-hover-yellow-orange:hover,
.st_default_btn.st_transparent.text-hover-yellow-orange:focus,
.st_default_btn.st_transparent.text-hover-yellow-orange:active {
  color: #FFAA3B !important;
}

.fieldset-active-yellow-orange input:checked + label {
  color: #FFAA3B !important;
}

.fieldset-active-yellow-orange .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(255, 170, 59, 0.1) 20%, rgba(255, 170, 59, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(255, 170, 59, 0.1) 20%, rgba(255, 170, 59, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(255, 170, 59, 0.1) 20%, rgba(255, 170, 59, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-yellow-orange .st-pricing-underlines .st-pricing-underlines-2 {
  background: #FFAA3B;
  background: -moz-linear-gradient(left, rgba(255, 170, 59, 0.04) 0%, #FFAA3B 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 170, 59, 0.04) 0%, #FFAA3B 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(255, 170, 59, 0.04) 0%, #FFAA3B 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-st-pink:hover,
.bg-hover-st-pink:focus,
.bg-hover-st-pink:active,
.st_default_btn.bg-hover-st-pink:hover,
.st_default_btn.bg-hover-st-pink:focus,
.st_default_btn.bg-hover-st-pink:active {
  background-color: #F23F79 !important;
}

header.sticky-menu.sticky-bg-st-pink {
  background: #F23F79 !important;
}

.text-hover-st-pink:hover,
.text-hover-st-pink:focus,
.text-hover-st-pink:active,
.st_default_btn.st_transparent.text-hover-st-pink:hover,
.st_default_btn.st_transparent.text-hover-st-pink:focus,
.st_default_btn.st_transparent.text-hover-st-pink:active {
  color: #F23F79 !important;
}

.fieldset-active-st-pink input:checked + label {
  color: #F23F79 !important;
}

.fieldset-active-st-pink .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(242, 63, 121, 0.1) 20%, rgba(242, 63, 121, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(242, 63, 121, 0.1) 20%, rgba(242, 63, 121, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(242, 63, 121, 0.1) 20%, rgba(242, 63, 121, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-st-pink .st-pricing-underlines .st-pricing-underlines-2 {
  background: #F23F79;
  background: -moz-linear-gradient(left, rgba(242, 63, 121, 0.04) 0%, #F23F79 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(242, 63, 121, 0.04) 0%, #F23F79 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(242, 63, 121, 0.04) 0%, #F23F79 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-st-orange:hover,
.bg-hover-st-orange:focus,
.bg-hover-st-orange:active,
.st_default_btn.bg-hover-st-orange:hover,
.st_default_btn.bg-hover-st-orange:focus,
.st_default_btn.bg-hover-st-orange:active {
  background-color: #FF5D1C !important;
}

header.sticky-menu.sticky-bg-st-orange {
  background: #FF5D1C !important;
}

.text-hover-st-orange:hover,
.text-hover-st-orange:focus,
.text-hover-st-orange:active,
.st_default_btn.st_transparent.text-hover-st-orange:hover,
.st_default_btn.st_transparent.text-hover-st-orange:focus,
.st_default_btn.st_transparent.text-hover-st-orange:active {
  color: #FF5D1C !important;
}

.fieldset-active-st-orange input:checked + label {
  color: #FF5D1C !important;
}

.fieldset-active-st-orange .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(255, 93, 28, 0.1) 20%, rgba(255, 93, 28, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(255, 93, 28, 0.1) 20%, rgba(255, 93, 28, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(255, 93, 28, 0.1) 20%, rgba(255, 93, 28, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-st-orange .st-pricing-underlines .st-pricing-underlines-2 {
  background: #FF5D1C;
  background: -moz-linear-gradient(left, rgba(255, 93, 28, 0.04) 0%, #FF5D1C 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 93, 28, 0.04) 0%, #FF5D1C 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(255, 93, 28, 0.04) 0%, #FF5D1C 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-science-blue:hover,
.bg-hover-science-blue:focus,
.bg-hover-science-blue:active,
.st_default_btn.bg-hover-science-blue:hover,
.st_default_btn.bg-hover-science-blue:focus,
.st_default_btn.bg-hover-science-blue:active {
  background-color: #0366D6 !important;
}

header.sticky-menu.sticky-bg-science-blue {
  background: #0366D6 !important;
}

.text-hover-science-blue:hover,
.text-hover-science-blue:focus,
.text-hover-science-blue:active,
.st_default_btn.st_transparent.text-hover-science-blue:hover,
.st_default_btn.st_transparent.text-hover-science-blue:focus,
.st_default_btn.st_transparent.text-hover-science-blue:active {
  color: #0366D6 !important;
}

.fieldset-active-science-blue input:checked + label {
  color: #0366D6 !important;
}

.fieldset-active-science-blue .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(3, 102, 214, 0.1) 20%, rgba(3, 102, 214, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(3, 102, 214, 0.1) 20%, rgba(3, 102, 214, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(3, 102, 214, 0.1) 20%, rgba(3, 102, 214, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-science-blue .st-pricing-underlines .st-pricing-underlines-2 {
  background: #0366D6;
  background: -moz-linear-gradient(left, rgba(3, 102, 214, 0.04) 0%, #0366D6 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(3, 102, 214, 0.04) 0%, #0366D6 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(3, 102, 214, 0.04) 0%, #0366D6 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-emerald:hover,
.bg-hover-emerald:focus,
.bg-hover-emerald:active,
.st_default_btn.bg-hover-emerald:hover,
.st_default_btn.bg-hover-emerald:focus,
.st_default_btn.bg-hover-emerald:active {
  background-color: #38D16A !important;
}

header.sticky-menu.sticky-bg-emerald {
  background: #38D16A !important;
}

.text-hover-emerald:hover,
.text-hover-emerald:focus,
.text-hover-emerald:active,
.st_default_btn.st_transparent.text-hover-emerald:hover,
.st_default_btn.st_transparent.text-hover-emerald:focus,
.st_default_btn.st_transparent.text-hover-emerald:active {
  color: #38D16A !important;
}

.fieldset-active-emerald input:checked + label {
  color: #38D16A !important;
}

.fieldset-active-emerald .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(56, 209, 106, 0.1) 20%, rgba(56, 209, 106, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(56, 209, 106, 0.1) 20%, rgba(56, 209, 106, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(56, 209, 106, 0.1) 20%, rgba(56, 209, 106, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-emerald .st-pricing-underlines .st-pricing-underlines-2 {
  background: #38D16A;
  background: -moz-linear-gradient(left, rgba(56, 209, 106, 0.04) 0%, #38D16A 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(56, 209, 106, 0.04) 0%, #38D16A 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(56, 209, 106, 0.04) 0%, #38D16A 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-chambray:hover,
.bg-hover-chambray:focus,
.bg-hover-chambray:active,
.st_default_btn.bg-hover-chambray:hover,
.st_default_btn.bg-hover-chambray:focus,
.st_default_btn.bg-hover-chambray:active {
  background-color: #3B3E99 !important;
}

header.sticky-menu.sticky-bg-chambray {
  background: #3B3E99 !important;
}

.text-hover-chambray:hover,
.text-hover-chambray:focus,
.text-hover-chambray:active,
.st_default_btn.st_transparent.text-hover-chambray:hover,
.st_default_btn.st_transparent.text-hover-chambray:focus,
.st_default_btn.st_transparent.text-hover-chambray:active {
  color: #3B3E99 !important;
}

.fieldset-active-chambray input:checked + label {
  color: #3B3E99 !important;
}

.fieldset-active-chambray .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(59, 62, 153, 0.1) 20%, rgba(59, 62, 153, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(59, 62, 153, 0.1) 20%, rgba(59, 62, 153, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(59, 62, 153, 0.1) 20%, rgba(59, 62, 153, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-chambray .st-pricing-underlines .st-pricing-underlines-2 {
  background: #3B3E99;
  background: -moz-linear-gradient(left, rgba(59, 62, 153, 0.04) 0%, #3B3E99 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(59, 62, 153, 0.04) 0%, #3B3E99 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(59, 62, 153, 0.04) 0%, #3B3E99 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-medium-purple:hover,
.bg-hover-medium-purple:focus,
.bg-hover-medium-purple:active,
.st_default_btn.bg-hover-medium-purple:hover,
.st_default_btn.bg-hover-medium-purple:focus,
.st_default_btn.bg-hover-medium-purple:active {
  background-color: #8066DC !important;
}

header.sticky-menu.sticky-bg-medium-purple {
  background: #8066DC !important;
}

.text-hover-medium-purple:hover,
.text-hover-medium-purple:focus,
.text-hover-medium-purple:active,
.st_default_btn.st_transparent.text-hover-medium-purple:hover,
.st_default_btn.st_transparent.text-hover-medium-purple:focus,
.st_default_btn.st_transparent.text-hover-medium-purple:active {
  color: #8066DC !important;
}

.fieldset-active-medium-purple input:checked + label {
  color: #8066DC !important;
}

.fieldset-active-medium-purple .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(128, 102, 220, 0.1) 20%, rgba(128, 102, 220, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(128, 102, 220, 0.1) 20%, rgba(128, 102, 220, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(128, 102, 220, 0.1) 20%, rgba(128, 102, 220, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-medium-purple .st-pricing-underlines .st-pricing-underlines-2 {
  background: #8066DC;
  background: -moz-linear-gradient(left, rgba(128, 102, 220, 0.04) 0%, #8066DC 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(128, 102, 220, 0.04) 0%, #8066DC 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(128, 102, 220, 0.04) 0%, #8066DC 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-lochmara:hover,
.bg-hover-lochmara:focus,
.bg-hover-lochmara:active,
.st_default_btn.bg-hover-lochmara:hover,
.st_default_btn.bg-hover-lochmara:focus,
.st_default_btn.bg-hover-lochmara:active {
  background-color: #0079BF !important;
}

header.sticky-menu.sticky-bg-lochmara {
  background: #0079BF !important;
}

.text-hover-lochmara:hover,
.text-hover-lochmara:focus,
.text-hover-lochmara:active,
.st_default_btn.st_transparent.text-hover-lochmara:hover,
.st_default_btn.st_transparent.text-hover-lochmara:focus,
.st_default_btn.st_transparent.text-hover-lochmara:active {
  color: #0079BF !important;
}

.fieldset-active-lochmara input:checked + label {
  color: #0079BF !important;
}

.fieldset-active-lochmara .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(0, 121, 191, 0.1) 20%, rgba(0, 121, 191, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(0, 121, 191, 0.1) 20%, rgba(0, 121, 191, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(0, 121, 191, 0.1) 20%, rgba(0, 121, 191, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-lochmara .st-pricing-underlines .st-pricing-underlines-2 {
  background: #0079BF;
  background: -moz-linear-gradient(left, rgba(0, 121, 191, 0.04) 0%, #0079BF 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 121, 191, 0.04) 0%, #0079BF 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(0, 121, 191, 0.04) 0%, #0079BF 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-froly:hover,
.bg-hover-froly:focus,
.bg-hover-froly:active,
.st_default_btn.bg-hover-froly:hover,
.st_default_btn.bg-hover-froly:focus,
.st_default_btn.bg-hover-froly:active {
  background-color: #F46F6C !important;
}

header.sticky-menu.sticky-bg-froly {
  background: #F46F6C !important;
}

.text-hover-froly:hover,
.text-hover-froly:focus,
.text-hover-froly:active,
.st_default_btn.st_transparent.text-hover-froly:hover,
.st_default_btn.st_transparent.text-hover-froly:focus,
.st_default_btn.st_transparent.text-hover-froly:active {
  color: #F46F6C !important;
}

.fieldset-active-froly input:checked + label {
  color: #F46F6C !important;
}

.fieldset-active-froly .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(244, 111, 108, 0.1) 20%, rgba(244, 111, 108, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(244, 111, 108, 0.1) 20%, rgba(244, 111, 108, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(244, 111, 108, 0.1) 20%, rgba(244, 111, 108, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-froly .st-pricing-underlines .st-pricing-underlines-2 {
  background: #F46F6C;
  background: -moz-linear-gradient(left, rgba(244, 111, 108, 0.04) 0%, #F46F6C 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(244, 111, 108, 0.04) 0%, #F46F6C 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(244, 111, 108, 0.04) 0%, #F46F6C 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-alpha-froly:hover,
.bg-hover-alpha-froly:focus,
.bg-hover-alpha-froly:active,
.st_default_btn.bg-hover-alpha-froly:hover,
.st_default_btn.bg-hover-alpha-froly:focus,
.st_default_btn.bg-hover-alpha-froly:active {
  background-color: rgba(244, 111, 108, 0.1) !important;
}

header.sticky-menu.sticky-bg-alpha-froly {
  background: rgba(244, 111, 108, 0.1) !important;
}

.text-hover-alpha-froly:hover,
.text-hover-alpha-froly:focus,
.text-hover-alpha-froly:active,
.st_default_btn.st_transparent.text-hover-alpha-froly:hover,
.st_default_btn.st_transparent.text-hover-alpha-froly:focus,
.st_default_btn.st_transparent.text-hover-alpha-froly:active {
  color: rgba(244, 111, 108, 0.1) !important;
}

.fieldset-active-alpha-froly input:checked + label {
  color: rgba(244, 111, 108, 0.1) !important;
}

.fieldset-active-alpha-froly .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(244, 111, 108, 0.1) 20%, rgba(244, 111, 108, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(244, 111, 108, 0.1) 20%, rgba(244, 111, 108, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(244, 111, 108, 0.1) 20%, rgba(244, 111, 108, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-alpha-froly .st-pricing-underlines .st-pricing-underlines-2 {
  background: rgba(244, 111, 108, 0.1);
  background: -moz-linear-gradient(left, rgba(244, 111, 108, 0.04) 0%, rgba(244, 111, 108, 0.1) 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(244, 111, 108, 0.04) 0%, rgba(244, 111, 108, 0.1) 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(244, 111, 108, 0.04) 0%, rgba(244, 111, 108, 0.1) 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-sweet-pink:hover,
.bg-hover-sweet-pink:focus,
.bg-hover-sweet-pink:active,
.st_default_btn.bg-hover-sweet-pink:hover,
.st_default_btn.bg-hover-sweet-pink:focus,
.st_default_btn.bg-hover-sweet-pink:active {
  background-color: #FFA3A1 !important;
}

header.sticky-menu.sticky-bg-sweet-pink {
  background: #FFA3A1 !important;
}

.text-hover-sweet-pink:hover,
.text-hover-sweet-pink:focus,
.text-hover-sweet-pink:active,
.st_default_btn.st_transparent.text-hover-sweet-pink:hover,
.st_default_btn.st_transparent.text-hover-sweet-pink:focus,
.st_default_btn.st_transparent.text-hover-sweet-pink:active {
  color: #FFA3A1 !important;
}

.fieldset-active-sweet-pink input:checked + label {
  color: #FFA3A1 !important;
}

.fieldset-active-sweet-pink .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(255, 163, 161, 0.1) 20%, rgba(255, 163, 161, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(255, 163, 161, 0.1) 20%, rgba(255, 163, 161, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(255, 163, 161, 0.1) 20%, rgba(255, 163, 161, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-sweet-pink .st-pricing-underlines .st-pricing-underlines-2 {
  background: #FFA3A1;
  background: -moz-linear-gradient(left, rgba(255, 163, 161, 0.04) 0%, #FFA3A1 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 163, 161, 0.04) 0%, #FFA3A1 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(255, 163, 161, 0.04) 0%, #FFA3A1 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-koamaru:hover,
.bg-hover-koamaru:focus,
.bg-hover-koamaru:active,
.st_default_btn.bg-hover-koamaru:hover,
.st_default_btn.bg-hover-koamaru:focus,
.st_default_btn.bg-hover-koamaru:active {
  background-color: #1D1068 !important;
}

header.sticky-menu.sticky-bg-koamaru {
  background: #1D1068 !important;
}

.text-hover-koamaru:hover,
.text-hover-koamaru:focus,
.text-hover-koamaru:active,
.st_default_btn.st_transparent.text-hover-koamaru:hover,
.st_default_btn.st_transparent.text-hover-koamaru:focus,
.st_default_btn.st_transparent.text-hover-koamaru:active {
  color: #1D1068 !important;
}

.fieldset-active-koamaru input:checked + label {
  color: #1D1068 !important;
}

.fieldset-active-koamaru .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(29, 16, 104, 0.1) 20%, rgba(29, 16, 104, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(29, 16, 104, 0.1) 20%, rgba(29, 16, 104, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(29, 16, 104, 0.1) 20%, rgba(29, 16, 104, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-koamaru .st-pricing-underlines .st-pricing-underlines-2 {
  background: #1D1068;
  background: -moz-linear-gradient(left, rgba(29, 16, 104, 0.04) 0%, #1D1068 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(29, 16, 104, 0.04) 0%, #1D1068 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(29, 16, 104, 0.04) 0%, #1D1068 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-haiti:hover,
.bg-hover-haiti:focus,
.bg-hover-haiti:active,
.st_default_btn.bg-hover-haiti:hover,
.st_default_btn.bg-hover-haiti:focus,
.st_default_btn.bg-hover-haiti:active {
  background-color: #140F30 !important;
}

header.sticky-menu.sticky-bg-haiti {
  background: #140F30 !important;
}

.text-hover-haiti:hover,
.text-hover-haiti:focus,
.text-hover-haiti:active,
.st_default_btn.st_transparent.text-hover-haiti:hover,
.st_default_btn.st_transparent.text-hover-haiti:focus,
.st_default_btn.st_transparent.text-hover-haiti:active {
  color: #140F30 !important;
}

.fieldset-active-haiti input:checked + label {
  color: #140F30 !important;
}

.fieldset-active-haiti .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(20, 15, 48, 0.1) 20%, rgba(20, 15, 48, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(20, 15, 48, 0.1) 20%, rgba(20, 15, 48, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(20, 15, 48, 0.1) 20%, rgba(20, 15, 48, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-haiti .st-pricing-underlines .st-pricing-underlines-2 {
  background: #140F30;
  background: -moz-linear-gradient(left, rgba(20, 15, 48, 0.04) 0%, #140F30 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(20, 15, 48, 0.04) 0%, #140F30 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(20, 15, 48, 0.04) 0%, #140F30 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-magnolia:hover,
.bg-hover-magnolia:focus,
.bg-hover-magnolia:active,
.st_default_btn.bg-hover-magnolia:hover,
.st_default_btn.bg-hover-magnolia:focus,
.st_default_btn.bg-hover-magnolia:active {
  background-color: #F9F8FF !important;
}

header.sticky-menu.sticky-bg-magnolia {
  background: #F9F8FF !important;
}

.text-hover-magnolia:hover,
.text-hover-magnolia:focus,
.text-hover-magnolia:active,
.st_default_btn.st_transparent.text-hover-magnolia:hover,
.st_default_btn.st_transparent.text-hover-magnolia:focus,
.st_default_btn.st_transparent.text-hover-magnolia:active {
  color: #F9F8FF !important;
}

.fieldset-active-magnolia input:checked + label {
  color: #F9F8FF !important;
}

.fieldset-active-magnolia .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(249, 248, 255, 0.1) 20%, rgba(249, 248, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(249, 248, 255, 0.1) 20%, rgba(249, 248, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(249, 248, 255, 0.1) 20%, rgba(249, 248, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-magnolia .st-pricing-underlines .st-pricing-underlines-2 {
  background: #F9F8FF;
  background: -moz-linear-gradient(left, rgba(249, 248, 255, 0.04) 0%, #F9F8FF 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(249, 248, 255, 0.04) 0%, #F9F8FF 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(249, 248, 255, 0.04) 0%, #F9F8FF 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-alice-blue:hover,
.bg-hover-alice-blue:focus,
.bg-hover-alice-blue:active,
.st_default_btn.bg-hover-alice-blue:hover,
.st_default_btn.bg-hover-alice-blue:focus,
.st_default_btn.bg-hover-alice-blue:active {
  background-color: #F5FBFF !important;
}

header.sticky-menu.sticky-bg-alice-blue {
  background: #F5FBFF !important;
}

.text-hover-alice-blue:hover,
.text-hover-alice-blue:focus,
.text-hover-alice-blue:active,
.st_default_btn.st_transparent.text-hover-alice-blue:hover,
.st_default_btn.st_transparent.text-hover-alice-blue:focus,
.st_default_btn.st_transparent.text-hover-alice-blue:active {
  color: #F5FBFF !important;
}

.fieldset-active-alice-blue input:checked + label {
  color: #F5FBFF !important;
}

.fieldset-active-alice-blue .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(245, 251, 255, 0.1) 20%, rgba(245, 251, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(245, 251, 255, 0.1) 20%, rgba(245, 251, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(245, 251, 255, 0.1) 20%, rgba(245, 251, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-alice-blue .st-pricing-underlines .st-pricing-underlines-2 {
  background: #F5FBFF;
  background: -moz-linear-gradient(left, rgba(245, 251, 255, 0.04) 0%, #F5FBFF 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(245, 251, 255, 0.04) 0%, #F5FBFF 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(245, 251, 255, 0.04) 0%, #F5FBFF 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-polar:hover,
.bg-hover-polar:focus,
.bg-hover-polar:active,
.st_default_btn.bg-hover-polar:hover,
.st_default_btn.bg-hover-polar:focus,
.st_default_btn.bg-hover-polar:active {
  background-color: #F5F9FD !important;
}

header.sticky-menu.sticky-bg-polar {
  background: #F5F9FD !important;
}

.text-hover-polar:hover,
.text-hover-polar:focus,
.text-hover-polar:active,
.st_default_btn.st_transparent.text-hover-polar:hover,
.st_default_btn.st_transparent.text-hover-polar:focus,
.st_default_btn.st_transparent.text-hover-polar:active {
  color: #F5F9FD !important;
}

.fieldset-active-polar input:checked + label {
  color: #F5F9FD !important;
}

.fieldset-active-polar .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(245, 249, 253, 0.1) 20%, rgba(245, 249, 253, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(245, 249, 253, 0.1) 20%, rgba(245, 249, 253, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(245, 249, 253, 0.1) 20%, rgba(245, 249, 253, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-polar .st-pricing-underlines .st-pricing-underlines-2 {
  background: #F5F9FD;
  background: -moz-linear-gradient(left, rgba(245, 249, 253, 0.04) 0%, #F5F9FD 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(245, 249, 253, 0.04) 0%, #F5F9FD 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(245, 249, 253, 0.04) 0%, #F5F9FD 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-st-primary:hover,
.bg-hover-st-primary:focus,
.bg-hover-st-primary:active,
.st_default_btn.bg-hover-st-primary:hover,
.st_default_btn.bg-hover-st-primary:focus,
.st_default_btn.bg-hover-st-primary:active {
  background-color: #4A516A !important;
}

header.sticky-menu.sticky-bg-st-primary {
  background: #4A516A !important;
}

.text-hover-st-primary:hover,
.text-hover-st-primary:focus,
.text-hover-st-primary:active,
.st_default_btn.st_transparent.text-hover-st-primary:hover,
.st_default_btn.st_transparent.text-hover-st-primary:focus,
.st_default_btn.st_transparent.text-hover-st-primary:active {
  color: #4A516A !important;
}

.fieldset-active-st-primary input:checked + label {
  color: #4A516A !important;
}

.fieldset-active-st-primary .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(74, 81, 106, 0.1) 20%, rgba(74, 81, 106, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(74, 81, 106, 0.1) 20%, rgba(74, 81, 106, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(74, 81, 106, 0.1) 20%, rgba(74, 81, 106, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-st-primary .st-pricing-underlines .st-pricing-underlines-2 {
  background: #4A516A;
  background: -moz-linear-gradient(left, rgba(74, 81, 106, 0.04) 0%, #4A516A 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(74, 81, 106, 0.04) 0%, #4A516A 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(74, 81, 106, 0.04) 0%, #4A516A 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-st-white:hover,
.bg-hover-st-white:focus,
.bg-hover-st-white:active,
.st_default_btn.bg-hover-st-white:hover,
.st_default_btn.bg-hover-st-white:focus,
.st_default_btn.bg-hover-st-white:active {
  background-color: #FFFFFF !important;
}

header.sticky-menu.sticky-bg-st-white {
  background: #FFFFFF !important;
}

.text-hover-st-white:hover,
.text-hover-st-white:focus,
.text-hover-st-white:active,
.st_default_btn.st_transparent.text-hover-st-white:hover,
.st_default_btn.st_transparent.text-hover-st-white:focus,
.st_default_btn.st_transparent.text-hover-st-white:active {
  color: #FFFFFF !important;
}

.fieldset-active-st-white input:checked + label {
  color: #FFFFFF !important;
}

.fieldset-active-st-white .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.1) 20%, rgba(255, 255, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.1) 20%, rgba(255, 255, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.1) 20%, rgba(255, 255, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-st-white .st-pricing-underlines .st-pricing-underlines-2 {
  background: #FFFFFF;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.04) 0%, #FFFFFF 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.04) 0%, #FFFFFF 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.04) 0%, #FFFFFF 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-st-black:hover,
.bg-hover-st-black:focus,
.bg-hover-st-black:active,
.st_default_btn.bg-hover-st-black:hover,
.st_default_btn.bg-hover-st-black:focus,
.st_default_btn.bg-hover-st-black:active {
  background-color: #000000 !important;
}

header.sticky-menu.sticky-bg-st-black {
  background: #000000 !important;
}

.text-hover-st-black:hover,
.text-hover-st-black:focus,
.text-hover-st-black:active,
.st_default_btn.st_transparent.text-hover-st-black:hover,
.st_default_btn.st_transparent.text-hover-st-black:focus,
.st_default_btn.st_transparent.text-hover-st-black:active {
  color: #000000 !important;
}

.fieldset-active-st-black input:checked + label {
  color: #000000 !important;
}

.fieldset-active-st-black .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-st-black .st-pricing-underlines .st-pricing-underlines-2 {
  background: #000000;
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.04) 0%, #000000 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.04) 0%, #000000 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.04) 0%, #000000 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-st-fog:hover,
.bg-hover-st-fog:focus,
.bg-hover-st-fog:active,
.st_default_btn.bg-hover-st-fog:hover,
.st_default_btn.bg-hover-st-fog:focus,
.st_default_btn.bg-hover-st-fog:active {
  background-color: #D6CCFF !important;
}

header.sticky-menu.sticky-bg-st-fog {
  background: #D6CCFF !important;
}

.text-hover-st-fog:hover,
.text-hover-st-fog:focus,
.text-hover-st-fog:active,
.st_default_btn.st_transparent.text-hover-st-fog:hover,
.st_default_btn.st_transparent.text-hover-st-fog:focus,
.st_default_btn.st_transparent.text-hover-st-fog:active {
  color: #D6CCFF !important;
}

.fieldset-active-st-fog input:checked + label {
  color: #D6CCFF !important;
}

.fieldset-active-st-fog .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(214, 204, 255, 0.1) 20%, rgba(214, 204, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(214, 204, 255, 0.1) 20%, rgba(214, 204, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(214, 204, 255, 0.1) 20%, rgba(214, 204, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-st-fog .st-pricing-underlines .st-pricing-underlines-2 {
  background: #D6CCFF;
  background: -moz-linear-gradient(left, rgba(214, 204, 255, 0.04) 0%, #D6CCFF 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(214, 204, 255, 0.04) 0%, #D6CCFF 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(214, 204, 255, 0.04) 0%, #D6CCFF 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-st-gray:hover,
.bg-hover-st-gray:focus,
.bg-hover-st-gray:active,
.st_default_btn.bg-hover-st-gray:hover,
.st_default_btn.bg-hover-st-gray:focus,
.st_default_btn.bg-hover-st-gray:active {
  background-color: #999999 !important;
}

header.sticky-menu.sticky-bg-st-gray {
  background: #999999 !important;
}

.text-hover-st-gray:hover,
.text-hover-st-gray:focus,
.text-hover-st-gray:active,
.st_default_btn.st_transparent.text-hover-st-gray:hover,
.st_default_btn.st_transparent.text-hover-st-gray:focus,
.st_default_btn.st_transparent.text-hover-st-gray:active {
  color: #999999 !important;
}

.fieldset-active-st-gray input:checked + label {
  color: #999999 !important;
}

.fieldset-active-st-gray .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(153, 153, 153, 0.1) 20%, rgba(153, 153, 153, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(153, 153, 153, 0.1) 20%, rgba(153, 153, 153, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(153, 153, 153, 0.1) 20%, rgba(153, 153, 153, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-st-gray .st-pricing-underlines .st-pricing-underlines-2 {
  background: #999999;
  background: -moz-linear-gradient(left, rgba(153, 153, 153, 0.04) 0%, #999999 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(153, 153, 153, 0.04) 0%, #999999 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(153, 153, 153, 0.04) 0%, #999999 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-st-concrete:hover,
.bg-hover-st-concrete:focus,
.bg-hover-st-concrete:active,
.st_default_btn.bg-hover-st-concrete:hover,
.st_default_btn.bg-hover-st-concrete:focus,
.st_default_btn.bg-hover-st-concrete:active {
  background-color: #F3F3F3 !important;
}

header.sticky-menu.sticky-bg-st-concrete {
  background: #F3F3F3 !important;
}

.text-hover-st-concrete:hover,
.text-hover-st-concrete:focus,
.text-hover-st-concrete:active,
.st_default_btn.st_transparent.text-hover-st-concrete:hover,
.st_default_btn.st_transparent.text-hover-st-concrete:focus,
.st_default_btn.st_transparent.text-hover-st-concrete:active {
  color: #F3F3F3 !important;
}

.fieldset-active-st-concrete input:checked + label {
  color: #F3F3F3 !important;
}

.fieldset-active-st-concrete .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(243, 243, 243, 0.1) 20%, rgba(243, 243, 243, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(243, 243, 243, 0.1) 20%, rgba(243, 243, 243, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(243, 243, 243, 0.1) 20%, rgba(243, 243, 243, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-st-concrete .st-pricing-underlines .st-pricing-underlines-2 {
  background: #F3F3F3;
  background: -moz-linear-gradient(left, rgba(243, 243, 243, 0.04) 0%, #F3F3F3 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(243, 243, 243, 0.04) 0%, #F3F3F3 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(243, 243, 243, 0.04) 0%, #F3F3F3 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-st-violet:hover,
.bg-hover-st-violet:focus,
.bg-hover-st-violet:active,
.st_default_btn.bg-hover-st-violet:hover,
.st_default_btn.bg-hover-st-violet:focus,
.st_default_btn.bg-hover-st-violet:active {
  background-color: #D52EEF !important;
}

header.sticky-menu.sticky-bg-st-violet {
  background: #D52EEF !important;
}

.text-hover-st-violet:hover,
.text-hover-st-violet:focus,
.text-hover-st-violet:active,
.st_default_btn.st_transparent.text-hover-st-violet:hover,
.st_default_btn.st_transparent.text-hover-st-violet:focus,
.st_default_btn.st_transparent.text-hover-st-violet:active {
  color: #D52EEF !important;
}

.fieldset-active-st-violet input:checked + label {
  color: #D52EEF !important;
}

.fieldset-active-st-violet .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(213, 46, 239, 0.1) 20%, rgba(213, 46, 239, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(213, 46, 239, 0.1) 20%, rgba(213, 46, 239, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(213, 46, 239, 0.1) 20%, rgba(213, 46, 239, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-st-violet .st-pricing-underlines .st-pricing-underlines-2 {
  background: #D52EEF;
  background: -moz-linear-gradient(left, rgba(213, 46, 239, 0.04) 0%, #D52EEF 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(213, 46, 239, 0.04) 0%, #D52EEF 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(213, 46, 239, 0.04) 0%, #D52EEF 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-sun-glow:hover,
.bg-hover-sun-glow:focus,
.bg-hover-sun-glow:active,
.st_default_btn.bg-hover-sun-glow:hover,
.st_default_btn.bg-hover-sun-glow:focus,
.st_default_btn.bg-hover-sun-glow:active {
  background-color: #FCCC2C !important;
}

header.sticky-menu.sticky-bg-sun-glow {
  background: #FCCC2C !important;
}

.text-hover-sun-glow:hover,
.text-hover-sun-glow:focus,
.text-hover-sun-glow:active,
.st_default_btn.st_transparent.text-hover-sun-glow:hover,
.st_default_btn.st_transparent.text-hover-sun-glow:focus,
.st_default_btn.st_transparent.text-hover-sun-glow:active {
  color: #FCCC2C !important;
}

.fieldset-active-sun-glow input:checked + label {
  color: #FCCC2C !important;
}

.fieldset-active-sun-glow .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(252, 204, 44, 0.1) 20%, rgba(252, 204, 44, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(252, 204, 44, 0.1) 20%, rgba(252, 204, 44, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(252, 204, 44, 0.1) 20%, rgba(252, 204, 44, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-sun-glow .st-pricing-underlines .st-pricing-underlines-2 {
  background: #FCCC2C;
  background: -moz-linear-gradient(left, rgba(252, 204, 44, 0.04) 0%, #FCCC2C 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(252, 204, 44, 0.04) 0%, #FCCC2C 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(252, 204, 44, 0.04) 0%, #FCCC2C 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-st-cabaret:hover,
.bg-hover-st-cabaret:focus,
.bg-hover-st-cabaret:active,
.st_default_btn.bg-hover-st-cabaret:hover,
.st_default_btn.bg-hover-st-cabaret:focus,
.st_default_btn.bg-hover-st-cabaret:active {
  background-color: #D1386C !important;
}

header.sticky-menu.sticky-bg-st-cabaret {
  background: #D1386C !important;
}

.text-hover-st-cabaret:hover,
.text-hover-st-cabaret:focus,
.text-hover-st-cabaret:active,
.st_default_btn.st_transparent.text-hover-st-cabaret:hover,
.st_default_btn.st_transparent.text-hover-st-cabaret:focus,
.st_default_btn.st_transparent.text-hover-st-cabaret:active {
  color: #D1386C !important;
}

.fieldset-active-st-cabaret input:checked + label {
  color: #D1386C !important;
}

.fieldset-active-st-cabaret .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(209, 56, 108, 0.1) 20%, rgba(209, 56, 108, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(209, 56, 108, 0.1) 20%, rgba(209, 56, 108, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(209, 56, 108, 0.1) 20%, rgba(209, 56, 108, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-st-cabaret .st-pricing-underlines .st-pricing-underlines-2 {
  background: #D1386C;
  background: -moz-linear-gradient(left, rgba(209, 56, 108, 0.04) 0%, #D1386C 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(209, 56, 108, 0.04) 0%, #D1386C 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(209, 56, 108, 0.04) 0%, #D1386C 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-st-zircon:hover,
.bg-hover-st-zircon:focus,
.bg-hover-st-zircon:active,
.st_default_btn.bg-hover-st-zircon:hover,
.st_default_btn.bg-hover-st-zircon:focus,
.st_default_btn.bg-hover-st-zircon:active {
  background-color: #F8FAFF !important;
}

header.sticky-menu.sticky-bg-st-zircon {
  background: #F8FAFF !important;
}

.text-hover-st-zircon:hover,
.text-hover-st-zircon:focus,
.text-hover-st-zircon:active,
.st_default_btn.st_transparent.text-hover-st-zircon:hover,
.st_default_btn.st_transparent.text-hover-st-zircon:focus,
.st_default_btn.st_transparent.text-hover-st-zircon:active {
  color: #F8FAFF !important;
}

.fieldset-active-st-zircon input:checked + label {
  color: #F8FAFF !important;
}

.fieldset-active-st-zircon .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(248, 250, 255, 0.1) 20%, rgba(248, 250, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(248, 250, 255, 0.1) 20%, rgba(248, 250, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(248, 250, 255, 0.1) 20%, rgba(248, 250, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-st-zircon .st-pricing-underlines .st-pricing-underlines-2 {
  background: #F8FAFF;
  background: -moz-linear-gradient(left, rgba(248, 250, 255, 0.04) 0%, #F8FAFF 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(248, 250, 255, 0.04) 0%, #F8FAFF 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(248, 250, 255, 0.04) 0%, #F8FAFF 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-clear-day:hover,
.bg-hover-clear-day:focus,
.bg-hover-clear-day:active,
.st_default_btn.bg-hover-clear-day:hover,
.st_default_btn.bg-hover-clear-day:focus,
.st_default_btn.bg-hover-clear-day:active {
  background-color: #F9FFFD !important;
}

header.sticky-menu.sticky-bg-clear-day {
  background: #F9FFFD !important;
}

.text-hover-clear-day:hover,
.text-hover-clear-day:focus,
.text-hover-clear-day:active,
.st_default_btn.st_transparent.text-hover-clear-day:hover,
.st_default_btn.st_transparent.text-hover-clear-day:focus,
.st_default_btn.st_transparent.text-hover-clear-day:active {
  color: #F9FFFD !important;
}

.fieldset-active-clear-day input:checked + label {
  color: #F9FFFD !important;
}

.fieldset-active-clear-day .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(249, 255, 253, 0.1) 20%, rgba(249, 255, 253, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(249, 255, 253, 0.1) 20%, rgba(249, 255, 253, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(249, 255, 253, 0.1) 20%, rgba(249, 255, 253, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-clear-day .st-pricing-underlines .st-pricing-underlines-2 {
  background: #F9FFFD;
  background: -moz-linear-gradient(left, rgba(249, 255, 253, 0.04) 0%, #F9FFFD 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(249, 255, 253, 0.04) 0%, #F9FFFD 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(249, 255, 253, 0.04) 0%, #F9FFFD 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-st-shamrock:hover,
.bg-hover-st-shamrock:focus,
.bg-hover-st-shamrock:active,
.st_default_btn.bg-hover-st-shamrock:hover,
.st_default_btn.bg-hover-st-shamrock:focus,
.st_default_btn.bg-hover-st-shamrock:active {
  background-color: #3AD984 !important;
}

header.sticky-menu.sticky-bg-st-shamrock {
  background: #3AD984 !important;
}

.text-hover-st-shamrock:hover,
.text-hover-st-shamrock:focus,
.text-hover-st-shamrock:active,
.st_default_btn.st_transparent.text-hover-st-shamrock:hover,
.st_default_btn.st_transparent.text-hover-st-shamrock:focus,
.st_default_btn.st_transparent.text-hover-st-shamrock:active {
  color: #3AD984 !important;
}

.fieldset-active-st-shamrock input:checked + label {
  color: #3AD984 !important;
}

.fieldset-active-st-shamrock .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(58, 217, 132, 0.1) 20%, rgba(58, 217, 132, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(58, 217, 132, 0.1) 20%, rgba(58, 217, 132, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(58, 217, 132, 0.1) 20%, rgba(58, 217, 132, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-st-shamrock .st-pricing-underlines .st-pricing-underlines-2 {
  background: #3AD984;
  background: -moz-linear-gradient(left, rgba(58, 217, 132, 0.04) 0%, #3AD984 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(58, 217, 132, 0.04) 0%, #3AD984 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(58, 217, 132, 0.04) 0%, #3AD984 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-blue-bell:hover,
.bg-hover-blue-bell:focus,
.bg-hover-blue-bell:active,
.st_default_btn.bg-hover-blue-bell:hover,
.st_default_btn.bg-hover-blue-bell:focus,
.st_default_btn.bg-hover-blue-bell:active {
  background-color: #9D9ECE !important;
}

header.sticky-menu.sticky-bg-blue-bell {
  background: #9D9ECE !important;
}

.text-hover-blue-bell:hover,
.text-hover-blue-bell:focus,
.text-hover-blue-bell:active,
.st_default_btn.st_transparent.text-hover-blue-bell:hover,
.st_default_btn.st_transparent.text-hover-blue-bell:focus,
.st_default_btn.st_transparent.text-hover-blue-bell:active {
  color: #9D9ECE !important;
}

.fieldset-active-blue-bell input:checked + label {
  color: #9D9ECE !important;
}

.fieldset-active-blue-bell .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(157, 158, 206, 0.1) 20%, rgba(157, 158, 206, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(157, 158, 206, 0.1) 20%, rgba(157, 158, 206, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(157, 158, 206, 0.1) 20%, rgba(157, 158, 206, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-blue-bell .st-pricing-underlines .st-pricing-underlines-2 {
  background: #9D9ECE;
  background: -moz-linear-gradient(left, rgba(157, 158, 206, 0.04) 0%, #9D9ECE 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(157, 158, 206, 0.04) 0%, #9D9ECE 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(157, 158, 206, 0.04) 0%, #9D9ECE 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-snuff:hover,
.bg-hover-snuff:focus,
.bg-hover-snuff:active,
.st_default_btn.bg-hover-snuff:hover,
.st_default_btn.bg-hover-snuff:focus,
.st_default_btn.bg-hover-snuff:active {
  background-color: #DBD7E9 !important;
}

header.sticky-menu.sticky-bg-snuff {
  background: #DBD7E9 !important;
}

.text-hover-snuff:hover,
.text-hover-snuff:focus,
.text-hover-snuff:active,
.st_default_btn.st_transparent.text-hover-snuff:hover,
.st_default_btn.st_transparent.text-hover-snuff:focus,
.st_default_btn.st_transparent.text-hover-snuff:active {
  color: #DBD7E9 !important;
}

.fieldset-active-snuff input:checked + label {
  color: #DBD7E9 !important;
}

.fieldset-active-snuff .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(219, 215, 233, 0.1) 20%, rgba(219, 215, 233, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(219, 215, 233, 0.1) 20%, rgba(219, 215, 233, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(219, 215, 233, 0.1) 20%, rgba(219, 215, 233, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-snuff .st-pricing-underlines .st-pricing-underlines-2 {
  background: #DBD7E9;
  background: -moz-linear-gradient(left, rgba(219, 215, 233, 0.04) 0%, #DBD7E9 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(219, 215, 233, 0.04) 0%, #DBD7E9 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(219, 215, 233, 0.04) 0%, #DBD7E9 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-french-grey:hover,
.bg-hover-french-grey:focus,
.bg-hover-french-grey:active,
.st_default_btn.bg-hover-french-grey:hover,
.st_default_btn.bg-hover-french-grey:focus,
.st_default_btn.bg-hover-french-grey:active {
  background-color: #C3C3C7 !important;
}

header.sticky-menu.sticky-bg-french-grey {
  background: #C3C3C7 !important;
}

.text-hover-french-grey:hover,
.text-hover-french-grey:focus,
.text-hover-french-grey:active,
.st_default_btn.st_transparent.text-hover-french-grey:hover,
.st_default_btn.st_transparent.text-hover-french-grey:focus,
.st_default_btn.st_transparent.text-hover-french-grey:active {
  color: #C3C3C7 !important;
}

.fieldset-active-french-grey input:checked + label {
  color: #C3C3C7 !important;
}

.fieldset-active-french-grey .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(195, 195, 199, 0.1) 20%, rgba(195, 195, 199, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(195, 195, 199, 0.1) 20%, rgba(195, 195, 199, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(195, 195, 199, 0.1) 20%, rgba(195, 195, 199, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-french-grey .st-pricing-underlines .st-pricing-underlines-2 {
  background: #C3C3C7;
  background: -moz-linear-gradient(left, rgba(195, 195, 199, 0.04) 0%, #C3C3C7 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(195, 195, 199, 0.04) 0%, #C3C3C7 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(195, 195, 199, 0.04) 0%, #C3C3C7 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-ebony-clay:hover,
.bg-hover-ebony-clay:focus,
.bg-hover-ebony-clay:active,
.st_default_btn.bg-hover-ebony-clay:hover,
.st_default_btn.bg-hover-ebony-clay:focus,
.st_default_btn.bg-hover-ebony-clay:active {
  background-color: #1F2833 !important;
}

header.sticky-menu.sticky-bg-ebony-clay {
  background: #1F2833 !important;
}

.text-hover-ebony-clay:hover,
.text-hover-ebony-clay:focus,
.text-hover-ebony-clay:active,
.st_default_btn.st_transparent.text-hover-ebony-clay:hover,
.st_default_btn.st_transparent.text-hover-ebony-clay:focus,
.st_default_btn.st_transparent.text-hover-ebony-clay:active {
  color: #1F2833 !important;
}

.fieldset-active-ebony-clay input:checked + label {
  color: #1F2833 !important;
}

.fieldset-active-ebony-clay .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(31, 40, 51, 0.1) 20%, rgba(31, 40, 51, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(31, 40, 51, 0.1) 20%, rgba(31, 40, 51, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(31, 40, 51, 0.1) 20%, rgba(31, 40, 51, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-ebony-clay .st-pricing-underlines .st-pricing-underlines-2 {
  background: #1F2833;
  background: -moz-linear-gradient(left, rgba(31, 40, 51, 0.04) 0%, #1F2833 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(31, 40, 51, 0.04) 0%, #1F2833 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(31, 40, 51, 0.04) 0%, #1F2833 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-black-rock:hover,
.bg-hover-black-rock:focus,
.bg-hover-black-rock:active,
.st_default_btn.bg-hover-black-rock:hover,
.st_default_btn.bg-hover-black-rock:focus,
.st_default_btn.bg-hover-black-rock:active {
  background-color: #080327 !important;
}

header.sticky-menu.sticky-bg-black-rock {
  background: #080327 !important;
}

.text-hover-black-rock:hover,
.text-hover-black-rock:focus,
.text-hover-black-rock:active,
.st_default_btn.st_transparent.text-hover-black-rock:hover,
.st_default_btn.st_transparent.text-hover-black-rock:focus,
.st_default_btn.st_transparent.text-hover-black-rock:active {
  color: #080327 !important;
}

.fieldset-active-black-rock input:checked + label {
  color: #080327 !important;
}

.fieldset-active-black-rock .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(8, 3, 39, 0.1) 20%, rgba(8, 3, 39, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(8, 3, 39, 0.1) 20%, rgba(8, 3, 39, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(8, 3, 39, 0.1) 20%, rgba(8, 3, 39, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-black-rock .st-pricing-underlines .st-pricing-underlines-2 {
  background: #080327;
  background: -moz-linear-gradient(left, rgba(8, 3, 39, 0.04) 0%, #080327 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(8, 3, 39, 0.04) 0%, #080327 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(8, 3, 39, 0.04) 0%, #080327 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-st-lavender:hover,
.bg-hover-st-lavender:focus,
.bg-hover-st-lavender:active,
.st_default_btn.bg-hover-st-lavender:hover,
.st_default_btn.bg-hover-st-lavender:focus,
.st_default_btn.bg-hover-st-lavender:active {
  background-color: #B4B5D5 !important;
}

header.sticky-menu.sticky-bg-st-lavender {
  background: #B4B5D5 !important;
}

.text-hover-st-lavender:hover,
.text-hover-st-lavender:focus,
.text-hover-st-lavender:active,
.st_default_btn.st_transparent.text-hover-st-lavender:hover,
.st_default_btn.st_transparent.text-hover-st-lavender:focus,
.st_default_btn.st_transparent.text-hover-st-lavender:active {
  color: #B4B5D5 !important;
}

.fieldset-active-st-lavender input:checked + label {
  color: #B4B5D5 !important;
}

.fieldset-active-st-lavender .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(180, 181, 213, 0.1) 20%, rgba(180, 181, 213, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(180, 181, 213, 0.1) 20%, rgba(180, 181, 213, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(180, 181, 213, 0.1) 20%, rgba(180, 181, 213, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-st-lavender .st-pricing-underlines .st-pricing-underlines-2 {
  background: #B4B5D5;
  background: -moz-linear-gradient(left, rgba(180, 181, 213, 0.04) 0%, #B4B5D5 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(180, 181, 213, 0.04) 0%, #B4B5D5 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(180, 181, 213, 0.04) 0%, #B4B5D5 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-titan-white:hover,
.bg-hover-titan-white:focus,
.bg-hover-titan-white:active,
.st_default_btn.bg-hover-titan-white:hover,
.st_default_btn.bg-hover-titan-white:focus,
.st_default_btn.bg-hover-titan-white:active {
  background-color: #F5F5FF !important;
}

header.sticky-menu.sticky-bg-titan-white {
  background: #F5F5FF !important;
}

.text-hover-titan-white:hover,
.text-hover-titan-white:focus,
.text-hover-titan-white:active,
.st_default_btn.st_transparent.text-hover-titan-white:hover,
.st_default_btn.st_transparent.text-hover-titan-white:focus,
.st_default_btn.st_transparent.text-hover-titan-white:active {
  color: #F5F5FF !important;
}

.fieldset-active-titan-white input:checked + label {
  color: #F5F5FF !important;
}

.fieldset-active-titan-white .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(245, 245, 255, 0.1) 20%, rgba(245, 245, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(245, 245, 255, 0.1) 20%, rgba(245, 245, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(245, 245, 255, 0.1) 20%, rgba(245, 245, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-titan-white .st-pricing-underlines .st-pricing-underlines-2 {
  background: #F5F5FF;
  background: -moz-linear-gradient(left, rgba(245, 245, 255, 0.04) 0%, #F5F5FF 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(245, 245, 255, 0.04) 0%, #F5F5FF 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(245, 245, 255, 0.04) 0%, #F5F5FF 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-regent:hover,
.bg-hover-regent:focus,
.bg-hover-regent:active,
.st_default_btn.bg-hover-regent:hover,
.st_default_btn.bg-hover-regent:focus,
.st_default_btn.bg-hover-regent:active {
  background-color: #8D9BA4 !important;
}

header.sticky-menu.sticky-bg-regent {
  background: #8D9BA4 !important;
}

.text-hover-regent:hover,
.text-hover-regent:focus,
.text-hover-regent:active,
.st_default_btn.st_transparent.text-hover-regent:hover,
.st_default_btn.st_transparent.text-hover-regent:focus,
.st_default_btn.st_transparent.text-hover-regent:active {
  color: #8D9BA4 !important;
}

.fieldset-active-regent input:checked + label {
  color: #8D9BA4 !important;
}

.fieldset-active-regent .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(141, 155, 164, 0.1) 20%, rgba(141, 155, 164, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(141, 155, 164, 0.1) 20%, rgba(141, 155, 164, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(141, 155, 164, 0.1) 20%, rgba(141, 155, 164, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-regent .st-pricing-underlines .st-pricing-underlines-2 {
  background: #8D9BA4;
  background: -moz-linear-gradient(left, rgba(141, 155, 164, 0.04) 0%, #8D9BA4 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(141, 155, 164, 0.04) 0%, #8D9BA4 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(141, 155, 164, 0.04) 0%, #8D9BA4 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-shhamrock:hover,
.bg-hover-shhamrock:focus,
.bg-hover-shhamrock:active,
.st_default_btn.bg-hover-shhamrock:hover,
.st_default_btn.bg-hover-shhamrock:focus,
.st_default_btn.bg-hover-shhamrock:active {
  background-color: #40D1A7 !important;
}

header.sticky-menu.sticky-bg-shhamrock {
  background: #40D1A7 !important;
}

.text-hover-shhamrock:hover,
.text-hover-shhamrock:focus,
.text-hover-shhamrock:active,
.st_default_btn.st_transparent.text-hover-shhamrock:hover,
.st_default_btn.st_transparent.text-hover-shhamrock:focus,
.st_default_btn.st_transparent.text-hover-shhamrock:active {
  color: #40D1A7 !important;
}

.fieldset-active-shhamrock input:checked + label {
  color: #40D1A7 !important;
}

.fieldset-active-shhamrock .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(64, 209, 167, 0.1) 20%, rgba(64, 209, 167, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(64, 209, 167, 0.1) 20%, rgba(64, 209, 167, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(64, 209, 167, 0.1) 20%, rgba(64, 209, 167, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-shhamrock .st-pricing-underlines .st-pricing-underlines-2 {
  background: #40D1A7;
  background: -moz-linear-gradient(left, rgba(64, 209, 167, 0.04) 0%, #40D1A7 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(64, 209, 167, 0.04) 0%, #40D1A7 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(64, 209, 167, 0.04) 0%, #40D1A7 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-gray-light:hover,
.bg-hover-gray-light:focus,
.bg-hover-gray-light:active,
.st_default_btn.bg-hover-gray-light:hover,
.st_default_btn.bg-hover-gray-light:focus,
.st_default_btn.bg-hover-gray-light:active {
  background-color: #f0f2f8 !important;
}

header.sticky-menu.sticky-bg-gray-light {
  background: #f0f2f8 !important;
}

.text-hover-gray-light:hover,
.text-hover-gray-light:focus,
.text-hover-gray-light:active,
.st_default_btn.st_transparent.text-hover-gray-light:hover,
.st_default_btn.st_transparent.text-hover-gray-light:focus,
.st_default_btn.st_transparent.text-hover-gray-light:active {
  color: #f0f2f8 !important;
}

.fieldset-active-gray-light input:checked + label {
  color: #f0f2f8 !important;
}

.fieldset-active-gray-light .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(240, 242, 248, 0.1) 20%, rgba(240, 242, 248, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(240, 242, 248, 0.1) 20%, rgba(240, 242, 248, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(240, 242, 248, 0.1) 20%, rgba(240, 242, 248, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-gray-light .st-pricing-underlines .st-pricing-underlines-2 {
  background: #f0f2f8;
  background: -moz-linear-gradient(left, rgba(240, 242, 248, 0.04) 0%, #f0f2f8 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(240, 242, 248, 0.04) 0%, #f0f2f8 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(240, 242, 248, 0.04) 0%, #f0f2f8 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-gray:hover,
.bg-hover-gray:focus,
.bg-hover-gray:active,
.st_default_btn.bg-hover-gray:hover,
.st_default_btn.bg-hover-gray:focus,
.st_default_btn.bg-hover-gray:active {
  background-color: #b6beda !important;
}

header.sticky-menu.sticky-bg-gray {
  background: #b6beda !important;
}

.text-hover-gray:hover,
.text-hover-gray:focus,
.text-hover-gray:active,
.st_default_btn.st_transparent.text-hover-gray:hover,
.st_default_btn.st_transparent.text-hover-gray:focus,
.st_default_btn.st_transparent.text-hover-gray:active {
  color: #b6beda !important;
}

.fieldset-active-gray input:checked + label {
  color: #b6beda !important;
}

.fieldset-active-gray .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(182, 190, 218, 0.1) 20%, rgba(182, 190, 218, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(182, 190, 218, 0.1) 20%, rgba(182, 190, 218, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(182, 190, 218, 0.1) 20%, rgba(182, 190, 218, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-gray .st-pricing-underlines .st-pricing-underlines-2 {
  background: #b6beda;
  background: -moz-linear-gradient(left, rgba(182, 190, 218, 0.04) 0%, #b6beda 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(182, 190, 218, 0.04) 0%, #b6beda 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(182, 190, 218, 0.04) 0%, #b6beda 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-gray-dark:hover,
.bg-hover-gray-dark:focus,
.bg-hover-gray-dark:active,
.st_default_btn.bg-hover-gray-dark:hover,
.st_default_btn.bg-hover-gray-dark:focus,
.st_default_btn.bg-hover-gray-dark:active {
  background-color: #2f3447 !important;
}

header.sticky-menu.sticky-bg-gray-dark {
  background: #2f3447 !important;
}

.text-hover-gray-dark:hover,
.text-hover-gray-dark:focus,
.text-hover-gray-dark:active,
.st_default_btn.st_transparent.text-hover-gray-dark:hover,
.st_default_btn.st_transparent.text-hover-gray-dark:focus,
.st_default_btn.st_transparent.text-hover-gray-dark:active {
  color: #2f3447 !important;
}

.fieldset-active-gray-dark input:checked + label {
  color: #2f3447 !important;
}

.fieldset-active-gray-dark .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(47, 52, 71, 0.1) 20%, rgba(47, 52, 71, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(47, 52, 71, 0.1) 20%, rgba(47, 52, 71, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(47, 52, 71, 0.1) 20%, rgba(47, 52, 71, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-gray-dark .st-pricing-underlines .st-pricing-underlines-2 {
  background: #2f3447;
  background: -moz-linear-gradient(left, rgba(47, 52, 71, 0.04) 0%, #2f3447 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(47, 52, 71, 0.04) 0%, #2f3447 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(47, 52, 71, 0.04) 0%, #2f3447 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-blue-darker:hover,
.bg-hover-blue-darker:focus,
.bg-hover-blue-darker:active,
.st_default_btn.bg-hover-blue-darker:hover,
.st_default_btn.bg-hover-blue-darker:focus,
.st_default_btn.bg-hover-blue-darker:active {
  background-color: #3f455c !important;
}

header.sticky-menu.sticky-bg-blue-darker {
  background: #3f455c !important;
}

.text-hover-blue-darker:hover,
.text-hover-blue-darker:focus,
.text-hover-blue-darker:active,
.st_default_btn.st_transparent.text-hover-blue-darker:hover,
.st_default_btn.st_transparent.text-hover-blue-darker:focus,
.st_default_btn.st_transparent.text-hover-blue-darker:active {
  color: #3f455c !important;
}

.fieldset-active-blue-darker input:checked + label {
  color: #3f455c !important;
}

.fieldset-active-blue-darker .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(63, 69, 92, 0.1) 20%, rgba(63, 69, 92, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(63, 69, 92, 0.1) 20%, rgba(63, 69, 92, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(63, 69, 92, 0.1) 20%, rgba(63, 69, 92, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-blue-darker .st-pricing-underlines .st-pricing-underlines-2 {
  background: #3f455c;
  background: -moz-linear-gradient(left, rgba(63, 69, 92, 0.04) 0%, #3f455c 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(63, 69, 92, 0.04) 0%, #3f455c 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(63, 69, 92, 0.04) 0%, #3f455c 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-blue-dark:hover,
.bg-hover-blue-dark:focus,
.bg-hover-blue-dark:active,
.st_default_btn.bg-hover-blue-dark:hover,
.st_default_btn.bg-hover-blue-dark:focus,
.st_default_btn.bg-hover-blue-dark:active {
  background-color: #4a516a !important;
}

header.sticky-menu.sticky-bg-blue-dark {
  background: #4a516a !important;
}

.text-hover-blue-dark:hover,
.text-hover-blue-dark:focus,
.text-hover-blue-dark:active,
.st_default_btn.st_transparent.text-hover-blue-dark:hover,
.st_default_btn.st_transparent.text-hover-blue-dark:focus,
.st_default_btn.st_transparent.text-hover-blue-dark:active {
  color: #4a516a !important;
}

.fieldset-active-blue-dark input:checked + label {
  color: #4a516a !important;
}

.fieldset-active-blue-dark .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(74, 81, 106, 0.1) 20%, rgba(74, 81, 106, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(74, 81, 106, 0.1) 20%, rgba(74, 81, 106, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(74, 81, 106, 0.1) 20%, rgba(74, 81, 106, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-blue-dark .st-pricing-underlines .st-pricing-underlines-2 {
  background: #4a516a;
  background: -moz-linear-gradient(left, rgba(74, 81, 106, 0.04) 0%, #4a516a 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(74, 81, 106, 0.04) 0%, #4a516a 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(74, 81, 106, 0.04) 0%, #4a516a 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-blue:hover,
.bg-hover-blue:focus,
.bg-hover-blue:active,
.st_default_btn.bg-hover-blue:hover,
.st_default_btn.bg-hover-blue:focus,
.st_default_btn.bg-hover-blue:active {
  background-color: #2bbcff !important;
}

header.sticky-menu.sticky-bg-blue {
  background: #2bbcff !important;
}

.text-hover-blue:hover,
.text-hover-blue:focus,
.text-hover-blue:active,
.st_default_btn.st_transparent.text-hover-blue:hover,
.st_default_btn.st_transparent.text-hover-blue:focus,
.st_default_btn.st_transparent.text-hover-blue:active {
  color: #2bbcff !important;
}

.fieldset-active-blue input:checked + label {
  color: #2bbcff !important;
}

.fieldset-active-blue .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(43, 188, 255, 0.1) 20%, rgba(43, 188, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(43, 188, 255, 0.1) 20%, rgba(43, 188, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(43, 188, 255, 0.1) 20%, rgba(43, 188, 255, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-blue .st-pricing-underlines .st-pricing-underlines-2 {
  background: #2bbcff;
  background: -moz-linear-gradient(left, rgba(43, 188, 255, 0.04) 0%, #2bbcff 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(43, 188, 255, 0.04) 0%, #2bbcff 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(43, 188, 255, 0.04) 0%, #2bbcff 50%, rgba(255, 255, 255, 0.1) 100%);
}

.bg-hover-green:hover,
.bg-hover-green:focus,
.bg-hover-green:active,
.st_default_btn.bg-hover-green:hover,
.st_default_btn.bg-hover-green:focus,
.st_default_btn.bg-hover-green:active {
  background-color: #2ad9d3 !important;
}

header.sticky-menu.sticky-bg-green {
  background: #2ad9d3 !important;
}

.text-hover-green:hover,
.text-hover-green:focus,
.text-hover-green:active,
.st_default_btn.st_transparent.text-hover-green:hover,
.st_default_btn.st_transparent.text-hover-green:focus,
.st_default_btn.st_transparent.text-hover-green:active {
  color: #2ad9d3 !important;
}

.fieldset-active-green input:checked + label {
  color: #2ad9d3 !important;
}

.fieldset-active-green .st-pricing-underlines .st-pricing-underlines-1 {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(42, 217, 211, 0.1) 20%, rgba(42, 217, 211, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(42, 217, 211, 0.1) 20%, rgba(42, 217, 211, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(42, 217, 211, 0.1) 20%, rgba(42, 217, 211, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
}

.fieldset-active-green .st-pricing-underlines .st-pricing-underlines-2 {
  background: #2ad9d3;
  background: -moz-linear-gradient(left, rgba(42, 217, 211, 0.04) 0%, #2ad9d3 50%, rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(42, 217, 211, 0.04) 0%, #2ad9d3 50%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(42, 217, 211, 0.04) 0%, #2ad9d3 50%, rgba(255, 255, 255, 0.1) 100%);
}

@media only screen and (max-width: 1499.98px) {
  .bg-xl-gigas {
    background-color: #5040A0 !important;
  }
  .rgba-xl-gigas {
    background-color: rgba(80, 64, 160, 0.1) !important;
  }
  .text-xl-gigas {
    color: #5040A0 !important;
  }
  .bg-xl-perison-blue {
    background-color: #1E43DD !important;
  }
  .rgba-xl-perison-blue {
    background-color: rgba(30, 67, 221, 0.1) !important;
  }
  .text-xl-perison-blue {
    color: #1E43DD !important;
  }
  .bg-xl-st-aqua {
    background-color: #00E3F6 !important;
  }
  .rgba-xl-st-aqua {
    background-color: rgba(0, 227, 246, 0.1) !important;
  }
  .text-xl-st-aqua {
    color: #00E3F6 !important;
  }
  .bg-xl-shamrock {
    background-color: #3AD984 !important;
  }
  .rgba-xl-shamrock {
    background-color: rgba(58, 217, 132, 0.1) !important;
  }
  .text-xl-shamrock {
    color: #3AD984 !important;
  }
  .bg-xl-yellow-orange {
    background-color: #FFAA3B !important;
  }
  .rgba-xl-yellow-orange {
    background-color: rgba(255, 170, 59, 0.1) !important;
  }
  .text-xl-yellow-orange {
    color: #FFAA3B !important;
  }
  .bg-xl-st-pink {
    background-color: #F23F79 !important;
  }
  .rgba-xl-st-pink {
    background-color: rgba(242, 63, 121, 0.1) !important;
  }
  .text-xl-st-pink {
    color: #F23F79 !important;
  }
  .bg-xl-st-orange {
    background-color: #FF5D1C !important;
  }
  .rgba-xl-st-orange {
    background-color: rgba(255, 93, 28, 0.1) !important;
  }
  .text-xl-st-orange {
    color: #FF5D1C !important;
  }
  .bg-xl-science-blue {
    background-color: #0366D6 !important;
  }
  .rgba-xl-science-blue {
    background-color: rgba(3, 102, 214, 0.1) !important;
  }
  .text-xl-science-blue {
    color: #0366D6 !important;
  }
  .bg-xl-emerald {
    background-color: #38D16A !important;
  }
  .rgba-xl-emerald {
    background-color: rgba(56, 209, 106, 0.1) !important;
  }
  .text-xl-emerald {
    color: #38D16A !important;
  }
  .bg-xl-chambray {
    background-color: #3B3E99 !important;
  }
  .rgba-xl-chambray {
    background-color: rgba(59, 62, 153, 0.1) !important;
  }
  .text-xl-chambray {
    color: #3B3E99 !important;
  }
  .bg-xl-medium-purple {
    background-color: #8066DC !important;
  }
  .rgba-xl-medium-purple {
    background-color: rgba(128, 102, 220, 0.1) !important;
  }
  .text-xl-medium-purple {
    color: #8066DC !important;
  }
  .bg-xl-lochmara {
    background-color: #0079BF !important;
  }
  .rgba-xl-lochmara {
    background-color: rgba(0, 121, 191, 0.1) !important;
  }
  .text-xl-lochmara {
    color: #0079BF !important;
  }
  .bg-xl-froly {
    background-color: #F46F6C !important;
  }
  .rgba-xl-froly {
    background-color: rgba(244, 111, 108, 0.1) !important;
  }
  .text-xl-froly {
    color: #F46F6C !important;
  }
  .bg-xl-alpha-froly {
    background-color: rgba(244, 111, 108, 0.1) !important;
  }
  .rgba-xl-alpha-froly {
    background-color: rgba(244, 111, 108, 0.1) !important;
  }
  .text-xl-alpha-froly {
    color: rgba(244, 111, 108, 0.1) !important;
  }
  .bg-xl-sweet-pink {
    background-color: #FFA3A1 !important;
  }
  .rgba-xl-sweet-pink {
    background-color: rgba(255, 163, 161, 0.1) !important;
  }
  .text-xl-sweet-pink {
    color: #FFA3A1 !important;
  }
  .bg-xl-koamaru {
    background-color: #1D1068 !important;
  }
  .rgba-xl-koamaru {
    background-color: rgba(29, 16, 104, 0.1) !important;
  }
  .text-xl-koamaru {
    color: #1D1068 !important;
  }
  .bg-xl-haiti {
    background-color: #140F30 !important;
  }
  .rgba-xl-haiti {
    background-color: rgba(20, 15, 48, 0.1) !important;
  }
  .text-xl-haiti {
    color: #140F30 !important;
  }
  .bg-xl-magnolia {
    background-color: #F9F8FF !important;
  }
  .rgba-xl-magnolia {
    background-color: rgba(249, 248, 255, 0.1) !important;
  }
  .text-xl-magnolia {
    color: #F9F8FF !important;
  }
  .bg-xl-alice-blue {
    background-color: #F5FBFF !important;
  }
  .rgba-xl-alice-blue {
    background-color: rgba(245, 251, 255, 0.1) !important;
  }
  .text-xl-alice-blue {
    color: #F5FBFF !important;
  }
  .bg-xl-polar {
    background-color: #F5F9FD !important;
  }
  .rgba-xl-polar {
    background-color: rgba(245, 249, 253, 0.1) !important;
  }
  .text-xl-polar {
    color: #F5F9FD !important;
  }
  .bg-xl-st-primary {
    background-color: #4A516A !important;
  }
  .rgba-xl-st-primary {
    background-color: rgba(74, 81, 106, 0.1) !important;
  }
  .text-xl-st-primary {
    color: #4A516A !important;
  }
  .bg-xl-st-white {
    background-color: #FFFFFF !important;
  }
  .rgba-xl-st-white {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .text-xl-st-white {
    color: #FFFFFF !important;
  }
  .bg-xl-st-black {
    background-color: #000000 !important;
  }
  .rgba-xl-st-black {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
  .text-xl-st-black {
    color: #000000 !important;
  }
  .bg-xl-st-fog {
    background-color: #D6CCFF !important;
  }
  .rgba-xl-st-fog {
    background-color: rgba(214, 204, 255, 0.1) !important;
  }
  .text-xl-st-fog {
    color: #D6CCFF !important;
  }
  .bg-xl-st-gray {
    background-color: #999999 !important;
  }
  .rgba-xl-st-gray {
    background-color: rgba(153, 153, 153, 0.1) !important;
  }
  .text-xl-st-gray {
    color: #999999 !important;
  }
  .bg-xl-st-concrete {
    background-color: #F3F3F3 !important;
  }
  .rgba-xl-st-concrete {
    background-color: rgba(243, 243, 243, 0.1) !important;
  }
  .text-xl-st-concrete {
    color: #F3F3F3 !important;
  }
  .bg-xl-st-violet {
    background-color: #D52EEF !important;
  }
  .rgba-xl-st-violet {
    background-color: rgba(213, 46, 239, 0.1) !important;
  }
  .text-xl-st-violet {
    color: #D52EEF !important;
  }
  .bg-xl-sun-glow {
    background-color: #FCCC2C !important;
  }
  .rgba-xl-sun-glow {
    background-color: rgba(252, 204, 44, 0.1) !important;
  }
  .text-xl-sun-glow {
    color: #FCCC2C !important;
  }
  .bg-xl-st-cabaret {
    background-color: #D1386C !important;
  }
  .rgba-xl-st-cabaret {
    background-color: rgba(209, 56, 108, 0.1) !important;
  }
  .text-xl-st-cabaret {
    color: #D1386C !important;
  }
  .bg-xl-st-zircon {
    background-color: #F8FAFF !important;
  }
  .rgba-xl-st-zircon {
    background-color: rgba(248, 250, 255, 0.1) !important;
  }
  .text-xl-st-zircon {
    color: #F8FAFF !important;
  }
  .bg-xl-clear-day {
    background-color: #F9FFFD !important;
  }
  .rgba-xl-clear-day {
    background-color: rgba(249, 255, 253, 0.1) !important;
  }
  .text-xl-clear-day {
    color: #F9FFFD !important;
  }
  .bg-xl-st-shamrock {
    background-color: #3AD984 !important;
  }
  .rgba-xl-st-shamrock {
    background-color: rgba(58, 217, 132, 0.1) !important;
  }
  .text-xl-st-shamrock {
    color: #3AD984 !important;
  }
  .bg-xl-blue-bell {
    background-color: #9D9ECE !important;
  }
  .rgba-xl-blue-bell {
    background-color: rgba(157, 158, 206, 0.1) !important;
  }
  .text-xl-blue-bell {
    color: #9D9ECE !important;
  }
  .bg-xl-snuff {
    background-color: #DBD7E9 !important;
  }
  .rgba-xl-snuff {
    background-color: rgba(219, 215, 233, 0.1) !important;
  }
  .text-xl-snuff {
    color: #DBD7E9 !important;
  }
  .bg-xl-french-grey {
    background-color: #C3C3C7 !important;
  }
  .rgba-xl-french-grey {
    background-color: rgba(195, 195, 199, 0.1) !important;
  }
  .text-xl-french-grey {
    color: #C3C3C7 !important;
  }
  .bg-xl-ebony-clay {
    background-color: #1F2833 !important;
  }
  .rgba-xl-ebony-clay {
    background-color: rgba(31, 40, 51, 0.1) !important;
  }
  .text-xl-ebony-clay {
    color: #1F2833 !important;
  }
  .bg-xl-black-rock {
    background-color: #080327 !important;
  }
  .rgba-xl-black-rock {
    background-color: rgba(8, 3, 39, 0.1) !important;
  }
  .text-xl-black-rock {
    color: #080327 !important;
  }
  .bg-xl-st-lavender {
    background-color: #B4B5D5 !important;
  }
  .rgba-xl-st-lavender {
    background-color: rgba(180, 181, 213, 0.1) !important;
  }
  .text-xl-st-lavender {
    color: #B4B5D5 !important;
  }
  .bg-xl-titan-white {
    background-color: #F5F5FF !important;
  }
  .rgba-xl-titan-white {
    background-color: rgba(245, 245, 255, 0.1) !important;
  }
  .text-xl-titan-white {
    color: #F5F5FF !important;
  }
  .bg-xl-regent {
    background-color: #8D9BA4 !important;
  }
  .rgba-xl-regent {
    background-color: rgba(141, 155, 164, 0.1) !important;
  }
  .text-xl-regent {
    color: #8D9BA4 !important;
  }
  .bg-xl-shhamrock {
    background-color: #40D1A7 !important;
  }
  .rgba-xl-shhamrock {
    background-color: rgba(64, 209, 167, 0.1) !important;
  }
  .text-xl-shhamrock {
    color: #40D1A7 !important;
  }
  .bg-xl-gray-light {
    background-color: #f0f2f8 !important;
  }
  .rgba-xl-gray-light {
    background-color: rgba(240, 242, 248, 0.1) !important;
  }
  .text-xl-gray-light {
    color: #f0f2f8 !important;
  }
  .bg-xl-gray {
    background-color: #b6beda !important;
  }
  .rgba-xl-gray {
    background-color: rgba(182, 190, 218, 0.1) !important;
  }
  .text-xl-gray {
    color: #b6beda !important;
  }
  .bg-xl-gray-dark {
    background-color: #2f3447 !important;
  }
  .rgba-xl-gray-dark {
    background-color: rgba(47, 52, 71, 0.1) !important;
  }
  .text-xl-gray-dark {
    color: #2f3447 !important;
  }
  .bg-xl-blue-darker {
    background-color: #3f455c !important;
  }
  .rgba-xl-blue-darker {
    background-color: rgba(63, 69, 92, 0.1) !important;
  }
  .text-xl-blue-darker {
    color: #3f455c !important;
  }
  .bg-xl-blue-dark {
    background-color: #4a516a !important;
  }
  .rgba-xl-blue-dark {
    background-color: rgba(74, 81, 106, 0.1) !important;
  }
  .text-xl-blue-dark {
    color: #4a516a !important;
  }
  .bg-xl-blue {
    background-color: #2bbcff !important;
  }
  .rgba-xl-blue {
    background-color: rgba(43, 188, 255, 0.1) !important;
  }
  .text-xl-blue {
    color: #2bbcff !important;
  }
  .bg-xl-green {
    background-color: #2ad9d3 !important;
  }
  .rgba-xl-green {
    background-color: rgba(42, 217, 211, 0.1) !important;
  }
  .text-xl-green {
    color: #2ad9d3 !important;
  }
  .bg-xl-none {
    background: none !important;
  }
  .bgc-xl-none {
    background-color: transparent !important;
  }
  .bgi-xl-none {
    background-image: none !important;
  }
  .br-xl-none br {
    display: none !important;
  }
}

@media only screen and (max-width: 1199.98px) {
  .bg-lg-gigas {
    background-color: #5040A0 !important;
  }
  .rgba-lg-gigas {
    background-color: rgba(80, 64, 160, 0.1) !important;
  }
  .text-lg-gigas {
    color: #5040A0 !important;
  }
  .bg-lg-perison-blue {
    background-color: #1E43DD !important;
  }
  .rgba-lg-perison-blue {
    background-color: rgba(30, 67, 221, 0.1) !important;
  }
  .text-lg-perison-blue {
    color: #1E43DD !important;
  }
  .bg-lg-st-aqua {
    background-color: #00E3F6 !important;
  }
  .rgba-lg-st-aqua {
    background-color: rgba(0, 227, 246, 0.1) !important;
  }
  .text-lg-st-aqua {
    color: #00E3F6 !important;
  }
  .bg-lg-shamrock {
    background-color: #3AD984 !important;
  }
  .rgba-lg-shamrock {
    background-color: rgba(58, 217, 132, 0.1) !important;
  }
  .text-lg-shamrock {
    color: #3AD984 !important;
  }
  .bg-lg-yellow-orange {
    background-color: #FFAA3B !important;
  }
  .rgba-lg-yellow-orange {
    background-color: rgba(255, 170, 59, 0.1) !important;
  }
  .text-lg-yellow-orange {
    color: #FFAA3B !important;
  }
  .bg-lg-st-pink {
    background-color: #F23F79 !important;
  }
  .rgba-lg-st-pink {
    background-color: rgba(242, 63, 121, 0.1) !important;
  }
  .text-lg-st-pink {
    color: #F23F79 !important;
  }
  .bg-lg-st-orange {
    background-color: #FF5D1C !important;
  }
  .rgba-lg-st-orange {
    background-color: rgba(255, 93, 28, 0.1) !important;
  }
  .text-lg-st-orange {
    color: #FF5D1C !important;
  }
  .bg-lg-science-blue {
    background-color: #0366D6 !important;
  }
  .rgba-lg-science-blue {
    background-color: rgba(3, 102, 214, 0.1) !important;
  }
  .text-lg-science-blue {
    color: #0366D6 !important;
  }
  .bg-lg-emerald {
    background-color: #38D16A !important;
  }
  .rgba-lg-emerald {
    background-color: rgba(56, 209, 106, 0.1) !important;
  }
  .text-lg-emerald {
    color: #38D16A !important;
  }
  .bg-lg-chambray {
    background-color: #3B3E99 !important;
  }
  .rgba-lg-chambray {
    background-color: rgba(59, 62, 153, 0.1) !important;
  }
  .text-lg-chambray {
    color: #3B3E99 !important;
  }
  .bg-lg-medium-purple {
    background-color: #8066DC !important;
  }
  .rgba-lg-medium-purple {
    background-color: rgba(128, 102, 220, 0.1) !important;
  }
  .text-lg-medium-purple {
    color: #8066DC !important;
  }
  .bg-lg-lochmara {
    background-color: #0079BF !important;
  }
  .rgba-lg-lochmara {
    background-color: rgba(0, 121, 191, 0.1) !important;
  }
  .text-lg-lochmara {
    color: #0079BF !important;
  }
  .bg-lg-froly {
    background-color: #F46F6C !important;
  }
  .rgba-lg-froly {
    background-color: rgba(244, 111, 108, 0.1) !important;
  }
  .text-lg-froly {
    color: #F46F6C !important;
  }
  .bg-lg-alpha-froly {
    background-color: rgba(244, 111, 108, 0.1) !important;
  }
  .rgba-lg-alpha-froly {
    background-color: rgba(244, 111, 108, 0.1) !important;
  }
  .text-lg-alpha-froly {
    color: rgba(244, 111, 108, 0.1) !important;
  }
  .bg-lg-sweet-pink {
    background-color: #FFA3A1 !important;
  }
  .rgba-lg-sweet-pink {
    background-color: rgba(255, 163, 161, 0.1) !important;
  }
  .text-lg-sweet-pink {
    color: #FFA3A1 !important;
  }
  .bg-lg-koamaru {
    background-color: #1D1068 !important;
  }
  .rgba-lg-koamaru {
    background-color: rgba(29, 16, 104, 0.1) !important;
  }
  .text-lg-koamaru {
    color: #1D1068 !important;
  }
  .bg-lg-haiti {
    background-color: #140F30 !important;
  }
  .rgba-lg-haiti {
    background-color: rgba(20, 15, 48, 0.1) !important;
  }
  .text-lg-haiti {
    color: #140F30 !important;
  }
  .bg-lg-magnolia {
    background-color: #F9F8FF !important;
  }
  .rgba-lg-magnolia {
    background-color: rgba(249, 248, 255, 0.1) !important;
  }
  .text-lg-magnolia {
    color: #F9F8FF !important;
  }
  .bg-lg-alice-blue {
    background-color: #F5FBFF !important;
  }
  .rgba-lg-alice-blue {
    background-color: rgba(245, 251, 255, 0.1) !important;
  }
  .text-lg-alice-blue {
    color: #F5FBFF !important;
  }
  .bg-lg-polar {
    background-color: #F5F9FD !important;
  }
  .rgba-lg-polar {
    background-color: rgba(245, 249, 253, 0.1) !important;
  }
  .text-lg-polar {
    color: #F5F9FD !important;
  }
  .bg-lg-st-primary {
    background-color: #4A516A !important;
  }
  .rgba-lg-st-primary {
    background-color: rgba(74, 81, 106, 0.1) !important;
  }
  .text-lg-st-primary {
    color: #4A516A !important;
  }
  .bg-lg-st-white {
    background-color: #FFFFFF !important;
  }
  .rgba-lg-st-white {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .text-lg-st-white {
    color: #FFFFFF !important;
  }
  .bg-lg-st-black {
    background-color: #000000 !important;
  }
  .rgba-lg-st-black {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
  .text-lg-st-black {
    color: #000000 !important;
  }
  .bg-lg-st-fog {
    background-color: #D6CCFF !important;
  }
  .rgba-lg-st-fog {
    background-color: rgba(214, 204, 255, 0.1) !important;
  }
  .text-lg-st-fog {
    color: #D6CCFF !important;
  }
  .bg-lg-st-gray {
    background-color: #999999 !important;
  }
  .rgba-lg-st-gray {
    background-color: rgba(153, 153, 153, 0.1) !important;
  }
  .text-lg-st-gray {
    color: #999999 !important;
  }
  .bg-lg-st-concrete {
    background-color: #F3F3F3 !important;
  }
  .rgba-lg-st-concrete {
    background-color: rgba(243, 243, 243, 0.1) !important;
  }
  .text-lg-st-concrete {
    color: #F3F3F3 !important;
  }
  .bg-lg-st-violet {
    background-color: #D52EEF !important;
  }
  .rgba-lg-st-violet {
    background-color: rgba(213, 46, 239, 0.1) !important;
  }
  .text-lg-st-violet {
    color: #D52EEF !important;
  }
  .bg-lg-sun-glow {
    background-color: #FCCC2C !important;
  }
  .rgba-lg-sun-glow {
    background-color: rgba(252, 204, 44, 0.1) !important;
  }
  .text-lg-sun-glow {
    color: #FCCC2C !important;
  }
  .bg-lg-st-cabaret {
    background-color: #D1386C !important;
  }
  .rgba-lg-st-cabaret {
    background-color: rgba(209, 56, 108, 0.1) !important;
  }
  .text-lg-st-cabaret {
    color: #D1386C !important;
  }
  .bg-lg-st-zircon {
    background-color: #F8FAFF !important;
  }
  .rgba-lg-st-zircon {
    background-color: rgba(248, 250, 255, 0.1) !important;
  }
  .text-lg-st-zircon {
    color: #F8FAFF !important;
  }
  .bg-lg-clear-day {
    background-color: #F9FFFD !important;
  }
  .rgba-lg-clear-day {
    background-color: rgba(249, 255, 253, 0.1) !important;
  }
  .text-lg-clear-day {
    color: #F9FFFD !important;
  }
  .bg-lg-st-shamrock {
    background-color: #3AD984 !important;
  }
  .rgba-lg-st-shamrock {
    background-color: rgba(58, 217, 132, 0.1) !important;
  }
  .text-lg-st-shamrock {
    color: #3AD984 !important;
  }
  .bg-lg-blue-bell {
    background-color: #9D9ECE !important;
  }
  .rgba-lg-blue-bell {
    background-color: rgba(157, 158, 206, 0.1) !important;
  }
  .text-lg-blue-bell {
    color: #9D9ECE !important;
  }
  .bg-lg-snuff {
    background-color: #DBD7E9 !important;
  }
  .rgba-lg-snuff {
    background-color: rgba(219, 215, 233, 0.1) !important;
  }
  .text-lg-snuff {
    color: #DBD7E9 !important;
  }
  .bg-lg-french-grey {
    background-color: #C3C3C7 !important;
  }
  .rgba-lg-french-grey {
    background-color: rgba(195, 195, 199, 0.1) !important;
  }
  .text-lg-french-grey {
    color: #C3C3C7 !important;
  }
  .bg-lg-ebony-clay {
    background-color: #1F2833 !important;
  }
  .rgba-lg-ebony-clay {
    background-color: rgba(31, 40, 51, 0.1) !important;
  }
  .text-lg-ebony-clay {
    color: #1F2833 !important;
  }
  .bg-lg-black-rock {
    background-color: #080327 !important;
  }
  .rgba-lg-black-rock {
    background-color: rgba(8, 3, 39, 0.1) !important;
  }
  .text-lg-black-rock {
    color: #080327 !important;
  }
  .bg-lg-st-lavender {
    background-color: #B4B5D5 !important;
  }
  .rgba-lg-st-lavender {
    background-color: rgba(180, 181, 213, 0.1) !important;
  }
  .text-lg-st-lavender {
    color: #B4B5D5 !important;
  }
  .bg-lg-titan-white {
    background-color: #F5F5FF !important;
  }
  .rgba-lg-titan-white {
    background-color: rgba(245, 245, 255, 0.1) !important;
  }
  .text-lg-titan-white {
    color: #F5F5FF !important;
  }
  .bg-lg-regent {
    background-color: #8D9BA4 !important;
  }
  .rgba-lg-regent {
    background-color: rgba(141, 155, 164, 0.1) !important;
  }
  .text-lg-regent {
    color: #8D9BA4 !important;
  }
  .bg-lg-shhamrock {
    background-color: #40D1A7 !important;
  }
  .rgba-lg-shhamrock {
    background-color: rgba(64, 209, 167, 0.1) !important;
  }
  .text-lg-shhamrock {
    color: #40D1A7 !important;
  }
  .bg-lg-gray-light {
    background-color: #f0f2f8 !important;
  }
  .rgba-lg-gray-light {
    background-color: rgba(240, 242, 248, 0.1) !important;
  }
  .text-lg-gray-light {
    color: #f0f2f8 !important;
  }
  .bg-lg-gray {
    background-color: #b6beda !important;
  }
  .rgba-lg-gray {
    background-color: rgba(182, 190, 218, 0.1) !important;
  }
  .text-lg-gray {
    color: #b6beda !important;
  }
  .bg-lg-gray-dark {
    background-color: #2f3447 !important;
  }
  .rgba-lg-gray-dark {
    background-color: rgba(47, 52, 71, 0.1) !important;
  }
  .text-lg-gray-dark {
    color: #2f3447 !important;
  }
  .bg-lg-blue-darker {
    background-color: #3f455c !important;
  }
  .rgba-lg-blue-darker {
    background-color: rgba(63, 69, 92, 0.1) !important;
  }
  .text-lg-blue-darker {
    color: #3f455c !important;
  }
  .bg-lg-blue-dark {
    background-color: #4a516a !important;
  }
  .rgba-lg-blue-dark {
    background-color: rgba(74, 81, 106, 0.1) !important;
  }
  .text-lg-blue-dark {
    color: #4a516a !important;
  }
  .bg-lg-blue {
    background-color: #2bbcff !important;
  }
  .rgba-lg-blue {
    background-color: rgba(43, 188, 255, 0.1) !important;
  }
  .text-lg-blue {
    color: #2bbcff !important;
  }
  .bg-lg-green {
    background-color: #2ad9d3 !important;
  }
  .rgba-lg-green {
    background-color: rgba(42, 217, 211, 0.1) !important;
  }
  .text-lg-green {
    color: #2ad9d3 !important;
  }
  .bg-lg-none {
    background: none !important;
  }
  .bgc-lg-none {
    background-color: transparent !important;
  }
  .bgi-lg-none {
    background-image: none !important;
  }
  .br-lg-none br {
    display: none !important;
  }
}

@media only screen and (max-width: 991.98px) {
  .bg-md-gigas {
    background-color: #5040A0 !important;
  }
  .rgba-md-gigas {
    background-color: rgba(80, 64, 160, 0.1) !important;
  }
  .text-md-gigas {
    color: #5040A0 !important;
  }
  .bg-md-perison-blue {
    background-color: #1E43DD !important;
  }
  .rgba-md-perison-blue {
    background-color: rgba(30, 67, 221, 0.1) !important;
  }
  .text-md-perison-blue {
    color: #1E43DD !important;
  }
  .bg-md-st-aqua {
    background-color: #00E3F6 !important;
  }
  .rgba-md-st-aqua {
    background-color: rgba(0, 227, 246, 0.1) !important;
  }
  .text-md-st-aqua {
    color: #00E3F6 !important;
  }
  .bg-md-shamrock {
    background-color: #3AD984 !important;
  }
  .rgba-md-shamrock {
    background-color: rgba(58, 217, 132, 0.1) !important;
  }
  .text-md-shamrock {
    color: #3AD984 !important;
  }
  .bg-md-yellow-orange {
    background-color: #FFAA3B !important;
  }
  .rgba-md-yellow-orange {
    background-color: rgba(255, 170, 59, 0.1) !important;
  }
  .text-md-yellow-orange {
    color: #FFAA3B !important;
  }
  .bg-md-st-pink {
    background-color: #F23F79 !important;
  }
  .rgba-md-st-pink {
    background-color: rgba(242, 63, 121, 0.1) !important;
  }
  .text-md-st-pink {
    color: #F23F79 !important;
  }
  .bg-md-st-orange {
    background-color: #FF5D1C !important;
  }
  .rgba-md-st-orange {
    background-color: rgba(255, 93, 28, 0.1) !important;
  }
  .text-md-st-orange {
    color: #FF5D1C !important;
  }
  .bg-md-science-blue {
    background-color: #0366D6 !important;
  }
  .rgba-md-science-blue {
    background-color: rgba(3, 102, 214, 0.1) !important;
  }
  .text-md-science-blue {
    color: #0366D6 !important;
  }
  .bg-md-emerald {
    background-color: #38D16A !important;
  }
  .rgba-md-emerald {
    background-color: rgba(56, 209, 106, 0.1) !important;
  }
  .text-md-emerald {
    color: #38D16A !important;
  }
  .bg-md-chambray {
    background-color: #3B3E99 !important;
  }
  .rgba-md-chambray {
    background-color: rgba(59, 62, 153, 0.1) !important;
  }
  .text-md-chambray {
    color: #3B3E99 !important;
  }
  .bg-md-medium-purple {
    background-color: #8066DC !important;
  }
  .rgba-md-medium-purple {
    background-color: rgba(128, 102, 220, 0.1) !important;
  }
  .text-md-medium-purple {
    color: #8066DC !important;
  }
  .bg-md-lochmara {
    background-color: #0079BF !important;
  }
  .rgba-md-lochmara {
    background-color: rgba(0, 121, 191, 0.1) !important;
  }
  .text-md-lochmara {
    color: #0079BF !important;
  }
  .bg-md-froly {
    background-color: #F46F6C !important;
  }
  .rgba-md-froly {
    background-color: rgba(244, 111, 108, 0.1) !important;
  }
  .text-md-froly {
    color: #F46F6C !important;
  }
  .bg-md-alpha-froly {
    background-color: rgba(244, 111, 108, 0.1) !important;
  }
  .rgba-md-alpha-froly {
    background-color: rgba(244, 111, 108, 0.1) !important;
  }
  .text-md-alpha-froly {
    color: rgba(244, 111, 108, 0.1) !important;
  }
  .bg-md-sweet-pink {
    background-color: #FFA3A1 !important;
  }
  .rgba-md-sweet-pink {
    background-color: rgba(255, 163, 161, 0.1) !important;
  }
  .text-md-sweet-pink {
    color: #FFA3A1 !important;
  }
  .bg-md-koamaru {
    background-color: #1D1068 !important;
  }
  .rgba-md-koamaru {
    background-color: rgba(29, 16, 104, 0.1) !important;
  }
  .text-md-koamaru {
    color: #1D1068 !important;
  }
  .bg-md-haiti {
    background-color: #140F30 !important;
  }
  .rgba-md-haiti {
    background-color: rgba(20, 15, 48, 0.1) !important;
  }
  .text-md-haiti {
    color: #140F30 !important;
  }
  .bg-md-magnolia {
    background-color: #F9F8FF !important;
  }
  .rgba-md-magnolia {
    background-color: rgba(249, 248, 255, 0.1) !important;
  }
  .text-md-magnolia {
    color: #F9F8FF !important;
  }
  .bg-md-alice-blue {
    background-color: #F5FBFF !important;
  }
  .rgba-md-alice-blue {
    background-color: rgba(245, 251, 255, 0.1) !important;
  }
  .text-md-alice-blue {
    color: #F5FBFF !important;
  }
  .bg-md-polar {
    background-color: #F5F9FD !important;
  }
  .rgba-md-polar {
    background-color: rgba(245, 249, 253, 0.1) !important;
  }
  .text-md-polar {
    color: #F5F9FD !important;
  }
  .bg-md-st-primary {
    background-color: #4A516A !important;
  }
  .rgba-md-st-primary {
    background-color: rgba(74, 81, 106, 0.1) !important;
  }
  .text-md-st-primary {
    color: #4A516A !important;
  }
  .bg-md-st-white {
    background-color: #FFFFFF !important;
  }
  .rgba-md-st-white {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .text-md-st-white {
    color: #FFFFFF !important;
  }
  .bg-md-st-black {
    background-color: #000000 !important;
  }
  .rgba-md-st-black {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
  .text-md-st-black {
    color: #000000 !important;
  }
  .bg-md-st-fog {
    background-color: #D6CCFF !important;
  }
  .rgba-md-st-fog {
    background-color: rgba(214, 204, 255, 0.1) !important;
  }
  .text-md-st-fog {
    color: #D6CCFF !important;
  }
  .bg-md-st-gray {
    background-color: #999999 !important;
  }
  .rgba-md-st-gray {
    background-color: rgba(153, 153, 153, 0.1) !important;
  }
  .text-md-st-gray {
    color: #999999 !important;
  }
  .bg-md-st-concrete {
    background-color: #F3F3F3 !important;
  }
  .rgba-md-st-concrete {
    background-color: rgba(243, 243, 243, 0.1) !important;
  }
  .text-md-st-concrete {
    color: #F3F3F3 !important;
  }
  .bg-md-st-violet {
    background-color: #D52EEF !important;
  }
  .rgba-md-st-violet {
    background-color: rgba(213, 46, 239, 0.1) !important;
  }
  .text-md-st-violet {
    color: #D52EEF !important;
  }
  .bg-md-sun-glow {
    background-color: #FCCC2C !important;
  }
  .rgba-md-sun-glow {
    background-color: rgba(252, 204, 44, 0.1) !important;
  }
  .text-md-sun-glow {
    color: #FCCC2C !important;
  }
  .bg-md-st-cabaret {
    background-color: #D1386C !important;
  }
  .rgba-md-st-cabaret {
    background-color: rgba(209, 56, 108, 0.1) !important;
  }
  .text-md-st-cabaret {
    color: #D1386C !important;
  }
  .bg-md-st-zircon {
    background-color: #F8FAFF !important;
  }
  .rgba-md-st-zircon {
    background-color: rgba(248, 250, 255, 0.1) !important;
  }
  .text-md-st-zircon {
    color: #F8FAFF !important;
  }
  .bg-md-clear-day {
    background-color: #F9FFFD !important;
  }
  .rgba-md-clear-day {
    background-color: rgba(249, 255, 253, 0.1) !important;
  }
  .text-md-clear-day {
    color: #F9FFFD !important;
  }
  .bg-md-st-shamrock {
    background-color: #3AD984 !important;
  }
  .rgba-md-st-shamrock {
    background-color: rgba(58, 217, 132, 0.1) !important;
  }
  .text-md-st-shamrock {
    color: #3AD984 !important;
  }
  .bg-md-blue-bell {
    background-color: #9D9ECE !important;
  }
  .rgba-md-blue-bell {
    background-color: rgba(157, 158, 206, 0.1) !important;
  }
  .text-md-blue-bell {
    color: #9D9ECE !important;
  }
  .bg-md-snuff {
    background-color: #DBD7E9 !important;
  }
  .rgba-md-snuff {
    background-color: rgba(219, 215, 233, 0.1) !important;
  }
  .text-md-snuff {
    color: #DBD7E9 !important;
  }
  .bg-md-french-grey {
    background-color: #C3C3C7 !important;
  }
  .rgba-md-french-grey {
    background-color: rgba(195, 195, 199, 0.1) !important;
  }
  .text-md-french-grey {
    color: #C3C3C7 !important;
  }
  .bg-md-ebony-clay {
    background-color: #1F2833 !important;
  }
  .rgba-md-ebony-clay {
    background-color: rgba(31, 40, 51, 0.1) !important;
  }
  .text-md-ebony-clay {
    color: #1F2833 !important;
  }
  .bg-md-black-rock {
    background-color: #080327 !important;
  }
  .rgba-md-black-rock {
    background-color: rgba(8, 3, 39, 0.1) !important;
  }
  .text-md-black-rock {
    color: #080327 !important;
  }
  .bg-md-st-lavender {
    background-color: #B4B5D5 !important;
  }
  .rgba-md-st-lavender {
    background-color: rgba(180, 181, 213, 0.1) !important;
  }
  .text-md-st-lavender {
    color: #B4B5D5 !important;
  }
  .bg-md-titan-white {
    background-color: #F5F5FF !important;
  }
  .rgba-md-titan-white {
    background-color: rgba(245, 245, 255, 0.1) !important;
  }
  .text-md-titan-white {
    color: #F5F5FF !important;
  }
  .bg-md-regent {
    background-color: #8D9BA4 !important;
  }
  .rgba-md-regent {
    background-color: rgba(141, 155, 164, 0.1) !important;
  }
  .text-md-regent {
    color: #8D9BA4 !important;
  }
  .bg-md-shhamrock {
    background-color: #40D1A7 !important;
  }
  .rgba-md-shhamrock {
    background-color: rgba(64, 209, 167, 0.1) !important;
  }
  .text-md-shhamrock {
    color: #40D1A7 !important;
  }
  .bg-md-gray-light {
    background-color: #f0f2f8 !important;
  }
  .rgba-md-gray-light {
    background-color: rgba(240, 242, 248, 0.1) !important;
  }
  .text-md-gray-light {
    color: #f0f2f8 !important;
  }
  .bg-md-gray {
    background-color: #b6beda !important;
  }
  .rgba-md-gray {
    background-color: rgba(182, 190, 218, 0.1) !important;
  }
  .text-md-gray {
    color: #b6beda !important;
  }
  .bg-md-gray-dark {
    background-color: #2f3447 !important;
  }
  .rgba-md-gray-dark {
    background-color: rgba(47, 52, 71, 0.1) !important;
  }
  .text-md-gray-dark {
    color: #2f3447 !important;
  }
  .bg-md-blue-darker {
    background-color: #3f455c !important;
  }
  .rgba-md-blue-darker {
    background-color: rgba(63, 69, 92, 0.1) !important;
  }
  .text-md-blue-darker {
    color: #3f455c !important;
  }
  .bg-md-blue-dark {
    background-color: #4a516a !important;
  }
  .rgba-md-blue-dark {
    background-color: rgba(74, 81, 106, 0.1) !important;
  }
  .text-md-blue-dark {
    color: #4a516a !important;
  }
  .bg-md-blue {
    background-color: #2bbcff !important;
  }
  .rgba-md-blue {
    background-color: rgba(43, 188, 255, 0.1) !important;
  }
  .text-md-blue {
    color: #2bbcff !important;
  }
  .bg-md-green {
    background-color: #2ad9d3 !important;
  }
  .rgba-md-green {
    background-color: rgba(42, 217, 211, 0.1) !important;
  }
  .text-md-green {
    color: #2ad9d3 !important;
  }
  .bg-md-none {
    background: none !important;
  }
  .bgc-md-none {
    background-color: transparent !important;
  }
  .bgi-md-none {
    background-image: none !important;
  }
  .br-md-none br {
    display: none !important;
  }
}

@media only screen and (max-width: 767.98px) {
  .bg-sm-gigas {
    background-color: #5040A0 !important;
  }
  .rgba-sm-gigas {
    background-color: rgba(80, 64, 160, 0.1) !important;
  }
  .text-sm-gigas {
    color: #5040A0 !important;
  }
  .bg-sm-perison-blue {
    background-color: #1E43DD !important;
  }
  .rgba-sm-perison-blue {
    background-color: rgba(30, 67, 221, 0.1) !important;
  }
  .text-sm-perison-blue {
    color: #1E43DD !important;
  }
  .bg-sm-st-aqua {
    background-color: #00E3F6 !important;
  }
  .rgba-sm-st-aqua {
    background-color: rgba(0, 227, 246, 0.1) !important;
  }
  .text-sm-st-aqua {
    color: #00E3F6 !important;
  }
  .bg-sm-shamrock {
    background-color: #3AD984 !important;
  }
  .rgba-sm-shamrock {
    background-color: rgba(58, 217, 132, 0.1) !important;
  }
  .text-sm-shamrock {
    color: #3AD984 !important;
  }
  .bg-sm-yellow-orange {
    background-color: #FFAA3B !important;
  }
  .rgba-sm-yellow-orange {
    background-color: rgba(255, 170, 59, 0.1) !important;
  }
  .text-sm-yellow-orange {
    color: #FFAA3B !important;
  }
  .bg-sm-st-pink {
    background-color: #F23F79 !important;
  }
  .rgba-sm-st-pink {
    background-color: rgba(242, 63, 121, 0.1) !important;
  }
  .text-sm-st-pink {
    color: #F23F79 !important;
  }
  .bg-sm-st-orange {
    background-color: #FF5D1C !important;
  }
  .rgba-sm-st-orange {
    background-color: rgba(255, 93, 28, 0.1) !important;
  }
  .text-sm-st-orange {
    color: #FF5D1C !important;
  }
  .bg-sm-science-blue {
    background-color: #0366D6 !important;
  }
  .rgba-sm-science-blue {
    background-color: rgba(3, 102, 214, 0.1) !important;
  }
  .text-sm-science-blue {
    color: #0366D6 !important;
  }
  .bg-sm-emerald {
    background-color: #38D16A !important;
  }
  .rgba-sm-emerald {
    background-color: rgba(56, 209, 106, 0.1) !important;
  }
  .text-sm-emerald {
    color: #38D16A !important;
  }
  .bg-sm-chambray {
    background-color: #3B3E99 !important;
  }
  .rgba-sm-chambray {
    background-color: rgba(59, 62, 153, 0.1) !important;
  }
  .text-sm-chambray {
    color: #3B3E99 !important;
  }
  .bg-sm-medium-purple {
    background-color: #8066DC !important;
  }
  .rgba-sm-medium-purple {
    background-color: rgba(128, 102, 220, 0.1) !important;
  }
  .text-sm-medium-purple {
    color: #8066DC !important;
  }
  .bg-sm-lochmara {
    background-color: #0079BF !important;
  }
  .rgba-sm-lochmara {
    background-color: rgba(0, 121, 191, 0.1) !important;
  }
  .text-sm-lochmara {
    color: #0079BF !important;
  }
  .bg-sm-froly {
    background-color: #F46F6C !important;
  }
  .rgba-sm-froly {
    background-color: rgba(244, 111, 108, 0.1) !important;
  }
  .text-sm-froly {
    color: #F46F6C !important;
  }
  .bg-sm-alpha-froly {
    background-color: rgba(244, 111, 108, 0.1) !important;
  }
  .rgba-sm-alpha-froly {
    background-color: rgba(244, 111, 108, 0.1) !important;
  }
  .text-sm-alpha-froly {
    color: rgba(244, 111, 108, 0.1) !important;
  }
  .bg-sm-sweet-pink {
    background-color: #FFA3A1 !important;
  }
  .rgba-sm-sweet-pink {
    background-color: rgba(255, 163, 161, 0.1) !important;
  }
  .text-sm-sweet-pink {
    color: #FFA3A1 !important;
  }
  .bg-sm-koamaru {
    background-color: #1D1068 !important;
  }
  .rgba-sm-koamaru {
    background-color: rgba(29, 16, 104, 0.1) !important;
  }
  .text-sm-koamaru {
    color: #1D1068 !important;
  }
  .bg-sm-haiti {
    background-color: #140F30 !important;
  }
  .rgba-sm-haiti {
    background-color: rgba(20, 15, 48, 0.1) !important;
  }
  .text-sm-haiti {
    color: #140F30 !important;
  }
  .bg-sm-magnolia {
    background-color: #F9F8FF !important;
  }
  .rgba-sm-magnolia {
    background-color: rgba(249, 248, 255, 0.1) !important;
  }
  .text-sm-magnolia {
    color: #F9F8FF !important;
  }
  .bg-sm-alice-blue {
    background-color: #F5FBFF !important;
  }
  .rgba-sm-alice-blue {
    background-color: rgba(245, 251, 255, 0.1) !important;
  }
  .text-sm-alice-blue {
    color: #F5FBFF !important;
  }
  .bg-sm-polar {
    background-color: #F5F9FD !important;
  }
  .rgba-sm-polar {
    background-color: rgba(245, 249, 253, 0.1) !important;
  }
  .text-sm-polar {
    color: #F5F9FD !important;
  }
  .bg-sm-st-primary {
    background-color: #4A516A !important;
  }
  .rgba-sm-st-primary {
    background-color: rgba(74, 81, 106, 0.1) !important;
  }
  .text-sm-st-primary {
    color: #4A516A !important;
  }
  .bg-sm-st-white {
    background-color: #FFFFFF !important;
  }
  .rgba-sm-st-white {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .text-sm-st-white {
    color: #FFFFFF !important;
  }
  .bg-sm-st-black {
    background-color: #000000 !important;
  }
  .rgba-sm-st-black {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
  .text-sm-st-black {
    color: #000000 !important;
  }
  .bg-sm-st-fog {
    background-color: #D6CCFF !important;
  }
  .rgba-sm-st-fog {
    background-color: rgba(214, 204, 255, 0.1) !important;
  }
  .text-sm-st-fog {
    color: #D6CCFF !important;
  }
  .bg-sm-st-gray {
    background-color: #999999 !important;
  }
  .rgba-sm-st-gray {
    background-color: rgba(153, 153, 153, 0.1) !important;
  }
  .text-sm-st-gray {
    color: #999999 !important;
  }
  .bg-sm-st-concrete {
    background-color: #F3F3F3 !important;
  }
  .rgba-sm-st-concrete {
    background-color: rgba(243, 243, 243, 0.1) !important;
  }
  .text-sm-st-concrete {
    color: #F3F3F3 !important;
  }
  .bg-sm-st-violet {
    background-color: #D52EEF !important;
  }
  .rgba-sm-st-violet {
    background-color: rgba(213, 46, 239, 0.1) !important;
  }
  .text-sm-st-violet {
    color: #D52EEF !important;
  }
  .bg-sm-sun-glow {
    background-color: #FCCC2C !important;
  }
  .rgba-sm-sun-glow {
    background-color: rgba(252, 204, 44, 0.1) !important;
  }
  .text-sm-sun-glow {
    color: #FCCC2C !important;
  }
  .bg-sm-st-cabaret {
    background-color: #D1386C !important;
  }
  .rgba-sm-st-cabaret {
    background-color: rgba(209, 56, 108, 0.1) !important;
  }
  .text-sm-st-cabaret {
    color: #D1386C !important;
  }
  .bg-sm-st-zircon {
    background-color: #F8FAFF !important;
  }
  .rgba-sm-st-zircon {
    background-color: rgba(248, 250, 255, 0.1) !important;
  }
  .text-sm-st-zircon {
    color: #F8FAFF !important;
  }
  .bg-sm-clear-day {
    background-color: #F9FFFD !important;
  }
  .rgba-sm-clear-day {
    background-color: rgba(249, 255, 253, 0.1) !important;
  }
  .text-sm-clear-day {
    color: #F9FFFD !important;
  }
  .bg-sm-st-shamrock {
    background-color: #3AD984 !important;
  }
  .rgba-sm-st-shamrock {
    background-color: rgba(58, 217, 132, 0.1) !important;
  }
  .text-sm-st-shamrock {
    color: #3AD984 !important;
  }
  .bg-sm-blue-bell {
    background-color: #9D9ECE !important;
  }
  .rgba-sm-blue-bell {
    background-color: rgba(157, 158, 206, 0.1) !important;
  }
  .text-sm-blue-bell {
    color: #9D9ECE !important;
  }
  .bg-sm-snuff {
    background-color: #DBD7E9 !important;
  }
  .rgba-sm-snuff {
    background-color: rgba(219, 215, 233, 0.1) !important;
  }
  .text-sm-snuff {
    color: #DBD7E9 !important;
  }
  .bg-sm-french-grey {
    background-color: #C3C3C7 !important;
  }
  .rgba-sm-french-grey {
    background-color: rgba(195, 195, 199, 0.1) !important;
  }
  .text-sm-french-grey {
    color: #C3C3C7 !important;
  }
  .bg-sm-ebony-clay {
    background-color: #1F2833 !important;
  }
  .rgba-sm-ebony-clay {
    background-color: rgba(31, 40, 51, 0.1) !important;
  }
  .text-sm-ebony-clay {
    color: #1F2833 !important;
  }
  .bg-sm-black-rock {
    background-color: #080327 !important;
  }
  .rgba-sm-black-rock {
    background-color: rgba(8, 3, 39, 0.1) !important;
  }
  .text-sm-black-rock {
    color: #080327 !important;
  }
  .bg-sm-st-lavender {
    background-color: #B4B5D5 !important;
  }
  .rgba-sm-st-lavender {
    background-color: rgba(180, 181, 213, 0.1) !important;
  }
  .text-sm-st-lavender {
    color: #B4B5D5 !important;
  }
  .bg-sm-titan-white {
    background-color: #F5F5FF !important;
  }
  .rgba-sm-titan-white {
    background-color: rgba(245, 245, 255, 0.1) !important;
  }
  .text-sm-titan-white {
    color: #F5F5FF !important;
  }
  .bg-sm-regent {
    background-color: #8D9BA4 !important;
  }
  .rgba-sm-regent {
    background-color: rgba(141, 155, 164, 0.1) !important;
  }
  .text-sm-regent {
    color: #8D9BA4 !important;
  }
  .bg-sm-shhamrock {
    background-color: #40D1A7 !important;
  }
  .rgba-sm-shhamrock {
    background-color: rgba(64, 209, 167, 0.1) !important;
  }
  .text-sm-shhamrock {
    color: #40D1A7 !important;
  }
  .bg-sm-gray-light {
    background-color: #f0f2f8 !important;
  }
  .rgba-sm-gray-light {
    background-color: rgba(240, 242, 248, 0.1) !important;
  }
  .text-sm-gray-light {
    color: #f0f2f8 !important;
  }
  .bg-sm-gray {
    background-color: #b6beda !important;
  }
  .rgba-sm-gray {
    background-color: rgba(182, 190, 218, 0.1) !important;
  }
  .text-sm-gray {
    color: #b6beda !important;
  }
  .bg-sm-gray-dark {
    background-color: #2f3447 !important;
  }
  .rgba-sm-gray-dark {
    background-color: rgba(47, 52, 71, 0.1) !important;
  }
  .text-sm-gray-dark {
    color: #2f3447 !important;
  }
  .bg-sm-blue-darker {
    background-color: #3f455c !important;
  }
  .rgba-sm-blue-darker {
    background-color: rgba(63, 69, 92, 0.1) !important;
  }
  .text-sm-blue-darker {
    color: #3f455c !important;
  }
  .bg-sm-blue-dark {
    background-color: #4a516a !important;
  }
  .rgba-sm-blue-dark {
    background-color: rgba(74, 81, 106, 0.1) !important;
  }
  .text-sm-blue-dark {
    color: #4a516a !important;
  }
  .bg-sm-blue {
    background-color: #2bbcff !important;
  }
  .rgba-sm-blue {
    background-color: rgba(43, 188, 255, 0.1) !important;
  }
  .text-sm-blue {
    color: #2bbcff !important;
  }
  .bg-sm-green {
    background-color: #2ad9d3 !important;
  }
  .rgba-sm-green {
    background-color: rgba(42, 217, 211, 0.1) !important;
  }
  .text-sm-green {
    color: #2ad9d3 !important;
  }
  .bg-sm-none {
    background: none !important;
  }
  .bgc-sm-none {
    background-color: transparent !important;
  }
  .bgi-sm-none {
    background-image: none !important;
  }
  .br-sm-none br {
    display: none !important;
  }
}

@media only screen and (max-width: 575.98px) {
  .bg-xs-gigas {
    background-color: #5040A0 !important;
  }
  .rgba-xs-gigas {
    background-color: rgba(80, 64, 160, 0.1) !important;
  }
  .text-xs-gigas {
    color: #5040A0 !important;
  }
  .bg-xs-perison-blue {
    background-color: #1E43DD !important;
  }
  .rgba-xs-perison-blue {
    background-color: rgba(30, 67, 221, 0.1) !important;
  }
  .text-xs-perison-blue {
    color: #1E43DD !important;
  }
  .bg-xs-st-aqua {
    background-color: #00E3F6 !important;
  }
  .rgba-xs-st-aqua {
    background-color: rgba(0, 227, 246, 0.1) !important;
  }
  .text-xs-st-aqua {
    color: #00E3F6 !important;
  }
  .bg-xs-shamrock {
    background-color: #3AD984 !important;
  }
  .rgba-xs-shamrock {
    background-color: rgba(58, 217, 132, 0.1) !important;
  }
  .text-xs-shamrock {
    color: #3AD984 !important;
  }
  .bg-xs-yellow-orange {
    background-color: #FFAA3B !important;
  }
  .rgba-xs-yellow-orange {
    background-color: rgba(255, 170, 59, 0.1) !important;
  }
  .text-xs-yellow-orange {
    color: #FFAA3B !important;
  }
  .bg-xs-st-pink {
    background-color: #F23F79 !important;
  }
  .rgba-xs-st-pink {
    background-color: rgba(242, 63, 121, 0.1) !important;
  }
  .text-xs-st-pink {
    color: #F23F79 !important;
  }
  .bg-xs-st-orange {
    background-color: #FF5D1C !important;
  }
  .rgba-xs-st-orange {
    background-color: rgba(255, 93, 28, 0.1) !important;
  }
  .text-xs-st-orange {
    color: #FF5D1C !important;
  }
  .bg-xs-science-blue {
    background-color: #0366D6 !important;
  }
  .rgba-xs-science-blue {
    background-color: rgba(3, 102, 214, 0.1) !important;
  }
  .text-xs-science-blue {
    color: #0366D6 !important;
  }
  .bg-xs-emerald {
    background-color: #38D16A !important;
  }
  .rgba-xs-emerald {
    background-color: rgba(56, 209, 106, 0.1) !important;
  }
  .text-xs-emerald {
    color: #38D16A !important;
  }
  .bg-xs-chambray {
    background-color: #3B3E99 !important;
  }
  .rgba-xs-chambray {
    background-color: rgba(59, 62, 153, 0.1) !important;
  }
  .text-xs-chambray {
    color: #3B3E99 !important;
  }
  .bg-xs-medium-purple {
    background-color: #8066DC !important;
  }
  .rgba-xs-medium-purple {
    background-color: rgba(128, 102, 220, 0.1) !important;
  }
  .text-xs-medium-purple {
    color: #8066DC !important;
  }
  .bg-xs-lochmara {
    background-color: #0079BF !important;
  }
  .rgba-xs-lochmara {
    background-color: rgba(0, 121, 191, 0.1) !important;
  }
  .text-xs-lochmara {
    color: #0079BF !important;
  }
  .bg-xs-froly {
    background-color: #F46F6C !important;
  }
  .rgba-xs-froly {
    background-color: rgba(244, 111, 108, 0.1) !important;
  }
  .text-xs-froly {
    color: #F46F6C !important;
  }
  .bg-xs-alpha-froly {
    background-color: rgba(244, 111, 108, 0.1) !important;
  }
  .rgba-xs-alpha-froly {
    background-color: rgba(244, 111, 108, 0.1) !important;
  }
  .text-xs-alpha-froly {
    color: rgba(244, 111, 108, 0.1) !important;
  }
  .bg-xs-sweet-pink {
    background-color: #FFA3A1 !important;
  }
  .rgba-xs-sweet-pink {
    background-color: rgba(255, 163, 161, 0.1) !important;
  }
  .text-xs-sweet-pink {
    color: #FFA3A1 !important;
  }
  .bg-xs-koamaru {
    background-color: #1D1068 !important;
  }
  .rgba-xs-koamaru {
    background-color: rgba(29, 16, 104, 0.1) !important;
  }
  .text-xs-koamaru {
    color: #1D1068 !important;
  }
  .bg-xs-haiti {
    background-color: #140F30 !important;
  }
  .rgba-xs-haiti {
    background-color: rgba(20, 15, 48, 0.1) !important;
  }
  .text-xs-haiti {
    color: #140F30 !important;
  }
  .bg-xs-magnolia {
    background-color: #F9F8FF !important;
  }
  .rgba-xs-magnolia {
    background-color: rgba(249, 248, 255, 0.1) !important;
  }
  .text-xs-magnolia {
    color: #F9F8FF !important;
  }
  .bg-xs-alice-blue {
    background-color: #F5FBFF !important;
  }
  .rgba-xs-alice-blue {
    background-color: rgba(245, 251, 255, 0.1) !important;
  }
  .text-xs-alice-blue {
    color: #F5FBFF !important;
  }
  .bg-xs-polar {
    background-color: #F5F9FD !important;
  }
  .rgba-xs-polar {
    background-color: rgba(245, 249, 253, 0.1) !important;
  }
  .text-xs-polar {
    color: #F5F9FD !important;
  }
  .bg-xs-st-primary {
    background-color: #4A516A !important;
  }
  .rgba-xs-st-primary {
    background-color: rgba(74, 81, 106, 0.1) !important;
  }
  .text-xs-st-primary {
    color: #4A516A !important;
  }
  .bg-xs-st-white {
    background-color: #FFFFFF !important;
  }
  .rgba-xs-st-white {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .text-xs-st-white {
    color: #FFFFFF !important;
  }
  .bg-xs-st-black {
    background-color: #000000 !important;
  }
  .rgba-xs-st-black {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
  .text-xs-st-black {
    color: #000000 !important;
  }
  .bg-xs-st-fog {
    background-color: #D6CCFF !important;
  }
  .rgba-xs-st-fog {
    background-color: rgba(214, 204, 255, 0.1) !important;
  }
  .text-xs-st-fog {
    color: #D6CCFF !important;
  }
  .bg-xs-st-gray {
    background-color: #999999 !important;
  }
  .rgba-xs-st-gray {
    background-color: rgba(153, 153, 153, 0.1) !important;
  }
  .text-xs-st-gray {
    color: #999999 !important;
  }
  .bg-xs-st-concrete {
    background-color: #F3F3F3 !important;
  }
  .rgba-xs-st-concrete {
    background-color: rgba(243, 243, 243, 0.1) !important;
  }
  .text-xs-st-concrete {
    color: #F3F3F3 !important;
  }
  .bg-xs-st-violet {
    background-color: #D52EEF !important;
  }
  .rgba-xs-st-violet {
    background-color: rgba(213, 46, 239, 0.1) !important;
  }
  .text-xs-st-violet {
    color: #D52EEF !important;
  }
  .bg-xs-sun-glow {
    background-color: #FCCC2C !important;
  }
  .rgba-xs-sun-glow {
    background-color: rgba(252, 204, 44, 0.1) !important;
  }
  .text-xs-sun-glow {
    color: #FCCC2C !important;
  }
  .bg-xs-st-cabaret {
    background-color: #D1386C !important;
  }
  .rgba-xs-st-cabaret {
    background-color: rgba(209, 56, 108, 0.1) !important;
  }
  .text-xs-st-cabaret {
    color: #D1386C !important;
  }
  .bg-xs-st-zircon {
    background-color: #F8FAFF !important;
  }
  .rgba-xs-st-zircon {
    background-color: rgba(248, 250, 255, 0.1) !important;
  }
  .text-xs-st-zircon {
    color: #F8FAFF !important;
  }
  .bg-xs-clear-day {
    background-color: #F9FFFD !important;
  }
  .rgba-xs-clear-day {
    background-color: rgba(249, 255, 253, 0.1) !important;
  }
  .text-xs-clear-day {
    color: #F9FFFD !important;
  }
  .bg-xs-st-shamrock {
    background-color: #3AD984 !important;
  }
  .rgba-xs-st-shamrock {
    background-color: rgba(58, 217, 132, 0.1) !important;
  }
  .text-xs-st-shamrock {
    color: #3AD984 !important;
  }
  .bg-xs-blue-bell {
    background-color: #9D9ECE !important;
  }
  .rgba-xs-blue-bell {
    background-color: rgba(157, 158, 206, 0.1) !important;
  }
  .text-xs-blue-bell {
    color: #9D9ECE !important;
  }
  .bg-xs-snuff {
    background-color: #DBD7E9 !important;
  }
  .rgba-xs-snuff {
    background-color: rgba(219, 215, 233, 0.1) !important;
  }
  .text-xs-snuff {
    color: #DBD7E9 !important;
  }
  .bg-xs-french-grey {
    background-color: #C3C3C7 !important;
  }
  .rgba-xs-french-grey {
    background-color: rgba(195, 195, 199, 0.1) !important;
  }
  .text-xs-french-grey {
    color: #C3C3C7 !important;
  }
  .bg-xs-ebony-clay {
    background-color: #1F2833 !important;
  }
  .rgba-xs-ebony-clay {
    background-color: rgba(31, 40, 51, 0.1) !important;
  }
  .text-xs-ebony-clay {
    color: #1F2833 !important;
  }
  .bg-xs-black-rock {
    background-color: #080327 !important;
  }
  .rgba-xs-black-rock {
    background-color: rgba(8, 3, 39, 0.1) !important;
  }
  .text-xs-black-rock {
    color: #080327 !important;
  }
  .bg-xs-st-lavender {
    background-color: #B4B5D5 !important;
  }
  .rgba-xs-st-lavender {
    background-color: rgba(180, 181, 213, 0.1) !important;
  }
  .text-xs-st-lavender {
    color: #B4B5D5 !important;
  }
  .bg-xs-titan-white {
    background-color: #F5F5FF !important;
  }
  .rgba-xs-titan-white {
    background-color: rgba(245, 245, 255, 0.1) !important;
  }
  .text-xs-titan-white {
    color: #F5F5FF !important;
  }
  .bg-xs-regent {
    background-color: #8D9BA4 !important;
  }
  .rgba-xs-regent {
    background-color: rgba(141, 155, 164, 0.1) !important;
  }
  .text-xs-regent {
    color: #8D9BA4 !important;
  }
  .bg-xs-shhamrock {
    background-color: #40D1A7 !important;
  }
  .rgba-xs-shhamrock {
    background-color: rgba(64, 209, 167, 0.1) !important;
  }
  .text-xs-shhamrock {
    color: #40D1A7 !important;
  }
  .bg-xs-gray-light {
    background-color: #f0f2f8 !important;
  }
  .rgba-xs-gray-light {
    background-color: rgba(240, 242, 248, 0.1) !important;
  }
  .text-xs-gray-light {
    color: #f0f2f8 !important;
  }
  .bg-xs-gray {
    background-color: #b6beda !important;
  }
  .rgba-xs-gray {
    background-color: rgba(182, 190, 218, 0.1) !important;
  }
  .text-xs-gray {
    color: #b6beda !important;
  }
  .bg-xs-gray-dark {
    background-color: #2f3447 !important;
  }
  .rgba-xs-gray-dark {
    background-color: rgba(47, 52, 71, 0.1) !important;
  }
  .text-xs-gray-dark {
    color: #2f3447 !important;
  }
  .bg-xs-blue-darker {
    background-color: #3f455c !important;
  }
  .rgba-xs-blue-darker {
    background-color: rgba(63, 69, 92, 0.1) !important;
  }
  .text-xs-blue-darker {
    color: #3f455c !important;
  }
  .bg-xs-blue-dark {
    background-color: #4a516a !important;
  }
  .rgba-xs-blue-dark {
    background-color: rgba(74, 81, 106, 0.1) !important;
  }
  .text-xs-blue-dark {
    color: #4a516a !important;
  }
  .bg-xs-blue {
    background-color: #2bbcff !important;
  }
  .rgba-xs-blue {
    background-color: rgba(43, 188, 255, 0.1) !important;
  }
  .text-xs-blue {
    color: #2bbcff !important;
  }
  .bg-xs-green {
    background-color: #2ad9d3 !important;
  }
  .rgba-xs-green {
    background-color: rgba(42, 217, 211, 0.1) !important;
  }
  .text-xs-green {
    color: #2ad9d3 !important;
  }
  .bg-xs-none {
    background: none !important;
  }
  .bgc-xs-none {
    background-color: transparent !important;
  }
  .bgi-xs-none {
    background-image: none !important;
  }
  .br-xs-none br {
    display: none !important;
  }
}

.sticky-menu.sticky-box-shadow {
  box-shadow: 0 1px 18px rgba(0, 0, 0, 0.04);
}

@media only screen and (max-width: 991.98px) {
  .sticky-menu.sticky-box-shadow {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.04);
  }
}

@-webkit-keyframes waves {
  0% {
    -webkit-transform: scale(0.2);
    -ms-transform: scale(0.2);
    -moz-transform: scale(0.2);
    -o-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@-moz-keyframes waves {
  0% {
    -webkit-transform: scale(0.2);
    -ms-transform: scale(0.2);
    -moz-transform: scale(0.2);
    -o-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@-ms-keyframes waves {
  0% {
    -webkit-transform: scale(0.2);
    -ms-transform: scale(0.2);
    -moz-transform: scale(0.2);
    -o-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2);
    -ms-transform: scale(0.2);
    -moz-transform: scale(0.2);
    -o-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@-webkit-keyframes map-waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    -ms-transform: scale(0.2, 0.2);
    -moz-transform: scale(0.2, 0.2);
    -o-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(3, 3);
    -ms-transform: scale(3, 3);
    -moz-transform: scale(3, 3);
    -o-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@-moz-keyframes map-waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    -ms-transform: scale(0.2, 0.2);
    -moz-transform: scale(0.2, 0.2);
    -o-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(3, 3);
    -ms-transform: scale(3, 3);
    -moz-transform: scale(3, 3);
    -o-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@-ms-keyframes map-waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    -ms-transform: scale(0.2, 0.2);
    -moz-transform: scale(0.2, 0.2);
    -o-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(3, 3);
    -ms-transform: scale(3, 3);
    -moz-transform: scale(3, 3);
    -o-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@keyframes map-waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    -ms-transform: scale(0.2, 0.2);
    -moz-transform: scale(0.2, 0.2);
    -o-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(3, 3);
    -ms-transform: scale(3, 3);
    -moz-transform: scale(3, 3);
    -o-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@-webkit-keyframes pulse_animation {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: .2;
  }
  50% {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0.6;
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: .2;
  }
}

@-moz-keyframes pulse_animation {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: .2;
  }
  50% {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0.6;
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: .2;
  }
}

@-ms-keyframes pulse_animation {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: .2;
  }
  50% {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0.6;
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: .2;
  }
}

@keyframes pulse_animation {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: .2;
  }
  50% {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0.6;
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: .2;
  }
}

@-webkit-keyframes stripeSlideIn {
  0% {
    -webkit-transform: translateX(-100px) skew(-4deg);
    -ms-transform: translateX(-100px) skew(-4deg);
    -moz-transform: translateX(-100px) skew(-4deg);
    -o-transform: translateX(-100px) skew(-4deg);
    transform: translateX(-100px) skew(-4deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) skew(-4deg);
    -ms-transform: translateX(0) skew(-4deg);
    -moz-transform: translateX(0) skew(-4deg);
    -o-transform: translateX(0) skew(-4deg);
    transform: translateX(0) skew(-4deg);
    opacity: 1;
  }
}

@-moz-keyframes stripeSlideIn {
  0% {
    -webkit-transform: translateX(-100px) skew(-4deg);
    -ms-transform: translateX(-100px) skew(-4deg);
    -moz-transform: translateX(-100px) skew(-4deg);
    -o-transform: translateX(-100px) skew(-4deg);
    transform: translateX(-100px) skew(-4deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) skew(-4deg);
    -ms-transform: translateX(0) skew(-4deg);
    -moz-transform: translateX(0) skew(-4deg);
    -o-transform: translateX(0) skew(-4deg);
    transform: translateX(0) skew(-4deg);
    opacity: 1;
  }
}

@-ms-keyframes stripeSlideIn {
  0% {
    -webkit-transform: translateX(-100px) skew(-4deg);
    -ms-transform: translateX(-100px) skew(-4deg);
    -moz-transform: translateX(-100px) skew(-4deg);
    -o-transform: translateX(-100px) skew(-4deg);
    transform: translateX(-100px) skew(-4deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) skew(-4deg);
    -ms-transform: translateX(0) skew(-4deg);
    -moz-transform: translateX(0) skew(-4deg);
    -o-transform: translateX(0) skew(-4deg);
    transform: translateX(0) skew(-4deg);
    opacity: 1;
  }
}

@keyframes stripeSlideIn {
  0% {
    -webkit-transform: translateX(-100px) skew(-4deg);
    -ms-transform: translateX(-100px) skew(-4deg);
    -moz-transform: translateX(-100px) skew(-4deg);
    -o-transform: translateX(-100px) skew(-4deg);
    transform: translateX(-100px) skew(-4deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) skew(-4deg);
    -ms-transform: translateX(0) skew(-4deg);
    -moz-transform: translateX(0) skew(-4deg);
    -o-transform: translateX(0) skew(-4deg);
    transform: translateX(0) skew(-4deg);
    opacity: 1;
  }
}

@-webkit-keyframes stripeSlideInRight {
  0% {
    -webkit-transform: translateX(100px) skew(0);
    -ms-transform: translateX(100px) skew(0);
    -moz-transform: translateX(100px) skew(0);
    -o-transform: translateX(100px) skew(0);
    transform: translateX(100px) skew(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) skew(-4deg);
    -ms-transform: translateX(0) skew(-4deg);
    -moz-transform: translateX(0) skew(-4deg);
    -o-transform: translateX(0) skew(-4deg);
    transform: translateX(0) skew(-4deg);
    opacity: 1;
  }
}

@-moz-keyframes stripeSlideInRight {
  0% {
    -webkit-transform: translateX(100px) skew(0);
    -ms-transform: translateX(100px) skew(0);
    -moz-transform: translateX(100px) skew(0);
    -o-transform: translateX(100px) skew(0);
    transform: translateX(100px) skew(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) skew(-4deg);
    -ms-transform: translateX(0) skew(-4deg);
    -moz-transform: translateX(0) skew(-4deg);
    -o-transform: translateX(0) skew(-4deg);
    transform: translateX(0) skew(-4deg);
    opacity: 1;
  }
}

@-ms-keyframes stripeSlideInRight {
  0% {
    -webkit-transform: translateX(100px) skew(0);
    -ms-transform: translateX(100px) skew(0);
    -moz-transform: translateX(100px) skew(0);
    -o-transform: translateX(100px) skew(0);
    transform: translateX(100px) skew(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) skew(-4deg);
    -ms-transform: translateX(0) skew(-4deg);
    -moz-transform: translateX(0) skew(-4deg);
    -o-transform: translateX(0) skew(-4deg);
    transform: translateX(0) skew(-4deg);
    opacity: 1;
  }
}

@keyframes stripeSlideInRight {
  0% {
    -webkit-transform: translateX(100px) skew(0);
    -ms-transform: translateX(100px) skew(0);
    -moz-transform: translateX(100px) skew(0);
    -o-transform: translateX(100px) skew(0);
    transform: translateX(100px) skew(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) skew(-4deg);
    -ms-transform: translateX(0) skew(-4deg);
    -moz-transform: translateX(0) skew(-4deg);
    -o-transform: translateX(0) skew(-4deg);
    transform: translateX(0) skew(-4deg);
    opacity: 1;
  }
}

@-webkit-keyframes floating {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 1.5vh, 0);
    -ms-transform: translate3d(0, 1.5vh, 0);
    -moz-transform: translate3d(0, 1.5vh, 0);
    -o-transform: translate3d(0, 1.5vh, 0);
    transform: translate3d(0, 1.5vh, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes floating {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 1.5vh, 0);
    -ms-transform: translate3d(0, 1.5vh, 0);
    -moz-transform: translate3d(0, 1.5vh, 0);
    -o-transform: translate3d(0, 1.5vh, 0);
    transform: translate3d(0, 1.5vh, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-ms-keyframes floating {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 1.5vh, 0);
    -ms-transform: translate3d(0, 1.5vh, 0);
    -moz-transform: translate3d(0, 1.5vh, 0);
    -o-transform: translate3d(0, 1.5vh, 0);
    transform: translate3d(0, 1.5vh, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes floating {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 1.5vh, 0);
    -ms-transform: translate3d(0, 1.5vh, 0);
    -moz-transform: translate3d(0, 1.5vh, 0);
    -o-transform: translate3d(0, 1.5vh, 0);
    transform: translate3d(0, 1.5vh, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes float2 {
  0% {
    animation-timing-function: ease-in-out;
    transform: translate3d(0, 0, 0);
  }
  50% {
    animation-timing-function: ease-in-out;
    transform: translate3d(0, 1.5vh, 0);
  }
  100% {
    animation-timing-function: ease-in-out;
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes st-rotate {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
  70% {
    /* this creates the bounce effect */
    -webkit-transform: perspective(2000px) rotateY(200deg);
    -ms-transform: perspective(2000px) rotateY(200deg);
    -moz-transform: perspective(2000px) rotateY(200deg);
    -o-transform: perspective(2000px) rotateY(200deg);
    transform: perspective(2000px) rotateY(200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
    -ms-transform: perspective(2000px) rotateY(180deg);
    -moz-transform: perspective(2000px) rotateY(180deg);
    -o-transform: perspective(2000px) rotateY(180deg);
    transform: perspective(2000px) rotateY(180deg);
  }
}

@-moz-keyframes st-rotate {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
  70% {
    /* this creates the bounce effect */
    -webkit-transform: perspective(2000px) rotateY(200deg);
    -ms-transform: perspective(2000px) rotateY(200deg);
    -moz-transform: perspective(2000px) rotateY(200deg);
    -o-transform: perspective(2000px) rotateY(200deg);
    transform: perspective(2000px) rotateY(200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
    -ms-transform: perspective(2000px) rotateY(180deg);
    -moz-transform: perspective(2000px) rotateY(180deg);
    -o-transform: perspective(2000px) rotateY(180deg);
    transform: perspective(2000px) rotateY(180deg);
  }
}

@-ms-keyframes st-rotate {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
  70% {
    /* this creates the bounce effect */
    -webkit-transform: perspective(2000px) rotateY(200deg);
    -ms-transform: perspective(2000px) rotateY(200deg);
    -moz-transform: perspective(2000px) rotateY(200deg);
    -o-transform: perspective(2000px) rotateY(200deg);
    transform: perspective(2000px) rotateY(200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
    -ms-transform: perspective(2000px) rotateY(180deg);
    -moz-transform: perspective(2000px) rotateY(180deg);
    -o-transform: perspective(2000px) rotateY(180deg);
    transform: perspective(2000px) rotateY(180deg);
  }
}

@keyframes st-rotate {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
  70% {
    /* this creates the bounce effect */
    -webkit-transform: perspective(2000px) rotateY(200deg);
    -ms-transform: perspective(2000px) rotateY(200deg);
    -moz-transform: perspective(2000px) rotateY(200deg);
    -o-transform: perspective(2000px) rotateY(200deg);
    transform: perspective(2000px) rotateY(200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
    -ms-transform: perspective(2000px) rotateY(180deg);
    -moz-transform: perspective(2000px) rotateY(180deg);
    -o-transform: perspective(2000px) rotateY(180deg);
    transform: perspective(2000px) rotateY(180deg);
  }
}

@-webkit-keyframes st-rotate-inverse {
  0% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
    -ms-transform: perspective(2000px) rotateY(-180deg);
    -moz-transform: perspective(2000px) rotateY(-180deg);
    -o-transform: perspective(2000px) rotateY(-180deg);
    transform: perspective(2000px) rotateY(-180deg);
  }
  70% {
    /* this creates the bounce effect */
    -webkit-transform: perspective(2000px) rotateY(20deg);
    -ms-transform: perspective(2000px) rotateY(20deg);
    -moz-transform: perspective(2000px) rotateY(20deg);
    -o-transform: perspective(2000px) rotateY(20deg);
    transform: perspective(2000px) rotateY(20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
}

@-moz-keyframes st-rotate-inverse {
  0% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
    -ms-transform: perspective(2000px) rotateY(-180deg);
    -moz-transform: perspective(2000px) rotateY(-180deg);
    -o-transform: perspective(2000px) rotateY(-180deg);
    transform: perspective(2000px) rotateY(-180deg);
  }
  70% {
    /* this creates the bounce effect */
    -webkit-transform: perspective(2000px) rotateY(20deg);
    -ms-transform: perspective(2000px) rotateY(20deg);
    -moz-transform: perspective(2000px) rotateY(20deg);
    -o-transform: perspective(2000px) rotateY(20deg);
    transform: perspective(2000px) rotateY(20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
}

@-ms-keyframes st-rotate-inverse {
  0% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
    -ms-transform: perspective(2000px) rotateY(-180deg);
    -moz-transform: perspective(2000px) rotateY(-180deg);
    -o-transform: perspective(2000px) rotateY(-180deg);
    transform: perspective(2000px) rotateY(-180deg);
  }
  70% {
    /* this creates the bounce effect */
    -webkit-transform: perspective(2000px) rotateY(20deg);
    -ms-transform: perspective(2000px) rotateY(20deg);
    -moz-transform: perspective(2000px) rotateY(20deg);
    -o-transform: perspective(2000px) rotateY(20deg);
    transform: perspective(2000px) rotateY(20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
}

@keyframes st-rotate-inverse {
  0% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
    -ms-transform: perspective(2000px) rotateY(-180deg);
    -moz-transform: perspective(2000px) rotateY(-180deg);
    -o-transform: perspective(2000px) rotateY(-180deg);
    transform: perspective(2000px) rotateY(-180deg);
  }
  70% {
    /* this creates the bounce effect */
    -webkit-transform: perspective(2000px) rotateY(20deg);
    -ms-transform: perspective(2000px) rotateY(20deg);
    -moz-transform: perspective(2000px) rotateY(20deg);
    -o-transform: perspective(2000px) rotateY(20deg);
    transform: perspective(2000px) rotateY(20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
}

@-webkit-keyframes st-rotate-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
  70% {
    /* this creates the bounce effect */
    -webkit-transform: perspective(2000px) rotateY(-200deg);
    -ms-transform: perspective(2000px) rotateY(-200deg);
    -moz-transform: perspective(2000px) rotateY(-200deg);
    -o-transform: perspective(2000px) rotateY(-200deg);
    transform: perspective(2000px) rotateY(-200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
    -ms-transform: perspective(2000px) rotateY(-180deg);
    -moz-transform: perspective(2000px) rotateY(-180deg);
    -o-transform: perspective(2000px) rotateY(-180deg);
    transform: perspective(2000px) rotateY(-180deg);
  }
}

@-moz-keyframes st-rotate-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
  70% {
    /* this creates the bounce effect */
    -webkit-transform: perspective(2000px) rotateY(-200deg);
    -ms-transform: perspective(2000px) rotateY(-200deg);
    -moz-transform: perspective(2000px) rotateY(-200deg);
    -o-transform: perspective(2000px) rotateY(-200deg);
    transform: perspective(2000px) rotateY(-200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
    -ms-transform: perspective(2000px) rotateY(-180deg);
    -moz-transform: perspective(2000px) rotateY(-180deg);
    -o-transform: perspective(2000px) rotateY(-180deg);
    transform: perspective(2000px) rotateY(-180deg);
  }
}

@-ms-keyframes st-rotate-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
  70% {
    /* this creates the bounce effect */
    -webkit-transform: perspective(2000px) rotateY(-200deg);
    -ms-transform: perspective(2000px) rotateY(-200deg);
    -moz-transform: perspective(2000px) rotateY(-200deg);
    -o-transform: perspective(2000px) rotateY(-200deg);
    transform: perspective(2000px) rotateY(-200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
    -ms-transform: perspective(2000px) rotateY(-180deg);
    -moz-transform: perspective(2000px) rotateY(-180deg);
    -o-transform: perspective(2000px) rotateY(-180deg);
    transform: perspective(2000px) rotateY(-180deg);
  }
}

@keyframes st-rotate-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
  70% {
    /* this creates the bounce effect */
    -webkit-transform: perspective(2000px) rotateY(-200deg);
    -ms-transform: perspective(2000px) rotateY(-200deg);
    -moz-transform: perspective(2000px) rotateY(-200deg);
    -o-transform: perspective(2000px) rotateY(-200deg);
    transform: perspective(2000px) rotateY(-200deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(-180deg);
    -ms-transform: perspective(2000px) rotateY(-180deg);
    -moz-transform: perspective(2000px) rotateY(-180deg);
    -o-transform: perspective(2000px) rotateY(-180deg);
    transform: perspective(2000px) rotateY(-180deg);
  }
}

@-webkit-keyframes st-rotate-inverse-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
    -ms-transform: perspective(2000px) rotateY(180deg);
    -moz-transform: perspective(2000px) rotateY(180deg);
    -o-transform: perspective(2000px) rotateY(180deg);
    transform: perspective(2000px) rotateY(180deg);
  }
  70% {
    /* this creates the bounce effect */
    -webkit-transform: perspective(2000px) rotateY(-20deg);
    -ms-transform: perspective(2000px) rotateY(-20deg);
    -moz-transform: perspective(2000px) rotateY(-20deg);
    -o-transform: perspective(2000px) rotateY(-20deg);
    transform: perspective(2000px) rotateY(-20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
}

@-moz-keyframes st-rotate-inverse-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
    -ms-transform: perspective(2000px) rotateY(180deg);
    -moz-transform: perspective(2000px) rotateY(180deg);
    -o-transform: perspective(2000px) rotateY(180deg);
    transform: perspective(2000px) rotateY(180deg);
  }
  70% {
    /* this creates the bounce effect */
    -webkit-transform: perspective(2000px) rotateY(-20deg);
    -ms-transform: perspective(2000px) rotateY(-20deg);
    -moz-transform: perspective(2000px) rotateY(-20deg);
    -o-transform: perspective(2000px) rotateY(-20deg);
    transform: perspective(2000px) rotateY(-20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
}

@-ms-keyframes st-rotate-inverse-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
    -ms-transform: perspective(2000px) rotateY(180deg);
    -moz-transform: perspective(2000px) rotateY(180deg);
    -o-transform: perspective(2000px) rotateY(180deg);
    transform: perspective(2000px) rotateY(180deg);
  }
  70% {
    /* this creates the bounce effect */
    -webkit-transform: perspective(2000px) rotateY(-20deg);
    -ms-transform: perspective(2000px) rotateY(-20deg);
    -moz-transform: perspective(2000px) rotateY(-20deg);
    -o-transform: perspective(2000px) rotateY(-20deg);
    transform: perspective(2000px) rotateY(-20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
}

@keyframes st-rotate-inverse-back {
  0% {
    -webkit-transform: perspective(2000px) rotateY(180deg);
    -ms-transform: perspective(2000px) rotateY(180deg);
    -moz-transform: perspective(2000px) rotateY(180deg);
    -o-transform: perspective(2000px) rotateY(180deg);
    transform: perspective(2000px) rotateY(180deg);
  }
  70% {
    /* this creates the bounce effect */
    -webkit-transform: perspective(2000px) rotateY(-20deg);
    -ms-transform: perspective(2000px) rotateY(-20deg);
    -moz-transform: perspective(2000px) rotateY(-20deg);
    -o-transform: perspective(2000px) rotateY(-20deg);
    transform: perspective(2000px) rotateY(-20deg);
  }
  100% {
    -webkit-transform: perspective(2000px) rotateY(0);
    -ms-transform: perspective(2000px) rotateY(0);
    -moz-transform: perspective(2000px) rotateY(0);
    -o-transform: perspective(2000px) rotateY(0);
    transform: perspective(2000px) rotateY(0);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-ms-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/*
This .scss loop will create "margin helpers" and "padding helpers" for use in your web projects.
It will generate several classes such as:
.m-r-10 which gives margin-right 10 pixels.
.m-r-15 gives MARGIN to the RIGHT 15 pixels.
.m-t-15 gives MARGIN to the TOP 15 pixels and so on.
.p-b-5 gives PADDING to the BOTTOM of 5 pixels
.p-l-40 gives PADDING to the LEFT of 40 pixels
The first letter is "m" or "p" for MARGIN or PADDING
Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
Third letter is the number of spacing in pixels. Adjust the amounts generated by editing the $spaceamounts variable below.
*/
.min-w-none {
  min-width: 0px !important;
}

.max-w-none {
  max-width: 0px !important;
}

.sticky-menu.sticky-padding-none {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.m-t-b-none {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.p-t-b-none {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.m-l-r-none {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.p-l-r-none {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.m-all-none {
  margin: 0px !important;
}

.p-all-none {
  padding: 0px !important;
}

.m-t-none {
  margin-top: 0px !important;
}

.p-t-none {
  padding-top: 0px !important;
}

.m-b-none {
  margin-bottom: 0px !important;
}

.p-b-none {
  padding-bottom: 0px !important;
}

.m-l-none {
  margin-left: 0px !important;
}

.p-l-none {
  padding-left: 0px !important;
}

.m-r-none {
  margin-right: 0px !important;
}

.p-r-none {
  padding-right: 0px !important;
}

.min-w-auto {
  min-width: auto !important;
}

.max-w-auto {
  max-width: auto !important;
}

.sticky-menu.sticky-padding-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.m-t-b-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.p-t-b-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.m-l-r-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.p-l-r-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}

.m-all-auto {
  margin: auto !important;
}

.p-all-auto {
  padding: auto !important;
}

.m-t-auto {
  margin-top: auto !important;
}

.p-t-auto {
  padding-top: auto !important;
}

.m-b-auto {
  margin-bottom: auto !important;
}

.p-b-auto {
  padding-bottom: auto !important;
}

.m-l-auto {
  margin-left: auto !important;
}

.p-l-auto {
  padding-left: auto !important;
}

.m-r-auto {
  margin-right: auto !important;
}

.p-r-auto {
  padding-right: auto !important;
}

.min-w-5 {
  min-width: 5px !important;
}

.max-w-5 {
  max-width: 5px !important;
}

.sticky-menu.sticky-padding-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.m-t-b-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.p-t-b-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.m-l-r-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.p-l-r-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.m-all-5 {
  margin: 5px !important;
}

.p-all-5 {
  padding: 5px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.min-w-10 {
  min-width: 10px !important;
}

.max-w-10 {
  max-width: 10px !important;
}

.sticky-menu.sticky-padding-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.m-t-b-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.p-t-b-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.m-l-r-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.p-l-r-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.m-all-10 {
  margin: 10px !important;
}

.p-all-10 {
  padding: 10px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.min-w-12 {
  min-width: 12px !important;
}

.max-w-12 {
  max-width: 12px !important;
}

.sticky-menu.sticky-padding-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.m-t-b-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.p-t-b-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.m-l-r-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.p-l-r-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.m-all-12 {
  margin: 12px !important;
}

.p-all-12 {
  padding: 12px !important;
}

.m-t-12 {
  margin-top: 12px !important;
}

.p-t-12 {
  padding-top: 12px !important;
}

.m-b-12 {
  margin-bottom: 12px !important;
}

.p-b-12 {
  padding-bottom: 12px !important;
}

.m-l-12 {
  margin-left: 12px !important;
}

.p-l-12 {
  padding-left: 12px !important;
}

.m-r-12 {
  margin-right: 12px !important;
}

.p-r-12 {
  padding-right: 12px !important;
}

.min-w-13 {
  min-width: 13px !important;
}

.max-w-13 {
  max-width: 13px !important;
}

.sticky-menu.sticky-padding-13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.m-t-b-13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.p-t-b-13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.m-l-r-13 {
  margin-left: 13px !important;
  margin-right: 13px !important;
}

.p-l-r-13 {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.m-all-13 {
  margin: 13px !important;
}

.p-all-13 {
  padding: 13px !important;
}

.m-t-13 {
  margin-top: 13px !important;
}

.p-t-13 {
  padding-top: 13px !important;
}

.m-b-13 {
  margin-bottom: 13px !important;
}

.p-b-13 {
  padding-bottom: 13px !important;
}

.m-l-13 {
  margin-left: 13px !important;
}

.p-l-13 {
  padding-left: 13px !important;
}

.m-r-13 {
  margin-right: 13px !important;
}

.p-r-13 {
  padding-right: 13px !important;
}

.min-w-15 {
  min-width: 15px !important;
}

.max-w-15 {
  max-width: 15px !important;
}

.sticky-menu.sticky-padding-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.m-t-b-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.p-t-b-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.m-l-r-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.p-l-r-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.m-all-15 {
  margin: 15px !important;
}

.p-all-15 {
  padding: 15px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.min-w-20 {
  min-width: 20px !important;
}

.max-w-20 {
  max-width: 20px !important;
}

.sticky-menu.sticky-padding-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.m-t-b-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.p-t-b-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.m-l-r-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.p-l-r-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.m-all-20 {
  margin: 20px !important;
}

.p-all-20 {
  padding: 20px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.min-w-25 {
  min-width: 25px !important;
}

.max-w-25 {
  max-width: 25px !important;
}

.sticky-menu.sticky-padding-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.m-t-b-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.p-t-b-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.m-l-r-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.p-l-r-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.m-all-25 {
  margin: 25px !important;
}

.p-all-25 {
  padding: 25px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.min-w-30 {
  min-width: 30px !important;
}

.max-w-30 {
  max-width: 30px !important;
}

.sticky-menu.sticky-padding-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.m-t-b-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.p-t-b-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.m-l-r-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.p-l-r-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.m-all-30 {
  margin: 30px !important;
}

.p-all-30 {
  padding: 30px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.min-w-35 {
  min-width: 35px !important;
}

.max-w-35 {
  max-width: 35px !important;
}

.sticky-menu.sticky-padding-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.m-t-b-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.p-t-b-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.m-l-r-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.p-l-r-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.m-all-35 {
  margin: 35px !important;
}

.p-all-35 {
  padding: 35px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.min-w-40 {
  min-width: 40px !important;
}

.max-w-40 {
  max-width: 40px !important;
}

.sticky-menu.sticky-padding-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.m-t-b-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.p-t-b-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.m-l-r-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.p-l-r-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.m-all-40 {
  margin: 40px !important;
}

.p-all-40 {
  padding: 40px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.min-w-45 {
  min-width: 45px !important;
}

.max-w-45 {
  max-width: 45px !important;
}

.sticky-menu.sticky-padding-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.m-t-b-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.p-t-b-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.m-l-r-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.p-l-r-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.m-all-45 {
  margin: 45px !important;
}

.p-all-45 {
  padding: 45px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.m-r-45 {
  margin-right: 45px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.min-w-50 {
  min-width: 50px !important;
}

.max-w-50 {
  max-width: 50px !important;
}

.sticky-menu.sticky-padding-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.m-t-b-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.p-t-b-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.m-l-r-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.p-l-r-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.m-all-50 {
  margin: 50px !important;
}

.p-all-50 {
  padding: 50px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.min-w-55 {
  min-width: 55px !important;
}

.max-w-55 {
  max-width: 55px !important;
}

.sticky-menu.sticky-padding-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.m-t-b-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.p-t-b-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.m-l-r-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.p-l-r-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.m-all-55 {
  margin: 55px !important;
}

.p-all-55 {
  padding: 55px !important;
}

.m-t-55 {
  margin-top: 55px !important;
}

.p-t-55 {
  padding-top: 55px !important;
}

.m-b-55 {
  margin-bottom: 55px !important;
}

.p-b-55 {
  padding-bottom: 55px !important;
}

.m-l-55 {
  margin-left: 55px !important;
}

.p-l-55 {
  padding-left: 55px !important;
}

.m-r-55 {
  margin-right: 55px !important;
}

.p-r-55 {
  padding-right: 55px !important;
}

.min-w-60 {
  min-width: 60px !important;
}

.max-w-60 {
  max-width: 60px !important;
}

.sticky-menu.sticky-padding-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.m-t-b-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.p-t-b-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.m-l-r-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.p-l-r-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.m-all-60 {
  margin: 60px !important;
}

.p-all-60 {
  padding: 60px !important;
}

.m-t-60 {
  margin-top: 60px !important;
}

.p-t-60 {
  padding-top: 60px !important;
}

.m-b-60 {
  margin-bottom: 60px !important;
}

.p-b-60 {
  padding-bottom: 60px !important;
}

.m-l-60 {
  margin-left: 60px !important;
}

.p-l-60 {
  padding-left: 60px !important;
}

.m-r-60 {
  margin-right: 60px !important;
}

.p-r-60 {
  padding-right: 60px !important;
}

.min-w-70 {
  min-width: 70px !important;
}

.max-w-70 {
  max-width: 70px !important;
}

.sticky-menu.sticky-padding-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.m-t-b-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.p-t-b-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.m-l-r-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.p-l-r-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.m-all-70 {
  margin: 70px !important;
}

.p-all-70 {
  padding: 70px !important;
}

.m-t-70 {
  margin-top: 70px !important;
}

.p-t-70 {
  padding-top: 70px !important;
}

.m-b-70 {
  margin-bottom: 70px !important;
}

.p-b-70 {
  padding-bottom: 70px !important;
}

.m-l-70 {
  margin-left: 70px !important;
}

.p-l-70 {
  padding-left: 70px !important;
}

.m-r-70 {
  margin-right: 70px !important;
}

.p-r-70 {
  padding-right: 70px !important;
}

.min-w-75 {
  min-width: 75px !important;
}

.max-w-75 {
  max-width: 75px !important;
}

.sticky-menu.sticky-padding-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.m-t-b-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.p-t-b-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.m-l-r-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.p-l-r-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.m-all-75 {
  margin: 75px !important;
}

.p-all-75 {
  padding: 75px !important;
}

.m-t-75 {
  margin-top: 75px !important;
}

.p-t-75 {
  padding-top: 75px !important;
}

.m-b-75 {
  margin-bottom: 75px !important;
}

.p-b-75 {
  padding-bottom: 75px !important;
}

.m-l-75 {
  margin-left: 75px !important;
}

.p-l-75 {
  padding-left: 75px !important;
}

.m-r-75 {
  margin-right: 75px !important;
}

.p-r-75 {
  padding-right: 75px !important;
}

.min-w-80 {
  min-width: 80px !important;
}

.max-w-80 {
  max-width: 80px !important;
}

.sticky-menu.sticky-padding-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.m-t-b-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.p-t-b-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.m-l-r-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.p-l-r-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.m-all-80 {
  margin: 80px !important;
}

.p-all-80 {
  padding: 80px !important;
}

.m-t-80 {
  margin-top: 80px !important;
}

.p-t-80 {
  padding-top: 80px !important;
}

.m-b-80 {
  margin-bottom: 80px !important;
}

.p-b-80 {
  padding-bottom: 80px !important;
}

.m-l-80 {
  margin-left: 80px !important;
}

.p-l-80 {
  padding-left: 80px !important;
}

.m-r-80 {
  margin-right: 80px !important;
}

.p-r-80 {
  padding-right: 80px !important;
}

.min-w-85 {
  min-width: 85px !important;
}

.max-w-85 {
  max-width: 85px !important;
}

.sticky-menu.sticky-padding-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.m-t-b-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.p-t-b-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.m-l-r-85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.p-l-r-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.m-all-85 {
  margin: 85px !important;
}

.p-all-85 {
  padding: 85px !important;
}

.m-t-85 {
  margin-top: 85px !important;
}

.p-t-85 {
  padding-top: 85px !important;
}

.m-b-85 {
  margin-bottom: 85px !important;
}

.p-b-85 {
  padding-bottom: 85px !important;
}

.m-l-85 {
  margin-left: 85px !important;
}

.p-l-85 {
  padding-left: 85px !important;
}

.m-r-85 {
  margin-right: 85px !important;
}

.p-r-85 {
  padding-right: 85px !important;
}

.min-w-90 {
  min-width: 90px !important;
}

.max-w-90 {
  max-width: 90px !important;
}

.sticky-menu.sticky-padding-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.m-t-b-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.p-t-b-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.m-l-r-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.p-l-r-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.m-all-90 {
  margin: 90px !important;
}

.p-all-90 {
  padding: 90px !important;
}

.m-t-90 {
  margin-top: 90px !important;
}

.p-t-90 {
  padding-top: 90px !important;
}

.m-b-90 {
  margin-bottom: 90px !important;
}

.p-b-90 {
  padding-bottom: 90px !important;
}

.m-l-90 {
  margin-left: 90px !important;
}

.p-l-90 {
  padding-left: 90px !important;
}

.m-r-90 {
  margin-right: 90px !important;
}

.p-r-90 {
  padding-right: 90px !important;
}

.min-w-95 {
  min-width: 95px !important;
}

.max-w-95 {
  max-width: 95px !important;
}

.sticky-menu.sticky-padding-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.m-t-b-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.p-t-b-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.m-l-r-95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.p-l-r-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.m-all-95 {
  margin: 95px !important;
}

.p-all-95 {
  padding: 95px !important;
}

.m-t-95 {
  margin-top: 95px !important;
}

.p-t-95 {
  padding-top: 95px !important;
}

.m-b-95 {
  margin-bottom: 95px !important;
}

.p-b-95 {
  padding-bottom: 95px !important;
}

.m-l-95 {
  margin-left: 95px !important;
}

.p-l-95 {
  padding-left: 95px !important;
}

.m-r-95 {
  margin-right: 95px !important;
}

.p-r-95 {
  padding-right: 95px !important;
}

.min-w-100 {
  min-width: 100px !important;
}

.max-w-100 {
  max-width: 100px !important;
}

.sticky-menu.sticky-padding-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.m-t-b-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.p-t-b-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.m-l-r-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.p-l-r-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.m-all-100 {
  margin: 100px !important;
}

.p-all-100 {
  padding: 100px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.p-t-100 {
  padding-top: 100px !important;
}

.m-b-100 {
  margin-bottom: 100px !important;
}

.p-b-100 {
  padding-bottom: 100px !important;
}

.m-l-100 {
  margin-left: 100px !important;
}

.p-l-100 {
  padding-left: 100px !important;
}

.m-r-100 {
  margin-right: 100px !important;
}

.p-r-100 {
  padding-right: 100px !important;
}

.min-w-110 {
  min-width: 110px !important;
}

.max-w-110 {
  max-width: 110px !important;
}

.sticky-menu.sticky-padding-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.m-t-b-110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

.p-t-b-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.m-l-r-110 {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

.p-l-r-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.m-all-110 {
  margin: 110px !important;
}

.p-all-110 {
  padding: 110px !important;
}

.m-t-110 {
  margin-top: 110px !important;
}

.p-t-110 {
  padding-top: 110px !important;
}

.m-b-110 {
  margin-bottom: 110px !important;
}

.p-b-110 {
  padding-bottom: 110px !important;
}

.m-l-110 {
  margin-left: 110px !important;
}

.p-l-110 {
  padding-left: 110px !important;
}

.m-r-110 {
  margin-right: 110px !important;
}

.p-r-110 {
  padding-right: 110px !important;
}

.min-w-115 {
  min-width: 115px !important;
}

.max-w-115 {
  max-width: 115px !important;
}

.sticky-menu.sticky-padding-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

.m-t-b-115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

.p-t-b-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

.m-l-r-115 {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

.p-l-r-115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

.m-all-115 {
  margin: 115px !important;
}

.p-all-115 {
  padding: 115px !important;
}

.m-t-115 {
  margin-top: 115px !important;
}

.p-t-115 {
  padding-top: 115px !important;
}

.m-b-115 {
  margin-bottom: 115px !important;
}

.p-b-115 {
  padding-bottom: 115px !important;
}

.m-l-115 {
  margin-left: 115px !important;
}

.p-l-115 {
  padding-left: 115px !important;
}

.m-r-115 {
  margin-right: 115px !important;
}

.p-r-115 {
  padding-right: 115px !important;
}

.min-w-120 {
  min-width: 120px !important;
}

.max-w-120 {
  max-width: 120px !important;
}

.sticky-menu.sticky-padding-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.m-t-b-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.p-t-b-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.m-l-r-120 {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

.p-l-r-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.m-all-120 {
  margin: 120px !important;
}

.p-all-120 {
  padding: 120px !important;
}

.m-t-120 {
  margin-top: 120px !important;
}

.p-t-120 {
  padding-top: 120px !important;
}

.m-b-120 {
  margin-bottom: 120px !important;
}

.p-b-120 {
  padding-bottom: 120px !important;
}

.m-l-120 {
  margin-left: 120px !important;
}

.p-l-120 {
  padding-left: 120px !important;
}

.m-r-120 {
  margin-right: 120px !important;
}

.p-r-120 {
  padding-right: 120px !important;
}

.min-w-130 {
  min-width: 130px !important;
}

.max-w-130 {
  max-width: 130px !important;
}

.sticky-menu.sticky-padding-130 {
  padding-top: 130px !important;
  padding-bottom: 130px !important;
}

.m-t-b-130 {
  margin-top: 130px !important;
  margin-bottom: 130px !important;
}

.p-t-b-130 {
  padding-top: 130px !important;
  padding-bottom: 130px !important;
}

.m-l-r-130 {
  margin-left: 130px !important;
  margin-right: 130px !important;
}

.p-l-r-130 {
  padding-left: 130px !important;
  padding-right: 130px !important;
}

.m-all-130 {
  margin: 130px !important;
}

.p-all-130 {
  padding: 130px !important;
}

.m-t-130 {
  margin-top: 130px !important;
}

.p-t-130 {
  padding-top: 130px !important;
}

.m-b-130 {
  margin-bottom: 130px !important;
}

.p-b-130 {
  padding-bottom: 130px !important;
}

.m-l-130 {
  margin-left: 130px !important;
}

.p-l-130 {
  padding-left: 130px !important;
}

.m-r-130 {
  margin-right: 130px !important;
}

.p-r-130 {
  padding-right: 130px !important;
}

.min-w-140 {
  min-width: 140px !important;
}

.max-w-140 {
  max-width: 140px !important;
}

.sticky-menu.sticky-padding-140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.m-t-b-140 {
  margin-top: 140px !important;
  margin-bottom: 140px !important;
}

.p-t-b-140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.m-l-r-140 {
  margin-left: 140px !important;
  margin-right: 140px !important;
}

.p-l-r-140 {
  padding-left: 140px !important;
  padding-right: 140px !important;
}

.m-all-140 {
  margin: 140px !important;
}

.p-all-140 {
  padding: 140px !important;
}

.m-t-140 {
  margin-top: 140px !important;
}

.p-t-140 {
  padding-top: 140px !important;
}

.m-b-140 {
  margin-bottom: 140px !important;
}

.p-b-140 {
  padding-bottom: 140px !important;
}

.m-l-140 {
  margin-left: 140px !important;
}

.p-l-140 {
  padding-left: 140px !important;
}

.m-r-140 {
  margin-right: 140px !important;
}

.p-r-140 {
  padding-right: 140px !important;
}

.min-w-150 {
  min-width: 150px !important;
}

.max-w-150 {
  max-width: 150px !important;
}

.sticky-menu.sticky-padding-150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.m-t-b-150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

.p-t-b-150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.m-l-r-150 {
  margin-left: 150px !important;
  margin-right: 150px !important;
}

.p-l-r-150 {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.m-all-150 {
  margin: 150px !important;
}

.p-all-150 {
  padding: 150px !important;
}

.m-t-150 {
  margin-top: 150px !important;
}

.p-t-150 {
  padding-top: 150px !important;
}

.m-b-150 {
  margin-bottom: 150px !important;
}

.p-b-150 {
  padding-bottom: 150px !important;
}

.m-l-150 {
  margin-left: 150px !important;
}

.p-l-150 {
  padding-left: 150px !important;
}

.m-r-150 {
  margin-right: 150px !important;
}

.p-r-150 {
  padding-right: 150px !important;
}

.min-w-175 {
  min-width: 175px !important;
}

.max-w-175 {
  max-width: 175px !important;
}

.sticky-menu.sticky-padding-175 {
  padding-top: 175px !important;
  padding-bottom: 175px !important;
}

.m-t-b-175 {
  margin-top: 175px !important;
  margin-bottom: 175px !important;
}

.p-t-b-175 {
  padding-top: 175px !important;
  padding-bottom: 175px !important;
}

.m-l-r-175 {
  margin-left: 175px !important;
  margin-right: 175px !important;
}

.p-l-r-175 {
  padding-left: 175px !important;
  padding-right: 175px !important;
}

.m-all-175 {
  margin: 175px !important;
}

.p-all-175 {
  padding: 175px !important;
}

.m-t-175 {
  margin-top: 175px !important;
}

.p-t-175 {
  padding-top: 175px !important;
}

.m-b-175 {
  margin-bottom: 175px !important;
}

.p-b-175 {
  padding-bottom: 175px !important;
}

.m-l-175 {
  margin-left: 175px !important;
}

.p-l-175 {
  padding-left: 175px !important;
}

.m-r-175 {
  margin-right: 175px !important;
}

.p-r-175 {
  padding-right: 175px !important;
}

.min-w-200 {
  min-width: 200px !important;
}

.max-w-200 {
  max-width: 200px !important;
}

.sticky-menu.sticky-padding-200 {
  padding-top: 200px !important;
  padding-bottom: 200px !important;
}

.m-t-b-200 {
  margin-top: 200px !important;
  margin-bottom: 200px !important;
}

.p-t-b-200 {
  padding-top: 200px !important;
  padding-bottom: 200px !important;
}

.m-l-r-200 {
  margin-left: 200px !important;
  margin-right: 200px !important;
}

.p-l-r-200 {
  padding-left: 200px !important;
  padding-right: 200px !important;
}

.m-all-200 {
  margin: 200px !important;
}

.p-all-200 {
  padding: 200px !important;
}

.m-t-200 {
  margin-top: 200px !important;
}

.p-t-200 {
  padding-top: 200px !important;
}

.m-b-200 {
  margin-bottom: 200px !important;
}

.p-b-200 {
  padding-bottom: 200px !important;
}

.m-l-200 {
  margin-left: 200px !important;
}

.p-l-200 {
  padding-left: 200px !important;
}

.m-r-200 {
  margin-right: 200px !important;
}

.p-r-200 {
  padding-right: 200px !important;
}

.min-w-225 {
  min-width: 225px !important;
}

.max-w-225 {
  max-width: 225px !important;
}

.sticky-menu.sticky-padding-225 {
  padding-top: 225px !important;
  padding-bottom: 225px !important;
}

.m-t-b-225 {
  margin-top: 225px !important;
  margin-bottom: 225px !important;
}

.p-t-b-225 {
  padding-top: 225px !important;
  padding-bottom: 225px !important;
}

.m-l-r-225 {
  margin-left: 225px !important;
  margin-right: 225px !important;
}

.p-l-r-225 {
  padding-left: 225px !important;
  padding-right: 225px !important;
}

.m-all-225 {
  margin: 225px !important;
}

.p-all-225 {
  padding: 225px !important;
}

.m-t-225 {
  margin-top: 225px !important;
}

.p-t-225 {
  padding-top: 225px !important;
}

.m-b-225 {
  margin-bottom: 225px !important;
}

.p-b-225 {
  padding-bottom: 225px !important;
}

.m-l-225 {
  margin-left: 225px !important;
}

.p-l-225 {
  padding-left: 225px !important;
}

.m-r-225 {
  margin-right: 225px !important;
}

.p-r-225 {
  padding-right: 225px !important;
}

.min-w-240 {
  min-width: 240px !important;
}

.max-w-240 {
  max-width: 240px !important;
}

.sticky-menu.sticky-padding-240 {
  padding-top: 240px !important;
  padding-bottom: 240px !important;
}

.m-t-b-240 {
  margin-top: 240px !important;
  margin-bottom: 240px !important;
}

.p-t-b-240 {
  padding-top: 240px !important;
  padding-bottom: 240px !important;
}

.m-l-r-240 {
  margin-left: 240px !important;
  margin-right: 240px !important;
}

.p-l-r-240 {
  padding-left: 240px !important;
  padding-right: 240px !important;
}

.m-all-240 {
  margin: 240px !important;
}

.p-all-240 {
  padding: 240px !important;
}

.m-t-240 {
  margin-top: 240px !important;
}

.p-t-240 {
  padding-top: 240px !important;
}

.m-b-240 {
  margin-bottom: 240px !important;
}

.p-b-240 {
  padding-bottom: 240px !important;
}

.m-l-240 {
  margin-left: 240px !important;
}

.p-l-240 {
  padding-left: 240px !important;
}

.m-r-240 {
  margin-right: 240px !important;
}

.p-r-240 {
  padding-right: 240px !important;
}

.min-w-250 {
  min-width: 250px !important;
}

.max-w-250 {
  max-width: 250px !important;
}

.sticky-menu.sticky-padding-250 {
  padding-top: 250px !important;
  padding-bottom: 250px !important;
}

.m-t-b-250 {
  margin-top: 250px !important;
  margin-bottom: 250px !important;
}

.p-t-b-250 {
  padding-top: 250px !important;
  padding-bottom: 250px !important;
}

.m-l-r-250 {
  margin-left: 250px !important;
  margin-right: 250px !important;
}

.p-l-r-250 {
  padding-left: 250px !important;
  padding-right: 250px !important;
}

.m-all-250 {
  margin: 250px !important;
}

.p-all-250 {
  padding: 250px !important;
}

.m-t-250 {
  margin-top: 250px !important;
}

.p-t-250 {
  padding-top: 250px !important;
}

.m-b-250 {
  margin-bottom: 250px !important;
}

.p-b-250 {
  padding-bottom: 250px !important;
}

.m-l-250 {
  margin-left: 250px !important;
}

.p-l-250 {
  padding-left: 250px !important;
}

.m-r-250 {
  margin-right: 250px !important;
}

.p-r-250 {
  padding-right: 250px !important;
}

.min-w-300 {
  min-width: 300px !important;
}

.max-w-300 {
  max-width: 300px !important;
}

.sticky-menu.sticky-padding-300 {
  padding-top: 300px !important;
  padding-bottom: 300px !important;
}

.m-t-b-300 {
  margin-top: 300px !important;
  margin-bottom: 300px !important;
}

.p-t-b-300 {
  padding-top: 300px !important;
  padding-bottom: 300px !important;
}

.m-l-r-300 {
  margin-left: 300px !important;
  margin-right: 300px !important;
}

.p-l-r-300 {
  padding-left: 300px !important;
  padding-right: 300px !important;
}

.m-all-300 {
  margin: 300px !important;
}

.p-all-300 {
  padding: 300px !important;
}

.m-t-300 {
  margin-top: 300px !important;
}

.p-t-300 {
  padding-top: 300px !important;
}

.m-b-300 {
  margin-bottom: 300px !important;
}

.p-b-300 {
  padding-bottom: 300px !important;
}

.m-l-300 {
  margin-left: 300px !important;
}

.p-l-300 {
  padding-left: 300px !important;
}

.m-r-300 {
  margin-right: 300px !important;
}

.p-r-300 {
  padding-right: 300px !important;
}

.min-w-350 {
  min-width: 350px !important;
}

.max-w-350 {
  max-width: 350px !important;
}

.sticky-menu.sticky-padding-350 {
  padding-top: 350px !important;
  padding-bottom: 350px !important;
}

.m-t-b-350 {
  margin-top: 350px !important;
  margin-bottom: 350px !important;
}

.p-t-b-350 {
  padding-top: 350px !important;
  padding-bottom: 350px !important;
}

.m-l-r-350 {
  margin-left: 350px !important;
  margin-right: 350px !important;
}

.p-l-r-350 {
  padding-left: 350px !important;
  padding-right: 350px !important;
}

.m-all-350 {
  margin: 350px !important;
}

.p-all-350 {
  padding: 350px !important;
}

.m-t-350 {
  margin-top: 350px !important;
}

.p-t-350 {
  padding-top: 350px !important;
}

.m-b-350 {
  margin-bottom: 350px !important;
}

.p-b-350 {
  padding-bottom: 350px !important;
}

.m-l-350 {
  margin-left: 350px !important;
}

.p-l-350 {
  padding-left: 350px !important;
}

.m-r-350 {
  margin-right: 350px !important;
}

.p-r-350 {
  padding-right: 350px !important;
}

.st_section_padding {
  padding: 100px 0;
}

@media only screen and (max-width: 1199.98px) {
  .st_section_padding {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 991.98px) {
  .st_section_padding {
    padding: 60px 0;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_section_padding {
    padding: 40px 0;
  }
}

.st_section_100_padding {
  padding: 100px 0;
}

.st_section_80_padding {
  padding: 80px 0;
}

.st_section_padding.bottom_0 {
  padding-bottom: 0;
}

.st_section_padding.top_0 {
  padding-top: 0;
}

.st_section_padding.top_100 {
  padding-top: 100px;
}

.st_section_padding.bottom_100 {
  padding-top: 100px;
}

.margin_bottom_60 {
  margin-bottom: 60px;
}

.height-100vh {
  height: 100vh;
  min-height: 980px;
  max-height: 1200px;
}

@media only screen and (max-width: 575.98px) {
  .height-100vh {
    min-height: 800px;
  }
}

.height-100vh.st-min-1150 {
  min-height: 1150px;
}

.st_no_padding {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (max-width: 767.98px) {
  .st_no_padding[class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.height-100vh-full-screen {
  height: 100vh;
  min-height: 966px;
  max-height: 1250px;
}

.st_screenfix {
  margin-right: calc((-100vw + 100%) / 2);
  max-width: 1600px;
}

@media only screen and (max-width: 991.98px) {
  .st_screenfix {
    margin-right: 0;
    max-width: inherit;
  }
  .st_screenfix.row {
    margin-right: -15px;
  }
}

.st-minus-25 {
  margin: 0 -25px;
}

.st-zindex-0 {
  z-index: 0 !important;
}

.st-zindex-1 {
  z-index: 1 !important;
}

.st-zindex-2 {
  z-index: 2 !important;
}

.st-zindex-3 {
  z-index: 3 !important;
}

.st-zindex-4 {
  z-index: 4 !important;
}

.st-zindex-5 {
  z-index: 5 !important;
}

.st-zindex-6 {
  z-index: 6 !important;
}

.st-zindex-7 {
  z-index: 7 !important;
}

.st-zindex-8 {
  z-index: 8 !important;
}

.st-zindex-9 {
  z-index: 9 !important;
}

.st-zindex-10 {
  z-index: 10 !important;
}

.st-zindex-11 {
  z-index: 11 !important;
}

@media only screen and (max-width: 1499.98px) {
  .min-xl-w-none {
    min-width: 0px !important;
  }
  .max-xl-w-none {
    max-width: 0px !important;
  }
  .sticky-menu.sticky-xl-padding-none {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .m-xl-t-b-none {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .p-xl-t-b-none {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .m-xl-l-r-none {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .p-xl-l-r-none {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .m-xl-all-none {
    margin: 0px !important;
  }
  .p-xl-all-none {
    padding: 0px !important;
  }
  .m-xl-t-none {
    margin-top: 0px !important;
  }
  .p-xl-t-none {
    padding-top: 0px !important;
  }
  .m-xl-b-none {
    margin-bottom: 0px !important;
  }
  .p-xl-b-none {
    padding-bottom: 0px !important;
  }
  .m-xl-l-none {
    margin-left: 0px !important;
  }
  .p-xl-l-none {
    padding-left: 0px !important;
  }
  .m-xl-r-none {
    margin-right: 0px !important;
  }
  .p-xl-r-none {
    padding-right: 0px !important;
  }
  .min-xl-w-auto {
    min-width: auto !important;
  }
  .max-xl-w-auto {
    max-width: auto !important;
  }
  .sticky-menu.sticky-xl-padding-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .m-xl-t-b-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .p-xl-t-b-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .m-xl-l-r-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .p-xl-l-r-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }
  .m-xl-all-auto {
    margin: auto !important;
  }
  .p-xl-all-auto {
    padding: auto !important;
  }
  .m-xl-t-auto {
    margin-top: auto !important;
  }
  .p-xl-t-auto {
    padding-top: auto !important;
  }
  .m-xl-b-auto {
    margin-bottom: auto !important;
  }
  .p-xl-b-auto {
    padding-bottom: auto !important;
  }
  .m-xl-l-auto {
    margin-left: auto !important;
  }
  .p-xl-l-auto {
    padding-left: auto !important;
  }
  .m-xl-r-auto {
    margin-right: auto !important;
  }
  .p-xl-r-auto {
    padding-right: auto !important;
  }
  .min-xl-w-5 {
    min-width: 5px !important;
  }
  .max-xl-w-5 {
    max-width: 5px !important;
  }
  .sticky-menu.sticky-xl-padding-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .m-xl-t-b-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .p-xl-t-b-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .m-xl-l-r-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .p-xl-l-r-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .m-xl-all-5 {
    margin: 5px !important;
  }
  .p-xl-all-5 {
    padding: 5px !important;
  }
  .m-xl-t-5 {
    margin-top: 5px !important;
  }
  .p-xl-t-5 {
    padding-top: 5px !important;
  }
  .m-xl-b-5 {
    margin-bottom: 5px !important;
  }
  .p-xl-b-5 {
    padding-bottom: 5px !important;
  }
  .m-xl-l-5 {
    margin-left: 5px !important;
  }
  .p-xl-l-5 {
    padding-left: 5px !important;
  }
  .m-xl-r-5 {
    margin-right: 5px !important;
  }
  .p-xl-r-5 {
    padding-right: 5px !important;
  }
  .min-xl-w-10 {
    min-width: 10px !important;
  }
  .max-xl-w-10 {
    max-width: 10px !important;
  }
  .sticky-menu.sticky-xl-padding-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .m-xl-t-b-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .p-xl-t-b-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .m-xl-l-r-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .p-xl-l-r-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .m-xl-all-10 {
    margin: 10px !important;
  }
  .p-xl-all-10 {
    padding: 10px !important;
  }
  .m-xl-t-10 {
    margin-top: 10px !important;
  }
  .p-xl-t-10 {
    padding-top: 10px !important;
  }
  .m-xl-b-10 {
    margin-bottom: 10px !important;
  }
  .p-xl-b-10 {
    padding-bottom: 10px !important;
  }
  .m-xl-l-10 {
    margin-left: 10px !important;
  }
  .p-xl-l-10 {
    padding-left: 10px !important;
  }
  .m-xl-r-10 {
    margin-right: 10px !important;
  }
  .p-xl-r-10 {
    padding-right: 10px !important;
  }
  .min-xl-w-12 {
    min-width: 12px !important;
  }
  .max-xl-w-12 {
    max-width: 12px !important;
  }
  .sticky-menu.sticky-xl-padding-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .m-xl-t-b-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .p-xl-t-b-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .m-xl-l-r-12 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .p-xl-l-r-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .m-xl-all-12 {
    margin: 12px !important;
  }
  .p-xl-all-12 {
    padding: 12px !important;
  }
  .m-xl-t-12 {
    margin-top: 12px !important;
  }
  .p-xl-t-12 {
    padding-top: 12px !important;
  }
  .m-xl-b-12 {
    margin-bottom: 12px !important;
  }
  .p-xl-b-12 {
    padding-bottom: 12px !important;
  }
  .m-xl-l-12 {
    margin-left: 12px !important;
  }
  .p-xl-l-12 {
    padding-left: 12px !important;
  }
  .m-xl-r-12 {
    margin-right: 12px !important;
  }
  .p-xl-r-12 {
    padding-right: 12px !important;
  }
  .min-xl-w-13 {
    min-width: 13px !important;
  }
  .max-xl-w-13 {
    max-width: 13px !important;
  }
  .sticky-menu.sticky-xl-padding-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .m-xl-t-b-13 {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }
  .p-xl-t-b-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .m-xl-l-r-13 {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }
  .p-xl-l-r-13 {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .m-xl-all-13 {
    margin: 13px !important;
  }
  .p-xl-all-13 {
    padding: 13px !important;
  }
  .m-xl-t-13 {
    margin-top: 13px !important;
  }
  .p-xl-t-13 {
    padding-top: 13px !important;
  }
  .m-xl-b-13 {
    margin-bottom: 13px !important;
  }
  .p-xl-b-13 {
    padding-bottom: 13px !important;
  }
  .m-xl-l-13 {
    margin-left: 13px !important;
  }
  .p-xl-l-13 {
    padding-left: 13px !important;
  }
  .m-xl-r-13 {
    margin-right: 13px !important;
  }
  .p-xl-r-13 {
    padding-right: 13px !important;
  }
  .min-xl-w-15 {
    min-width: 15px !important;
  }
  .max-xl-w-15 {
    max-width: 15px !important;
  }
  .sticky-menu.sticky-xl-padding-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .m-xl-t-b-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .p-xl-t-b-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .m-xl-l-r-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .p-xl-l-r-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .m-xl-all-15 {
    margin: 15px !important;
  }
  .p-xl-all-15 {
    padding: 15px !important;
  }
  .m-xl-t-15 {
    margin-top: 15px !important;
  }
  .p-xl-t-15 {
    padding-top: 15px !important;
  }
  .m-xl-b-15 {
    margin-bottom: 15px !important;
  }
  .p-xl-b-15 {
    padding-bottom: 15px !important;
  }
  .m-xl-l-15 {
    margin-left: 15px !important;
  }
  .p-xl-l-15 {
    padding-left: 15px !important;
  }
  .m-xl-r-15 {
    margin-right: 15px !important;
  }
  .p-xl-r-15 {
    padding-right: 15px !important;
  }
  .min-xl-w-20 {
    min-width: 20px !important;
  }
  .max-xl-w-20 {
    max-width: 20px !important;
  }
  .sticky-menu.sticky-xl-padding-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .m-xl-t-b-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .p-xl-t-b-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .m-xl-l-r-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .p-xl-l-r-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .m-xl-all-20 {
    margin: 20px !important;
  }
  .p-xl-all-20 {
    padding: 20px !important;
  }
  .m-xl-t-20 {
    margin-top: 20px !important;
  }
  .p-xl-t-20 {
    padding-top: 20px !important;
  }
  .m-xl-b-20 {
    margin-bottom: 20px !important;
  }
  .p-xl-b-20 {
    padding-bottom: 20px !important;
  }
  .m-xl-l-20 {
    margin-left: 20px !important;
  }
  .p-xl-l-20 {
    padding-left: 20px !important;
  }
  .m-xl-r-20 {
    margin-right: 20px !important;
  }
  .p-xl-r-20 {
    padding-right: 20px !important;
  }
  .min-xl-w-25 {
    min-width: 25px !important;
  }
  .max-xl-w-25 {
    max-width: 25px !important;
  }
  .sticky-menu.sticky-xl-padding-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .m-xl-t-b-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .p-xl-t-b-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .m-xl-l-r-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .p-xl-l-r-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .m-xl-all-25 {
    margin: 25px !important;
  }
  .p-xl-all-25 {
    padding: 25px !important;
  }
  .m-xl-t-25 {
    margin-top: 25px !important;
  }
  .p-xl-t-25 {
    padding-top: 25px !important;
  }
  .m-xl-b-25 {
    margin-bottom: 25px !important;
  }
  .p-xl-b-25 {
    padding-bottom: 25px !important;
  }
  .m-xl-l-25 {
    margin-left: 25px !important;
  }
  .p-xl-l-25 {
    padding-left: 25px !important;
  }
  .m-xl-r-25 {
    margin-right: 25px !important;
  }
  .p-xl-r-25 {
    padding-right: 25px !important;
  }
  .min-xl-w-30 {
    min-width: 30px !important;
  }
  .max-xl-w-30 {
    max-width: 30px !important;
  }
  .sticky-menu.sticky-xl-padding-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .m-xl-t-b-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .p-xl-t-b-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .m-xl-l-r-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .p-xl-l-r-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .m-xl-all-30 {
    margin: 30px !important;
  }
  .p-xl-all-30 {
    padding: 30px !important;
  }
  .m-xl-t-30 {
    margin-top: 30px !important;
  }
  .p-xl-t-30 {
    padding-top: 30px !important;
  }
  .m-xl-b-30 {
    margin-bottom: 30px !important;
  }
  .p-xl-b-30 {
    padding-bottom: 30px !important;
  }
  .m-xl-l-30 {
    margin-left: 30px !important;
  }
  .p-xl-l-30 {
    padding-left: 30px !important;
  }
  .m-xl-r-30 {
    margin-right: 30px !important;
  }
  .p-xl-r-30 {
    padding-right: 30px !important;
  }
  .min-xl-w-35 {
    min-width: 35px !important;
  }
  .max-xl-w-35 {
    max-width: 35px !important;
  }
  .sticky-menu.sticky-xl-padding-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .m-xl-t-b-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .p-xl-t-b-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .m-xl-l-r-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .p-xl-l-r-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .m-xl-all-35 {
    margin: 35px !important;
  }
  .p-xl-all-35 {
    padding: 35px !important;
  }
  .m-xl-t-35 {
    margin-top: 35px !important;
  }
  .p-xl-t-35 {
    padding-top: 35px !important;
  }
  .m-xl-b-35 {
    margin-bottom: 35px !important;
  }
  .p-xl-b-35 {
    padding-bottom: 35px !important;
  }
  .m-xl-l-35 {
    margin-left: 35px !important;
  }
  .p-xl-l-35 {
    padding-left: 35px !important;
  }
  .m-xl-r-35 {
    margin-right: 35px !important;
  }
  .p-xl-r-35 {
    padding-right: 35px !important;
  }
  .min-xl-w-40 {
    min-width: 40px !important;
  }
  .max-xl-w-40 {
    max-width: 40px !important;
  }
  .sticky-menu.sticky-xl-padding-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .m-xl-t-b-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .p-xl-t-b-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .m-xl-l-r-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .p-xl-l-r-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .m-xl-all-40 {
    margin: 40px !important;
  }
  .p-xl-all-40 {
    padding: 40px !important;
  }
  .m-xl-t-40 {
    margin-top: 40px !important;
  }
  .p-xl-t-40 {
    padding-top: 40px !important;
  }
  .m-xl-b-40 {
    margin-bottom: 40px !important;
  }
  .p-xl-b-40 {
    padding-bottom: 40px !important;
  }
  .m-xl-l-40 {
    margin-left: 40px !important;
  }
  .p-xl-l-40 {
    padding-left: 40px !important;
  }
  .m-xl-r-40 {
    margin-right: 40px !important;
  }
  .p-xl-r-40 {
    padding-right: 40px !important;
  }
  .min-xl-w-45 {
    min-width: 45px !important;
  }
  .max-xl-w-45 {
    max-width: 45px !important;
  }
  .sticky-menu.sticky-xl-padding-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .m-xl-t-b-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .p-xl-t-b-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .m-xl-l-r-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .p-xl-l-r-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .m-xl-all-45 {
    margin: 45px !important;
  }
  .p-xl-all-45 {
    padding: 45px !important;
  }
  .m-xl-t-45 {
    margin-top: 45px !important;
  }
  .p-xl-t-45 {
    padding-top: 45px !important;
  }
  .m-xl-b-45 {
    margin-bottom: 45px !important;
  }
  .p-xl-b-45 {
    padding-bottom: 45px !important;
  }
  .m-xl-l-45 {
    margin-left: 45px !important;
  }
  .p-xl-l-45 {
    padding-left: 45px !important;
  }
  .m-xl-r-45 {
    margin-right: 45px !important;
  }
  .p-xl-r-45 {
    padding-right: 45px !important;
  }
  .min-xl-w-50 {
    min-width: 50px !important;
  }
  .max-xl-w-50 {
    max-width: 50px !important;
  }
  .sticky-menu.sticky-xl-padding-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .m-xl-t-b-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .p-xl-t-b-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .m-xl-l-r-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .p-xl-l-r-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .m-xl-all-50 {
    margin: 50px !important;
  }
  .p-xl-all-50 {
    padding: 50px !important;
  }
  .m-xl-t-50 {
    margin-top: 50px !important;
  }
  .p-xl-t-50 {
    padding-top: 50px !important;
  }
  .m-xl-b-50 {
    margin-bottom: 50px !important;
  }
  .p-xl-b-50 {
    padding-bottom: 50px !important;
  }
  .m-xl-l-50 {
    margin-left: 50px !important;
  }
  .p-xl-l-50 {
    padding-left: 50px !important;
  }
  .m-xl-r-50 {
    margin-right: 50px !important;
  }
  .p-xl-r-50 {
    padding-right: 50px !important;
  }
  .min-xl-w-55 {
    min-width: 55px !important;
  }
  .max-xl-w-55 {
    max-width: 55px !important;
  }
  .sticky-menu.sticky-xl-padding-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .m-xl-t-b-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .p-xl-t-b-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .m-xl-l-r-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .p-xl-l-r-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .m-xl-all-55 {
    margin: 55px !important;
  }
  .p-xl-all-55 {
    padding: 55px !important;
  }
  .m-xl-t-55 {
    margin-top: 55px !important;
  }
  .p-xl-t-55 {
    padding-top: 55px !important;
  }
  .m-xl-b-55 {
    margin-bottom: 55px !important;
  }
  .p-xl-b-55 {
    padding-bottom: 55px !important;
  }
  .m-xl-l-55 {
    margin-left: 55px !important;
  }
  .p-xl-l-55 {
    padding-left: 55px !important;
  }
  .m-xl-r-55 {
    margin-right: 55px !important;
  }
  .p-xl-r-55 {
    padding-right: 55px !important;
  }
  .min-xl-w-60 {
    min-width: 60px !important;
  }
  .max-xl-w-60 {
    max-width: 60px !important;
  }
  .sticky-menu.sticky-xl-padding-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .m-xl-t-b-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .p-xl-t-b-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .m-xl-l-r-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .p-xl-l-r-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .m-xl-all-60 {
    margin: 60px !important;
  }
  .p-xl-all-60 {
    padding: 60px !important;
  }
  .m-xl-t-60 {
    margin-top: 60px !important;
  }
  .p-xl-t-60 {
    padding-top: 60px !important;
  }
  .m-xl-b-60 {
    margin-bottom: 60px !important;
  }
  .p-xl-b-60 {
    padding-bottom: 60px !important;
  }
  .m-xl-l-60 {
    margin-left: 60px !important;
  }
  .p-xl-l-60 {
    padding-left: 60px !important;
  }
  .m-xl-r-60 {
    margin-right: 60px !important;
  }
  .p-xl-r-60 {
    padding-right: 60px !important;
  }
  .min-xl-w-70 {
    min-width: 70px !important;
  }
  .max-xl-w-70 {
    max-width: 70px !important;
  }
  .sticky-menu.sticky-xl-padding-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .m-xl-t-b-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .p-xl-t-b-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .m-xl-l-r-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .p-xl-l-r-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .m-xl-all-70 {
    margin: 70px !important;
  }
  .p-xl-all-70 {
    padding: 70px !important;
  }
  .m-xl-t-70 {
    margin-top: 70px !important;
  }
  .p-xl-t-70 {
    padding-top: 70px !important;
  }
  .m-xl-b-70 {
    margin-bottom: 70px !important;
  }
  .p-xl-b-70 {
    padding-bottom: 70px !important;
  }
  .m-xl-l-70 {
    margin-left: 70px !important;
  }
  .p-xl-l-70 {
    padding-left: 70px !important;
  }
  .m-xl-r-70 {
    margin-right: 70px !important;
  }
  .p-xl-r-70 {
    padding-right: 70px !important;
  }
  .min-xl-w-75 {
    min-width: 75px !important;
  }
  .max-xl-w-75 {
    max-width: 75px !important;
  }
  .sticky-menu.sticky-xl-padding-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .m-xl-t-b-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .p-xl-t-b-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .m-xl-l-r-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .p-xl-l-r-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .m-xl-all-75 {
    margin: 75px !important;
  }
  .p-xl-all-75 {
    padding: 75px !important;
  }
  .m-xl-t-75 {
    margin-top: 75px !important;
  }
  .p-xl-t-75 {
    padding-top: 75px !important;
  }
  .m-xl-b-75 {
    margin-bottom: 75px !important;
  }
  .p-xl-b-75 {
    padding-bottom: 75px !important;
  }
  .m-xl-l-75 {
    margin-left: 75px !important;
  }
  .p-xl-l-75 {
    padding-left: 75px !important;
  }
  .m-xl-r-75 {
    margin-right: 75px !important;
  }
  .p-xl-r-75 {
    padding-right: 75px !important;
  }
  .min-xl-w-80 {
    min-width: 80px !important;
  }
  .max-xl-w-80 {
    max-width: 80px !important;
  }
  .sticky-menu.sticky-xl-padding-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .m-xl-t-b-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .p-xl-t-b-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .m-xl-l-r-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .p-xl-l-r-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .m-xl-all-80 {
    margin: 80px !important;
  }
  .p-xl-all-80 {
    padding: 80px !important;
  }
  .m-xl-t-80 {
    margin-top: 80px !important;
  }
  .p-xl-t-80 {
    padding-top: 80px !important;
  }
  .m-xl-b-80 {
    margin-bottom: 80px !important;
  }
  .p-xl-b-80 {
    padding-bottom: 80px !important;
  }
  .m-xl-l-80 {
    margin-left: 80px !important;
  }
  .p-xl-l-80 {
    padding-left: 80px !important;
  }
  .m-xl-r-80 {
    margin-right: 80px !important;
  }
  .p-xl-r-80 {
    padding-right: 80px !important;
  }
  .min-xl-w-85 {
    min-width: 85px !important;
  }
  .max-xl-w-85 {
    max-width: 85px !important;
  }
  .sticky-menu.sticky-xl-padding-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .m-xl-t-b-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .p-xl-t-b-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .m-xl-l-r-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .p-xl-l-r-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .m-xl-all-85 {
    margin: 85px !important;
  }
  .p-xl-all-85 {
    padding: 85px !important;
  }
  .m-xl-t-85 {
    margin-top: 85px !important;
  }
  .p-xl-t-85 {
    padding-top: 85px !important;
  }
  .m-xl-b-85 {
    margin-bottom: 85px !important;
  }
  .p-xl-b-85 {
    padding-bottom: 85px !important;
  }
  .m-xl-l-85 {
    margin-left: 85px !important;
  }
  .p-xl-l-85 {
    padding-left: 85px !important;
  }
  .m-xl-r-85 {
    margin-right: 85px !important;
  }
  .p-xl-r-85 {
    padding-right: 85px !important;
  }
  .min-xl-w-90 {
    min-width: 90px !important;
  }
  .max-xl-w-90 {
    max-width: 90px !important;
  }
  .sticky-menu.sticky-xl-padding-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .m-xl-t-b-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .p-xl-t-b-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .m-xl-l-r-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .p-xl-l-r-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .m-xl-all-90 {
    margin: 90px !important;
  }
  .p-xl-all-90 {
    padding: 90px !important;
  }
  .m-xl-t-90 {
    margin-top: 90px !important;
  }
  .p-xl-t-90 {
    padding-top: 90px !important;
  }
  .m-xl-b-90 {
    margin-bottom: 90px !important;
  }
  .p-xl-b-90 {
    padding-bottom: 90px !important;
  }
  .m-xl-l-90 {
    margin-left: 90px !important;
  }
  .p-xl-l-90 {
    padding-left: 90px !important;
  }
  .m-xl-r-90 {
    margin-right: 90px !important;
  }
  .p-xl-r-90 {
    padding-right: 90px !important;
  }
  .min-xl-w-95 {
    min-width: 95px !important;
  }
  .max-xl-w-95 {
    max-width: 95px !important;
  }
  .sticky-menu.sticky-xl-padding-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .m-xl-t-b-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .p-xl-t-b-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .m-xl-l-r-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .p-xl-l-r-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .m-xl-all-95 {
    margin: 95px !important;
  }
  .p-xl-all-95 {
    padding: 95px !important;
  }
  .m-xl-t-95 {
    margin-top: 95px !important;
  }
  .p-xl-t-95 {
    padding-top: 95px !important;
  }
  .m-xl-b-95 {
    margin-bottom: 95px !important;
  }
  .p-xl-b-95 {
    padding-bottom: 95px !important;
  }
  .m-xl-l-95 {
    margin-left: 95px !important;
  }
  .p-xl-l-95 {
    padding-left: 95px !important;
  }
  .m-xl-r-95 {
    margin-right: 95px !important;
  }
  .p-xl-r-95 {
    padding-right: 95px !important;
  }
  .min-xl-w-100 {
    min-width: 100px !important;
  }
  .max-xl-w-100 {
    max-width: 100px !important;
  }
  .sticky-menu.sticky-xl-padding-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-xl-t-b-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .p-xl-t-b-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-xl-l-r-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .p-xl-l-r-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .m-xl-all-100 {
    margin: 100px !important;
  }
  .p-xl-all-100 {
    padding: 100px !important;
  }
  .m-xl-t-100 {
    margin-top: 100px !important;
  }
  .p-xl-t-100 {
    padding-top: 100px !important;
  }
  .m-xl-b-100 {
    margin-bottom: 100px !important;
  }
  .p-xl-b-100 {
    padding-bottom: 100px !important;
  }
  .m-xl-l-100 {
    margin-left: 100px !important;
  }
  .p-xl-l-100 {
    padding-left: 100px !important;
  }
  .m-xl-r-100 {
    margin-right: 100px !important;
  }
  .p-xl-r-100 {
    padding-right: 100px !important;
  }
  .min-xl-w-110 {
    min-width: 110px !important;
  }
  .max-xl-w-110 {
    max-width: 110px !important;
  }
  .sticky-menu.sticky-xl-padding-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .m-xl-t-b-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  .p-xl-t-b-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .m-xl-l-r-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  .p-xl-l-r-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .m-xl-all-110 {
    margin: 110px !important;
  }
  .p-xl-all-110 {
    padding: 110px !important;
  }
  .m-xl-t-110 {
    margin-top: 110px !important;
  }
  .p-xl-t-110 {
    padding-top: 110px !important;
  }
  .m-xl-b-110 {
    margin-bottom: 110px !important;
  }
  .p-xl-b-110 {
    padding-bottom: 110px !important;
  }
  .m-xl-l-110 {
    margin-left: 110px !important;
  }
  .p-xl-l-110 {
    padding-left: 110px !important;
  }
  .m-xl-r-110 {
    margin-right: 110px !important;
  }
  .p-xl-r-110 {
    padding-right: 110px !important;
  }
  .min-xl-w-115 {
    min-width: 115px !important;
  }
  .max-xl-w-115 {
    max-width: 115px !important;
  }
  .sticky-menu.sticky-xl-padding-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .m-xl-t-b-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  .p-xl-t-b-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .m-xl-l-r-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  .p-xl-l-r-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .m-xl-all-115 {
    margin: 115px !important;
  }
  .p-xl-all-115 {
    padding: 115px !important;
  }
  .m-xl-t-115 {
    margin-top: 115px !important;
  }
  .p-xl-t-115 {
    padding-top: 115px !important;
  }
  .m-xl-b-115 {
    margin-bottom: 115px !important;
  }
  .p-xl-b-115 {
    padding-bottom: 115px !important;
  }
  .m-xl-l-115 {
    margin-left: 115px !important;
  }
  .p-xl-l-115 {
    padding-left: 115px !important;
  }
  .m-xl-r-115 {
    margin-right: 115px !important;
  }
  .p-xl-r-115 {
    padding-right: 115px !important;
  }
  .min-xl-w-120 {
    min-width: 120px !important;
  }
  .max-xl-w-120 {
    max-width: 120px !important;
  }
  .sticky-menu.sticky-xl-padding-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .m-xl-t-b-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .p-xl-t-b-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .m-xl-l-r-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  .p-xl-l-r-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .m-xl-all-120 {
    margin: 120px !important;
  }
  .p-xl-all-120 {
    padding: 120px !important;
  }
  .m-xl-t-120 {
    margin-top: 120px !important;
  }
  .p-xl-t-120 {
    padding-top: 120px !important;
  }
  .m-xl-b-120 {
    margin-bottom: 120px !important;
  }
  .p-xl-b-120 {
    padding-bottom: 120px !important;
  }
  .m-xl-l-120 {
    margin-left: 120px !important;
  }
  .p-xl-l-120 {
    padding-left: 120px !important;
  }
  .m-xl-r-120 {
    margin-right: 120px !important;
  }
  .p-xl-r-120 {
    padding-right: 120px !important;
  }
  .min-xl-w-130 {
    min-width: 130px !important;
  }
  .max-xl-w-130 {
    max-width: 130px !important;
  }
  .sticky-menu.sticky-xl-padding-130 {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .m-xl-t-b-130 {
    margin-top: 130px !important;
    margin-bottom: 130px !important;
  }
  .p-xl-t-b-130 {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .m-xl-l-r-130 {
    margin-left: 130px !important;
    margin-right: 130px !important;
  }
  .p-xl-l-r-130 {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }
  .m-xl-all-130 {
    margin: 130px !important;
  }
  .p-xl-all-130 {
    padding: 130px !important;
  }
  .m-xl-t-130 {
    margin-top: 130px !important;
  }
  .p-xl-t-130 {
    padding-top: 130px !important;
  }
  .m-xl-b-130 {
    margin-bottom: 130px !important;
  }
  .p-xl-b-130 {
    padding-bottom: 130px !important;
  }
  .m-xl-l-130 {
    margin-left: 130px !important;
  }
  .p-xl-l-130 {
    padding-left: 130px !important;
  }
  .m-xl-r-130 {
    margin-right: 130px !important;
  }
  .p-xl-r-130 {
    padding-right: 130px !important;
  }
  .min-xl-w-140 {
    min-width: 140px !important;
  }
  .max-xl-w-140 {
    max-width: 140px !important;
  }
  .sticky-menu.sticky-xl-padding-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .m-xl-t-b-140 {
    margin-top: 140px !important;
    margin-bottom: 140px !important;
  }
  .p-xl-t-b-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .m-xl-l-r-140 {
    margin-left: 140px !important;
    margin-right: 140px !important;
  }
  .p-xl-l-r-140 {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .m-xl-all-140 {
    margin: 140px !important;
  }
  .p-xl-all-140 {
    padding: 140px !important;
  }
  .m-xl-t-140 {
    margin-top: 140px !important;
  }
  .p-xl-t-140 {
    padding-top: 140px !important;
  }
  .m-xl-b-140 {
    margin-bottom: 140px !important;
  }
  .p-xl-b-140 {
    padding-bottom: 140px !important;
  }
  .m-xl-l-140 {
    margin-left: 140px !important;
  }
  .p-xl-l-140 {
    padding-left: 140px !important;
  }
  .m-xl-r-140 {
    margin-right: 140px !important;
  }
  .p-xl-r-140 {
    padding-right: 140px !important;
  }
  .min-xl-w-150 {
    min-width: 150px !important;
  }
  .max-xl-w-150 {
    max-width: 150px !important;
  }
  .sticky-menu.sticky-xl-padding-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .m-xl-t-b-150 {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .p-xl-t-b-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .m-xl-l-r-150 {
    margin-left: 150px !important;
    margin-right: 150px !important;
  }
  .p-xl-l-r-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .m-xl-all-150 {
    margin: 150px !important;
  }
  .p-xl-all-150 {
    padding: 150px !important;
  }
  .m-xl-t-150 {
    margin-top: 150px !important;
  }
  .p-xl-t-150 {
    padding-top: 150px !important;
  }
  .m-xl-b-150 {
    margin-bottom: 150px !important;
  }
  .p-xl-b-150 {
    padding-bottom: 150px !important;
  }
  .m-xl-l-150 {
    margin-left: 150px !important;
  }
  .p-xl-l-150 {
    padding-left: 150px !important;
  }
  .m-xl-r-150 {
    margin-right: 150px !important;
  }
  .p-xl-r-150 {
    padding-right: 150px !important;
  }
  .min-xl-w-175 {
    min-width: 175px !important;
  }
  .max-xl-w-175 {
    max-width: 175px !important;
  }
  .sticky-menu.sticky-xl-padding-175 {
    padding-top: 175px !important;
    padding-bottom: 175px !important;
  }
  .m-xl-t-b-175 {
    margin-top: 175px !important;
    margin-bottom: 175px !important;
  }
  .p-xl-t-b-175 {
    padding-top: 175px !important;
    padding-bottom: 175px !important;
  }
  .m-xl-l-r-175 {
    margin-left: 175px !important;
    margin-right: 175px !important;
  }
  .p-xl-l-r-175 {
    padding-left: 175px !important;
    padding-right: 175px !important;
  }
  .m-xl-all-175 {
    margin: 175px !important;
  }
  .p-xl-all-175 {
    padding: 175px !important;
  }
  .m-xl-t-175 {
    margin-top: 175px !important;
  }
  .p-xl-t-175 {
    padding-top: 175px !important;
  }
  .m-xl-b-175 {
    margin-bottom: 175px !important;
  }
  .p-xl-b-175 {
    padding-bottom: 175px !important;
  }
  .m-xl-l-175 {
    margin-left: 175px !important;
  }
  .p-xl-l-175 {
    padding-left: 175px !important;
  }
  .m-xl-r-175 {
    margin-right: 175px !important;
  }
  .p-xl-r-175 {
    padding-right: 175px !important;
  }
  .min-xl-w-200 {
    min-width: 200px !important;
  }
  .max-xl-w-200 {
    max-width: 200px !important;
  }
  .sticky-menu.sticky-xl-padding-200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .m-xl-t-b-200 {
    margin-top: 200px !important;
    margin-bottom: 200px !important;
  }
  .p-xl-t-b-200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .m-xl-l-r-200 {
    margin-left: 200px !important;
    margin-right: 200px !important;
  }
  .p-xl-l-r-200 {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .m-xl-all-200 {
    margin: 200px !important;
  }
  .p-xl-all-200 {
    padding: 200px !important;
  }
  .m-xl-t-200 {
    margin-top: 200px !important;
  }
  .p-xl-t-200 {
    padding-top: 200px !important;
  }
  .m-xl-b-200 {
    margin-bottom: 200px !important;
  }
  .p-xl-b-200 {
    padding-bottom: 200px !important;
  }
  .m-xl-l-200 {
    margin-left: 200px !important;
  }
  .p-xl-l-200 {
    padding-left: 200px !important;
  }
  .m-xl-r-200 {
    margin-right: 200px !important;
  }
  .p-xl-r-200 {
    padding-right: 200px !important;
  }
  .min-xl-w-225 {
    min-width: 225px !important;
  }
  .max-xl-w-225 {
    max-width: 225px !important;
  }
  .sticky-menu.sticky-xl-padding-225 {
    padding-top: 225px !important;
    padding-bottom: 225px !important;
  }
  .m-xl-t-b-225 {
    margin-top: 225px !important;
    margin-bottom: 225px !important;
  }
  .p-xl-t-b-225 {
    padding-top: 225px !important;
    padding-bottom: 225px !important;
  }
  .m-xl-l-r-225 {
    margin-left: 225px !important;
    margin-right: 225px !important;
  }
  .p-xl-l-r-225 {
    padding-left: 225px !important;
    padding-right: 225px !important;
  }
  .m-xl-all-225 {
    margin: 225px !important;
  }
  .p-xl-all-225 {
    padding: 225px !important;
  }
  .m-xl-t-225 {
    margin-top: 225px !important;
  }
  .p-xl-t-225 {
    padding-top: 225px !important;
  }
  .m-xl-b-225 {
    margin-bottom: 225px !important;
  }
  .p-xl-b-225 {
    padding-bottom: 225px !important;
  }
  .m-xl-l-225 {
    margin-left: 225px !important;
  }
  .p-xl-l-225 {
    padding-left: 225px !important;
  }
  .m-xl-r-225 {
    margin-right: 225px !important;
  }
  .p-xl-r-225 {
    padding-right: 225px !important;
  }
  .min-xl-w-240 {
    min-width: 240px !important;
  }
  .max-xl-w-240 {
    max-width: 240px !important;
  }
  .sticky-menu.sticky-xl-padding-240 {
    padding-top: 240px !important;
    padding-bottom: 240px !important;
  }
  .m-xl-t-b-240 {
    margin-top: 240px !important;
    margin-bottom: 240px !important;
  }
  .p-xl-t-b-240 {
    padding-top: 240px !important;
    padding-bottom: 240px !important;
  }
  .m-xl-l-r-240 {
    margin-left: 240px !important;
    margin-right: 240px !important;
  }
  .p-xl-l-r-240 {
    padding-left: 240px !important;
    padding-right: 240px !important;
  }
  .m-xl-all-240 {
    margin: 240px !important;
  }
  .p-xl-all-240 {
    padding: 240px !important;
  }
  .m-xl-t-240 {
    margin-top: 240px !important;
  }
  .p-xl-t-240 {
    padding-top: 240px !important;
  }
  .m-xl-b-240 {
    margin-bottom: 240px !important;
  }
  .p-xl-b-240 {
    padding-bottom: 240px !important;
  }
  .m-xl-l-240 {
    margin-left: 240px !important;
  }
  .p-xl-l-240 {
    padding-left: 240px !important;
  }
  .m-xl-r-240 {
    margin-right: 240px !important;
  }
  .p-xl-r-240 {
    padding-right: 240px !important;
  }
  .min-xl-w-250 {
    min-width: 250px !important;
  }
  .max-xl-w-250 {
    max-width: 250px !important;
  }
  .sticky-menu.sticky-xl-padding-250 {
    padding-top: 250px !important;
    padding-bottom: 250px !important;
  }
  .m-xl-t-b-250 {
    margin-top: 250px !important;
    margin-bottom: 250px !important;
  }
  .p-xl-t-b-250 {
    padding-top: 250px !important;
    padding-bottom: 250px !important;
  }
  .m-xl-l-r-250 {
    margin-left: 250px !important;
    margin-right: 250px !important;
  }
  .p-xl-l-r-250 {
    padding-left: 250px !important;
    padding-right: 250px !important;
  }
  .m-xl-all-250 {
    margin: 250px !important;
  }
  .p-xl-all-250 {
    padding: 250px !important;
  }
  .m-xl-t-250 {
    margin-top: 250px !important;
  }
  .p-xl-t-250 {
    padding-top: 250px !important;
  }
  .m-xl-b-250 {
    margin-bottom: 250px !important;
  }
  .p-xl-b-250 {
    padding-bottom: 250px !important;
  }
  .m-xl-l-250 {
    margin-left: 250px !important;
  }
  .p-xl-l-250 {
    padding-left: 250px !important;
  }
  .m-xl-r-250 {
    margin-right: 250px !important;
  }
  .p-xl-r-250 {
    padding-right: 250px !important;
  }
  .min-xl-w-300 {
    min-width: 300px !important;
  }
  .max-xl-w-300 {
    max-width: 300px !important;
  }
  .sticky-menu.sticky-xl-padding-300 {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .m-xl-t-b-300 {
    margin-top: 300px !important;
    margin-bottom: 300px !important;
  }
  .p-xl-t-b-300 {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .m-xl-l-r-300 {
    margin-left: 300px !important;
    margin-right: 300px !important;
  }
  .p-xl-l-r-300 {
    padding-left: 300px !important;
    padding-right: 300px !important;
  }
  .m-xl-all-300 {
    margin: 300px !important;
  }
  .p-xl-all-300 {
    padding: 300px !important;
  }
  .m-xl-t-300 {
    margin-top: 300px !important;
  }
  .p-xl-t-300 {
    padding-top: 300px !important;
  }
  .m-xl-b-300 {
    margin-bottom: 300px !important;
  }
  .p-xl-b-300 {
    padding-bottom: 300px !important;
  }
  .m-xl-l-300 {
    margin-left: 300px !important;
  }
  .p-xl-l-300 {
    padding-left: 300px !important;
  }
  .m-xl-r-300 {
    margin-right: 300px !important;
  }
  .p-xl-r-300 {
    padding-right: 300px !important;
  }
  .min-xl-w-350 {
    min-width: 350px !important;
  }
  .max-xl-w-350 {
    max-width: 350px !important;
  }
  .sticky-menu.sticky-xl-padding-350 {
    padding-top: 350px !important;
    padding-bottom: 350px !important;
  }
  .m-xl-t-b-350 {
    margin-top: 350px !important;
    margin-bottom: 350px !important;
  }
  .p-xl-t-b-350 {
    padding-top: 350px !important;
    padding-bottom: 350px !important;
  }
  .m-xl-l-r-350 {
    margin-left: 350px !important;
    margin-right: 350px !important;
  }
  .p-xl-l-r-350 {
    padding-left: 350px !important;
    padding-right: 350px !important;
  }
  .m-xl-all-350 {
    margin: 350px !important;
  }
  .p-xl-all-350 {
    padding: 350px !important;
  }
  .m-xl-t-350 {
    margin-top: 350px !important;
  }
  .p-xl-t-350 {
    padding-top: 350px !important;
  }
  .m-xl-b-350 {
    margin-bottom: 350px !important;
  }
  .p-xl-b-350 {
    padding-bottom: 350px !important;
  }
  .m-xl-l-350 {
    margin-left: 350px !important;
  }
  .p-xl-l-350 {
    padding-left: 350px !important;
  }
  .m-xl-r-350 {
    margin-right: 350px !important;
  }
  .p-xl-r-350 {
    padding-right: 350px !important;
  }
}

@media only screen and (max-width: 1199.98px) {
  .min-lg-w-none {
    min-width: 0px !important;
  }
  .max-lg-w-none {
    max-width: 0px !important;
  }
  .sticky-menu.sticky-lg-padding-none {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .m-lg-t-b-none {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .p-lg-t-b-none {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .m-lg-l-r-none {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .p-lg-l-r-none {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .m-lg-all-none {
    margin: 0px !important;
  }
  .p-lg-all-none {
    padding: 0px !important;
  }
  .m-lg-t-none {
    margin-top: 0px !important;
  }
  .p-lg-t-none {
    padding-top: 0px !important;
  }
  .m-lg-b-none {
    margin-bottom: 0px !important;
  }
  .p-lg-b-none {
    padding-bottom: 0px !important;
  }
  .m-lg-l-none {
    margin-left: 0px !important;
  }
  .p-lg-l-none {
    padding-left: 0px !important;
  }
  .m-lg-r-none {
    margin-right: 0px !important;
  }
  .p-lg-r-none {
    padding-right: 0px !important;
  }
  .min-lg-w-auto {
    min-width: auto !important;
  }
  .max-lg-w-auto {
    max-width: auto !important;
  }
  .sticky-menu.sticky-lg-padding-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .m-lg-t-b-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .p-lg-t-b-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .m-lg-l-r-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .p-lg-l-r-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }
  .m-lg-all-auto {
    margin: auto !important;
  }
  .p-lg-all-auto {
    padding: auto !important;
  }
  .m-lg-t-auto {
    margin-top: auto !important;
  }
  .p-lg-t-auto {
    padding-top: auto !important;
  }
  .m-lg-b-auto {
    margin-bottom: auto !important;
  }
  .p-lg-b-auto {
    padding-bottom: auto !important;
  }
  .m-lg-l-auto {
    margin-left: auto !important;
  }
  .p-lg-l-auto {
    padding-left: auto !important;
  }
  .m-lg-r-auto {
    margin-right: auto !important;
  }
  .p-lg-r-auto {
    padding-right: auto !important;
  }
  .min-lg-w-5 {
    min-width: 5px !important;
  }
  .max-lg-w-5 {
    max-width: 5px !important;
  }
  .sticky-menu.sticky-lg-padding-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .m-lg-t-b-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .p-lg-t-b-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .m-lg-l-r-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .p-lg-l-r-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .m-lg-all-5 {
    margin: 5px !important;
  }
  .p-lg-all-5 {
    padding: 5px !important;
  }
  .m-lg-t-5 {
    margin-top: 5px !important;
  }
  .p-lg-t-5 {
    padding-top: 5px !important;
  }
  .m-lg-b-5 {
    margin-bottom: 5px !important;
  }
  .p-lg-b-5 {
    padding-bottom: 5px !important;
  }
  .m-lg-l-5 {
    margin-left: 5px !important;
  }
  .p-lg-l-5 {
    padding-left: 5px !important;
  }
  .m-lg-r-5 {
    margin-right: 5px !important;
  }
  .p-lg-r-5 {
    padding-right: 5px !important;
  }
  .min-lg-w-10 {
    min-width: 10px !important;
  }
  .max-lg-w-10 {
    max-width: 10px !important;
  }
  .sticky-menu.sticky-lg-padding-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .m-lg-t-b-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .p-lg-t-b-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .m-lg-l-r-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .p-lg-l-r-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .m-lg-all-10 {
    margin: 10px !important;
  }
  .p-lg-all-10 {
    padding: 10px !important;
  }
  .m-lg-t-10 {
    margin-top: 10px !important;
  }
  .p-lg-t-10 {
    padding-top: 10px !important;
  }
  .m-lg-b-10 {
    margin-bottom: 10px !important;
  }
  .p-lg-b-10 {
    padding-bottom: 10px !important;
  }
  .m-lg-l-10 {
    margin-left: 10px !important;
  }
  .p-lg-l-10 {
    padding-left: 10px !important;
  }
  .m-lg-r-10 {
    margin-right: 10px !important;
  }
  .p-lg-r-10 {
    padding-right: 10px !important;
  }
  .min-lg-w-12 {
    min-width: 12px !important;
  }
  .max-lg-w-12 {
    max-width: 12px !important;
  }
  .sticky-menu.sticky-lg-padding-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .m-lg-t-b-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .p-lg-t-b-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .m-lg-l-r-12 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .p-lg-l-r-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .m-lg-all-12 {
    margin: 12px !important;
  }
  .p-lg-all-12 {
    padding: 12px !important;
  }
  .m-lg-t-12 {
    margin-top: 12px !important;
  }
  .p-lg-t-12 {
    padding-top: 12px !important;
  }
  .m-lg-b-12 {
    margin-bottom: 12px !important;
  }
  .p-lg-b-12 {
    padding-bottom: 12px !important;
  }
  .m-lg-l-12 {
    margin-left: 12px !important;
  }
  .p-lg-l-12 {
    padding-left: 12px !important;
  }
  .m-lg-r-12 {
    margin-right: 12px !important;
  }
  .p-lg-r-12 {
    padding-right: 12px !important;
  }
  .min-lg-w-13 {
    min-width: 13px !important;
  }
  .max-lg-w-13 {
    max-width: 13px !important;
  }
  .sticky-menu.sticky-lg-padding-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .m-lg-t-b-13 {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }
  .p-lg-t-b-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .m-lg-l-r-13 {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }
  .p-lg-l-r-13 {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .m-lg-all-13 {
    margin: 13px !important;
  }
  .p-lg-all-13 {
    padding: 13px !important;
  }
  .m-lg-t-13 {
    margin-top: 13px !important;
  }
  .p-lg-t-13 {
    padding-top: 13px !important;
  }
  .m-lg-b-13 {
    margin-bottom: 13px !important;
  }
  .p-lg-b-13 {
    padding-bottom: 13px !important;
  }
  .m-lg-l-13 {
    margin-left: 13px !important;
  }
  .p-lg-l-13 {
    padding-left: 13px !important;
  }
  .m-lg-r-13 {
    margin-right: 13px !important;
  }
  .p-lg-r-13 {
    padding-right: 13px !important;
  }
  .min-lg-w-15 {
    min-width: 15px !important;
  }
  .max-lg-w-15 {
    max-width: 15px !important;
  }
  .sticky-menu.sticky-lg-padding-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .m-lg-t-b-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .p-lg-t-b-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .m-lg-l-r-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .p-lg-l-r-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .m-lg-all-15 {
    margin: 15px !important;
  }
  .p-lg-all-15 {
    padding: 15px !important;
  }
  .m-lg-t-15 {
    margin-top: 15px !important;
  }
  .p-lg-t-15 {
    padding-top: 15px !important;
  }
  .m-lg-b-15 {
    margin-bottom: 15px !important;
  }
  .p-lg-b-15 {
    padding-bottom: 15px !important;
  }
  .m-lg-l-15 {
    margin-left: 15px !important;
  }
  .p-lg-l-15 {
    padding-left: 15px !important;
  }
  .m-lg-r-15 {
    margin-right: 15px !important;
  }
  .p-lg-r-15 {
    padding-right: 15px !important;
  }
  .min-lg-w-20 {
    min-width: 20px !important;
  }
  .max-lg-w-20 {
    max-width: 20px !important;
  }
  .sticky-menu.sticky-lg-padding-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .m-lg-t-b-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .p-lg-t-b-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .m-lg-l-r-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .p-lg-l-r-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .m-lg-all-20 {
    margin: 20px !important;
  }
  .p-lg-all-20 {
    padding: 20px !important;
  }
  .m-lg-t-20 {
    margin-top: 20px !important;
  }
  .p-lg-t-20 {
    padding-top: 20px !important;
  }
  .m-lg-b-20 {
    margin-bottom: 20px !important;
  }
  .p-lg-b-20 {
    padding-bottom: 20px !important;
  }
  .m-lg-l-20 {
    margin-left: 20px !important;
  }
  .p-lg-l-20 {
    padding-left: 20px !important;
  }
  .m-lg-r-20 {
    margin-right: 20px !important;
  }
  .p-lg-r-20 {
    padding-right: 20px !important;
  }
  .min-lg-w-25 {
    min-width: 25px !important;
  }
  .max-lg-w-25 {
    max-width: 25px !important;
  }
  .sticky-menu.sticky-lg-padding-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .m-lg-t-b-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .p-lg-t-b-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .m-lg-l-r-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .p-lg-l-r-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .m-lg-all-25 {
    margin: 25px !important;
  }
  .p-lg-all-25 {
    padding: 25px !important;
  }
  .m-lg-t-25 {
    margin-top: 25px !important;
  }
  .p-lg-t-25 {
    padding-top: 25px !important;
  }
  .m-lg-b-25 {
    margin-bottom: 25px !important;
  }
  .p-lg-b-25 {
    padding-bottom: 25px !important;
  }
  .m-lg-l-25 {
    margin-left: 25px !important;
  }
  .p-lg-l-25 {
    padding-left: 25px !important;
  }
  .m-lg-r-25 {
    margin-right: 25px !important;
  }
  .p-lg-r-25 {
    padding-right: 25px !important;
  }
  .min-lg-w-30 {
    min-width: 30px !important;
  }
  .max-lg-w-30 {
    max-width: 30px !important;
  }
  .sticky-menu.sticky-lg-padding-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .m-lg-t-b-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .p-lg-t-b-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .m-lg-l-r-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .p-lg-l-r-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .m-lg-all-30 {
    margin: 30px !important;
  }
  .p-lg-all-30 {
    padding: 30px !important;
  }
  .m-lg-t-30 {
    margin-top: 30px !important;
  }
  .p-lg-t-30 {
    padding-top: 30px !important;
  }
  .m-lg-b-30 {
    margin-bottom: 30px !important;
  }
  .p-lg-b-30 {
    padding-bottom: 30px !important;
  }
  .m-lg-l-30 {
    margin-left: 30px !important;
  }
  .p-lg-l-30 {
    padding-left: 30px !important;
  }
  .m-lg-r-30 {
    margin-right: 30px !important;
  }
  .p-lg-r-30 {
    padding-right: 30px !important;
  }
  .min-lg-w-35 {
    min-width: 35px !important;
  }
  .max-lg-w-35 {
    max-width: 35px !important;
  }
  .sticky-menu.sticky-lg-padding-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .m-lg-t-b-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .p-lg-t-b-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .m-lg-l-r-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .p-lg-l-r-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .m-lg-all-35 {
    margin: 35px !important;
  }
  .p-lg-all-35 {
    padding: 35px !important;
  }
  .m-lg-t-35 {
    margin-top: 35px !important;
  }
  .p-lg-t-35 {
    padding-top: 35px !important;
  }
  .m-lg-b-35 {
    margin-bottom: 35px !important;
  }
  .p-lg-b-35 {
    padding-bottom: 35px !important;
  }
  .m-lg-l-35 {
    margin-left: 35px !important;
  }
  .p-lg-l-35 {
    padding-left: 35px !important;
  }
  .m-lg-r-35 {
    margin-right: 35px !important;
  }
  .p-lg-r-35 {
    padding-right: 35px !important;
  }
  .min-lg-w-40 {
    min-width: 40px !important;
  }
  .max-lg-w-40 {
    max-width: 40px !important;
  }
  .sticky-menu.sticky-lg-padding-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .m-lg-t-b-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .p-lg-t-b-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .m-lg-l-r-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .p-lg-l-r-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .m-lg-all-40 {
    margin: 40px !important;
  }
  .p-lg-all-40 {
    padding: 40px !important;
  }
  .m-lg-t-40 {
    margin-top: 40px !important;
  }
  .p-lg-t-40 {
    padding-top: 40px !important;
  }
  .m-lg-b-40 {
    margin-bottom: 40px !important;
  }
  .p-lg-b-40 {
    padding-bottom: 40px !important;
  }
  .m-lg-l-40 {
    margin-left: 40px !important;
  }
  .p-lg-l-40 {
    padding-left: 40px !important;
  }
  .m-lg-r-40 {
    margin-right: 40px !important;
  }
  .p-lg-r-40 {
    padding-right: 40px !important;
  }
  .min-lg-w-45 {
    min-width: 45px !important;
  }
  .max-lg-w-45 {
    max-width: 45px !important;
  }
  .sticky-menu.sticky-lg-padding-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .m-lg-t-b-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .p-lg-t-b-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .m-lg-l-r-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .p-lg-l-r-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .m-lg-all-45 {
    margin: 45px !important;
  }
  .p-lg-all-45 {
    padding: 45px !important;
  }
  .m-lg-t-45 {
    margin-top: 45px !important;
  }
  .p-lg-t-45 {
    padding-top: 45px !important;
  }
  .m-lg-b-45 {
    margin-bottom: 45px !important;
  }
  .p-lg-b-45 {
    padding-bottom: 45px !important;
  }
  .m-lg-l-45 {
    margin-left: 45px !important;
  }
  .p-lg-l-45 {
    padding-left: 45px !important;
  }
  .m-lg-r-45 {
    margin-right: 45px !important;
  }
  .p-lg-r-45 {
    padding-right: 45px !important;
  }
  .min-lg-w-50 {
    min-width: 50px !important;
  }
  .max-lg-w-50 {
    max-width: 50px !important;
  }
  .sticky-menu.sticky-lg-padding-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .m-lg-t-b-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .p-lg-t-b-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .m-lg-l-r-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .p-lg-l-r-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .m-lg-all-50 {
    margin: 50px !important;
  }
  .p-lg-all-50 {
    padding: 50px !important;
  }
  .m-lg-t-50 {
    margin-top: 50px !important;
  }
  .p-lg-t-50 {
    padding-top: 50px !important;
  }
  .m-lg-b-50 {
    margin-bottom: 50px !important;
  }
  .p-lg-b-50 {
    padding-bottom: 50px !important;
  }
  .m-lg-l-50 {
    margin-left: 50px !important;
  }
  .p-lg-l-50 {
    padding-left: 50px !important;
  }
  .m-lg-r-50 {
    margin-right: 50px !important;
  }
  .p-lg-r-50 {
    padding-right: 50px !important;
  }
  .min-lg-w-55 {
    min-width: 55px !important;
  }
  .max-lg-w-55 {
    max-width: 55px !important;
  }
  .sticky-menu.sticky-lg-padding-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .m-lg-t-b-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .p-lg-t-b-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .m-lg-l-r-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .p-lg-l-r-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .m-lg-all-55 {
    margin: 55px !important;
  }
  .p-lg-all-55 {
    padding: 55px !important;
  }
  .m-lg-t-55 {
    margin-top: 55px !important;
  }
  .p-lg-t-55 {
    padding-top: 55px !important;
  }
  .m-lg-b-55 {
    margin-bottom: 55px !important;
  }
  .p-lg-b-55 {
    padding-bottom: 55px !important;
  }
  .m-lg-l-55 {
    margin-left: 55px !important;
  }
  .p-lg-l-55 {
    padding-left: 55px !important;
  }
  .m-lg-r-55 {
    margin-right: 55px !important;
  }
  .p-lg-r-55 {
    padding-right: 55px !important;
  }
  .min-lg-w-60 {
    min-width: 60px !important;
  }
  .max-lg-w-60 {
    max-width: 60px !important;
  }
  .sticky-menu.sticky-lg-padding-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .m-lg-t-b-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .p-lg-t-b-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .m-lg-l-r-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .p-lg-l-r-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .m-lg-all-60 {
    margin: 60px !important;
  }
  .p-lg-all-60 {
    padding: 60px !important;
  }
  .m-lg-t-60 {
    margin-top: 60px !important;
  }
  .p-lg-t-60 {
    padding-top: 60px !important;
  }
  .m-lg-b-60 {
    margin-bottom: 60px !important;
  }
  .p-lg-b-60 {
    padding-bottom: 60px !important;
  }
  .m-lg-l-60 {
    margin-left: 60px !important;
  }
  .p-lg-l-60 {
    padding-left: 60px !important;
  }
  .m-lg-r-60 {
    margin-right: 60px !important;
  }
  .p-lg-r-60 {
    padding-right: 60px !important;
  }
  .min-lg-w-70 {
    min-width: 70px !important;
  }
  .max-lg-w-70 {
    max-width: 70px !important;
  }
  .sticky-menu.sticky-lg-padding-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .m-lg-t-b-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .p-lg-t-b-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .m-lg-l-r-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .p-lg-l-r-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .m-lg-all-70 {
    margin: 70px !important;
  }
  .p-lg-all-70 {
    padding: 70px !important;
  }
  .m-lg-t-70 {
    margin-top: 70px !important;
  }
  .p-lg-t-70 {
    padding-top: 70px !important;
  }
  .m-lg-b-70 {
    margin-bottom: 70px !important;
  }
  .p-lg-b-70 {
    padding-bottom: 70px !important;
  }
  .m-lg-l-70 {
    margin-left: 70px !important;
  }
  .p-lg-l-70 {
    padding-left: 70px !important;
  }
  .m-lg-r-70 {
    margin-right: 70px !important;
  }
  .p-lg-r-70 {
    padding-right: 70px !important;
  }
  .min-lg-w-75 {
    min-width: 75px !important;
  }
  .max-lg-w-75 {
    max-width: 75px !important;
  }
  .sticky-menu.sticky-lg-padding-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .m-lg-t-b-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .p-lg-t-b-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .m-lg-l-r-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .p-lg-l-r-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .m-lg-all-75 {
    margin: 75px !important;
  }
  .p-lg-all-75 {
    padding: 75px !important;
  }
  .m-lg-t-75 {
    margin-top: 75px !important;
  }
  .p-lg-t-75 {
    padding-top: 75px !important;
  }
  .m-lg-b-75 {
    margin-bottom: 75px !important;
  }
  .p-lg-b-75 {
    padding-bottom: 75px !important;
  }
  .m-lg-l-75 {
    margin-left: 75px !important;
  }
  .p-lg-l-75 {
    padding-left: 75px !important;
  }
  .m-lg-r-75 {
    margin-right: 75px !important;
  }
  .p-lg-r-75 {
    padding-right: 75px !important;
  }
  .min-lg-w-80 {
    min-width: 80px !important;
  }
  .max-lg-w-80 {
    max-width: 80px !important;
  }
  .sticky-menu.sticky-lg-padding-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .m-lg-t-b-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .p-lg-t-b-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .m-lg-l-r-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .p-lg-l-r-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .m-lg-all-80 {
    margin: 80px !important;
  }
  .p-lg-all-80 {
    padding: 80px !important;
  }
  .m-lg-t-80 {
    margin-top: 80px !important;
  }
  .p-lg-t-80 {
    padding-top: 80px !important;
  }
  .m-lg-b-80 {
    margin-bottom: 80px !important;
  }
  .p-lg-b-80 {
    padding-bottom: 80px !important;
  }
  .m-lg-l-80 {
    margin-left: 80px !important;
  }
  .p-lg-l-80 {
    padding-left: 80px !important;
  }
  .m-lg-r-80 {
    margin-right: 80px !important;
  }
  .p-lg-r-80 {
    padding-right: 80px !important;
  }
  .min-lg-w-85 {
    min-width: 85px !important;
  }
  .max-lg-w-85 {
    max-width: 85px !important;
  }
  .sticky-menu.sticky-lg-padding-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .m-lg-t-b-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .p-lg-t-b-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .m-lg-l-r-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .p-lg-l-r-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .m-lg-all-85 {
    margin: 85px !important;
  }
  .p-lg-all-85 {
    padding: 85px !important;
  }
  .m-lg-t-85 {
    margin-top: 85px !important;
  }
  .p-lg-t-85 {
    padding-top: 85px !important;
  }
  .m-lg-b-85 {
    margin-bottom: 85px !important;
  }
  .p-lg-b-85 {
    padding-bottom: 85px !important;
  }
  .m-lg-l-85 {
    margin-left: 85px !important;
  }
  .p-lg-l-85 {
    padding-left: 85px !important;
  }
  .m-lg-r-85 {
    margin-right: 85px !important;
  }
  .p-lg-r-85 {
    padding-right: 85px !important;
  }
  .min-lg-w-90 {
    min-width: 90px !important;
  }
  .max-lg-w-90 {
    max-width: 90px !important;
  }
  .sticky-menu.sticky-lg-padding-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .m-lg-t-b-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .p-lg-t-b-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .m-lg-l-r-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .p-lg-l-r-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .m-lg-all-90 {
    margin: 90px !important;
  }
  .p-lg-all-90 {
    padding: 90px !important;
  }
  .m-lg-t-90 {
    margin-top: 90px !important;
  }
  .p-lg-t-90 {
    padding-top: 90px !important;
  }
  .m-lg-b-90 {
    margin-bottom: 90px !important;
  }
  .p-lg-b-90 {
    padding-bottom: 90px !important;
  }
  .m-lg-l-90 {
    margin-left: 90px !important;
  }
  .p-lg-l-90 {
    padding-left: 90px !important;
  }
  .m-lg-r-90 {
    margin-right: 90px !important;
  }
  .p-lg-r-90 {
    padding-right: 90px !important;
  }
  .min-lg-w-95 {
    min-width: 95px !important;
  }
  .max-lg-w-95 {
    max-width: 95px !important;
  }
  .sticky-menu.sticky-lg-padding-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .m-lg-t-b-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .p-lg-t-b-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .m-lg-l-r-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .p-lg-l-r-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .m-lg-all-95 {
    margin: 95px !important;
  }
  .p-lg-all-95 {
    padding: 95px !important;
  }
  .m-lg-t-95 {
    margin-top: 95px !important;
  }
  .p-lg-t-95 {
    padding-top: 95px !important;
  }
  .m-lg-b-95 {
    margin-bottom: 95px !important;
  }
  .p-lg-b-95 {
    padding-bottom: 95px !important;
  }
  .m-lg-l-95 {
    margin-left: 95px !important;
  }
  .p-lg-l-95 {
    padding-left: 95px !important;
  }
  .m-lg-r-95 {
    margin-right: 95px !important;
  }
  .p-lg-r-95 {
    padding-right: 95px !important;
  }
  .min-lg-w-100 {
    min-width: 100px !important;
  }
  .max-lg-w-100 {
    max-width: 100px !important;
  }
  .sticky-menu.sticky-lg-padding-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-lg-t-b-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .p-lg-t-b-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-lg-l-r-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .p-lg-l-r-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .m-lg-all-100 {
    margin: 100px !important;
  }
  .p-lg-all-100 {
    padding: 100px !important;
  }
  .m-lg-t-100 {
    margin-top: 100px !important;
  }
  .p-lg-t-100 {
    padding-top: 100px !important;
  }
  .m-lg-b-100 {
    margin-bottom: 100px !important;
  }
  .p-lg-b-100 {
    padding-bottom: 100px !important;
  }
  .m-lg-l-100 {
    margin-left: 100px !important;
  }
  .p-lg-l-100 {
    padding-left: 100px !important;
  }
  .m-lg-r-100 {
    margin-right: 100px !important;
  }
  .p-lg-r-100 {
    padding-right: 100px !important;
  }
  .min-lg-w-110 {
    min-width: 110px !important;
  }
  .max-lg-w-110 {
    max-width: 110px !important;
  }
  .sticky-menu.sticky-lg-padding-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .m-lg-t-b-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  .p-lg-t-b-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .m-lg-l-r-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  .p-lg-l-r-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .m-lg-all-110 {
    margin: 110px !important;
  }
  .p-lg-all-110 {
    padding: 110px !important;
  }
  .m-lg-t-110 {
    margin-top: 110px !important;
  }
  .p-lg-t-110 {
    padding-top: 110px !important;
  }
  .m-lg-b-110 {
    margin-bottom: 110px !important;
  }
  .p-lg-b-110 {
    padding-bottom: 110px !important;
  }
  .m-lg-l-110 {
    margin-left: 110px !important;
  }
  .p-lg-l-110 {
    padding-left: 110px !important;
  }
  .m-lg-r-110 {
    margin-right: 110px !important;
  }
  .p-lg-r-110 {
    padding-right: 110px !important;
  }
  .min-lg-w-115 {
    min-width: 115px !important;
  }
  .max-lg-w-115 {
    max-width: 115px !important;
  }
  .sticky-menu.sticky-lg-padding-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .m-lg-t-b-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  .p-lg-t-b-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .m-lg-l-r-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  .p-lg-l-r-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .m-lg-all-115 {
    margin: 115px !important;
  }
  .p-lg-all-115 {
    padding: 115px !important;
  }
  .m-lg-t-115 {
    margin-top: 115px !important;
  }
  .p-lg-t-115 {
    padding-top: 115px !important;
  }
  .m-lg-b-115 {
    margin-bottom: 115px !important;
  }
  .p-lg-b-115 {
    padding-bottom: 115px !important;
  }
  .m-lg-l-115 {
    margin-left: 115px !important;
  }
  .p-lg-l-115 {
    padding-left: 115px !important;
  }
  .m-lg-r-115 {
    margin-right: 115px !important;
  }
  .p-lg-r-115 {
    padding-right: 115px !important;
  }
  .min-lg-w-120 {
    min-width: 120px !important;
  }
  .max-lg-w-120 {
    max-width: 120px !important;
  }
  .sticky-menu.sticky-lg-padding-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .m-lg-t-b-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .p-lg-t-b-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .m-lg-l-r-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  .p-lg-l-r-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .m-lg-all-120 {
    margin: 120px !important;
  }
  .p-lg-all-120 {
    padding: 120px !important;
  }
  .m-lg-t-120 {
    margin-top: 120px !important;
  }
  .p-lg-t-120 {
    padding-top: 120px !important;
  }
  .m-lg-b-120 {
    margin-bottom: 120px !important;
  }
  .p-lg-b-120 {
    padding-bottom: 120px !important;
  }
  .m-lg-l-120 {
    margin-left: 120px !important;
  }
  .p-lg-l-120 {
    padding-left: 120px !important;
  }
  .m-lg-r-120 {
    margin-right: 120px !important;
  }
  .p-lg-r-120 {
    padding-right: 120px !important;
  }
  .min-lg-w-130 {
    min-width: 130px !important;
  }
  .max-lg-w-130 {
    max-width: 130px !important;
  }
  .sticky-menu.sticky-lg-padding-130 {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .m-lg-t-b-130 {
    margin-top: 130px !important;
    margin-bottom: 130px !important;
  }
  .p-lg-t-b-130 {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .m-lg-l-r-130 {
    margin-left: 130px !important;
    margin-right: 130px !important;
  }
  .p-lg-l-r-130 {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }
  .m-lg-all-130 {
    margin: 130px !important;
  }
  .p-lg-all-130 {
    padding: 130px !important;
  }
  .m-lg-t-130 {
    margin-top: 130px !important;
  }
  .p-lg-t-130 {
    padding-top: 130px !important;
  }
  .m-lg-b-130 {
    margin-bottom: 130px !important;
  }
  .p-lg-b-130 {
    padding-bottom: 130px !important;
  }
  .m-lg-l-130 {
    margin-left: 130px !important;
  }
  .p-lg-l-130 {
    padding-left: 130px !important;
  }
  .m-lg-r-130 {
    margin-right: 130px !important;
  }
  .p-lg-r-130 {
    padding-right: 130px !important;
  }
  .min-lg-w-140 {
    min-width: 140px !important;
  }
  .max-lg-w-140 {
    max-width: 140px !important;
  }
  .sticky-menu.sticky-lg-padding-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .m-lg-t-b-140 {
    margin-top: 140px !important;
    margin-bottom: 140px !important;
  }
  .p-lg-t-b-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .m-lg-l-r-140 {
    margin-left: 140px !important;
    margin-right: 140px !important;
  }
  .p-lg-l-r-140 {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .m-lg-all-140 {
    margin: 140px !important;
  }
  .p-lg-all-140 {
    padding: 140px !important;
  }
  .m-lg-t-140 {
    margin-top: 140px !important;
  }
  .p-lg-t-140 {
    padding-top: 140px !important;
  }
  .m-lg-b-140 {
    margin-bottom: 140px !important;
  }
  .p-lg-b-140 {
    padding-bottom: 140px !important;
  }
  .m-lg-l-140 {
    margin-left: 140px !important;
  }
  .p-lg-l-140 {
    padding-left: 140px !important;
  }
  .m-lg-r-140 {
    margin-right: 140px !important;
  }
  .p-lg-r-140 {
    padding-right: 140px !important;
  }
  .min-lg-w-150 {
    min-width: 150px !important;
  }
  .max-lg-w-150 {
    max-width: 150px !important;
  }
  .sticky-menu.sticky-lg-padding-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .m-lg-t-b-150 {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .p-lg-t-b-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .m-lg-l-r-150 {
    margin-left: 150px !important;
    margin-right: 150px !important;
  }
  .p-lg-l-r-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .m-lg-all-150 {
    margin: 150px !important;
  }
  .p-lg-all-150 {
    padding: 150px !important;
  }
  .m-lg-t-150 {
    margin-top: 150px !important;
  }
  .p-lg-t-150 {
    padding-top: 150px !important;
  }
  .m-lg-b-150 {
    margin-bottom: 150px !important;
  }
  .p-lg-b-150 {
    padding-bottom: 150px !important;
  }
  .m-lg-l-150 {
    margin-left: 150px !important;
  }
  .p-lg-l-150 {
    padding-left: 150px !important;
  }
  .m-lg-r-150 {
    margin-right: 150px !important;
  }
  .p-lg-r-150 {
    padding-right: 150px !important;
  }
  .min-lg-w-175 {
    min-width: 175px !important;
  }
  .max-lg-w-175 {
    max-width: 175px !important;
  }
  .sticky-menu.sticky-lg-padding-175 {
    padding-top: 175px !important;
    padding-bottom: 175px !important;
  }
  .m-lg-t-b-175 {
    margin-top: 175px !important;
    margin-bottom: 175px !important;
  }
  .p-lg-t-b-175 {
    padding-top: 175px !important;
    padding-bottom: 175px !important;
  }
  .m-lg-l-r-175 {
    margin-left: 175px !important;
    margin-right: 175px !important;
  }
  .p-lg-l-r-175 {
    padding-left: 175px !important;
    padding-right: 175px !important;
  }
  .m-lg-all-175 {
    margin: 175px !important;
  }
  .p-lg-all-175 {
    padding: 175px !important;
  }
  .m-lg-t-175 {
    margin-top: 175px !important;
  }
  .p-lg-t-175 {
    padding-top: 175px !important;
  }
  .m-lg-b-175 {
    margin-bottom: 175px !important;
  }
  .p-lg-b-175 {
    padding-bottom: 175px !important;
  }
  .m-lg-l-175 {
    margin-left: 175px !important;
  }
  .p-lg-l-175 {
    padding-left: 175px !important;
  }
  .m-lg-r-175 {
    margin-right: 175px !important;
  }
  .p-lg-r-175 {
    padding-right: 175px !important;
  }
  .min-lg-w-200 {
    min-width: 200px !important;
  }
  .max-lg-w-200 {
    max-width: 200px !important;
  }
  .sticky-menu.sticky-lg-padding-200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .m-lg-t-b-200 {
    margin-top: 200px !important;
    margin-bottom: 200px !important;
  }
  .p-lg-t-b-200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .m-lg-l-r-200 {
    margin-left: 200px !important;
    margin-right: 200px !important;
  }
  .p-lg-l-r-200 {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .m-lg-all-200 {
    margin: 200px !important;
  }
  .p-lg-all-200 {
    padding: 200px !important;
  }
  .m-lg-t-200 {
    margin-top: 200px !important;
  }
  .p-lg-t-200 {
    padding-top: 200px !important;
  }
  .m-lg-b-200 {
    margin-bottom: 200px !important;
  }
  .p-lg-b-200 {
    padding-bottom: 200px !important;
  }
  .m-lg-l-200 {
    margin-left: 200px !important;
  }
  .p-lg-l-200 {
    padding-left: 200px !important;
  }
  .m-lg-r-200 {
    margin-right: 200px !important;
  }
  .p-lg-r-200 {
    padding-right: 200px !important;
  }
  .min-lg-w-225 {
    min-width: 225px !important;
  }
  .max-lg-w-225 {
    max-width: 225px !important;
  }
  .sticky-menu.sticky-lg-padding-225 {
    padding-top: 225px !important;
    padding-bottom: 225px !important;
  }
  .m-lg-t-b-225 {
    margin-top: 225px !important;
    margin-bottom: 225px !important;
  }
  .p-lg-t-b-225 {
    padding-top: 225px !important;
    padding-bottom: 225px !important;
  }
  .m-lg-l-r-225 {
    margin-left: 225px !important;
    margin-right: 225px !important;
  }
  .p-lg-l-r-225 {
    padding-left: 225px !important;
    padding-right: 225px !important;
  }
  .m-lg-all-225 {
    margin: 225px !important;
  }
  .p-lg-all-225 {
    padding: 225px !important;
  }
  .m-lg-t-225 {
    margin-top: 225px !important;
  }
  .p-lg-t-225 {
    padding-top: 225px !important;
  }
  .m-lg-b-225 {
    margin-bottom: 225px !important;
  }
  .p-lg-b-225 {
    padding-bottom: 225px !important;
  }
  .m-lg-l-225 {
    margin-left: 225px !important;
  }
  .p-lg-l-225 {
    padding-left: 225px !important;
  }
  .m-lg-r-225 {
    margin-right: 225px !important;
  }
  .p-lg-r-225 {
    padding-right: 225px !important;
  }
  .min-lg-w-240 {
    min-width: 240px !important;
  }
  .max-lg-w-240 {
    max-width: 240px !important;
  }
  .sticky-menu.sticky-lg-padding-240 {
    padding-top: 240px !important;
    padding-bottom: 240px !important;
  }
  .m-lg-t-b-240 {
    margin-top: 240px !important;
    margin-bottom: 240px !important;
  }
  .p-lg-t-b-240 {
    padding-top: 240px !important;
    padding-bottom: 240px !important;
  }
  .m-lg-l-r-240 {
    margin-left: 240px !important;
    margin-right: 240px !important;
  }
  .p-lg-l-r-240 {
    padding-left: 240px !important;
    padding-right: 240px !important;
  }
  .m-lg-all-240 {
    margin: 240px !important;
  }
  .p-lg-all-240 {
    padding: 240px !important;
  }
  .m-lg-t-240 {
    margin-top: 240px !important;
  }
  .p-lg-t-240 {
    padding-top: 240px !important;
  }
  .m-lg-b-240 {
    margin-bottom: 240px !important;
  }
  .p-lg-b-240 {
    padding-bottom: 240px !important;
  }
  .m-lg-l-240 {
    margin-left: 240px !important;
  }
  .p-lg-l-240 {
    padding-left: 240px !important;
  }
  .m-lg-r-240 {
    margin-right: 240px !important;
  }
  .p-lg-r-240 {
    padding-right: 240px !important;
  }
  .min-lg-w-250 {
    min-width: 250px !important;
  }
  .max-lg-w-250 {
    max-width: 250px !important;
  }
  .sticky-menu.sticky-lg-padding-250 {
    padding-top: 250px !important;
    padding-bottom: 250px !important;
  }
  .m-lg-t-b-250 {
    margin-top: 250px !important;
    margin-bottom: 250px !important;
  }
  .p-lg-t-b-250 {
    padding-top: 250px !important;
    padding-bottom: 250px !important;
  }
  .m-lg-l-r-250 {
    margin-left: 250px !important;
    margin-right: 250px !important;
  }
  .p-lg-l-r-250 {
    padding-left: 250px !important;
    padding-right: 250px !important;
  }
  .m-lg-all-250 {
    margin: 250px !important;
  }
  .p-lg-all-250 {
    padding: 250px !important;
  }
  .m-lg-t-250 {
    margin-top: 250px !important;
  }
  .p-lg-t-250 {
    padding-top: 250px !important;
  }
  .m-lg-b-250 {
    margin-bottom: 250px !important;
  }
  .p-lg-b-250 {
    padding-bottom: 250px !important;
  }
  .m-lg-l-250 {
    margin-left: 250px !important;
  }
  .p-lg-l-250 {
    padding-left: 250px !important;
  }
  .m-lg-r-250 {
    margin-right: 250px !important;
  }
  .p-lg-r-250 {
    padding-right: 250px !important;
  }
  .min-lg-w-300 {
    min-width: 300px !important;
  }
  .max-lg-w-300 {
    max-width: 300px !important;
  }
  .sticky-menu.sticky-lg-padding-300 {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .m-lg-t-b-300 {
    margin-top: 300px !important;
    margin-bottom: 300px !important;
  }
  .p-lg-t-b-300 {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .m-lg-l-r-300 {
    margin-left: 300px !important;
    margin-right: 300px !important;
  }
  .p-lg-l-r-300 {
    padding-left: 300px !important;
    padding-right: 300px !important;
  }
  .m-lg-all-300 {
    margin: 300px !important;
  }
  .p-lg-all-300 {
    padding: 300px !important;
  }
  .m-lg-t-300 {
    margin-top: 300px !important;
  }
  .p-lg-t-300 {
    padding-top: 300px !important;
  }
  .m-lg-b-300 {
    margin-bottom: 300px !important;
  }
  .p-lg-b-300 {
    padding-bottom: 300px !important;
  }
  .m-lg-l-300 {
    margin-left: 300px !important;
  }
  .p-lg-l-300 {
    padding-left: 300px !important;
  }
  .m-lg-r-300 {
    margin-right: 300px !important;
  }
  .p-lg-r-300 {
    padding-right: 300px !important;
  }
  .min-lg-w-350 {
    min-width: 350px !important;
  }
  .max-lg-w-350 {
    max-width: 350px !important;
  }
  .sticky-menu.sticky-lg-padding-350 {
    padding-top: 350px !important;
    padding-bottom: 350px !important;
  }
  .m-lg-t-b-350 {
    margin-top: 350px !important;
    margin-bottom: 350px !important;
  }
  .p-lg-t-b-350 {
    padding-top: 350px !important;
    padding-bottom: 350px !important;
  }
  .m-lg-l-r-350 {
    margin-left: 350px !important;
    margin-right: 350px !important;
  }
  .p-lg-l-r-350 {
    padding-left: 350px !important;
    padding-right: 350px !important;
  }
  .m-lg-all-350 {
    margin: 350px !important;
  }
  .p-lg-all-350 {
    padding: 350px !important;
  }
  .m-lg-t-350 {
    margin-top: 350px !important;
  }
  .p-lg-t-350 {
    padding-top: 350px !important;
  }
  .m-lg-b-350 {
    margin-bottom: 350px !important;
  }
  .p-lg-b-350 {
    padding-bottom: 350px !important;
  }
  .m-lg-l-350 {
    margin-left: 350px !important;
  }
  .p-lg-l-350 {
    padding-left: 350px !important;
  }
  .m-lg-r-350 {
    margin-right: 350px !important;
  }
  .p-lg-r-350 {
    padding-right: 350px !important;
  }
}

@media only screen and (max-width: 991.98px) {
  .min-md-w-none {
    min-width: 0px !important;
  }
  .max-md-w-none {
    max-width: 0px !important;
  }
  .sticky-menu.sticky-md-padding-none {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .m-md-t-b-none {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .p-md-t-b-none {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .m-md-l-r-none {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .p-md-l-r-none {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .m-md-all-none {
    margin: 0px !important;
  }
  .p-md-all-none {
    padding: 0px !important;
  }
  .m-md-t-none {
    margin-top: 0px !important;
  }
  .p-md-t-none {
    padding-top: 0px !important;
  }
  .m-md-b-none {
    margin-bottom: 0px !important;
  }
  .p-md-b-none {
    padding-bottom: 0px !important;
  }
  .m-md-l-none {
    margin-left: 0px !important;
  }
  .p-md-l-none {
    padding-left: 0px !important;
  }
  .m-md-r-none {
    margin-right: 0px !important;
  }
  .p-md-r-none {
    padding-right: 0px !important;
  }
  .min-md-w-auto {
    min-width: auto !important;
  }
  .max-md-w-auto {
    max-width: auto !important;
  }
  .sticky-menu.sticky-md-padding-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .m-md-t-b-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .p-md-t-b-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .m-md-l-r-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .p-md-l-r-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }
  .m-md-all-auto {
    margin: auto !important;
  }
  .p-md-all-auto {
    padding: auto !important;
  }
  .m-md-t-auto {
    margin-top: auto !important;
  }
  .p-md-t-auto {
    padding-top: auto !important;
  }
  .m-md-b-auto {
    margin-bottom: auto !important;
  }
  .p-md-b-auto {
    padding-bottom: auto !important;
  }
  .m-md-l-auto {
    margin-left: auto !important;
  }
  .p-md-l-auto {
    padding-left: auto !important;
  }
  .m-md-r-auto {
    margin-right: auto !important;
  }
  .p-md-r-auto {
    padding-right: auto !important;
  }
  .min-md-w-5 {
    min-width: 5px !important;
  }
  .max-md-w-5 {
    max-width: 5px !important;
  }
  .sticky-menu.sticky-md-padding-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .m-md-t-b-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .p-md-t-b-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .m-md-l-r-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .p-md-l-r-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .m-md-all-5 {
    margin: 5px !important;
  }
  .p-md-all-5 {
    padding: 5px !important;
  }
  .m-md-t-5 {
    margin-top: 5px !important;
  }
  .p-md-t-5 {
    padding-top: 5px !important;
  }
  .m-md-b-5 {
    margin-bottom: 5px !important;
  }
  .p-md-b-5 {
    padding-bottom: 5px !important;
  }
  .m-md-l-5 {
    margin-left: 5px !important;
  }
  .p-md-l-5 {
    padding-left: 5px !important;
  }
  .m-md-r-5 {
    margin-right: 5px !important;
  }
  .p-md-r-5 {
    padding-right: 5px !important;
  }
  .min-md-w-10 {
    min-width: 10px !important;
  }
  .max-md-w-10 {
    max-width: 10px !important;
  }
  .sticky-menu.sticky-md-padding-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .m-md-t-b-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .p-md-t-b-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .m-md-l-r-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .p-md-l-r-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .m-md-all-10 {
    margin: 10px !important;
  }
  .p-md-all-10 {
    padding: 10px !important;
  }
  .m-md-t-10 {
    margin-top: 10px !important;
  }
  .p-md-t-10 {
    padding-top: 10px !important;
  }
  .m-md-b-10 {
    margin-bottom: 10px !important;
  }
  .p-md-b-10 {
    padding-bottom: 10px !important;
  }
  .m-md-l-10 {
    margin-left: 10px !important;
  }
  .p-md-l-10 {
    padding-left: 10px !important;
  }
  .m-md-r-10 {
    margin-right: 10px !important;
  }
  .p-md-r-10 {
    padding-right: 10px !important;
  }
  .min-md-w-12 {
    min-width: 12px !important;
  }
  .max-md-w-12 {
    max-width: 12px !important;
  }
  .sticky-menu.sticky-md-padding-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .m-md-t-b-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .p-md-t-b-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .m-md-l-r-12 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .p-md-l-r-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .m-md-all-12 {
    margin: 12px !important;
  }
  .p-md-all-12 {
    padding: 12px !important;
  }
  .m-md-t-12 {
    margin-top: 12px !important;
  }
  .p-md-t-12 {
    padding-top: 12px !important;
  }
  .m-md-b-12 {
    margin-bottom: 12px !important;
  }
  .p-md-b-12 {
    padding-bottom: 12px !important;
  }
  .m-md-l-12 {
    margin-left: 12px !important;
  }
  .p-md-l-12 {
    padding-left: 12px !important;
  }
  .m-md-r-12 {
    margin-right: 12px !important;
  }
  .p-md-r-12 {
    padding-right: 12px !important;
  }
  .min-md-w-13 {
    min-width: 13px !important;
  }
  .max-md-w-13 {
    max-width: 13px !important;
  }
  .sticky-menu.sticky-md-padding-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .m-md-t-b-13 {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }
  .p-md-t-b-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .m-md-l-r-13 {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }
  .p-md-l-r-13 {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .m-md-all-13 {
    margin: 13px !important;
  }
  .p-md-all-13 {
    padding: 13px !important;
  }
  .m-md-t-13 {
    margin-top: 13px !important;
  }
  .p-md-t-13 {
    padding-top: 13px !important;
  }
  .m-md-b-13 {
    margin-bottom: 13px !important;
  }
  .p-md-b-13 {
    padding-bottom: 13px !important;
  }
  .m-md-l-13 {
    margin-left: 13px !important;
  }
  .p-md-l-13 {
    padding-left: 13px !important;
  }
  .m-md-r-13 {
    margin-right: 13px !important;
  }
  .p-md-r-13 {
    padding-right: 13px !important;
  }
  .min-md-w-15 {
    min-width: 15px !important;
  }
  .max-md-w-15 {
    max-width: 15px !important;
  }
  .sticky-menu.sticky-md-padding-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .m-md-t-b-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .p-md-t-b-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .m-md-l-r-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .p-md-l-r-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .m-md-all-15 {
    margin: 15px !important;
  }
  .p-md-all-15 {
    padding: 15px !important;
  }
  .m-md-t-15 {
    margin-top: 15px !important;
  }
  .p-md-t-15 {
    padding-top: 15px !important;
  }
  .m-md-b-15 {
    margin-bottom: 15px !important;
  }
  .p-md-b-15 {
    padding-bottom: 15px !important;
  }
  .m-md-l-15 {
    margin-left: 15px !important;
  }
  .p-md-l-15 {
    padding-left: 15px !important;
  }
  .m-md-r-15 {
    margin-right: 15px !important;
  }
  .p-md-r-15 {
    padding-right: 15px !important;
  }
  .min-md-w-20 {
    min-width: 20px !important;
  }
  .max-md-w-20 {
    max-width: 20px !important;
  }
  .sticky-menu.sticky-md-padding-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .m-md-t-b-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .p-md-t-b-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .m-md-l-r-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .p-md-l-r-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .m-md-all-20 {
    margin: 20px !important;
  }
  .p-md-all-20 {
    padding: 20px !important;
  }
  .m-md-t-20 {
    margin-top: 20px !important;
  }
  .p-md-t-20 {
    padding-top: 20px !important;
  }
  .m-md-b-20 {
    margin-bottom: 20px !important;
  }
  .p-md-b-20 {
    padding-bottom: 20px !important;
  }
  .m-md-l-20 {
    margin-left: 20px !important;
  }
  .p-md-l-20 {
    padding-left: 20px !important;
  }
  .m-md-r-20 {
    margin-right: 20px !important;
  }
  .p-md-r-20 {
    padding-right: 20px !important;
  }
  .min-md-w-25 {
    min-width: 25px !important;
  }
  .max-md-w-25 {
    max-width: 25px !important;
  }
  .sticky-menu.sticky-md-padding-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .m-md-t-b-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .p-md-t-b-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .m-md-l-r-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .p-md-l-r-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .m-md-all-25 {
    margin: 25px !important;
  }
  .p-md-all-25 {
    padding: 25px !important;
  }
  .m-md-t-25 {
    margin-top: 25px !important;
  }
  .p-md-t-25 {
    padding-top: 25px !important;
  }
  .m-md-b-25 {
    margin-bottom: 25px !important;
  }
  .p-md-b-25 {
    padding-bottom: 25px !important;
  }
  .m-md-l-25 {
    margin-left: 25px !important;
  }
  .p-md-l-25 {
    padding-left: 25px !important;
  }
  .m-md-r-25 {
    margin-right: 25px !important;
  }
  .p-md-r-25 {
    padding-right: 25px !important;
  }
  .min-md-w-30 {
    min-width: 30px !important;
  }
  .max-md-w-30 {
    max-width: 30px !important;
  }
  .sticky-menu.sticky-md-padding-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .m-md-t-b-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .p-md-t-b-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .m-md-l-r-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .p-md-l-r-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .m-md-all-30 {
    margin: 30px !important;
  }
  .p-md-all-30 {
    padding: 30px !important;
  }
  .m-md-t-30 {
    margin-top: 30px !important;
  }
  .p-md-t-30 {
    padding-top: 30px !important;
  }
  .m-md-b-30 {
    margin-bottom: 30px !important;
  }
  .p-md-b-30 {
    padding-bottom: 30px !important;
  }
  .m-md-l-30 {
    margin-left: 30px !important;
  }
  .p-md-l-30 {
    padding-left: 30px !important;
  }
  .m-md-r-30 {
    margin-right: 30px !important;
  }
  .p-md-r-30 {
    padding-right: 30px !important;
  }
  .min-md-w-35 {
    min-width: 35px !important;
  }
  .max-md-w-35 {
    max-width: 35px !important;
  }
  .sticky-menu.sticky-md-padding-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .m-md-t-b-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .p-md-t-b-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .m-md-l-r-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .p-md-l-r-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .m-md-all-35 {
    margin: 35px !important;
  }
  .p-md-all-35 {
    padding: 35px !important;
  }
  .m-md-t-35 {
    margin-top: 35px !important;
  }
  .p-md-t-35 {
    padding-top: 35px !important;
  }
  .m-md-b-35 {
    margin-bottom: 35px !important;
  }
  .p-md-b-35 {
    padding-bottom: 35px !important;
  }
  .m-md-l-35 {
    margin-left: 35px !important;
  }
  .p-md-l-35 {
    padding-left: 35px !important;
  }
  .m-md-r-35 {
    margin-right: 35px !important;
  }
  .p-md-r-35 {
    padding-right: 35px !important;
  }
  .min-md-w-40 {
    min-width: 40px !important;
  }
  .max-md-w-40 {
    max-width: 40px !important;
  }
  .sticky-menu.sticky-md-padding-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .m-md-t-b-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .p-md-t-b-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .m-md-l-r-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .p-md-l-r-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .m-md-all-40 {
    margin: 40px !important;
  }
  .p-md-all-40 {
    padding: 40px !important;
  }
  .m-md-t-40 {
    margin-top: 40px !important;
  }
  .p-md-t-40 {
    padding-top: 40px !important;
  }
  .m-md-b-40 {
    margin-bottom: 40px !important;
  }
  .p-md-b-40 {
    padding-bottom: 40px !important;
  }
  .m-md-l-40 {
    margin-left: 40px !important;
  }
  .p-md-l-40 {
    padding-left: 40px !important;
  }
  .m-md-r-40 {
    margin-right: 40px !important;
  }
  .p-md-r-40 {
    padding-right: 40px !important;
  }
  .min-md-w-45 {
    min-width: 45px !important;
  }
  .max-md-w-45 {
    max-width: 45px !important;
  }
  .sticky-menu.sticky-md-padding-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .m-md-t-b-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .p-md-t-b-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .m-md-l-r-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .p-md-l-r-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .m-md-all-45 {
    margin: 45px !important;
  }
  .p-md-all-45 {
    padding: 45px !important;
  }
  .m-md-t-45 {
    margin-top: 45px !important;
  }
  .p-md-t-45 {
    padding-top: 45px !important;
  }
  .m-md-b-45 {
    margin-bottom: 45px !important;
  }
  .p-md-b-45 {
    padding-bottom: 45px !important;
  }
  .m-md-l-45 {
    margin-left: 45px !important;
  }
  .p-md-l-45 {
    padding-left: 45px !important;
  }
  .m-md-r-45 {
    margin-right: 45px !important;
  }
  .p-md-r-45 {
    padding-right: 45px !important;
  }
  .min-md-w-50 {
    min-width: 50px !important;
  }
  .max-md-w-50 {
    max-width: 50px !important;
  }
  .sticky-menu.sticky-md-padding-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .m-md-t-b-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .p-md-t-b-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .m-md-l-r-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .p-md-l-r-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .m-md-all-50 {
    margin: 50px !important;
  }
  .p-md-all-50 {
    padding: 50px !important;
  }
  .m-md-t-50 {
    margin-top: 50px !important;
  }
  .p-md-t-50 {
    padding-top: 50px !important;
  }
  .m-md-b-50 {
    margin-bottom: 50px !important;
  }
  .p-md-b-50 {
    padding-bottom: 50px !important;
  }
  .m-md-l-50 {
    margin-left: 50px !important;
  }
  .p-md-l-50 {
    padding-left: 50px !important;
  }
  .m-md-r-50 {
    margin-right: 50px !important;
  }
  .p-md-r-50 {
    padding-right: 50px !important;
  }
  .min-md-w-55 {
    min-width: 55px !important;
  }
  .max-md-w-55 {
    max-width: 55px !important;
  }
  .sticky-menu.sticky-md-padding-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .m-md-t-b-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .p-md-t-b-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .m-md-l-r-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .p-md-l-r-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .m-md-all-55 {
    margin: 55px !important;
  }
  .p-md-all-55 {
    padding: 55px !important;
  }
  .m-md-t-55 {
    margin-top: 55px !important;
  }
  .p-md-t-55 {
    padding-top: 55px !important;
  }
  .m-md-b-55 {
    margin-bottom: 55px !important;
  }
  .p-md-b-55 {
    padding-bottom: 55px !important;
  }
  .m-md-l-55 {
    margin-left: 55px !important;
  }
  .p-md-l-55 {
    padding-left: 55px !important;
  }
  .m-md-r-55 {
    margin-right: 55px !important;
  }
  .p-md-r-55 {
    padding-right: 55px !important;
  }
  .min-md-w-60 {
    min-width: 60px !important;
  }
  .max-md-w-60 {
    max-width: 60px !important;
  }
  .sticky-menu.sticky-md-padding-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .m-md-t-b-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .p-md-t-b-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .m-md-l-r-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .p-md-l-r-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .m-md-all-60 {
    margin: 60px !important;
  }
  .p-md-all-60 {
    padding: 60px !important;
  }
  .m-md-t-60 {
    margin-top: 60px !important;
  }
  .p-md-t-60 {
    padding-top: 60px !important;
  }
  .m-md-b-60 {
    margin-bottom: 60px !important;
  }
  .p-md-b-60 {
    padding-bottom: 60px !important;
  }
  .m-md-l-60 {
    margin-left: 60px !important;
  }
  .p-md-l-60 {
    padding-left: 60px !important;
  }
  .m-md-r-60 {
    margin-right: 60px !important;
  }
  .p-md-r-60 {
    padding-right: 60px !important;
  }
  .min-md-w-70 {
    min-width: 70px !important;
  }
  .max-md-w-70 {
    max-width: 70px !important;
  }
  .sticky-menu.sticky-md-padding-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .m-md-t-b-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .p-md-t-b-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .m-md-l-r-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .p-md-l-r-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .m-md-all-70 {
    margin: 70px !important;
  }
  .p-md-all-70 {
    padding: 70px !important;
  }
  .m-md-t-70 {
    margin-top: 70px !important;
  }
  .p-md-t-70 {
    padding-top: 70px !important;
  }
  .m-md-b-70 {
    margin-bottom: 70px !important;
  }
  .p-md-b-70 {
    padding-bottom: 70px !important;
  }
  .m-md-l-70 {
    margin-left: 70px !important;
  }
  .p-md-l-70 {
    padding-left: 70px !important;
  }
  .m-md-r-70 {
    margin-right: 70px !important;
  }
  .p-md-r-70 {
    padding-right: 70px !important;
  }
  .min-md-w-75 {
    min-width: 75px !important;
  }
  .max-md-w-75 {
    max-width: 75px !important;
  }
  .sticky-menu.sticky-md-padding-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .m-md-t-b-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .p-md-t-b-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .m-md-l-r-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .p-md-l-r-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .m-md-all-75 {
    margin: 75px !important;
  }
  .p-md-all-75 {
    padding: 75px !important;
  }
  .m-md-t-75 {
    margin-top: 75px !important;
  }
  .p-md-t-75 {
    padding-top: 75px !important;
  }
  .m-md-b-75 {
    margin-bottom: 75px !important;
  }
  .p-md-b-75 {
    padding-bottom: 75px !important;
  }
  .m-md-l-75 {
    margin-left: 75px !important;
  }
  .p-md-l-75 {
    padding-left: 75px !important;
  }
  .m-md-r-75 {
    margin-right: 75px !important;
  }
  .p-md-r-75 {
    padding-right: 75px !important;
  }
  .min-md-w-80 {
    min-width: 80px !important;
  }
  .max-md-w-80 {
    max-width: 80px !important;
  }
  .sticky-menu.sticky-md-padding-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .m-md-t-b-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .p-md-t-b-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .m-md-l-r-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .p-md-l-r-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .m-md-all-80 {
    margin: 80px !important;
  }
  .p-md-all-80 {
    padding: 80px !important;
  }
  .m-md-t-80 {
    margin-top: 80px !important;
  }
  .p-md-t-80 {
    padding-top: 80px !important;
  }
  .m-md-b-80 {
    margin-bottom: 80px !important;
  }
  .p-md-b-80 {
    padding-bottom: 80px !important;
  }
  .m-md-l-80 {
    margin-left: 80px !important;
  }
  .p-md-l-80 {
    padding-left: 80px !important;
  }
  .m-md-r-80 {
    margin-right: 80px !important;
  }
  .p-md-r-80 {
    padding-right: 80px !important;
  }
  .min-md-w-85 {
    min-width: 85px !important;
  }
  .max-md-w-85 {
    max-width: 85px !important;
  }
  .sticky-menu.sticky-md-padding-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .m-md-t-b-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .p-md-t-b-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .m-md-l-r-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .p-md-l-r-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .m-md-all-85 {
    margin: 85px !important;
  }
  .p-md-all-85 {
    padding: 85px !important;
  }
  .m-md-t-85 {
    margin-top: 85px !important;
  }
  .p-md-t-85 {
    padding-top: 85px !important;
  }
  .m-md-b-85 {
    margin-bottom: 85px !important;
  }
  .p-md-b-85 {
    padding-bottom: 85px !important;
  }
  .m-md-l-85 {
    margin-left: 85px !important;
  }
  .p-md-l-85 {
    padding-left: 85px !important;
  }
  .m-md-r-85 {
    margin-right: 85px !important;
  }
  .p-md-r-85 {
    padding-right: 85px !important;
  }
  .min-md-w-90 {
    min-width: 90px !important;
  }
  .max-md-w-90 {
    max-width: 90px !important;
  }
  .sticky-menu.sticky-md-padding-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .m-md-t-b-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .p-md-t-b-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .m-md-l-r-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .p-md-l-r-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .m-md-all-90 {
    margin: 90px !important;
  }
  .p-md-all-90 {
    padding: 90px !important;
  }
  .m-md-t-90 {
    margin-top: 90px !important;
  }
  .p-md-t-90 {
    padding-top: 90px !important;
  }
  .m-md-b-90 {
    margin-bottom: 90px !important;
  }
  .p-md-b-90 {
    padding-bottom: 90px !important;
  }
  .m-md-l-90 {
    margin-left: 90px !important;
  }
  .p-md-l-90 {
    padding-left: 90px !important;
  }
  .m-md-r-90 {
    margin-right: 90px !important;
  }
  .p-md-r-90 {
    padding-right: 90px !important;
  }
  .min-md-w-95 {
    min-width: 95px !important;
  }
  .max-md-w-95 {
    max-width: 95px !important;
  }
  .sticky-menu.sticky-md-padding-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .m-md-t-b-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .p-md-t-b-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .m-md-l-r-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .p-md-l-r-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .m-md-all-95 {
    margin: 95px !important;
  }
  .p-md-all-95 {
    padding: 95px !important;
  }
  .m-md-t-95 {
    margin-top: 95px !important;
  }
  .p-md-t-95 {
    padding-top: 95px !important;
  }
  .m-md-b-95 {
    margin-bottom: 95px !important;
  }
  .p-md-b-95 {
    padding-bottom: 95px !important;
  }
  .m-md-l-95 {
    margin-left: 95px !important;
  }
  .p-md-l-95 {
    padding-left: 95px !important;
  }
  .m-md-r-95 {
    margin-right: 95px !important;
  }
  .p-md-r-95 {
    padding-right: 95px !important;
  }
  .min-md-w-100 {
    min-width: 100px !important;
  }
  .max-md-w-100 {
    max-width: 100px !important;
  }
  .sticky-menu.sticky-md-padding-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-md-t-b-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .p-md-t-b-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-md-l-r-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .p-md-l-r-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .m-md-all-100 {
    margin: 100px !important;
  }
  .p-md-all-100 {
    padding: 100px !important;
  }
  .m-md-t-100 {
    margin-top: 100px !important;
  }
  .p-md-t-100 {
    padding-top: 100px !important;
  }
  .m-md-b-100 {
    margin-bottom: 100px !important;
  }
  .p-md-b-100 {
    padding-bottom: 100px !important;
  }
  .m-md-l-100 {
    margin-left: 100px !important;
  }
  .p-md-l-100 {
    padding-left: 100px !important;
  }
  .m-md-r-100 {
    margin-right: 100px !important;
  }
  .p-md-r-100 {
    padding-right: 100px !important;
  }
  .min-md-w-110 {
    min-width: 110px !important;
  }
  .max-md-w-110 {
    max-width: 110px !important;
  }
  .sticky-menu.sticky-md-padding-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .m-md-t-b-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  .p-md-t-b-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .m-md-l-r-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  .p-md-l-r-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .m-md-all-110 {
    margin: 110px !important;
  }
  .p-md-all-110 {
    padding: 110px !important;
  }
  .m-md-t-110 {
    margin-top: 110px !important;
  }
  .p-md-t-110 {
    padding-top: 110px !important;
  }
  .m-md-b-110 {
    margin-bottom: 110px !important;
  }
  .p-md-b-110 {
    padding-bottom: 110px !important;
  }
  .m-md-l-110 {
    margin-left: 110px !important;
  }
  .p-md-l-110 {
    padding-left: 110px !important;
  }
  .m-md-r-110 {
    margin-right: 110px !important;
  }
  .p-md-r-110 {
    padding-right: 110px !important;
  }
  .min-md-w-115 {
    min-width: 115px !important;
  }
  .max-md-w-115 {
    max-width: 115px !important;
  }
  .sticky-menu.sticky-md-padding-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .m-md-t-b-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  .p-md-t-b-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .m-md-l-r-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  .p-md-l-r-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .m-md-all-115 {
    margin: 115px !important;
  }
  .p-md-all-115 {
    padding: 115px !important;
  }
  .m-md-t-115 {
    margin-top: 115px !important;
  }
  .p-md-t-115 {
    padding-top: 115px !important;
  }
  .m-md-b-115 {
    margin-bottom: 115px !important;
  }
  .p-md-b-115 {
    padding-bottom: 115px !important;
  }
  .m-md-l-115 {
    margin-left: 115px !important;
  }
  .p-md-l-115 {
    padding-left: 115px !important;
  }
  .m-md-r-115 {
    margin-right: 115px !important;
  }
  .p-md-r-115 {
    padding-right: 115px !important;
  }
  .min-md-w-120 {
    min-width: 120px !important;
  }
  .max-md-w-120 {
    max-width: 120px !important;
  }
  .sticky-menu.sticky-md-padding-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .m-md-t-b-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .p-md-t-b-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .m-md-l-r-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  .p-md-l-r-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .m-md-all-120 {
    margin: 120px !important;
  }
  .p-md-all-120 {
    padding: 120px !important;
  }
  .m-md-t-120 {
    margin-top: 120px !important;
  }
  .p-md-t-120 {
    padding-top: 120px !important;
  }
  .m-md-b-120 {
    margin-bottom: 120px !important;
  }
  .p-md-b-120 {
    padding-bottom: 120px !important;
  }
  .m-md-l-120 {
    margin-left: 120px !important;
  }
  .p-md-l-120 {
    padding-left: 120px !important;
  }
  .m-md-r-120 {
    margin-right: 120px !important;
  }
  .p-md-r-120 {
    padding-right: 120px !important;
  }
  .min-md-w-130 {
    min-width: 130px !important;
  }
  .max-md-w-130 {
    max-width: 130px !important;
  }
  .sticky-menu.sticky-md-padding-130 {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .m-md-t-b-130 {
    margin-top: 130px !important;
    margin-bottom: 130px !important;
  }
  .p-md-t-b-130 {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .m-md-l-r-130 {
    margin-left: 130px !important;
    margin-right: 130px !important;
  }
  .p-md-l-r-130 {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }
  .m-md-all-130 {
    margin: 130px !important;
  }
  .p-md-all-130 {
    padding: 130px !important;
  }
  .m-md-t-130 {
    margin-top: 130px !important;
  }
  .p-md-t-130 {
    padding-top: 130px !important;
  }
  .m-md-b-130 {
    margin-bottom: 130px !important;
  }
  .p-md-b-130 {
    padding-bottom: 130px !important;
  }
  .m-md-l-130 {
    margin-left: 130px !important;
  }
  .p-md-l-130 {
    padding-left: 130px !important;
  }
  .m-md-r-130 {
    margin-right: 130px !important;
  }
  .p-md-r-130 {
    padding-right: 130px !important;
  }
  .min-md-w-140 {
    min-width: 140px !important;
  }
  .max-md-w-140 {
    max-width: 140px !important;
  }
  .sticky-menu.sticky-md-padding-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .m-md-t-b-140 {
    margin-top: 140px !important;
    margin-bottom: 140px !important;
  }
  .p-md-t-b-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .m-md-l-r-140 {
    margin-left: 140px !important;
    margin-right: 140px !important;
  }
  .p-md-l-r-140 {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .m-md-all-140 {
    margin: 140px !important;
  }
  .p-md-all-140 {
    padding: 140px !important;
  }
  .m-md-t-140 {
    margin-top: 140px !important;
  }
  .p-md-t-140 {
    padding-top: 140px !important;
  }
  .m-md-b-140 {
    margin-bottom: 140px !important;
  }
  .p-md-b-140 {
    padding-bottom: 140px !important;
  }
  .m-md-l-140 {
    margin-left: 140px !important;
  }
  .p-md-l-140 {
    padding-left: 140px !important;
  }
  .m-md-r-140 {
    margin-right: 140px !important;
  }
  .p-md-r-140 {
    padding-right: 140px !important;
  }
  .min-md-w-150 {
    min-width: 150px !important;
  }
  .max-md-w-150 {
    max-width: 150px !important;
  }
  .sticky-menu.sticky-md-padding-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .m-md-t-b-150 {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .p-md-t-b-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .m-md-l-r-150 {
    margin-left: 150px !important;
    margin-right: 150px !important;
  }
  .p-md-l-r-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .m-md-all-150 {
    margin: 150px !important;
  }
  .p-md-all-150 {
    padding: 150px !important;
  }
  .m-md-t-150 {
    margin-top: 150px !important;
  }
  .p-md-t-150 {
    padding-top: 150px !important;
  }
  .m-md-b-150 {
    margin-bottom: 150px !important;
  }
  .p-md-b-150 {
    padding-bottom: 150px !important;
  }
  .m-md-l-150 {
    margin-left: 150px !important;
  }
  .p-md-l-150 {
    padding-left: 150px !important;
  }
  .m-md-r-150 {
    margin-right: 150px !important;
  }
  .p-md-r-150 {
    padding-right: 150px !important;
  }
  .min-md-w-175 {
    min-width: 175px !important;
  }
  .max-md-w-175 {
    max-width: 175px !important;
  }
  .sticky-menu.sticky-md-padding-175 {
    padding-top: 175px !important;
    padding-bottom: 175px !important;
  }
  .m-md-t-b-175 {
    margin-top: 175px !important;
    margin-bottom: 175px !important;
  }
  .p-md-t-b-175 {
    padding-top: 175px !important;
    padding-bottom: 175px !important;
  }
  .m-md-l-r-175 {
    margin-left: 175px !important;
    margin-right: 175px !important;
  }
  .p-md-l-r-175 {
    padding-left: 175px !important;
    padding-right: 175px !important;
  }
  .m-md-all-175 {
    margin: 175px !important;
  }
  .p-md-all-175 {
    padding: 175px !important;
  }
  .m-md-t-175 {
    margin-top: 175px !important;
  }
  .p-md-t-175 {
    padding-top: 175px !important;
  }
  .m-md-b-175 {
    margin-bottom: 175px !important;
  }
  .p-md-b-175 {
    padding-bottom: 175px !important;
  }
  .m-md-l-175 {
    margin-left: 175px !important;
  }
  .p-md-l-175 {
    padding-left: 175px !important;
  }
  .m-md-r-175 {
    margin-right: 175px !important;
  }
  .p-md-r-175 {
    padding-right: 175px !important;
  }
  .min-md-w-200 {
    min-width: 200px !important;
  }
  .max-md-w-200 {
    max-width: 200px !important;
  }
  .sticky-menu.sticky-md-padding-200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .m-md-t-b-200 {
    margin-top: 200px !important;
    margin-bottom: 200px !important;
  }
  .p-md-t-b-200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .m-md-l-r-200 {
    margin-left: 200px !important;
    margin-right: 200px !important;
  }
  .p-md-l-r-200 {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .m-md-all-200 {
    margin: 200px !important;
  }
  .p-md-all-200 {
    padding: 200px !important;
  }
  .m-md-t-200 {
    margin-top: 200px !important;
  }
  .p-md-t-200 {
    padding-top: 200px !important;
  }
  .m-md-b-200 {
    margin-bottom: 200px !important;
  }
  .p-md-b-200 {
    padding-bottom: 200px !important;
  }
  .m-md-l-200 {
    margin-left: 200px !important;
  }
  .p-md-l-200 {
    padding-left: 200px !important;
  }
  .m-md-r-200 {
    margin-right: 200px !important;
  }
  .p-md-r-200 {
    padding-right: 200px !important;
  }
  .min-md-w-225 {
    min-width: 225px !important;
  }
  .max-md-w-225 {
    max-width: 225px !important;
  }
  .sticky-menu.sticky-md-padding-225 {
    padding-top: 225px !important;
    padding-bottom: 225px !important;
  }
  .m-md-t-b-225 {
    margin-top: 225px !important;
    margin-bottom: 225px !important;
  }
  .p-md-t-b-225 {
    padding-top: 225px !important;
    padding-bottom: 225px !important;
  }
  .m-md-l-r-225 {
    margin-left: 225px !important;
    margin-right: 225px !important;
  }
  .p-md-l-r-225 {
    padding-left: 225px !important;
    padding-right: 225px !important;
  }
  .m-md-all-225 {
    margin: 225px !important;
  }
  .p-md-all-225 {
    padding: 225px !important;
  }
  .m-md-t-225 {
    margin-top: 225px !important;
  }
  .p-md-t-225 {
    padding-top: 225px !important;
  }
  .m-md-b-225 {
    margin-bottom: 225px !important;
  }
  .p-md-b-225 {
    padding-bottom: 225px !important;
  }
  .m-md-l-225 {
    margin-left: 225px !important;
  }
  .p-md-l-225 {
    padding-left: 225px !important;
  }
  .m-md-r-225 {
    margin-right: 225px !important;
  }
  .p-md-r-225 {
    padding-right: 225px !important;
  }
  .min-md-w-240 {
    min-width: 240px !important;
  }
  .max-md-w-240 {
    max-width: 240px !important;
  }
  .sticky-menu.sticky-md-padding-240 {
    padding-top: 240px !important;
    padding-bottom: 240px !important;
  }
  .m-md-t-b-240 {
    margin-top: 240px !important;
    margin-bottom: 240px !important;
  }
  .p-md-t-b-240 {
    padding-top: 240px !important;
    padding-bottom: 240px !important;
  }
  .m-md-l-r-240 {
    margin-left: 240px !important;
    margin-right: 240px !important;
  }
  .p-md-l-r-240 {
    padding-left: 240px !important;
    padding-right: 240px !important;
  }
  .m-md-all-240 {
    margin: 240px !important;
  }
  .p-md-all-240 {
    padding: 240px !important;
  }
  .m-md-t-240 {
    margin-top: 240px !important;
  }
  .p-md-t-240 {
    padding-top: 240px !important;
  }
  .m-md-b-240 {
    margin-bottom: 240px !important;
  }
  .p-md-b-240 {
    padding-bottom: 240px !important;
  }
  .m-md-l-240 {
    margin-left: 240px !important;
  }
  .p-md-l-240 {
    padding-left: 240px !important;
  }
  .m-md-r-240 {
    margin-right: 240px !important;
  }
  .p-md-r-240 {
    padding-right: 240px !important;
  }
  .min-md-w-250 {
    min-width: 250px !important;
  }
  .max-md-w-250 {
    max-width: 250px !important;
  }
  .sticky-menu.sticky-md-padding-250 {
    padding-top: 250px !important;
    padding-bottom: 250px !important;
  }
  .m-md-t-b-250 {
    margin-top: 250px !important;
    margin-bottom: 250px !important;
  }
  .p-md-t-b-250 {
    padding-top: 250px !important;
    padding-bottom: 250px !important;
  }
  .m-md-l-r-250 {
    margin-left: 250px !important;
    margin-right: 250px !important;
  }
  .p-md-l-r-250 {
    padding-left: 250px !important;
    padding-right: 250px !important;
  }
  .m-md-all-250 {
    margin: 250px !important;
  }
  .p-md-all-250 {
    padding: 250px !important;
  }
  .m-md-t-250 {
    margin-top: 250px !important;
  }
  .p-md-t-250 {
    padding-top: 250px !important;
  }
  .m-md-b-250 {
    margin-bottom: 250px !important;
  }
  .p-md-b-250 {
    padding-bottom: 250px !important;
  }
  .m-md-l-250 {
    margin-left: 250px !important;
  }
  .p-md-l-250 {
    padding-left: 250px !important;
  }
  .m-md-r-250 {
    margin-right: 250px !important;
  }
  .p-md-r-250 {
    padding-right: 250px !important;
  }
  .min-md-w-300 {
    min-width: 300px !important;
  }
  .max-md-w-300 {
    max-width: 300px !important;
  }
  .sticky-menu.sticky-md-padding-300 {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .m-md-t-b-300 {
    margin-top: 300px !important;
    margin-bottom: 300px !important;
  }
  .p-md-t-b-300 {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .m-md-l-r-300 {
    margin-left: 300px !important;
    margin-right: 300px !important;
  }
  .p-md-l-r-300 {
    padding-left: 300px !important;
    padding-right: 300px !important;
  }
  .m-md-all-300 {
    margin: 300px !important;
  }
  .p-md-all-300 {
    padding: 300px !important;
  }
  .m-md-t-300 {
    margin-top: 300px !important;
  }
  .p-md-t-300 {
    padding-top: 300px !important;
  }
  .m-md-b-300 {
    margin-bottom: 300px !important;
  }
  .p-md-b-300 {
    padding-bottom: 300px !important;
  }
  .m-md-l-300 {
    margin-left: 300px !important;
  }
  .p-md-l-300 {
    padding-left: 300px !important;
  }
  .m-md-r-300 {
    margin-right: 300px !important;
  }
  .p-md-r-300 {
    padding-right: 300px !important;
  }
  .min-md-w-350 {
    min-width: 350px !important;
  }
  .max-md-w-350 {
    max-width: 350px !important;
  }
  .sticky-menu.sticky-md-padding-350 {
    padding-top: 350px !important;
    padding-bottom: 350px !important;
  }
  .m-md-t-b-350 {
    margin-top: 350px !important;
    margin-bottom: 350px !important;
  }
  .p-md-t-b-350 {
    padding-top: 350px !important;
    padding-bottom: 350px !important;
  }
  .m-md-l-r-350 {
    margin-left: 350px !important;
    margin-right: 350px !important;
  }
  .p-md-l-r-350 {
    padding-left: 350px !important;
    padding-right: 350px !important;
  }
  .m-md-all-350 {
    margin: 350px !important;
  }
  .p-md-all-350 {
    padding: 350px !important;
  }
  .m-md-t-350 {
    margin-top: 350px !important;
  }
  .p-md-t-350 {
    padding-top: 350px !important;
  }
  .m-md-b-350 {
    margin-bottom: 350px !important;
  }
  .p-md-b-350 {
    padding-bottom: 350px !important;
  }
  .m-md-l-350 {
    margin-left: 350px !important;
  }
  .p-md-l-350 {
    padding-left: 350px !important;
  }
  .m-md-r-350 {
    margin-right: 350px !important;
  }
  .p-md-r-350 {
    padding-right: 350px !important;
  }
}

@media only screen and (max-width: 767.98px) {
  .min-sm-w-none {
    min-width: 0px !important;
  }
  .max-sm-w-none {
    max-width: 0px !important;
  }
  .sticky-menu.sticky-sm-padding-none {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .m-sm-t-b-none {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .p-sm-t-b-none {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .m-sm-l-r-none {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .p-sm-l-r-none {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .m-sm-all-none {
    margin: 0px !important;
  }
  .p-sm-all-none {
    padding: 0px !important;
  }
  .m-sm-t-none {
    margin-top: 0px !important;
  }
  .p-sm-t-none {
    padding-top: 0px !important;
  }
  .m-sm-b-none {
    margin-bottom: 0px !important;
  }
  .p-sm-b-none {
    padding-bottom: 0px !important;
  }
  .m-sm-l-none {
    margin-left: 0px !important;
  }
  .p-sm-l-none {
    padding-left: 0px !important;
  }
  .m-sm-r-none {
    margin-right: 0px !important;
  }
  .p-sm-r-none {
    padding-right: 0px !important;
  }
  .min-sm-w-auto {
    min-width: auto !important;
  }
  .max-sm-w-auto {
    max-width: auto !important;
  }
  .sticky-menu.sticky-sm-padding-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .m-sm-t-b-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .p-sm-t-b-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .m-sm-l-r-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .p-sm-l-r-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }
  .m-sm-all-auto {
    margin: auto !important;
  }
  .p-sm-all-auto {
    padding: auto !important;
  }
  .m-sm-t-auto {
    margin-top: auto !important;
  }
  .p-sm-t-auto {
    padding-top: auto !important;
  }
  .m-sm-b-auto {
    margin-bottom: auto !important;
  }
  .p-sm-b-auto {
    padding-bottom: auto !important;
  }
  .m-sm-l-auto {
    margin-left: auto !important;
  }
  .p-sm-l-auto {
    padding-left: auto !important;
  }
  .m-sm-r-auto {
    margin-right: auto !important;
  }
  .p-sm-r-auto {
    padding-right: auto !important;
  }
  .min-sm-w-5 {
    min-width: 5px !important;
  }
  .max-sm-w-5 {
    max-width: 5px !important;
  }
  .sticky-menu.sticky-sm-padding-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .m-sm-t-b-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .p-sm-t-b-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .m-sm-l-r-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .p-sm-l-r-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .m-sm-all-5 {
    margin: 5px !important;
  }
  .p-sm-all-5 {
    padding: 5px !important;
  }
  .m-sm-t-5 {
    margin-top: 5px !important;
  }
  .p-sm-t-5 {
    padding-top: 5px !important;
  }
  .m-sm-b-5 {
    margin-bottom: 5px !important;
  }
  .p-sm-b-5 {
    padding-bottom: 5px !important;
  }
  .m-sm-l-5 {
    margin-left: 5px !important;
  }
  .p-sm-l-5 {
    padding-left: 5px !important;
  }
  .m-sm-r-5 {
    margin-right: 5px !important;
  }
  .p-sm-r-5 {
    padding-right: 5px !important;
  }
  .min-sm-w-10 {
    min-width: 10px !important;
  }
  .max-sm-w-10 {
    max-width: 10px !important;
  }
  .sticky-menu.sticky-sm-padding-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .m-sm-t-b-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .p-sm-t-b-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .m-sm-l-r-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .p-sm-l-r-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .m-sm-all-10 {
    margin: 10px !important;
  }
  .p-sm-all-10 {
    padding: 10px !important;
  }
  .m-sm-t-10 {
    margin-top: 10px !important;
  }
  .p-sm-t-10 {
    padding-top: 10px !important;
  }
  .m-sm-b-10 {
    margin-bottom: 10px !important;
  }
  .p-sm-b-10 {
    padding-bottom: 10px !important;
  }
  .m-sm-l-10 {
    margin-left: 10px !important;
  }
  .p-sm-l-10 {
    padding-left: 10px !important;
  }
  .m-sm-r-10 {
    margin-right: 10px !important;
  }
  .p-sm-r-10 {
    padding-right: 10px !important;
  }
  .min-sm-w-12 {
    min-width: 12px !important;
  }
  .max-sm-w-12 {
    max-width: 12px !important;
  }
  .sticky-menu.sticky-sm-padding-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .m-sm-t-b-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .p-sm-t-b-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .m-sm-l-r-12 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .p-sm-l-r-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .m-sm-all-12 {
    margin: 12px !important;
  }
  .p-sm-all-12 {
    padding: 12px !important;
  }
  .m-sm-t-12 {
    margin-top: 12px !important;
  }
  .p-sm-t-12 {
    padding-top: 12px !important;
  }
  .m-sm-b-12 {
    margin-bottom: 12px !important;
  }
  .p-sm-b-12 {
    padding-bottom: 12px !important;
  }
  .m-sm-l-12 {
    margin-left: 12px !important;
  }
  .p-sm-l-12 {
    padding-left: 12px !important;
  }
  .m-sm-r-12 {
    margin-right: 12px !important;
  }
  .p-sm-r-12 {
    padding-right: 12px !important;
  }
  .min-sm-w-13 {
    min-width: 13px !important;
  }
  .max-sm-w-13 {
    max-width: 13px !important;
  }
  .sticky-menu.sticky-sm-padding-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .m-sm-t-b-13 {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }
  .p-sm-t-b-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .m-sm-l-r-13 {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }
  .p-sm-l-r-13 {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .m-sm-all-13 {
    margin: 13px !important;
  }
  .p-sm-all-13 {
    padding: 13px !important;
  }
  .m-sm-t-13 {
    margin-top: 13px !important;
  }
  .p-sm-t-13 {
    padding-top: 13px !important;
  }
  .m-sm-b-13 {
    margin-bottom: 13px !important;
  }
  .p-sm-b-13 {
    padding-bottom: 13px !important;
  }
  .m-sm-l-13 {
    margin-left: 13px !important;
  }
  .p-sm-l-13 {
    padding-left: 13px !important;
  }
  .m-sm-r-13 {
    margin-right: 13px !important;
  }
  .p-sm-r-13 {
    padding-right: 13px !important;
  }
  .min-sm-w-15 {
    min-width: 15px !important;
  }
  .max-sm-w-15 {
    max-width: 15px !important;
  }
  .sticky-menu.sticky-sm-padding-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .m-sm-t-b-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .p-sm-t-b-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .m-sm-l-r-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .p-sm-l-r-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .m-sm-all-15 {
    margin: 15px !important;
  }
  .p-sm-all-15 {
    padding: 15px !important;
  }
  .m-sm-t-15 {
    margin-top: 15px !important;
  }
  .p-sm-t-15 {
    padding-top: 15px !important;
  }
  .m-sm-b-15 {
    margin-bottom: 15px !important;
  }
  .p-sm-b-15 {
    padding-bottom: 15px !important;
  }
  .m-sm-l-15 {
    margin-left: 15px !important;
  }
  .p-sm-l-15 {
    padding-left: 15px !important;
  }
  .m-sm-r-15 {
    margin-right: 15px !important;
  }
  .p-sm-r-15 {
    padding-right: 15px !important;
  }
  .min-sm-w-20 {
    min-width: 20px !important;
  }
  .max-sm-w-20 {
    max-width: 20px !important;
  }
  .sticky-menu.sticky-sm-padding-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .m-sm-t-b-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .p-sm-t-b-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .m-sm-l-r-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .p-sm-l-r-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .m-sm-all-20 {
    margin: 20px !important;
  }
  .p-sm-all-20 {
    padding: 20px !important;
  }
  .m-sm-t-20 {
    margin-top: 20px !important;
  }
  .p-sm-t-20 {
    padding-top: 20px !important;
  }
  .m-sm-b-20 {
    margin-bottom: 20px !important;
  }
  .p-sm-b-20 {
    padding-bottom: 20px !important;
  }
  .m-sm-l-20 {
    margin-left: 20px !important;
  }
  .p-sm-l-20 {
    padding-left: 20px !important;
  }
  .m-sm-r-20 {
    margin-right: 20px !important;
  }
  .p-sm-r-20 {
    padding-right: 20px !important;
  }
  .min-sm-w-25 {
    min-width: 25px !important;
  }
  .max-sm-w-25 {
    max-width: 25px !important;
  }
  .sticky-menu.sticky-sm-padding-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .m-sm-t-b-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .p-sm-t-b-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .m-sm-l-r-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .p-sm-l-r-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .m-sm-all-25 {
    margin: 25px !important;
  }
  .p-sm-all-25 {
    padding: 25px !important;
  }
  .m-sm-t-25 {
    margin-top: 25px !important;
  }
  .p-sm-t-25 {
    padding-top: 25px !important;
  }
  .m-sm-b-25 {
    margin-bottom: 25px !important;
  }
  .p-sm-b-25 {
    padding-bottom: 25px !important;
  }
  .m-sm-l-25 {
    margin-left: 25px !important;
  }
  .p-sm-l-25 {
    padding-left: 25px !important;
  }
  .m-sm-r-25 {
    margin-right: 25px !important;
  }
  .p-sm-r-25 {
    padding-right: 25px !important;
  }
  .min-sm-w-30 {
    min-width: 30px !important;
  }
  .max-sm-w-30 {
    max-width: 30px !important;
  }
  .sticky-menu.sticky-sm-padding-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .m-sm-t-b-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .p-sm-t-b-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .m-sm-l-r-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .p-sm-l-r-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .m-sm-all-30 {
    margin: 30px !important;
  }
  .p-sm-all-30 {
    padding: 30px !important;
  }
  .m-sm-t-30 {
    margin-top: 30px !important;
  }
  .p-sm-t-30 {
    padding-top: 30px !important;
  }
  .m-sm-b-30 {
    margin-bottom: 30px !important;
  }
  .p-sm-b-30 {
    padding-bottom: 30px !important;
  }
  .m-sm-l-30 {
    margin-left: 30px !important;
  }
  .p-sm-l-30 {
    padding-left: 30px !important;
  }
  .m-sm-r-30 {
    margin-right: 30px !important;
  }
  .p-sm-r-30 {
    padding-right: 30px !important;
  }
  .min-sm-w-35 {
    min-width: 35px !important;
  }
  .max-sm-w-35 {
    max-width: 35px !important;
  }
  .sticky-menu.sticky-sm-padding-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .m-sm-t-b-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .p-sm-t-b-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .m-sm-l-r-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .p-sm-l-r-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .m-sm-all-35 {
    margin: 35px !important;
  }
  .p-sm-all-35 {
    padding: 35px !important;
  }
  .m-sm-t-35 {
    margin-top: 35px !important;
  }
  .p-sm-t-35 {
    padding-top: 35px !important;
  }
  .m-sm-b-35 {
    margin-bottom: 35px !important;
  }
  .p-sm-b-35 {
    padding-bottom: 35px !important;
  }
  .m-sm-l-35 {
    margin-left: 35px !important;
  }
  .p-sm-l-35 {
    padding-left: 35px !important;
  }
  .m-sm-r-35 {
    margin-right: 35px !important;
  }
  .p-sm-r-35 {
    padding-right: 35px !important;
  }
  .min-sm-w-40 {
    min-width: 40px !important;
  }
  .max-sm-w-40 {
    max-width: 40px !important;
  }
  .sticky-menu.sticky-sm-padding-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .m-sm-t-b-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .p-sm-t-b-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .m-sm-l-r-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .p-sm-l-r-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .m-sm-all-40 {
    margin: 40px !important;
  }
  .p-sm-all-40 {
    padding: 40px !important;
  }
  .m-sm-t-40 {
    margin-top: 40px !important;
  }
  .p-sm-t-40 {
    padding-top: 40px !important;
  }
  .m-sm-b-40 {
    margin-bottom: 40px !important;
  }
  .p-sm-b-40 {
    padding-bottom: 40px !important;
  }
  .m-sm-l-40 {
    margin-left: 40px !important;
  }
  .p-sm-l-40 {
    padding-left: 40px !important;
  }
  .m-sm-r-40 {
    margin-right: 40px !important;
  }
  .p-sm-r-40 {
    padding-right: 40px !important;
  }
  .min-sm-w-45 {
    min-width: 45px !important;
  }
  .max-sm-w-45 {
    max-width: 45px !important;
  }
  .sticky-menu.sticky-sm-padding-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .m-sm-t-b-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .p-sm-t-b-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .m-sm-l-r-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .p-sm-l-r-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .m-sm-all-45 {
    margin: 45px !important;
  }
  .p-sm-all-45 {
    padding: 45px !important;
  }
  .m-sm-t-45 {
    margin-top: 45px !important;
  }
  .p-sm-t-45 {
    padding-top: 45px !important;
  }
  .m-sm-b-45 {
    margin-bottom: 45px !important;
  }
  .p-sm-b-45 {
    padding-bottom: 45px !important;
  }
  .m-sm-l-45 {
    margin-left: 45px !important;
  }
  .p-sm-l-45 {
    padding-left: 45px !important;
  }
  .m-sm-r-45 {
    margin-right: 45px !important;
  }
  .p-sm-r-45 {
    padding-right: 45px !important;
  }
  .min-sm-w-50 {
    min-width: 50px !important;
  }
  .max-sm-w-50 {
    max-width: 50px !important;
  }
  .sticky-menu.sticky-sm-padding-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .m-sm-t-b-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .p-sm-t-b-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .m-sm-l-r-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .p-sm-l-r-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .m-sm-all-50 {
    margin: 50px !important;
  }
  .p-sm-all-50 {
    padding: 50px !important;
  }
  .m-sm-t-50 {
    margin-top: 50px !important;
  }
  .p-sm-t-50 {
    padding-top: 50px !important;
  }
  .m-sm-b-50 {
    margin-bottom: 50px !important;
  }
  .p-sm-b-50 {
    padding-bottom: 50px !important;
  }
  .m-sm-l-50 {
    margin-left: 50px !important;
  }
  .p-sm-l-50 {
    padding-left: 50px !important;
  }
  .m-sm-r-50 {
    margin-right: 50px !important;
  }
  .p-sm-r-50 {
    padding-right: 50px !important;
  }
  .min-sm-w-55 {
    min-width: 55px !important;
  }
  .max-sm-w-55 {
    max-width: 55px !important;
  }
  .sticky-menu.sticky-sm-padding-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .m-sm-t-b-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .p-sm-t-b-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .m-sm-l-r-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .p-sm-l-r-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .m-sm-all-55 {
    margin: 55px !important;
  }
  .p-sm-all-55 {
    padding: 55px !important;
  }
  .m-sm-t-55 {
    margin-top: 55px !important;
  }
  .p-sm-t-55 {
    padding-top: 55px !important;
  }
  .m-sm-b-55 {
    margin-bottom: 55px !important;
  }
  .p-sm-b-55 {
    padding-bottom: 55px !important;
  }
  .m-sm-l-55 {
    margin-left: 55px !important;
  }
  .p-sm-l-55 {
    padding-left: 55px !important;
  }
  .m-sm-r-55 {
    margin-right: 55px !important;
  }
  .p-sm-r-55 {
    padding-right: 55px !important;
  }
  .min-sm-w-60 {
    min-width: 60px !important;
  }
  .max-sm-w-60 {
    max-width: 60px !important;
  }
  .sticky-menu.sticky-sm-padding-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .m-sm-t-b-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .p-sm-t-b-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .m-sm-l-r-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .p-sm-l-r-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .m-sm-all-60 {
    margin: 60px !important;
  }
  .p-sm-all-60 {
    padding: 60px !important;
  }
  .m-sm-t-60 {
    margin-top: 60px !important;
  }
  .p-sm-t-60 {
    padding-top: 60px !important;
  }
  .m-sm-b-60 {
    margin-bottom: 60px !important;
  }
  .p-sm-b-60 {
    padding-bottom: 60px !important;
  }
  .m-sm-l-60 {
    margin-left: 60px !important;
  }
  .p-sm-l-60 {
    padding-left: 60px !important;
  }
  .m-sm-r-60 {
    margin-right: 60px !important;
  }
  .p-sm-r-60 {
    padding-right: 60px !important;
  }
  .min-sm-w-70 {
    min-width: 70px !important;
  }
  .max-sm-w-70 {
    max-width: 70px !important;
  }
  .sticky-menu.sticky-sm-padding-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .m-sm-t-b-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .p-sm-t-b-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .m-sm-l-r-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .p-sm-l-r-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .m-sm-all-70 {
    margin: 70px !important;
  }
  .p-sm-all-70 {
    padding: 70px !important;
  }
  .m-sm-t-70 {
    margin-top: 70px !important;
  }
  .p-sm-t-70 {
    padding-top: 70px !important;
  }
  .m-sm-b-70 {
    margin-bottom: 70px !important;
  }
  .p-sm-b-70 {
    padding-bottom: 70px !important;
  }
  .m-sm-l-70 {
    margin-left: 70px !important;
  }
  .p-sm-l-70 {
    padding-left: 70px !important;
  }
  .m-sm-r-70 {
    margin-right: 70px !important;
  }
  .p-sm-r-70 {
    padding-right: 70px !important;
  }
  .min-sm-w-75 {
    min-width: 75px !important;
  }
  .max-sm-w-75 {
    max-width: 75px !important;
  }
  .sticky-menu.sticky-sm-padding-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .m-sm-t-b-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .p-sm-t-b-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .m-sm-l-r-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .p-sm-l-r-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .m-sm-all-75 {
    margin: 75px !important;
  }
  .p-sm-all-75 {
    padding: 75px !important;
  }
  .m-sm-t-75 {
    margin-top: 75px !important;
  }
  .p-sm-t-75 {
    padding-top: 75px !important;
  }
  .m-sm-b-75 {
    margin-bottom: 75px !important;
  }
  .p-sm-b-75 {
    padding-bottom: 75px !important;
  }
  .m-sm-l-75 {
    margin-left: 75px !important;
  }
  .p-sm-l-75 {
    padding-left: 75px !important;
  }
  .m-sm-r-75 {
    margin-right: 75px !important;
  }
  .p-sm-r-75 {
    padding-right: 75px !important;
  }
  .min-sm-w-80 {
    min-width: 80px !important;
  }
  .max-sm-w-80 {
    max-width: 80px !important;
  }
  .sticky-menu.sticky-sm-padding-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .m-sm-t-b-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .p-sm-t-b-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .m-sm-l-r-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .p-sm-l-r-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .m-sm-all-80 {
    margin: 80px !important;
  }
  .p-sm-all-80 {
    padding: 80px !important;
  }
  .m-sm-t-80 {
    margin-top: 80px !important;
  }
  .p-sm-t-80 {
    padding-top: 80px !important;
  }
  .m-sm-b-80 {
    margin-bottom: 80px !important;
  }
  .p-sm-b-80 {
    padding-bottom: 80px !important;
  }
  .m-sm-l-80 {
    margin-left: 80px !important;
  }
  .p-sm-l-80 {
    padding-left: 80px !important;
  }
  .m-sm-r-80 {
    margin-right: 80px !important;
  }
  .p-sm-r-80 {
    padding-right: 80px !important;
  }
  .min-sm-w-85 {
    min-width: 85px !important;
  }
  .max-sm-w-85 {
    max-width: 85px !important;
  }
  .sticky-menu.sticky-sm-padding-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .m-sm-t-b-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .p-sm-t-b-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .m-sm-l-r-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .p-sm-l-r-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .m-sm-all-85 {
    margin: 85px !important;
  }
  .p-sm-all-85 {
    padding: 85px !important;
  }
  .m-sm-t-85 {
    margin-top: 85px !important;
  }
  .p-sm-t-85 {
    padding-top: 85px !important;
  }
  .m-sm-b-85 {
    margin-bottom: 85px !important;
  }
  .p-sm-b-85 {
    padding-bottom: 85px !important;
  }
  .m-sm-l-85 {
    margin-left: 85px !important;
  }
  .p-sm-l-85 {
    padding-left: 85px !important;
  }
  .m-sm-r-85 {
    margin-right: 85px !important;
  }
  .p-sm-r-85 {
    padding-right: 85px !important;
  }
  .min-sm-w-90 {
    min-width: 90px !important;
  }
  .max-sm-w-90 {
    max-width: 90px !important;
  }
  .sticky-menu.sticky-sm-padding-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .m-sm-t-b-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .p-sm-t-b-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .m-sm-l-r-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .p-sm-l-r-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .m-sm-all-90 {
    margin: 90px !important;
  }
  .p-sm-all-90 {
    padding: 90px !important;
  }
  .m-sm-t-90 {
    margin-top: 90px !important;
  }
  .p-sm-t-90 {
    padding-top: 90px !important;
  }
  .m-sm-b-90 {
    margin-bottom: 90px !important;
  }
  .p-sm-b-90 {
    padding-bottom: 90px !important;
  }
  .m-sm-l-90 {
    margin-left: 90px !important;
  }
  .p-sm-l-90 {
    padding-left: 90px !important;
  }
  .m-sm-r-90 {
    margin-right: 90px !important;
  }
  .p-sm-r-90 {
    padding-right: 90px !important;
  }
  .min-sm-w-95 {
    min-width: 95px !important;
  }
  .max-sm-w-95 {
    max-width: 95px !important;
  }
  .sticky-menu.sticky-sm-padding-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .m-sm-t-b-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .p-sm-t-b-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .m-sm-l-r-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .p-sm-l-r-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .m-sm-all-95 {
    margin: 95px !important;
  }
  .p-sm-all-95 {
    padding: 95px !important;
  }
  .m-sm-t-95 {
    margin-top: 95px !important;
  }
  .p-sm-t-95 {
    padding-top: 95px !important;
  }
  .m-sm-b-95 {
    margin-bottom: 95px !important;
  }
  .p-sm-b-95 {
    padding-bottom: 95px !important;
  }
  .m-sm-l-95 {
    margin-left: 95px !important;
  }
  .p-sm-l-95 {
    padding-left: 95px !important;
  }
  .m-sm-r-95 {
    margin-right: 95px !important;
  }
  .p-sm-r-95 {
    padding-right: 95px !important;
  }
  .min-sm-w-100 {
    min-width: 100px !important;
  }
  .max-sm-w-100 {
    max-width: 100px !important;
  }
  .sticky-menu.sticky-sm-padding-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-sm-t-b-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .p-sm-t-b-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-sm-l-r-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .p-sm-l-r-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .m-sm-all-100 {
    margin: 100px !important;
  }
  .p-sm-all-100 {
    padding: 100px !important;
  }
  .m-sm-t-100 {
    margin-top: 100px !important;
  }
  .p-sm-t-100 {
    padding-top: 100px !important;
  }
  .m-sm-b-100 {
    margin-bottom: 100px !important;
  }
  .p-sm-b-100 {
    padding-bottom: 100px !important;
  }
  .m-sm-l-100 {
    margin-left: 100px !important;
  }
  .p-sm-l-100 {
    padding-left: 100px !important;
  }
  .m-sm-r-100 {
    margin-right: 100px !important;
  }
  .p-sm-r-100 {
    padding-right: 100px !important;
  }
  .min-sm-w-110 {
    min-width: 110px !important;
  }
  .max-sm-w-110 {
    max-width: 110px !important;
  }
  .sticky-menu.sticky-sm-padding-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .m-sm-t-b-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  .p-sm-t-b-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .m-sm-l-r-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  .p-sm-l-r-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .m-sm-all-110 {
    margin: 110px !important;
  }
  .p-sm-all-110 {
    padding: 110px !important;
  }
  .m-sm-t-110 {
    margin-top: 110px !important;
  }
  .p-sm-t-110 {
    padding-top: 110px !important;
  }
  .m-sm-b-110 {
    margin-bottom: 110px !important;
  }
  .p-sm-b-110 {
    padding-bottom: 110px !important;
  }
  .m-sm-l-110 {
    margin-left: 110px !important;
  }
  .p-sm-l-110 {
    padding-left: 110px !important;
  }
  .m-sm-r-110 {
    margin-right: 110px !important;
  }
  .p-sm-r-110 {
    padding-right: 110px !important;
  }
  .min-sm-w-115 {
    min-width: 115px !important;
  }
  .max-sm-w-115 {
    max-width: 115px !important;
  }
  .sticky-menu.sticky-sm-padding-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .m-sm-t-b-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  .p-sm-t-b-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .m-sm-l-r-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  .p-sm-l-r-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .m-sm-all-115 {
    margin: 115px !important;
  }
  .p-sm-all-115 {
    padding: 115px !important;
  }
  .m-sm-t-115 {
    margin-top: 115px !important;
  }
  .p-sm-t-115 {
    padding-top: 115px !important;
  }
  .m-sm-b-115 {
    margin-bottom: 115px !important;
  }
  .p-sm-b-115 {
    padding-bottom: 115px !important;
  }
  .m-sm-l-115 {
    margin-left: 115px !important;
  }
  .p-sm-l-115 {
    padding-left: 115px !important;
  }
  .m-sm-r-115 {
    margin-right: 115px !important;
  }
  .p-sm-r-115 {
    padding-right: 115px !important;
  }
  .min-sm-w-120 {
    min-width: 120px !important;
  }
  .max-sm-w-120 {
    max-width: 120px !important;
  }
  .sticky-menu.sticky-sm-padding-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .m-sm-t-b-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .p-sm-t-b-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .m-sm-l-r-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  .p-sm-l-r-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .m-sm-all-120 {
    margin: 120px !important;
  }
  .p-sm-all-120 {
    padding: 120px !important;
  }
  .m-sm-t-120 {
    margin-top: 120px !important;
  }
  .p-sm-t-120 {
    padding-top: 120px !important;
  }
  .m-sm-b-120 {
    margin-bottom: 120px !important;
  }
  .p-sm-b-120 {
    padding-bottom: 120px !important;
  }
  .m-sm-l-120 {
    margin-left: 120px !important;
  }
  .p-sm-l-120 {
    padding-left: 120px !important;
  }
  .m-sm-r-120 {
    margin-right: 120px !important;
  }
  .p-sm-r-120 {
    padding-right: 120px !important;
  }
  .min-sm-w-130 {
    min-width: 130px !important;
  }
  .max-sm-w-130 {
    max-width: 130px !important;
  }
  .sticky-menu.sticky-sm-padding-130 {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .m-sm-t-b-130 {
    margin-top: 130px !important;
    margin-bottom: 130px !important;
  }
  .p-sm-t-b-130 {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .m-sm-l-r-130 {
    margin-left: 130px !important;
    margin-right: 130px !important;
  }
  .p-sm-l-r-130 {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }
  .m-sm-all-130 {
    margin: 130px !important;
  }
  .p-sm-all-130 {
    padding: 130px !important;
  }
  .m-sm-t-130 {
    margin-top: 130px !important;
  }
  .p-sm-t-130 {
    padding-top: 130px !important;
  }
  .m-sm-b-130 {
    margin-bottom: 130px !important;
  }
  .p-sm-b-130 {
    padding-bottom: 130px !important;
  }
  .m-sm-l-130 {
    margin-left: 130px !important;
  }
  .p-sm-l-130 {
    padding-left: 130px !important;
  }
  .m-sm-r-130 {
    margin-right: 130px !important;
  }
  .p-sm-r-130 {
    padding-right: 130px !important;
  }
  .min-sm-w-140 {
    min-width: 140px !important;
  }
  .max-sm-w-140 {
    max-width: 140px !important;
  }
  .sticky-menu.sticky-sm-padding-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .m-sm-t-b-140 {
    margin-top: 140px !important;
    margin-bottom: 140px !important;
  }
  .p-sm-t-b-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .m-sm-l-r-140 {
    margin-left: 140px !important;
    margin-right: 140px !important;
  }
  .p-sm-l-r-140 {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .m-sm-all-140 {
    margin: 140px !important;
  }
  .p-sm-all-140 {
    padding: 140px !important;
  }
  .m-sm-t-140 {
    margin-top: 140px !important;
  }
  .p-sm-t-140 {
    padding-top: 140px !important;
  }
  .m-sm-b-140 {
    margin-bottom: 140px !important;
  }
  .p-sm-b-140 {
    padding-bottom: 140px !important;
  }
  .m-sm-l-140 {
    margin-left: 140px !important;
  }
  .p-sm-l-140 {
    padding-left: 140px !important;
  }
  .m-sm-r-140 {
    margin-right: 140px !important;
  }
  .p-sm-r-140 {
    padding-right: 140px !important;
  }
  .min-sm-w-150 {
    min-width: 150px !important;
  }
  .max-sm-w-150 {
    max-width: 150px !important;
  }
  .sticky-menu.sticky-sm-padding-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .m-sm-t-b-150 {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .p-sm-t-b-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .m-sm-l-r-150 {
    margin-left: 150px !important;
    margin-right: 150px !important;
  }
  .p-sm-l-r-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .m-sm-all-150 {
    margin: 150px !important;
  }
  .p-sm-all-150 {
    padding: 150px !important;
  }
  .m-sm-t-150 {
    margin-top: 150px !important;
  }
  .p-sm-t-150 {
    padding-top: 150px !important;
  }
  .m-sm-b-150 {
    margin-bottom: 150px !important;
  }
  .p-sm-b-150 {
    padding-bottom: 150px !important;
  }
  .m-sm-l-150 {
    margin-left: 150px !important;
  }
  .p-sm-l-150 {
    padding-left: 150px !important;
  }
  .m-sm-r-150 {
    margin-right: 150px !important;
  }
  .p-sm-r-150 {
    padding-right: 150px !important;
  }
  .min-sm-w-175 {
    min-width: 175px !important;
  }
  .max-sm-w-175 {
    max-width: 175px !important;
  }
  .sticky-menu.sticky-sm-padding-175 {
    padding-top: 175px !important;
    padding-bottom: 175px !important;
  }
  .m-sm-t-b-175 {
    margin-top: 175px !important;
    margin-bottom: 175px !important;
  }
  .p-sm-t-b-175 {
    padding-top: 175px !important;
    padding-bottom: 175px !important;
  }
  .m-sm-l-r-175 {
    margin-left: 175px !important;
    margin-right: 175px !important;
  }
  .p-sm-l-r-175 {
    padding-left: 175px !important;
    padding-right: 175px !important;
  }
  .m-sm-all-175 {
    margin: 175px !important;
  }
  .p-sm-all-175 {
    padding: 175px !important;
  }
  .m-sm-t-175 {
    margin-top: 175px !important;
  }
  .p-sm-t-175 {
    padding-top: 175px !important;
  }
  .m-sm-b-175 {
    margin-bottom: 175px !important;
  }
  .p-sm-b-175 {
    padding-bottom: 175px !important;
  }
  .m-sm-l-175 {
    margin-left: 175px !important;
  }
  .p-sm-l-175 {
    padding-left: 175px !important;
  }
  .m-sm-r-175 {
    margin-right: 175px !important;
  }
  .p-sm-r-175 {
    padding-right: 175px !important;
  }
  .min-sm-w-200 {
    min-width: 200px !important;
  }
  .max-sm-w-200 {
    max-width: 200px !important;
  }
  .sticky-menu.sticky-sm-padding-200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .m-sm-t-b-200 {
    margin-top: 200px !important;
    margin-bottom: 200px !important;
  }
  .p-sm-t-b-200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .m-sm-l-r-200 {
    margin-left: 200px !important;
    margin-right: 200px !important;
  }
  .p-sm-l-r-200 {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .m-sm-all-200 {
    margin: 200px !important;
  }
  .p-sm-all-200 {
    padding: 200px !important;
  }
  .m-sm-t-200 {
    margin-top: 200px !important;
  }
  .p-sm-t-200 {
    padding-top: 200px !important;
  }
  .m-sm-b-200 {
    margin-bottom: 200px !important;
  }
  .p-sm-b-200 {
    padding-bottom: 200px !important;
  }
  .m-sm-l-200 {
    margin-left: 200px !important;
  }
  .p-sm-l-200 {
    padding-left: 200px !important;
  }
  .m-sm-r-200 {
    margin-right: 200px !important;
  }
  .p-sm-r-200 {
    padding-right: 200px !important;
  }
  .min-sm-w-225 {
    min-width: 225px !important;
  }
  .max-sm-w-225 {
    max-width: 225px !important;
  }
  .sticky-menu.sticky-sm-padding-225 {
    padding-top: 225px !important;
    padding-bottom: 225px !important;
  }
  .m-sm-t-b-225 {
    margin-top: 225px !important;
    margin-bottom: 225px !important;
  }
  .p-sm-t-b-225 {
    padding-top: 225px !important;
    padding-bottom: 225px !important;
  }
  .m-sm-l-r-225 {
    margin-left: 225px !important;
    margin-right: 225px !important;
  }
  .p-sm-l-r-225 {
    padding-left: 225px !important;
    padding-right: 225px !important;
  }
  .m-sm-all-225 {
    margin: 225px !important;
  }
  .p-sm-all-225 {
    padding: 225px !important;
  }
  .m-sm-t-225 {
    margin-top: 225px !important;
  }
  .p-sm-t-225 {
    padding-top: 225px !important;
  }
  .m-sm-b-225 {
    margin-bottom: 225px !important;
  }
  .p-sm-b-225 {
    padding-bottom: 225px !important;
  }
  .m-sm-l-225 {
    margin-left: 225px !important;
  }
  .p-sm-l-225 {
    padding-left: 225px !important;
  }
  .m-sm-r-225 {
    margin-right: 225px !important;
  }
  .p-sm-r-225 {
    padding-right: 225px !important;
  }
  .min-sm-w-240 {
    min-width: 240px !important;
  }
  .max-sm-w-240 {
    max-width: 240px !important;
  }
  .sticky-menu.sticky-sm-padding-240 {
    padding-top: 240px !important;
    padding-bottom: 240px !important;
  }
  .m-sm-t-b-240 {
    margin-top: 240px !important;
    margin-bottom: 240px !important;
  }
  .p-sm-t-b-240 {
    padding-top: 240px !important;
    padding-bottom: 240px !important;
  }
  .m-sm-l-r-240 {
    margin-left: 240px !important;
    margin-right: 240px !important;
  }
  .p-sm-l-r-240 {
    padding-left: 240px !important;
    padding-right: 240px !important;
  }
  .m-sm-all-240 {
    margin: 240px !important;
  }
  .p-sm-all-240 {
    padding: 240px !important;
  }
  .m-sm-t-240 {
    margin-top: 240px !important;
  }
  .p-sm-t-240 {
    padding-top: 240px !important;
  }
  .m-sm-b-240 {
    margin-bottom: 240px !important;
  }
  .p-sm-b-240 {
    padding-bottom: 240px !important;
  }
  .m-sm-l-240 {
    margin-left: 240px !important;
  }
  .p-sm-l-240 {
    padding-left: 240px !important;
  }
  .m-sm-r-240 {
    margin-right: 240px !important;
  }
  .p-sm-r-240 {
    padding-right: 240px !important;
  }
  .min-sm-w-250 {
    min-width: 250px !important;
  }
  .max-sm-w-250 {
    max-width: 250px !important;
  }
  .sticky-menu.sticky-sm-padding-250 {
    padding-top: 250px !important;
    padding-bottom: 250px !important;
  }
  .m-sm-t-b-250 {
    margin-top: 250px !important;
    margin-bottom: 250px !important;
  }
  .p-sm-t-b-250 {
    padding-top: 250px !important;
    padding-bottom: 250px !important;
  }
  .m-sm-l-r-250 {
    margin-left: 250px !important;
    margin-right: 250px !important;
  }
  .p-sm-l-r-250 {
    padding-left: 250px !important;
    padding-right: 250px !important;
  }
  .m-sm-all-250 {
    margin: 250px !important;
  }
  .p-sm-all-250 {
    padding: 250px !important;
  }
  .m-sm-t-250 {
    margin-top: 250px !important;
  }
  .p-sm-t-250 {
    padding-top: 250px !important;
  }
  .m-sm-b-250 {
    margin-bottom: 250px !important;
  }
  .p-sm-b-250 {
    padding-bottom: 250px !important;
  }
  .m-sm-l-250 {
    margin-left: 250px !important;
  }
  .p-sm-l-250 {
    padding-left: 250px !important;
  }
  .m-sm-r-250 {
    margin-right: 250px !important;
  }
  .p-sm-r-250 {
    padding-right: 250px !important;
  }
  .min-sm-w-300 {
    min-width: 300px !important;
  }
  .max-sm-w-300 {
    max-width: 300px !important;
  }
  .sticky-menu.sticky-sm-padding-300 {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .m-sm-t-b-300 {
    margin-top: 300px !important;
    margin-bottom: 300px !important;
  }
  .p-sm-t-b-300 {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .m-sm-l-r-300 {
    margin-left: 300px !important;
    margin-right: 300px !important;
  }
  .p-sm-l-r-300 {
    padding-left: 300px !important;
    padding-right: 300px !important;
  }
  .m-sm-all-300 {
    margin: 300px !important;
  }
  .p-sm-all-300 {
    padding: 300px !important;
  }
  .m-sm-t-300 {
    margin-top: 300px !important;
  }
  .p-sm-t-300 {
    padding-top: 300px !important;
  }
  .m-sm-b-300 {
    margin-bottom: 300px !important;
  }
  .p-sm-b-300 {
    padding-bottom: 300px !important;
  }
  .m-sm-l-300 {
    margin-left: 300px !important;
  }
  .p-sm-l-300 {
    padding-left: 300px !important;
  }
  .m-sm-r-300 {
    margin-right: 300px !important;
  }
  .p-sm-r-300 {
    padding-right: 300px !important;
  }
  .min-sm-w-350 {
    min-width: 350px !important;
  }
  .max-sm-w-350 {
    max-width: 350px !important;
  }
  .sticky-menu.sticky-sm-padding-350 {
    padding-top: 350px !important;
    padding-bottom: 350px !important;
  }
  .m-sm-t-b-350 {
    margin-top: 350px !important;
    margin-bottom: 350px !important;
  }
  .p-sm-t-b-350 {
    padding-top: 350px !important;
    padding-bottom: 350px !important;
  }
  .m-sm-l-r-350 {
    margin-left: 350px !important;
    margin-right: 350px !important;
  }
  .p-sm-l-r-350 {
    padding-left: 350px !important;
    padding-right: 350px !important;
  }
  .m-sm-all-350 {
    margin: 350px !important;
  }
  .p-sm-all-350 {
    padding: 350px !important;
  }
  .m-sm-t-350 {
    margin-top: 350px !important;
  }
  .p-sm-t-350 {
    padding-top: 350px !important;
  }
  .m-sm-b-350 {
    margin-bottom: 350px !important;
  }
  .p-sm-b-350 {
    padding-bottom: 350px !important;
  }
  .m-sm-l-350 {
    margin-left: 350px !important;
  }
  .p-sm-l-350 {
    padding-left: 350px !important;
  }
  .m-sm-r-350 {
    margin-right: 350px !important;
  }
  .p-sm-r-350 {
    padding-right: 350px !important;
  }
}

@media only screen and (max-width: 575.98px) {
  .min-xs-w-none {
    min-width: 0px !important;
  }
  .max-xs-w-none {
    max-width: 0px !important;
  }
  .sticky-menu.sticky-xs-padding-none {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .m-xs-t-b-none {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .p-xs-t-b-none {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .m-xs-l-r-none {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .p-xs-l-r-none {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .m-xs-all-none {
    margin: 0px !important;
  }
  .p-xs-all-none {
    padding: 0px !important;
  }
  .m-xs-t-none {
    margin-top: 0px !important;
  }
  .p-xs-t-none {
    padding-top: 0px !important;
  }
  .m-xs-b-none {
    margin-bottom: 0px !important;
  }
  .p-xs-b-none {
    padding-bottom: 0px !important;
  }
  .m-xs-l-none {
    margin-left: 0px !important;
  }
  .p-xs-l-none {
    padding-left: 0px !important;
  }
  .m-xs-r-none {
    margin-right: 0px !important;
  }
  .p-xs-r-none {
    padding-right: 0px !important;
  }
  .min-xs-w-auto {
    min-width: auto !important;
  }
  .max-xs-w-auto {
    max-width: auto !important;
  }
  .sticky-menu.sticky-xs-padding-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .m-xs-t-b-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .p-xs-t-b-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .m-xs-l-r-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .p-xs-l-r-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }
  .m-xs-all-auto {
    margin: auto !important;
  }
  .p-xs-all-auto {
    padding: auto !important;
  }
  .m-xs-t-auto {
    margin-top: auto !important;
  }
  .p-xs-t-auto {
    padding-top: auto !important;
  }
  .m-xs-b-auto {
    margin-bottom: auto !important;
  }
  .p-xs-b-auto {
    padding-bottom: auto !important;
  }
  .m-xs-l-auto {
    margin-left: auto !important;
  }
  .p-xs-l-auto {
    padding-left: auto !important;
  }
  .m-xs-r-auto {
    margin-right: auto !important;
  }
  .p-xs-r-auto {
    padding-right: auto !important;
  }
  .min-xs-w-5 {
    min-width: 5px !important;
  }
  .max-xs-w-5 {
    max-width: 5px !important;
  }
  .sticky-menu.sticky-xs-padding-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .m-xs-t-b-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .p-xs-t-b-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .m-xs-l-r-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .p-xs-l-r-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .m-xs-all-5 {
    margin: 5px !important;
  }
  .p-xs-all-5 {
    padding: 5px !important;
  }
  .m-xs-t-5 {
    margin-top: 5px !important;
  }
  .p-xs-t-5 {
    padding-top: 5px !important;
  }
  .m-xs-b-5 {
    margin-bottom: 5px !important;
  }
  .p-xs-b-5 {
    padding-bottom: 5px !important;
  }
  .m-xs-l-5 {
    margin-left: 5px !important;
  }
  .p-xs-l-5 {
    padding-left: 5px !important;
  }
  .m-xs-r-5 {
    margin-right: 5px !important;
  }
  .p-xs-r-5 {
    padding-right: 5px !important;
  }
  .min-xs-w-10 {
    min-width: 10px !important;
  }
  .max-xs-w-10 {
    max-width: 10px !important;
  }
  .sticky-menu.sticky-xs-padding-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .m-xs-t-b-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .p-xs-t-b-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .m-xs-l-r-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .p-xs-l-r-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .m-xs-all-10 {
    margin: 10px !important;
  }
  .p-xs-all-10 {
    padding: 10px !important;
  }
  .m-xs-t-10 {
    margin-top: 10px !important;
  }
  .p-xs-t-10 {
    padding-top: 10px !important;
  }
  .m-xs-b-10 {
    margin-bottom: 10px !important;
  }
  .p-xs-b-10 {
    padding-bottom: 10px !important;
  }
  .m-xs-l-10 {
    margin-left: 10px !important;
  }
  .p-xs-l-10 {
    padding-left: 10px !important;
  }
  .m-xs-r-10 {
    margin-right: 10px !important;
  }
  .p-xs-r-10 {
    padding-right: 10px !important;
  }
  .min-xs-w-12 {
    min-width: 12px !important;
  }
  .max-xs-w-12 {
    max-width: 12px !important;
  }
  .sticky-menu.sticky-xs-padding-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .m-xs-t-b-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .p-xs-t-b-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .m-xs-l-r-12 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .p-xs-l-r-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .m-xs-all-12 {
    margin: 12px !important;
  }
  .p-xs-all-12 {
    padding: 12px !important;
  }
  .m-xs-t-12 {
    margin-top: 12px !important;
  }
  .p-xs-t-12 {
    padding-top: 12px !important;
  }
  .m-xs-b-12 {
    margin-bottom: 12px !important;
  }
  .p-xs-b-12 {
    padding-bottom: 12px !important;
  }
  .m-xs-l-12 {
    margin-left: 12px !important;
  }
  .p-xs-l-12 {
    padding-left: 12px !important;
  }
  .m-xs-r-12 {
    margin-right: 12px !important;
  }
  .p-xs-r-12 {
    padding-right: 12px !important;
  }
  .min-xs-w-13 {
    min-width: 13px !important;
  }
  .max-xs-w-13 {
    max-width: 13px !important;
  }
  .sticky-menu.sticky-xs-padding-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .m-xs-t-b-13 {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
  }
  .p-xs-t-b-13 {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .m-xs-l-r-13 {
    margin-left: 13px !important;
    margin-right: 13px !important;
  }
  .p-xs-l-r-13 {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .m-xs-all-13 {
    margin: 13px !important;
  }
  .p-xs-all-13 {
    padding: 13px !important;
  }
  .m-xs-t-13 {
    margin-top: 13px !important;
  }
  .p-xs-t-13 {
    padding-top: 13px !important;
  }
  .m-xs-b-13 {
    margin-bottom: 13px !important;
  }
  .p-xs-b-13 {
    padding-bottom: 13px !important;
  }
  .m-xs-l-13 {
    margin-left: 13px !important;
  }
  .p-xs-l-13 {
    padding-left: 13px !important;
  }
  .m-xs-r-13 {
    margin-right: 13px !important;
  }
  .p-xs-r-13 {
    padding-right: 13px !important;
  }
  .min-xs-w-15 {
    min-width: 15px !important;
  }
  .max-xs-w-15 {
    max-width: 15px !important;
  }
  .sticky-menu.sticky-xs-padding-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .m-xs-t-b-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .p-xs-t-b-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .m-xs-l-r-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .p-xs-l-r-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .m-xs-all-15 {
    margin: 15px !important;
  }
  .p-xs-all-15 {
    padding: 15px !important;
  }
  .m-xs-t-15 {
    margin-top: 15px !important;
  }
  .p-xs-t-15 {
    padding-top: 15px !important;
  }
  .m-xs-b-15 {
    margin-bottom: 15px !important;
  }
  .p-xs-b-15 {
    padding-bottom: 15px !important;
  }
  .m-xs-l-15 {
    margin-left: 15px !important;
  }
  .p-xs-l-15 {
    padding-left: 15px !important;
  }
  .m-xs-r-15 {
    margin-right: 15px !important;
  }
  .p-xs-r-15 {
    padding-right: 15px !important;
  }
  .min-xs-w-20 {
    min-width: 20px !important;
  }
  .max-xs-w-20 {
    max-width: 20px !important;
  }
  .sticky-menu.sticky-xs-padding-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .m-xs-t-b-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .p-xs-t-b-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .m-xs-l-r-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .p-xs-l-r-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .m-xs-all-20 {
    margin: 20px !important;
  }
  .p-xs-all-20 {
    padding: 20px !important;
  }
  .m-xs-t-20 {
    margin-top: 20px !important;
  }
  .p-xs-t-20 {
    padding-top: 20px !important;
  }
  .m-xs-b-20 {
    margin-bottom: 20px !important;
  }
  .p-xs-b-20 {
    padding-bottom: 20px !important;
  }
  .m-xs-l-20 {
    margin-left: 20px !important;
  }
  .p-xs-l-20 {
    padding-left: 20px !important;
  }
  .m-xs-r-20 {
    margin-right: 20px !important;
  }
  .p-xs-r-20 {
    padding-right: 20px !important;
  }
  .min-xs-w-25 {
    min-width: 25px !important;
  }
  .max-xs-w-25 {
    max-width: 25px !important;
  }
  .sticky-menu.sticky-xs-padding-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .m-xs-t-b-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .p-xs-t-b-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .m-xs-l-r-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .p-xs-l-r-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .m-xs-all-25 {
    margin: 25px !important;
  }
  .p-xs-all-25 {
    padding: 25px !important;
  }
  .m-xs-t-25 {
    margin-top: 25px !important;
  }
  .p-xs-t-25 {
    padding-top: 25px !important;
  }
  .m-xs-b-25 {
    margin-bottom: 25px !important;
  }
  .p-xs-b-25 {
    padding-bottom: 25px !important;
  }
  .m-xs-l-25 {
    margin-left: 25px !important;
  }
  .p-xs-l-25 {
    padding-left: 25px !important;
  }
  .m-xs-r-25 {
    margin-right: 25px !important;
  }
  .p-xs-r-25 {
    padding-right: 25px !important;
  }
  .min-xs-w-30 {
    min-width: 30px !important;
  }
  .max-xs-w-30 {
    max-width: 30px !important;
  }
  .sticky-menu.sticky-xs-padding-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .m-xs-t-b-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .p-xs-t-b-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .m-xs-l-r-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .p-xs-l-r-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .m-xs-all-30 {
    margin: 30px !important;
  }
  .p-xs-all-30 {
    padding: 30px !important;
  }
  .m-xs-t-30 {
    margin-top: 30px !important;
  }
  .p-xs-t-30 {
    padding-top: 30px !important;
  }
  .m-xs-b-30 {
    margin-bottom: 30px !important;
  }
  .p-xs-b-30 {
    padding-bottom: 30px !important;
  }
  .m-xs-l-30 {
    margin-left: 30px !important;
  }
  .p-xs-l-30 {
    padding-left: 30px !important;
  }
  .m-xs-r-30 {
    margin-right: 30px !important;
  }
  .p-xs-r-30 {
    padding-right: 30px !important;
  }
  .min-xs-w-35 {
    min-width: 35px !important;
  }
  .max-xs-w-35 {
    max-width: 35px !important;
  }
  .sticky-menu.sticky-xs-padding-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .m-xs-t-b-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .p-xs-t-b-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .m-xs-l-r-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .p-xs-l-r-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .m-xs-all-35 {
    margin: 35px !important;
  }
  .p-xs-all-35 {
    padding: 35px !important;
  }
  .m-xs-t-35 {
    margin-top: 35px !important;
  }
  .p-xs-t-35 {
    padding-top: 35px !important;
  }
  .m-xs-b-35 {
    margin-bottom: 35px !important;
  }
  .p-xs-b-35 {
    padding-bottom: 35px !important;
  }
  .m-xs-l-35 {
    margin-left: 35px !important;
  }
  .p-xs-l-35 {
    padding-left: 35px !important;
  }
  .m-xs-r-35 {
    margin-right: 35px !important;
  }
  .p-xs-r-35 {
    padding-right: 35px !important;
  }
  .min-xs-w-40 {
    min-width: 40px !important;
  }
  .max-xs-w-40 {
    max-width: 40px !important;
  }
  .sticky-menu.sticky-xs-padding-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .m-xs-t-b-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .p-xs-t-b-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .m-xs-l-r-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .p-xs-l-r-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .m-xs-all-40 {
    margin: 40px !important;
  }
  .p-xs-all-40 {
    padding: 40px !important;
  }
  .m-xs-t-40 {
    margin-top: 40px !important;
  }
  .p-xs-t-40 {
    padding-top: 40px !important;
  }
  .m-xs-b-40 {
    margin-bottom: 40px !important;
  }
  .p-xs-b-40 {
    padding-bottom: 40px !important;
  }
  .m-xs-l-40 {
    margin-left: 40px !important;
  }
  .p-xs-l-40 {
    padding-left: 40px !important;
  }
  .m-xs-r-40 {
    margin-right: 40px !important;
  }
  .p-xs-r-40 {
    padding-right: 40px !important;
  }
  .min-xs-w-45 {
    min-width: 45px !important;
  }
  .max-xs-w-45 {
    max-width: 45px !important;
  }
  .sticky-menu.sticky-xs-padding-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .m-xs-t-b-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .p-xs-t-b-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .m-xs-l-r-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .p-xs-l-r-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .m-xs-all-45 {
    margin: 45px !important;
  }
  .p-xs-all-45 {
    padding: 45px !important;
  }
  .m-xs-t-45 {
    margin-top: 45px !important;
  }
  .p-xs-t-45 {
    padding-top: 45px !important;
  }
  .m-xs-b-45 {
    margin-bottom: 45px !important;
  }
  .p-xs-b-45 {
    padding-bottom: 45px !important;
  }
  .m-xs-l-45 {
    margin-left: 45px !important;
  }
  .p-xs-l-45 {
    padding-left: 45px !important;
  }
  .m-xs-r-45 {
    margin-right: 45px !important;
  }
  .p-xs-r-45 {
    padding-right: 45px !important;
  }
  .min-xs-w-50 {
    min-width: 50px !important;
  }
  .max-xs-w-50 {
    max-width: 50px !important;
  }
  .sticky-menu.sticky-xs-padding-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .m-xs-t-b-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .p-xs-t-b-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .m-xs-l-r-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .p-xs-l-r-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .m-xs-all-50 {
    margin: 50px !important;
  }
  .p-xs-all-50 {
    padding: 50px !important;
  }
  .m-xs-t-50 {
    margin-top: 50px !important;
  }
  .p-xs-t-50 {
    padding-top: 50px !important;
  }
  .m-xs-b-50 {
    margin-bottom: 50px !important;
  }
  .p-xs-b-50 {
    padding-bottom: 50px !important;
  }
  .m-xs-l-50 {
    margin-left: 50px !important;
  }
  .p-xs-l-50 {
    padding-left: 50px !important;
  }
  .m-xs-r-50 {
    margin-right: 50px !important;
  }
  .p-xs-r-50 {
    padding-right: 50px !important;
  }
  .min-xs-w-55 {
    min-width: 55px !important;
  }
  .max-xs-w-55 {
    max-width: 55px !important;
  }
  .sticky-menu.sticky-xs-padding-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .m-xs-t-b-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .p-xs-t-b-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .m-xs-l-r-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .p-xs-l-r-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .m-xs-all-55 {
    margin: 55px !important;
  }
  .p-xs-all-55 {
    padding: 55px !important;
  }
  .m-xs-t-55 {
    margin-top: 55px !important;
  }
  .p-xs-t-55 {
    padding-top: 55px !important;
  }
  .m-xs-b-55 {
    margin-bottom: 55px !important;
  }
  .p-xs-b-55 {
    padding-bottom: 55px !important;
  }
  .m-xs-l-55 {
    margin-left: 55px !important;
  }
  .p-xs-l-55 {
    padding-left: 55px !important;
  }
  .m-xs-r-55 {
    margin-right: 55px !important;
  }
  .p-xs-r-55 {
    padding-right: 55px !important;
  }
  .min-xs-w-60 {
    min-width: 60px !important;
  }
  .max-xs-w-60 {
    max-width: 60px !important;
  }
  .sticky-menu.sticky-xs-padding-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .m-xs-t-b-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .p-xs-t-b-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .m-xs-l-r-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .p-xs-l-r-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .m-xs-all-60 {
    margin: 60px !important;
  }
  .p-xs-all-60 {
    padding: 60px !important;
  }
  .m-xs-t-60 {
    margin-top: 60px !important;
  }
  .p-xs-t-60 {
    padding-top: 60px !important;
  }
  .m-xs-b-60 {
    margin-bottom: 60px !important;
  }
  .p-xs-b-60 {
    padding-bottom: 60px !important;
  }
  .m-xs-l-60 {
    margin-left: 60px !important;
  }
  .p-xs-l-60 {
    padding-left: 60px !important;
  }
  .m-xs-r-60 {
    margin-right: 60px !important;
  }
  .p-xs-r-60 {
    padding-right: 60px !important;
  }
  .min-xs-w-70 {
    min-width: 70px !important;
  }
  .max-xs-w-70 {
    max-width: 70px !important;
  }
  .sticky-menu.sticky-xs-padding-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .m-xs-t-b-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .p-xs-t-b-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .m-xs-l-r-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .p-xs-l-r-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .m-xs-all-70 {
    margin: 70px !important;
  }
  .p-xs-all-70 {
    padding: 70px !important;
  }
  .m-xs-t-70 {
    margin-top: 70px !important;
  }
  .p-xs-t-70 {
    padding-top: 70px !important;
  }
  .m-xs-b-70 {
    margin-bottom: 70px !important;
  }
  .p-xs-b-70 {
    padding-bottom: 70px !important;
  }
  .m-xs-l-70 {
    margin-left: 70px !important;
  }
  .p-xs-l-70 {
    padding-left: 70px !important;
  }
  .m-xs-r-70 {
    margin-right: 70px !important;
  }
  .p-xs-r-70 {
    padding-right: 70px !important;
  }
  .min-xs-w-75 {
    min-width: 75px !important;
  }
  .max-xs-w-75 {
    max-width: 75px !important;
  }
  .sticky-menu.sticky-xs-padding-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .m-xs-t-b-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .p-xs-t-b-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .m-xs-l-r-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .p-xs-l-r-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .m-xs-all-75 {
    margin: 75px !important;
  }
  .p-xs-all-75 {
    padding: 75px !important;
  }
  .m-xs-t-75 {
    margin-top: 75px !important;
  }
  .p-xs-t-75 {
    padding-top: 75px !important;
  }
  .m-xs-b-75 {
    margin-bottom: 75px !important;
  }
  .p-xs-b-75 {
    padding-bottom: 75px !important;
  }
  .m-xs-l-75 {
    margin-left: 75px !important;
  }
  .p-xs-l-75 {
    padding-left: 75px !important;
  }
  .m-xs-r-75 {
    margin-right: 75px !important;
  }
  .p-xs-r-75 {
    padding-right: 75px !important;
  }
  .min-xs-w-80 {
    min-width: 80px !important;
  }
  .max-xs-w-80 {
    max-width: 80px !important;
  }
  .sticky-menu.sticky-xs-padding-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .m-xs-t-b-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .p-xs-t-b-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .m-xs-l-r-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .p-xs-l-r-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .m-xs-all-80 {
    margin: 80px !important;
  }
  .p-xs-all-80 {
    padding: 80px !important;
  }
  .m-xs-t-80 {
    margin-top: 80px !important;
  }
  .p-xs-t-80 {
    padding-top: 80px !important;
  }
  .m-xs-b-80 {
    margin-bottom: 80px !important;
  }
  .p-xs-b-80 {
    padding-bottom: 80px !important;
  }
  .m-xs-l-80 {
    margin-left: 80px !important;
  }
  .p-xs-l-80 {
    padding-left: 80px !important;
  }
  .m-xs-r-80 {
    margin-right: 80px !important;
  }
  .p-xs-r-80 {
    padding-right: 80px !important;
  }
  .min-xs-w-85 {
    min-width: 85px !important;
  }
  .max-xs-w-85 {
    max-width: 85px !important;
  }
  .sticky-menu.sticky-xs-padding-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .m-xs-t-b-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .p-xs-t-b-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .m-xs-l-r-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .p-xs-l-r-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .m-xs-all-85 {
    margin: 85px !important;
  }
  .p-xs-all-85 {
    padding: 85px !important;
  }
  .m-xs-t-85 {
    margin-top: 85px !important;
  }
  .p-xs-t-85 {
    padding-top: 85px !important;
  }
  .m-xs-b-85 {
    margin-bottom: 85px !important;
  }
  .p-xs-b-85 {
    padding-bottom: 85px !important;
  }
  .m-xs-l-85 {
    margin-left: 85px !important;
  }
  .p-xs-l-85 {
    padding-left: 85px !important;
  }
  .m-xs-r-85 {
    margin-right: 85px !important;
  }
  .p-xs-r-85 {
    padding-right: 85px !important;
  }
  .min-xs-w-90 {
    min-width: 90px !important;
  }
  .max-xs-w-90 {
    max-width: 90px !important;
  }
  .sticky-menu.sticky-xs-padding-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .m-xs-t-b-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .p-xs-t-b-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .m-xs-l-r-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .p-xs-l-r-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .m-xs-all-90 {
    margin: 90px !important;
  }
  .p-xs-all-90 {
    padding: 90px !important;
  }
  .m-xs-t-90 {
    margin-top: 90px !important;
  }
  .p-xs-t-90 {
    padding-top: 90px !important;
  }
  .m-xs-b-90 {
    margin-bottom: 90px !important;
  }
  .p-xs-b-90 {
    padding-bottom: 90px !important;
  }
  .m-xs-l-90 {
    margin-left: 90px !important;
  }
  .p-xs-l-90 {
    padding-left: 90px !important;
  }
  .m-xs-r-90 {
    margin-right: 90px !important;
  }
  .p-xs-r-90 {
    padding-right: 90px !important;
  }
  .min-xs-w-95 {
    min-width: 95px !important;
  }
  .max-xs-w-95 {
    max-width: 95px !important;
  }
  .sticky-menu.sticky-xs-padding-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .m-xs-t-b-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .p-xs-t-b-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .m-xs-l-r-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .p-xs-l-r-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .m-xs-all-95 {
    margin: 95px !important;
  }
  .p-xs-all-95 {
    padding: 95px !important;
  }
  .m-xs-t-95 {
    margin-top: 95px !important;
  }
  .p-xs-t-95 {
    padding-top: 95px !important;
  }
  .m-xs-b-95 {
    margin-bottom: 95px !important;
  }
  .p-xs-b-95 {
    padding-bottom: 95px !important;
  }
  .m-xs-l-95 {
    margin-left: 95px !important;
  }
  .p-xs-l-95 {
    padding-left: 95px !important;
  }
  .m-xs-r-95 {
    margin-right: 95px !important;
  }
  .p-xs-r-95 {
    padding-right: 95px !important;
  }
  .min-xs-w-100 {
    min-width: 100px !important;
  }
  .max-xs-w-100 {
    max-width: 100px !important;
  }
  .sticky-menu.sticky-xs-padding-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-xs-t-b-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .p-xs-t-b-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-xs-l-r-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .p-xs-l-r-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .m-xs-all-100 {
    margin: 100px !important;
  }
  .p-xs-all-100 {
    padding: 100px !important;
  }
  .m-xs-t-100 {
    margin-top: 100px !important;
  }
  .p-xs-t-100 {
    padding-top: 100px !important;
  }
  .m-xs-b-100 {
    margin-bottom: 100px !important;
  }
  .p-xs-b-100 {
    padding-bottom: 100px !important;
  }
  .m-xs-l-100 {
    margin-left: 100px !important;
  }
  .p-xs-l-100 {
    padding-left: 100px !important;
  }
  .m-xs-r-100 {
    margin-right: 100px !important;
  }
  .p-xs-r-100 {
    padding-right: 100px !important;
  }
  .min-xs-w-110 {
    min-width: 110px !important;
  }
  .max-xs-w-110 {
    max-width: 110px !important;
  }
  .sticky-menu.sticky-xs-padding-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .m-xs-t-b-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  .p-xs-t-b-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .m-xs-l-r-110 {
    margin-left: 110px !important;
    margin-right: 110px !important;
  }
  .p-xs-l-r-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .m-xs-all-110 {
    margin: 110px !important;
  }
  .p-xs-all-110 {
    padding: 110px !important;
  }
  .m-xs-t-110 {
    margin-top: 110px !important;
  }
  .p-xs-t-110 {
    padding-top: 110px !important;
  }
  .m-xs-b-110 {
    margin-bottom: 110px !important;
  }
  .p-xs-b-110 {
    padding-bottom: 110px !important;
  }
  .m-xs-l-110 {
    margin-left: 110px !important;
  }
  .p-xs-l-110 {
    padding-left: 110px !important;
  }
  .m-xs-r-110 {
    margin-right: 110px !important;
  }
  .p-xs-r-110 {
    padding-right: 110px !important;
  }
  .min-xs-w-115 {
    min-width: 115px !important;
  }
  .max-xs-w-115 {
    max-width: 115px !important;
  }
  .sticky-menu.sticky-xs-padding-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .m-xs-t-b-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  .p-xs-t-b-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .m-xs-l-r-115 {
    margin-left: 115px !important;
    margin-right: 115px !important;
  }
  .p-xs-l-r-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .m-xs-all-115 {
    margin: 115px !important;
  }
  .p-xs-all-115 {
    padding: 115px !important;
  }
  .m-xs-t-115 {
    margin-top: 115px !important;
  }
  .p-xs-t-115 {
    padding-top: 115px !important;
  }
  .m-xs-b-115 {
    margin-bottom: 115px !important;
  }
  .p-xs-b-115 {
    padding-bottom: 115px !important;
  }
  .m-xs-l-115 {
    margin-left: 115px !important;
  }
  .p-xs-l-115 {
    padding-left: 115px !important;
  }
  .m-xs-r-115 {
    margin-right: 115px !important;
  }
  .p-xs-r-115 {
    padding-right: 115px !important;
  }
  .min-xs-w-120 {
    min-width: 120px !important;
  }
  .max-xs-w-120 {
    max-width: 120px !important;
  }
  .sticky-menu.sticky-xs-padding-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .m-xs-t-b-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .p-xs-t-b-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .m-xs-l-r-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  .p-xs-l-r-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .m-xs-all-120 {
    margin: 120px !important;
  }
  .p-xs-all-120 {
    padding: 120px !important;
  }
  .m-xs-t-120 {
    margin-top: 120px !important;
  }
  .p-xs-t-120 {
    padding-top: 120px !important;
  }
  .m-xs-b-120 {
    margin-bottom: 120px !important;
  }
  .p-xs-b-120 {
    padding-bottom: 120px !important;
  }
  .m-xs-l-120 {
    margin-left: 120px !important;
  }
  .p-xs-l-120 {
    padding-left: 120px !important;
  }
  .m-xs-r-120 {
    margin-right: 120px !important;
  }
  .p-xs-r-120 {
    padding-right: 120px !important;
  }
  .min-xs-w-130 {
    min-width: 130px !important;
  }
  .max-xs-w-130 {
    max-width: 130px !important;
  }
  .sticky-menu.sticky-xs-padding-130 {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .m-xs-t-b-130 {
    margin-top: 130px !important;
    margin-bottom: 130px !important;
  }
  .p-xs-t-b-130 {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .m-xs-l-r-130 {
    margin-left: 130px !important;
    margin-right: 130px !important;
  }
  .p-xs-l-r-130 {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }
  .m-xs-all-130 {
    margin: 130px !important;
  }
  .p-xs-all-130 {
    padding: 130px !important;
  }
  .m-xs-t-130 {
    margin-top: 130px !important;
  }
  .p-xs-t-130 {
    padding-top: 130px !important;
  }
  .m-xs-b-130 {
    margin-bottom: 130px !important;
  }
  .p-xs-b-130 {
    padding-bottom: 130px !important;
  }
  .m-xs-l-130 {
    margin-left: 130px !important;
  }
  .p-xs-l-130 {
    padding-left: 130px !important;
  }
  .m-xs-r-130 {
    margin-right: 130px !important;
  }
  .p-xs-r-130 {
    padding-right: 130px !important;
  }
  .min-xs-w-140 {
    min-width: 140px !important;
  }
  .max-xs-w-140 {
    max-width: 140px !important;
  }
  .sticky-menu.sticky-xs-padding-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .m-xs-t-b-140 {
    margin-top: 140px !important;
    margin-bottom: 140px !important;
  }
  .p-xs-t-b-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .m-xs-l-r-140 {
    margin-left: 140px !important;
    margin-right: 140px !important;
  }
  .p-xs-l-r-140 {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .m-xs-all-140 {
    margin: 140px !important;
  }
  .p-xs-all-140 {
    padding: 140px !important;
  }
  .m-xs-t-140 {
    margin-top: 140px !important;
  }
  .p-xs-t-140 {
    padding-top: 140px !important;
  }
  .m-xs-b-140 {
    margin-bottom: 140px !important;
  }
  .p-xs-b-140 {
    padding-bottom: 140px !important;
  }
  .m-xs-l-140 {
    margin-left: 140px !important;
  }
  .p-xs-l-140 {
    padding-left: 140px !important;
  }
  .m-xs-r-140 {
    margin-right: 140px !important;
  }
  .p-xs-r-140 {
    padding-right: 140px !important;
  }
  .min-xs-w-150 {
    min-width: 150px !important;
  }
  .max-xs-w-150 {
    max-width: 150px !important;
  }
  .sticky-menu.sticky-xs-padding-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .m-xs-t-b-150 {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .p-xs-t-b-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .m-xs-l-r-150 {
    margin-left: 150px !important;
    margin-right: 150px !important;
  }
  .p-xs-l-r-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .m-xs-all-150 {
    margin: 150px !important;
  }
  .p-xs-all-150 {
    padding: 150px !important;
  }
  .m-xs-t-150 {
    margin-top: 150px !important;
  }
  .p-xs-t-150 {
    padding-top: 150px !important;
  }
  .m-xs-b-150 {
    margin-bottom: 150px !important;
  }
  .p-xs-b-150 {
    padding-bottom: 150px !important;
  }
  .m-xs-l-150 {
    margin-left: 150px !important;
  }
  .p-xs-l-150 {
    padding-left: 150px !important;
  }
  .m-xs-r-150 {
    margin-right: 150px !important;
  }
  .p-xs-r-150 {
    padding-right: 150px !important;
  }
  .min-xs-w-175 {
    min-width: 175px !important;
  }
  .max-xs-w-175 {
    max-width: 175px !important;
  }
  .sticky-menu.sticky-xs-padding-175 {
    padding-top: 175px !important;
    padding-bottom: 175px !important;
  }
  .m-xs-t-b-175 {
    margin-top: 175px !important;
    margin-bottom: 175px !important;
  }
  .p-xs-t-b-175 {
    padding-top: 175px !important;
    padding-bottom: 175px !important;
  }
  .m-xs-l-r-175 {
    margin-left: 175px !important;
    margin-right: 175px !important;
  }
  .p-xs-l-r-175 {
    padding-left: 175px !important;
    padding-right: 175px !important;
  }
  .m-xs-all-175 {
    margin: 175px !important;
  }
  .p-xs-all-175 {
    padding: 175px !important;
  }
  .m-xs-t-175 {
    margin-top: 175px !important;
  }
  .p-xs-t-175 {
    padding-top: 175px !important;
  }
  .m-xs-b-175 {
    margin-bottom: 175px !important;
  }
  .p-xs-b-175 {
    padding-bottom: 175px !important;
  }
  .m-xs-l-175 {
    margin-left: 175px !important;
  }
  .p-xs-l-175 {
    padding-left: 175px !important;
  }
  .m-xs-r-175 {
    margin-right: 175px !important;
  }
  .p-xs-r-175 {
    padding-right: 175px !important;
  }
  .min-xs-w-200 {
    min-width: 200px !important;
  }
  .max-xs-w-200 {
    max-width: 200px !important;
  }
  .sticky-menu.sticky-xs-padding-200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .m-xs-t-b-200 {
    margin-top: 200px !important;
    margin-bottom: 200px !important;
  }
  .p-xs-t-b-200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .m-xs-l-r-200 {
    margin-left: 200px !important;
    margin-right: 200px !important;
  }
  .p-xs-l-r-200 {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .m-xs-all-200 {
    margin: 200px !important;
  }
  .p-xs-all-200 {
    padding: 200px !important;
  }
  .m-xs-t-200 {
    margin-top: 200px !important;
  }
  .p-xs-t-200 {
    padding-top: 200px !important;
  }
  .m-xs-b-200 {
    margin-bottom: 200px !important;
  }
  .p-xs-b-200 {
    padding-bottom: 200px !important;
  }
  .m-xs-l-200 {
    margin-left: 200px !important;
  }
  .p-xs-l-200 {
    padding-left: 200px !important;
  }
  .m-xs-r-200 {
    margin-right: 200px !important;
  }
  .p-xs-r-200 {
    padding-right: 200px !important;
  }
  .min-xs-w-225 {
    min-width: 225px !important;
  }
  .max-xs-w-225 {
    max-width: 225px !important;
  }
  .sticky-menu.sticky-xs-padding-225 {
    padding-top: 225px !important;
    padding-bottom: 225px !important;
  }
  .m-xs-t-b-225 {
    margin-top: 225px !important;
    margin-bottom: 225px !important;
  }
  .p-xs-t-b-225 {
    padding-top: 225px !important;
    padding-bottom: 225px !important;
  }
  .m-xs-l-r-225 {
    margin-left: 225px !important;
    margin-right: 225px !important;
  }
  .p-xs-l-r-225 {
    padding-left: 225px !important;
    padding-right: 225px !important;
  }
  .m-xs-all-225 {
    margin: 225px !important;
  }
  .p-xs-all-225 {
    padding: 225px !important;
  }
  .m-xs-t-225 {
    margin-top: 225px !important;
  }
  .p-xs-t-225 {
    padding-top: 225px !important;
  }
  .m-xs-b-225 {
    margin-bottom: 225px !important;
  }
  .p-xs-b-225 {
    padding-bottom: 225px !important;
  }
  .m-xs-l-225 {
    margin-left: 225px !important;
  }
  .p-xs-l-225 {
    padding-left: 225px !important;
  }
  .m-xs-r-225 {
    margin-right: 225px !important;
  }
  .p-xs-r-225 {
    padding-right: 225px !important;
  }
  .min-xs-w-240 {
    min-width: 240px !important;
  }
  .max-xs-w-240 {
    max-width: 240px !important;
  }
  .sticky-menu.sticky-xs-padding-240 {
    padding-top: 240px !important;
    padding-bottom: 240px !important;
  }
  .m-xs-t-b-240 {
    margin-top: 240px !important;
    margin-bottom: 240px !important;
  }
  .p-xs-t-b-240 {
    padding-top: 240px !important;
    padding-bottom: 240px !important;
  }
  .m-xs-l-r-240 {
    margin-left: 240px !important;
    margin-right: 240px !important;
  }
  .p-xs-l-r-240 {
    padding-left: 240px !important;
    padding-right: 240px !important;
  }
  .m-xs-all-240 {
    margin: 240px !important;
  }
  .p-xs-all-240 {
    padding: 240px !important;
  }
  .m-xs-t-240 {
    margin-top: 240px !important;
  }
  .p-xs-t-240 {
    padding-top: 240px !important;
  }
  .m-xs-b-240 {
    margin-bottom: 240px !important;
  }
  .p-xs-b-240 {
    padding-bottom: 240px !important;
  }
  .m-xs-l-240 {
    margin-left: 240px !important;
  }
  .p-xs-l-240 {
    padding-left: 240px !important;
  }
  .m-xs-r-240 {
    margin-right: 240px !important;
  }
  .p-xs-r-240 {
    padding-right: 240px !important;
  }
  .min-xs-w-250 {
    min-width: 250px !important;
  }
  .max-xs-w-250 {
    max-width: 250px !important;
  }
  .sticky-menu.sticky-xs-padding-250 {
    padding-top: 250px !important;
    padding-bottom: 250px !important;
  }
  .m-xs-t-b-250 {
    margin-top: 250px !important;
    margin-bottom: 250px !important;
  }
  .p-xs-t-b-250 {
    padding-top: 250px !important;
    padding-bottom: 250px !important;
  }
  .m-xs-l-r-250 {
    margin-left: 250px !important;
    margin-right: 250px !important;
  }
  .p-xs-l-r-250 {
    padding-left: 250px !important;
    padding-right: 250px !important;
  }
  .m-xs-all-250 {
    margin: 250px !important;
  }
  .p-xs-all-250 {
    padding: 250px !important;
  }
  .m-xs-t-250 {
    margin-top: 250px !important;
  }
  .p-xs-t-250 {
    padding-top: 250px !important;
  }
  .m-xs-b-250 {
    margin-bottom: 250px !important;
  }
  .p-xs-b-250 {
    padding-bottom: 250px !important;
  }
  .m-xs-l-250 {
    margin-left: 250px !important;
  }
  .p-xs-l-250 {
    padding-left: 250px !important;
  }
  .m-xs-r-250 {
    margin-right: 250px !important;
  }
  .p-xs-r-250 {
    padding-right: 250px !important;
  }
  .min-xs-w-300 {
    min-width: 300px !important;
  }
  .max-xs-w-300 {
    max-width: 300px !important;
  }
  .sticky-menu.sticky-xs-padding-300 {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .m-xs-t-b-300 {
    margin-top: 300px !important;
    margin-bottom: 300px !important;
  }
  .p-xs-t-b-300 {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .m-xs-l-r-300 {
    margin-left: 300px !important;
    margin-right: 300px !important;
  }
  .p-xs-l-r-300 {
    padding-left: 300px !important;
    padding-right: 300px !important;
  }
  .m-xs-all-300 {
    margin: 300px !important;
  }
  .p-xs-all-300 {
    padding: 300px !important;
  }
  .m-xs-t-300 {
    margin-top: 300px !important;
  }
  .p-xs-t-300 {
    padding-top: 300px !important;
  }
  .m-xs-b-300 {
    margin-bottom: 300px !important;
  }
  .p-xs-b-300 {
    padding-bottom: 300px !important;
  }
  .m-xs-l-300 {
    margin-left: 300px !important;
  }
  .p-xs-l-300 {
    padding-left: 300px !important;
  }
  .m-xs-r-300 {
    margin-right: 300px !important;
  }
  .p-xs-r-300 {
    padding-right: 300px !important;
  }
  .min-xs-w-350 {
    min-width: 350px !important;
  }
  .max-xs-w-350 {
    max-width: 350px !important;
  }
  .sticky-menu.sticky-xs-padding-350 {
    padding-top: 350px !important;
    padding-bottom: 350px !important;
  }
  .m-xs-t-b-350 {
    margin-top: 350px !important;
    margin-bottom: 350px !important;
  }
  .p-xs-t-b-350 {
    padding-top: 350px !important;
    padding-bottom: 350px !important;
  }
  .m-xs-l-r-350 {
    margin-left: 350px !important;
    margin-right: 350px !important;
  }
  .p-xs-l-r-350 {
    padding-left: 350px !important;
    padding-right: 350px !important;
  }
  .m-xs-all-350 {
    margin: 350px !important;
  }
  .p-xs-all-350 {
    padding: 350px !important;
  }
  .m-xs-t-350 {
    margin-top: 350px !important;
  }
  .p-xs-t-350 {
    padding-top: 350px !important;
  }
  .m-xs-b-350 {
    margin-bottom: 350px !important;
  }
  .p-xs-b-350 {
    padding-bottom: 350px !important;
  }
  .m-xs-l-350 {
    margin-left: 350px !important;
  }
  .p-xs-l-350 {
    padding-left: 350px !important;
  }
  .m-xs-r-350 {
    margin-right: 350px !important;
  }
  .p-xs-r-350 {
    padding-right: 350px !important;
  }
}

/**
* = 2. Button Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_default_btn {
  border-radius: 5px;
  background-color: rgba(182, 190, 218, 0.4);
  color: #4A516A;
  font-weight: bold;
  font-size: 0.875em;
  padding: 13px 25px;
  border: 0;
  text-align: center;
  display: inline-block;
  line-height: 1;
  margin-right: 25px;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.st_default_btn:hover, .st_default_btn:focus, .st_default_btn:active {
  background-color: rgba(182, 190, 218, 0.6);
}

.st_default_btn span i {
  margin-right: 5px;
}

.st_default_btn span.st-btn-tag {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  background-color: #3AD984;
  padding: 8px 10px;
  border-radius: 4px;
  -webkit-transform: translate(50%, -14px);
  -ms-transform: translate(50%, -14px);
  -moz-transform: translate(50%, -14px);
  -o-transform: translate(50%, -14px);
  transform: translate(50%, -14px);
  font-size: 0.625rem;
}

@media only screen and (max-width: 575.98px) {
  .st_default_btn {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.st_default_btn:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.st_default_btn.st_transparent {
  background: transparent;
  color: #FFFFFF;
  border: 1px solid currentColor;
  box-shadow: none;
}

.st_default_btn.st_transparent:hover {
  color: #FFFFFF !important;
  box-shadow: none;
}

.st_default_btn.st_plain_text {
  background: transparent;
  color: #FFFFFF;
  border: none;
  box-shadow: none;
  text-shadow: none;
}

.st_default_btn.st_plain_text:hover {
  box-shadow: none;
}

.st_default_btn.st_icon_btn {
  background: transparent;
  color: #FFFFFF;
  border: none;
  box-shadow: none;
  text-shadow: none;
  padding: 0;
}

.st_default_btn.st_icon_btn:hover {
  color: #FFFFFF !important;
  box-shadow: none;
}

.st_default_btn.st_icon_btn span {
  background-color: #FFFFFF;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 100%;
  display: inline-block;
  margin-right: 10px;
}

.st_default_btn.st_icon_btn span i {
  color: #4A516A;
  font-style: 0.8em;
  margin-right: 0;
}

.st_default_btn.st_icon_btn span img {
  width: auto;
  max-width: 100%;
  display: inline-block;
}

.st_default_btn.st_icon_btn p {
  font-size: .813em;
  font-weight: 600;
  color: #FFFFFF;
  display: inline-block;
  margin: 0;
}

.st_default_btn.bg-gradient {
  background: #2bbcff;
  background: linear-gradient(45deg, #2bbcff 0%, #2ad9d3 50%, #2bbcff 100%);
  background-size: 200% 100%;
  background-position: left center;
  color: #FFFFFF;
}

.st_default_btn.bg-gradient:hover, .st_default_btn.bg-gradient:focus, .st_default_btn.bg-gradient:active {
  background-position: right center;
}

.st_default_btn.bg-emerald-gredient:hover, .st_default_btn.bg-emerald-gredient:focus, .st_default_btn.bg-emerald-gredient:active {
  color: #FFFFFF;
}

.st_default_btn.st_btn_shadow {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.st_default_btn.st_btn_large_shadow {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.12);
}

.st_default_btn.st_btn_medium_shadow {
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.06);
}

.st_default_btn.st_btn_round {
  border-radius: 50px;
}

.st_default_btn.st_btn_square {
  border-radius: 0;
}

.st_default_btn:last-child {
  margin: 0;
}

.st_default_btn.st_default_btn_extra_pd {
  padding-right: 40px;
  padding-left: 40px;
}

.st_default_btn.st_default_btn_full,
.st_default_btn_full {
  width: 100%;
}

.app_store img,
.st_google_paly img {
  max-width: 132px;
  width: 100%;
  display: inline-block;
}

/**
* = 3. Header Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
header.st_header_area {
  z-index: 1;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0;
  border-bottom: 1px solid rgba(182, 190, 218, 0.4);
  background-color: #FFFFFF;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

header.st_header_area .st_default_btn {
  margin-left: 30px;
}

@media only screen and (max-width: 991.98px) {
  header.st_header_area {
    padding: 5px 0;
  }
}

.st_header-wrapper header.st_header_area {
  position: absolute;
}

.st_menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.st_menu ul > li {
  display: inline-block;
  margin: 0 22px;
  text-transform: capitalize;
  position: relative;
}

.st_menu ul > li:first-child {
  margin-left: 0;
}

.st_menu ul > li:last-child {
  margin-right: 0;
}

.st_menu ul > li > a:not(.st_default_btn) {
  display: block;
  padding: 1.875em 0;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.st_menu ul > li > a:not(.st_default_btn) > i {
  position: relative;
  top: 0;
  left: 3px;
  font-size: 0.8em;
}

@media only screen and (max-width: 991.98px) {
  .st_menu ul > li > a:not(.st_default_btn) > i {
    display: none;
  }
}

.st_menu ul > li a:not(.st_default_btn) {
  color: #4A516A;
}

.st_menu ul > li a:not(.st_default_btn):hover {
  color: #00E3F6;
}

@media only screen and (max-width: 1199.98px) {
  .st_menu ul > li {
    margin: 0 15px;
  }
}

.st_menu ul > li ul {
  position: absolute;
  list-style: none;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.04);
  background-color: #fff;
  margin: 0;
  padding: 0;
  width: 11rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(0, 10px);
  -ms-transform: translate(0, 10px);
  -moz-transform: translate(0, 10px);
  -o-transform: translate(0, 10px);
  transform: translate(0, 10px);
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.st_menu ul > li ul li {
  margin: 0;
  display: block;
  float: none;
  width: 100%;
}

.st_menu ul > li ul li a {
  color: #4A516A !important;
  padding: 0.375rem 1rem  !important;
  font-size: 14px;
  font-size: 0.875rem;
}

.st_menu ul > li ul li a:hover {
  color: #140F30 !important;
}

.st_menu ul > li ul li.st-has-submenu > a::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f054";
  position: absolute;
  right: 0.625em;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

@media only screen and (max-width: 991.98px) {
  .st_menu ul > li ul li.st-has-submenu > a::after {
    display: none;
  }
}

.st_menu ul > li ul::before, .st_menu ul > li ul::after {
  content: "";
  display: table;
  clear: both;
}

.st_menu ul > li ul.nav-col-2 {
  width: 22rem;
}

.st_menu ul > li ul.nav-col-2 li {
  float: left;
  width: 50%;
}

.st_menu ul > li ul.nav-col-3 {
  width: 33rem;
}

.st_menu ul > li ul.nav-col-3 li {
  float: left;
  width: 33.33333%;
}

.st_menu ul > li ul.nav-col-4 {
  width: 44rem;
}

.st_menu ul > li ul.nav-col-4 li {
  float: left;
  width: 25%;
}

.st_menu ul > li ul ul {
  left: 11rem;
  top: 0;
}

.st_menu ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

@media only screen and (max-width: 991.98px) {
  .st_menu {
    display: none;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_menu {
    display: none;
  }
}

header.sticky-menu {
  padding: 0;
  z-index: 999;
  position: fixed !important;
  top: 0;
}

header.sticky-menu .st_menu ul > li > a:not(.st_default_btn) {
  padding: 1.25rem 0;
}

@media only screen and (max-width: 991.98px) {
  header.sticky-menu {
    padding: 0;
  }
}

header.st_header_area .st_menu.text-gigas ul li a:not(.st_default_btn) {
  color: #5040A0;
}

header.sticky-menu.sticky-bg-name {
  background-color: #5040A0;
}

header.st_header_area .st_menu.text-hover-gigas ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-gigas ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-gigas ul li a:not(.st_default_btn):active {
  color: #5040A0;
}

header.st_header_area .st_menu.text-perison-blue ul li a:not(.st_default_btn) {
  color: #1E43DD;
}

header.sticky-menu.sticky-bg-name {
  background-color: #1E43DD;
}

header.st_header_area .st_menu.text-hover-perison-blue ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-perison-blue ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-perison-blue ul li a:not(.st_default_btn):active {
  color: #1E43DD;
}

header.st_header_area .st_menu.text-st-aqua ul li a:not(.st_default_btn) {
  color: #00E3F6;
}

header.sticky-menu.sticky-bg-name {
  background-color: #00E3F6;
}

header.st_header_area .st_menu.text-hover-st-aqua ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-st-aqua ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-st-aqua ul li a:not(.st_default_btn):active {
  color: #00E3F6;
}

header.st_header_area .st_menu.text-shamrock ul li a:not(.st_default_btn) {
  color: #3AD984;
}

header.sticky-menu.sticky-bg-name {
  background-color: #3AD984;
}

header.st_header_area .st_menu.text-hover-shamrock ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-shamrock ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-shamrock ul li a:not(.st_default_btn):active {
  color: #3AD984;
}

header.st_header_area .st_menu.text-yellow-orange ul li a:not(.st_default_btn) {
  color: #FFAA3B;
}

header.sticky-menu.sticky-bg-name {
  background-color: #FFAA3B;
}

header.st_header_area .st_menu.text-hover-yellow-orange ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-yellow-orange ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-yellow-orange ul li a:not(.st_default_btn):active {
  color: #FFAA3B;
}

header.st_header_area .st_menu.text-st-pink ul li a:not(.st_default_btn) {
  color: #F23F79;
}

header.sticky-menu.sticky-bg-name {
  background-color: #F23F79;
}

header.st_header_area .st_menu.text-hover-st-pink ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-st-pink ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-st-pink ul li a:not(.st_default_btn):active {
  color: #F23F79;
}

header.st_header_area .st_menu.text-st-orange ul li a:not(.st_default_btn) {
  color: #FF5D1C;
}

header.sticky-menu.sticky-bg-name {
  background-color: #FF5D1C;
}

header.st_header_area .st_menu.text-hover-st-orange ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-st-orange ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-st-orange ul li a:not(.st_default_btn):active {
  color: #FF5D1C;
}

header.st_header_area .st_menu.text-science-blue ul li a:not(.st_default_btn) {
  color: #0366D6;
}

header.sticky-menu.sticky-bg-name {
  background-color: #0366D6;
}

header.st_header_area .st_menu.text-hover-science-blue ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-science-blue ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-science-blue ul li a:not(.st_default_btn):active {
  color: #0366D6;
}

header.st_header_area .st_menu.text-emerald ul li a:not(.st_default_btn) {
  color: #38D16A;
}

header.sticky-menu.sticky-bg-name {
  background-color: #38D16A;
}

header.st_header_area .st_menu.text-hover-emerald ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-emerald ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-emerald ul li a:not(.st_default_btn):active {
  color: #38D16A;
}

header.st_header_area .st_menu.text-chambray ul li a:not(.st_default_btn) {
  color: #3B3E99;
}

header.sticky-menu.sticky-bg-name {
  background-color: #3B3E99;
}

header.st_header_area .st_menu.text-hover-chambray ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-chambray ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-chambray ul li a:not(.st_default_btn):active {
  color: #3B3E99;
}

header.st_header_area .st_menu.text-medium-purple ul li a:not(.st_default_btn) {
  color: #8066DC;
}

header.sticky-menu.sticky-bg-name {
  background-color: #8066DC;
}

header.st_header_area .st_menu.text-hover-medium-purple ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-medium-purple ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-medium-purple ul li a:not(.st_default_btn):active {
  color: #8066DC;
}

header.st_header_area .st_menu.text-lochmara ul li a:not(.st_default_btn) {
  color: #0079BF;
}

header.sticky-menu.sticky-bg-name {
  background-color: #0079BF;
}

header.st_header_area .st_menu.text-hover-lochmara ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-lochmara ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-lochmara ul li a:not(.st_default_btn):active {
  color: #0079BF;
}

header.st_header_area .st_menu.text-froly ul li a:not(.st_default_btn) {
  color: #F46F6C;
}

header.sticky-menu.sticky-bg-name {
  background-color: #F46F6C;
}

header.st_header_area .st_menu.text-hover-froly ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-froly ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-froly ul li a:not(.st_default_btn):active {
  color: #F46F6C;
}

header.st_header_area .st_menu.text-alpha-froly ul li a:not(.st_default_btn) {
  color: rgba(244, 111, 108, 0.1);
}

header.sticky-menu.sticky-bg-name {
  background-color: rgba(244, 111, 108, 0.1);
}

header.st_header_area .st_menu.text-hover-alpha-froly ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-alpha-froly ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-alpha-froly ul li a:not(.st_default_btn):active {
  color: rgba(244, 111, 108, 0.1);
}

header.st_header_area .st_menu.text-sweet-pink ul li a:not(.st_default_btn) {
  color: #FFA3A1;
}

header.sticky-menu.sticky-bg-name {
  background-color: #FFA3A1;
}

header.st_header_area .st_menu.text-hover-sweet-pink ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-sweet-pink ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-sweet-pink ul li a:not(.st_default_btn):active {
  color: #FFA3A1;
}

header.st_header_area .st_menu.text-koamaru ul li a:not(.st_default_btn) {
  color: #1D1068;
}

header.sticky-menu.sticky-bg-name {
  background-color: #1D1068;
}

header.st_header_area .st_menu.text-hover-koamaru ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-koamaru ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-koamaru ul li a:not(.st_default_btn):active {
  color: #1D1068;
}

header.st_header_area .st_menu.text-haiti ul li a:not(.st_default_btn) {
  color: #140F30;
}

header.sticky-menu.sticky-bg-name {
  background-color: #140F30;
}

header.st_header_area .st_menu.text-hover-haiti ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-haiti ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-haiti ul li a:not(.st_default_btn):active {
  color: #140F30;
}

header.st_header_area .st_menu.text-magnolia ul li a:not(.st_default_btn) {
  color: #F9F8FF;
}

header.sticky-menu.sticky-bg-name {
  background-color: #F9F8FF;
}

header.st_header_area .st_menu.text-hover-magnolia ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-magnolia ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-magnolia ul li a:not(.st_default_btn):active {
  color: #F9F8FF;
}

header.st_header_area .st_menu.text-alice-blue ul li a:not(.st_default_btn) {
  color: #F5FBFF;
}

header.sticky-menu.sticky-bg-name {
  background-color: #F5FBFF;
}

header.st_header_area .st_menu.text-hover-alice-blue ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-alice-blue ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-alice-blue ul li a:not(.st_default_btn):active {
  color: #F5FBFF;
}

header.st_header_area .st_menu.text-polar ul li a:not(.st_default_btn) {
  color: #F5F9FD;
}

header.sticky-menu.sticky-bg-name {
  background-color: #F5F9FD;
}

header.st_header_area .st_menu.text-hover-polar ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-polar ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-polar ul li a:not(.st_default_btn):active {
  color: #F5F9FD;
}

header.st_header_area .st_menu.text-st-primary ul li a:not(.st_default_btn) {
  color: #4A516A;
}

header.sticky-menu.sticky-bg-name {
  background-color: #4A516A;
}

header.st_header_area .st_menu.text-hover-st-primary ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-st-primary ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-st-primary ul li a:not(.st_default_btn):active {
  color: #4A516A;
}

header.st_header_area .st_menu.text-st-white ul li a:not(.st_default_btn) {
  color: #FFFFFF;
}

header.sticky-menu.sticky-bg-name {
  background-color: #FFFFFF;
}

header.st_header_area .st_menu.text-hover-st-white ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-st-white ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-st-white ul li a:not(.st_default_btn):active {
  color: #FFFFFF;
}

header.st_header_area .st_menu.text-st-black ul li a:not(.st_default_btn) {
  color: #000000;
}

header.sticky-menu.sticky-bg-name {
  background-color: #000000;
}

header.st_header_area .st_menu.text-hover-st-black ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-st-black ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-st-black ul li a:not(.st_default_btn):active {
  color: #000000;
}

header.st_header_area .st_menu.text-st-fog ul li a:not(.st_default_btn) {
  color: #D6CCFF;
}

header.sticky-menu.sticky-bg-name {
  background-color: #D6CCFF;
}

header.st_header_area .st_menu.text-hover-st-fog ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-st-fog ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-st-fog ul li a:not(.st_default_btn):active {
  color: #D6CCFF;
}

header.st_header_area .st_menu.text-st-gray ul li a:not(.st_default_btn) {
  color: #999999;
}

header.sticky-menu.sticky-bg-name {
  background-color: #999999;
}

header.st_header_area .st_menu.text-hover-st-gray ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-st-gray ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-st-gray ul li a:not(.st_default_btn):active {
  color: #999999;
}

header.st_header_area .st_menu.text-st-concrete ul li a:not(.st_default_btn) {
  color: #F3F3F3;
}

header.sticky-menu.sticky-bg-name {
  background-color: #F3F3F3;
}

header.st_header_area .st_menu.text-hover-st-concrete ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-st-concrete ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-st-concrete ul li a:not(.st_default_btn):active {
  color: #F3F3F3;
}

header.st_header_area .st_menu.text-st-violet ul li a:not(.st_default_btn) {
  color: #D52EEF;
}

header.sticky-menu.sticky-bg-name {
  background-color: #D52EEF;
}

header.st_header_area .st_menu.text-hover-st-violet ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-st-violet ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-st-violet ul li a:not(.st_default_btn):active {
  color: #D52EEF;
}

header.st_header_area .st_menu.text-sun-glow ul li a:not(.st_default_btn) {
  color: #FCCC2C;
}

header.sticky-menu.sticky-bg-name {
  background-color: #FCCC2C;
}

header.st_header_area .st_menu.text-hover-sun-glow ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-sun-glow ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-sun-glow ul li a:not(.st_default_btn):active {
  color: #FCCC2C;
}

header.st_header_area .st_menu.text-st-cabaret ul li a:not(.st_default_btn) {
  color: #D1386C;
}

header.sticky-menu.sticky-bg-name {
  background-color: #D1386C;
}

header.st_header_area .st_menu.text-hover-st-cabaret ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-st-cabaret ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-st-cabaret ul li a:not(.st_default_btn):active {
  color: #D1386C;
}

header.st_header_area .st_menu.text-st-zircon ul li a:not(.st_default_btn) {
  color: #F8FAFF;
}

header.sticky-menu.sticky-bg-name {
  background-color: #F8FAFF;
}

header.st_header_area .st_menu.text-hover-st-zircon ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-st-zircon ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-st-zircon ul li a:not(.st_default_btn):active {
  color: #F8FAFF;
}

header.st_header_area .st_menu.text-clear-day ul li a:not(.st_default_btn) {
  color: #F9FFFD;
}

header.sticky-menu.sticky-bg-name {
  background-color: #F9FFFD;
}

header.st_header_area .st_menu.text-hover-clear-day ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-clear-day ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-clear-day ul li a:not(.st_default_btn):active {
  color: #F9FFFD;
}

header.st_header_area .st_menu.text-st-shamrock ul li a:not(.st_default_btn) {
  color: #3AD984;
}

header.sticky-menu.sticky-bg-name {
  background-color: #3AD984;
}

header.st_header_area .st_menu.text-hover-st-shamrock ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-st-shamrock ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-st-shamrock ul li a:not(.st_default_btn):active {
  color: #3AD984;
}

header.st_header_area .st_menu.text-blue-bell ul li a:not(.st_default_btn) {
  color: #9D9ECE;
}

header.sticky-menu.sticky-bg-name {
  background-color: #9D9ECE;
}

header.st_header_area .st_menu.text-hover-blue-bell ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-blue-bell ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-blue-bell ul li a:not(.st_default_btn):active {
  color: #9D9ECE;
}

header.st_header_area .st_menu.text-snuff ul li a:not(.st_default_btn) {
  color: #DBD7E9;
}

header.sticky-menu.sticky-bg-name {
  background-color: #DBD7E9;
}

header.st_header_area .st_menu.text-hover-snuff ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-snuff ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-snuff ul li a:not(.st_default_btn):active {
  color: #DBD7E9;
}

header.st_header_area .st_menu.text-french-grey ul li a:not(.st_default_btn) {
  color: #C3C3C7;
}

header.sticky-menu.sticky-bg-name {
  background-color: #C3C3C7;
}

header.st_header_area .st_menu.text-hover-french-grey ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-french-grey ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-french-grey ul li a:not(.st_default_btn):active {
  color: #C3C3C7;
}

header.st_header_area .st_menu.text-ebony-clay ul li a:not(.st_default_btn) {
  color: #1F2833;
}

header.sticky-menu.sticky-bg-name {
  background-color: #1F2833;
}

header.st_header_area .st_menu.text-hover-ebony-clay ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-ebony-clay ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-ebony-clay ul li a:not(.st_default_btn):active {
  color: #1F2833;
}

header.st_header_area .st_menu.text-black-rock ul li a:not(.st_default_btn) {
  color: #080327;
}

header.sticky-menu.sticky-bg-name {
  background-color: #080327;
}

header.st_header_area .st_menu.text-hover-black-rock ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-black-rock ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-black-rock ul li a:not(.st_default_btn):active {
  color: #080327;
}

header.st_header_area .st_menu.text-st-lavender ul li a:not(.st_default_btn) {
  color: #B4B5D5;
}

header.sticky-menu.sticky-bg-name {
  background-color: #B4B5D5;
}

header.st_header_area .st_menu.text-hover-st-lavender ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-st-lavender ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-st-lavender ul li a:not(.st_default_btn):active {
  color: #B4B5D5;
}

header.st_header_area .st_menu.text-titan-white ul li a:not(.st_default_btn) {
  color: #F5F5FF;
}

header.sticky-menu.sticky-bg-name {
  background-color: #F5F5FF;
}

header.st_header_area .st_menu.text-hover-titan-white ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-titan-white ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-titan-white ul li a:not(.st_default_btn):active {
  color: #F5F5FF;
}

header.st_header_area .st_menu.text-regent ul li a:not(.st_default_btn) {
  color: #8D9BA4;
}

header.sticky-menu.sticky-bg-name {
  background-color: #8D9BA4;
}

header.st_header_area .st_menu.text-hover-regent ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-regent ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-regent ul li a:not(.st_default_btn):active {
  color: #8D9BA4;
}

header.st_header_area .st_menu.text-shhamrock ul li a:not(.st_default_btn) {
  color: #40D1A7;
}

header.sticky-menu.sticky-bg-name {
  background-color: #40D1A7;
}

header.st_header_area .st_menu.text-hover-shhamrock ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-shhamrock ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-shhamrock ul li a:not(.st_default_btn):active {
  color: #40D1A7;
}

header.st_header_area .st_menu.text-gray-light ul li a:not(.st_default_btn) {
  color: #f0f2f8;
}

header.sticky-menu.sticky-bg-name {
  background-color: #f0f2f8;
}

header.st_header_area .st_menu.text-hover-gray-light ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-gray-light ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-gray-light ul li a:not(.st_default_btn):active {
  color: #f0f2f8;
}

header.st_header_area .st_menu.text-gray ul li a:not(.st_default_btn) {
  color: #b6beda;
}

header.sticky-menu.sticky-bg-name {
  background-color: #b6beda;
}

header.st_header_area .st_menu.text-hover-gray ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-gray ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-gray ul li a:not(.st_default_btn):active {
  color: #b6beda;
}

header.st_header_area .st_menu.text-gray-dark ul li a:not(.st_default_btn) {
  color: #2f3447;
}

header.sticky-menu.sticky-bg-name {
  background-color: #2f3447;
}

header.st_header_area .st_menu.text-hover-gray-dark ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-gray-dark ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-gray-dark ul li a:not(.st_default_btn):active {
  color: #2f3447;
}

header.st_header_area .st_menu.text-blue-darker ul li a:not(.st_default_btn) {
  color: #3f455c;
}

header.sticky-menu.sticky-bg-name {
  background-color: #3f455c;
}

header.st_header_area .st_menu.text-hover-blue-darker ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-blue-darker ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-blue-darker ul li a:not(.st_default_btn):active {
  color: #3f455c;
}

header.st_header_area .st_menu.text-blue-dark ul li a:not(.st_default_btn) {
  color: #4a516a;
}

header.sticky-menu.sticky-bg-name {
  background-color: #4a516a;
}

header.st_header_area .st_menu.text-hover-blue-dark ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-blue-dark ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-blue-dark ul li a:not(.st_default_btn):active {
  color: #4a516a;
}

header.st_header_area .st_menu.text-blue ul li a:not(.st_default_btn) {
  color: #2bbcff;
}

header.sticky-menu.sticky-bg-name {
  background-color: #2bbcff;
}

header.st_header_area .st_menu.text-hover-blue ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-blue ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-blue ul li a:not(.st_default_btn):active {
  color: #2bbcff;
}

header.st_header_area .st_menu.text-green ul li a:not(.st_default_btn) {
  color: #2ad9d3;
}

header.sticky-menu.sticky-bg-name {
  background-color: #2ad9d3;
}

header.st_header_area .st_menu.text-hover-green ul li a:not(.st_default_btn):hover,
header.st_header_area .st_menu.text-hover-green ul li a:not(.st_default_btn):focus,
header.st_header_area .st_menu.text-hover-green ul li a:not(.st_default_btn):active {
  color: #2ad9d3;
}

header.st_header_area a.st_default_btn {
  margin: 0;
}

.st_header_top {
  background-color: #24292E;
  padding: 8px 0;
  display: flex;
  align-items: center;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.st_header_top .st_header_top_content {
  display: inline-block;
}

.st_header_top .st_header_top_content p {
  margin: 0;
}

.mean-container .mean-bar {
  background-color: transparent;
}

.mean-container .mean-nav {
  position: absolute;
  width: calc(100vw - 30px);
  max-width: 500px;
  right: 0;
  margin-top: 56px;
  background: #FFFFFF;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.08);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

@media only screen and (max-width: 767.98px) {
  .mean-container .mean-nav {
    margin-top: 50px;
    top: 0;
    max-height: calc(100vh - 55px);
    overflow: auto;
  }
}

.mean-container .mean-nav ul li a {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  color: #4A516A;
  text-transform: capitalize;
}

.mean-container .mean-nav ul li a.st_default_btn {
  border-radius: 0px !important;
}

.mean-container .mean-nav ul li a.st_default_btn.bg-gradient {
  color: #FFFFFF;
}

.st_header-wrapper {
  position: relative;
  z-index: 999;
}

@media only screen and (max-width: 991.98px) {
  .mean-container.st_nav_wrapper_v2 .mean-bar {
    right: 40px;
  }
  .mean-container.st_nav_wrapper_v2 .mean-bar .mean-nav {
    right: -40px;
  }
  .mean-container.st_nav_wrapper_v2 .st_menu_sign {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 20px;
  }
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  background-color: transparent;
}

@media only screen and (max-width: 991.98px) {
  .mean-nav > ul > li > a > i {
    display: none;
  }
  .st-has-submenu i {
    display: none;
  }
}

/**
* = 4. Footer Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
footer {
  border-top: 1px solid rgba(182, 190, 218, 0.4);
}

.footer-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
}

.footer-menu > li {
  margin-bottom: 10px;
}

.footer-menu > li > a {
  color: rgba(74, 81, 106, 0.6);
  line-height: 24px;
}

.footer-menu > li > a:hover {
  color: #4A516A;
}

.footer-menu.footer-menu-social > li > a {
  display: inline-flex;
  vertical-align: bottom;
}

.footer-menu.footer-menu-social > li > a > img {
  margin-right: 5px;
}

.footer-logo-wrapper p {
  margin-bottom: 30px;
}

.footer-logo-wrapper .about {
  font-weight: 300;
}

.footer-logo-wrapper .copy {
  margin-bottom: 0;
  font-size: 12px;
}

/**
* = 5. Heading / Section Heading Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_section_heding_title {
  text-align: center;
  margin-bottom: 80px;
}

.st_section_heding_title img {
  margin-bottom: 18px;
}

.st_section_heding_title h2 {
  font-size: 34px;
  font-size: 2.125rem;
}

@media only screen and (max-width: 767.98px) {
  .st_section_heding_title h2 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.st_section_heding_title > p {
  margin-bottom: 5px;
}

@media only screen and (max-width: 1199.98px) {
  .st_section_heding_title {
    margin-bottom: 65px;
  }
}

@media only screen and (max-width: 991.98px) {
  .st_section_heding_title {
    margin-bottom: 55px;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_section_heding_title {
    margin-bottom: 45px;
  }
}

.st_section_heding_title.st_white_color h2 {
  color: #fff;
}

.st_section_heding_title.st_white_color p {
  color: #D6D6D6;
}

.st_section_title_icon.bg-icon {
  background-color: rgba(3, 102, 214, 0.1);
  color: #0366D6;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 100%;
  margin: 0 auto;
  margin-bottom: 18px;
}

.st_section_title_icon.bg-icon img {
  margin-bottom: 0;
}

/**
* = 6. Hero Header/Banners Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_banner_area {
  z-index: 0;
  overflow: hidden;
  position: relative;
}

.st_banner_area.st_banner_radius_gradient:before {
  width: 150vw;
  height: 100%;
  position: absolute;
  left: -25vw;
  top: -30px;
  content: "";
  z-index: 0;
  border-radius: 0 0 50% 50%;
  bottom: 50px;
}

@media only screen and (max-width: 1199.98px) {
  .st_banner_area.st_banner_radius_gradient:before {
    top: -51px;
    border-radius: 0 0 42% 42%;
    bottom: 50px;
  }
}

@media only screen and (max-width: 991.98px) {
  .st_banner_area.st_banner_radius_gradient:before {
    width: 170vw;
    left: -35vw;
    top: -7px;
    border-radius: 0 0 42% 42%;
  }
}

@media only screen and (max-width: 991.98px) {
  .st_banner_area.st_banner_radius_gradient:before {
    width: 170vw;
    left: -35vw;
    top: 0;
    border-radius: 0;
  }
}

.st_banner_area .st_banner_shape {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
}

.st_banner_area .st_banner_shape.minus_zinex {
  z-index: -1;
}

.st_banner_aqua_gradient {
  z-index: 0;
  overflow: hidden;
  position: relative;
}

.st_banner_aqua_gradient:before {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  z-index: 0;
}

.st_banner_area.st_banner_aqua_gradient:before {
  width: 200vw;
  height: 100%;
  position: absolute;
  left: -50vw;
  top: 0;
  content: "";
  z-index: 0;
  border-radius: 0 0 50% 50%;
  bottom: 50px;
}

.st_banner_sidebar {
  position: relative;
  z-index: 1;
}

.st_banner_area .st_banner_waraper {
  margin-top: 85px;
  width: 100%;
}

.st_banner_area .st_banner_waraper .st_banner_sidebar:before {
  width: 1030px;
  height: 1030px;
  position: absolute;
  left: 50%;
  top: 0;
  content: "";
  transform: translateX(-50%);
  border: 2px solid rgba(255, 255, 255, 0.14);
  border-radius: 100%;
  z-index: 0;
}

.st_banner_area .st_banner_waraper .st_banner_sidebar .st_banner_single_img_01 {
  position: relative;
  text-align: center;
  z-index: 1;
  width: 760px;
}

@media only screen and (max-width: 991.98px) {
  .st_banner_area .st_banner_waraper .st_banner_sidebar .st_banner_single_img_01 {
    width: 90%;
    margin: 0 auto;
  }
}

.st_banner_area .st_banner_waraper .st_banner_sidebar .st_banner_single_img_01 .st_banner_small_img {
  position: absolute;
  top: 0;
  right: 25%;
  z-index: 1;
  width: 300px;
  -webkit-transform: translateY(-35%);
  -ms-transform: translateY(-35%);
  -moz-transform: translateY(-35%);
  -o-transform: translateY(-35%);
  transform: translateY(-35%);
}

.st_banner_area .st_banner_waraper .st_banner_sidebar .st_banner_single_img_01 .st_banner_small_img img {
  box-shadow: 0 20px 99px rgba(0, 0, 0, 0.19);
  border-radius: 12px;
  overflow: hidden;
}

@media only screen and (max-width: 767.98px) {
  .st_banner_area .st_banner_waraper .st_banner_sidebar .st_banner_single_img_01 .st_banner_small_img img {
    border-radius: 4px;
  }
}

.st_banner_area .st_banner_waraper .st_banner_sidebar .st-banner-single-img-02 {
  margin-left: -8%;
  margin-right: -8%;
}

@media only screen and (max-width: 1199.98px) {
  .st_banner_area .st_banner_waraper .st_banner_sidebar .st-banner-single-img-02 {
    margin-left: 0;
    margin-right: 0;
  }
}

.st_banner_area .st_banner_sidebar_v2.st_banner_sidebar:before {
  width: 1000px;
  height: 1000px;
  position: absolute;
  left: 48%;
  top: -69px;
  content: "";
  transform: translateX(-50%);
}

.st_banner_area .st_banner_sidebar_v2.st_banner_sidebar .st_banner_single_img_01 .st_banner_small_img {
  top: 0;
  right: 0;
  -webkit-transform: translateY(-35%);
  -ms-transform: translateY(-35%);
  -moz-transform: translateY(-35%);
  -o-transform: translateY(-35%);
  transform: translateY(-35%);
}

@media only screen and (max-width: 991.98px) {
  .st_banner_area .st_banner_sidebar_v2.st_banner_sidebar .st_banner_single_img_01 .st_banner_small_img {
    width: 300px;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_banner_area .st_banner_sidebar_v2.st_banner_sidebar .st_banner_single_img_01 .st_banner_small_img {
    width: 230px;
  }
}

@media only screen and (max-width: 575.98px) {
  .st_banner_area .st_banner_sidebar_v2.st_banner_sidebar .st_banner_single_img_01 .st_banner_small_img {
    width: 170px;
  }
}

.st_banner_area .st_banner_waraper.margin_0 {
  margin: 0;
}

.st_banner_content {
  position: relative;
  margin-bottom: 50px;
  z-index: 1;
}

.st_banner_content h2 {
  line-height: 1.4;
}

.st_banner_content p {
  line-height: 2;
  margin: 0 0 50px;
}

.st_banner_content p.margin_bottom_0 {
  margin-bottom: 0;
}

.st_banner_content h3 {
  margin-bottom: 40px;
  line-height: 1.53846;
}

.margin_0.st_banner_content {
  margin: 0;
}

.margin_bottom_80.st_banner_content {
  margin-bottom: 80px;
  margin-right: 40px;
}

.st_btn_group p {
  display: inline-block;
  color: #4A516A;
  font-size: 0.875em;
  margin: 0;
}

.st_btn_group p a {
  color: #5040A0;
  font-weight: 600;
  font-size: 0.813em;
  margin-left: 10px;
  text-transform: uppercase;
}

.st_banner_swiper_slider {
  position: relative;
  max-width: 100%;
  left: 50px;
}

@media only screen and (max-width: 991.98px) {
  .st_banner_swiper_slider {
    width: 100%;
    left: 0;
    margin-bottom: 50px;
  }
}

.st_banner_swiper_slider .st_banner_screenshot {
  overflow: hidden;
  border-left: 15px solid rgba(242, 243, 245, 0.5);
  border-right: 15px solid rgba(242, 243, 245, 0.5);
}

@media only screen and (max-width: 1199.98px) {
  .st_banner_swiper_slider .st_banner_screenshot {
    border-left: none;
    border-right: none;
  }
}

.st_banner_swiper_slider .st_banner_screenshot .swiper-slide.swiper-slide-prev {
  transform: translateX(500px) !important;
  opacity: .85;
}

.st_banner_swiper_slider .st_banner_screenshot .swiper-slide.swiper-slide-next {
  transform: translateX(-500px) !important;
  opacity: .85;
}

.st_banner_swiper_slider .st_banner_screenshot .swiper-slide img {
  width: 100%;
}

.st_banner_swiper_slider .st_banner_screenshot .swiper-slide .swiper-slide-shadow-right, .st_banner_swiper_slider .st_banner_screenshot .swiper-slide .swiper-slide-shadow-left {
  background-image: none;
}

.st_banner_swiper_slider .swiper-pagination {
  bottom: -125px;
  left: -70px;
}

@media only screen and (max-width: 991.98px) {
  .st_banner_swiper_slider .swiper-pagination {
    bottom: -37px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.st_banner_swiper_slider .swiper-pagination-bullet {
  width: 11px;
  height: 11px;
  background: #FFFFFF;
  opacity: 1;
}

.st_banner_swiper_slider .swiper-pagination-bullet-active {
  background: #FE5F1D;
  border: 2px solid #FFFFFF;
  width: 15px;
  height: 15px;
  top: 2px;
  position: relative;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
}

.st_banner_swiper_slider .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}

.st_sprit_falling {
  height: 100%;
  right: 0;
  position: absolute;
  top: 0;
  transform: rotate(-45deg);
  width: 60%;
  z-index: -1;
}

.st_sprit_falling span {
  background: rgba(80, 64, 160, 0.5);
  height: 4px;
  position: absolute;
  width: 69px;
}

.st_sprit_falling span:nth-child(1) {
  animation: 2s linear -5s normal none infinite running lf;
  top: 20%;
}

.st_sprit_falling span:nth-child(2) {
  animation: 1.5s linear -1s normal none infinite running lf2;
  top: 40%;
}

.st_sprit_falling span:nth-child(3) {
  animation: 1s linear 0s normal none infinite running lf3;
  top: 60%;
}

.st_sprit_falling span:nth-child(4) {
  animation: 1.5s linear -3s normal none infinite running lf4;
  top: 80%;
}

@keyframes lf {
  0% {
    left: 200%;
  }
  100% {
    left: -200%;
    opacity: 0;
  }
}

@keyframes lf2 {
  0% {
    left: 200%;
  }
  100% {
    left: -200%;
    opacity: 0;
  }
}

@keyframes lf3 {
  0% {
    left: 200%;
  }
  100% {
    left: -100%;
    opacity: 0;
  }
}

@keyframes lf4 {
  0% {
    left: 200%;
  }
  100% {
    left: -100%;
    opacity: 0;
  }
}

.st-background-wrap {
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  overflow: hidden;
}

.st_banner_tab {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  overflow: hidden;
}

.st_banner_tab .st_custom-tab {
  background: #E8FAEE;
  color: #0366D6;
  border-radius: 0;
  box-shadow: none;
  z-index: 1;
}

.st_banner_tab .st_custom-tab ul.nav.nav-pills a {
  color: #38D16A;
  height: 100%;
  font-size: .813em;
  font-weight: 600;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.st_banner_tab .st_custom-tab ul.nav.nav-pills a.active {
  background-color: #FFFFFF;
  color: #C3C3C7 !important;
}

.st_banner_tab .st_contact_waraper {
  padding: 38px 55px;
  width: 100%;
  position: relative;
  border-radius: 0 0 10px 10px;
}

.st_banner_tab .st_contact_waraper > h3 {
  font-size: 1.125em;
  font-weight: bold;
  margin-bottom: 28px;
}

.st_banner_tab .st_contact_waraper .st_input_item {
  margin: 0 0 20px;
}

.st_banner_tab .st_contact_waraper .st_input_item input {
  background-color: #FBFBFB;
  border: 1px solid #ECECEC;
  box-shadow: none;
}

.st_banner_tab .st_contact_waraper .st_input_item input::-webkit-input-placeholder {
  color: #BEC3CC;
}

.st_banner_tab .st_contact_waraper .st_input_item input::-moz-placeholder {
  color: #BEC3CC;
}

.st_banner_tab .st_contact_waraper .st_input_item input:-ms-input-placeholder {
  color: #BEC3CC;
}

.st_banner_tab .st_contact_waraper .st_input_item input:-moz-placeholder {
  color: #BEC3CC;
}

@media only screen and (max-width: 575.98px) {
  .st_banner_tab .st_contact_waraper {
    padding: 45px 30px;
  }
}

.st_skype {
  color: #FFFFFF;
  font-weight: 500;
  display: inline-block;
  padding: 6px 25px 6px 50px;
  position: relative;
  margin: 0 0 20px;
  line-height: 1;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
}

.st_skype::before {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 20px;
  top: 5px;
  content: "";
  background-color: #38D16A;
  border-radius: 100%;
  box-shadow: 0 3px 10px rgba(56, 209, 106, 0.6);
}

.st_banner_sidebar.st-banner-overlap {
  min-width: 55vw;
  position: absolute;
  left: 50px;
}

@media only screen and (max-width: 991.98px) {
  .st_banner_sidebar.st-banner-overlap {
    position: relative;
    left: inherit;
  }
}

.st_header_shape {
  position: absolute;
  left: 0;
  bottom: 9%;
  width: 9vw;
}

.st_header_shape img {
  width: 100%;
}

.st_banner_shape_group .st_banner_shape_01, .st_banner_shape_group .st_banner_shape_02 {
  position: absolute;
  top: 50%;
  left: 0;
  width: 10vw;
  transform: translateY(-50%);
}

.st_banner_shape_group .st_banner_shape_01 img, .st_banner_shape_group .st_banner_shape_02 img {
  width: 100%;
}

.st_banner_shape_group .st_banner_shape_02 {
  left: auto;
  right: 0;
}

.st_left_shape {
  position: absolute;
  top: 42%;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  -webkit-transform: skewY(-3deg);
  transform: skewY(-3deg);
  -webkit-transform-origin: 0;
  transform-origin: 0;
  z-index: -1;
}

.st_left_shape span {
  position: relative;
  opacity: 0;
  width: 100%;
  margin-bottom: -3px;
  top: 20px;
}

.st_loaded .st_left_shape span {
  -webkit-animation: stripeSlideIn 2s forwards;
  animation: stripeSlideIn 2s forwards;
}

.st_left_shape span:nth-child(1) {
  right: 90%;
  left: auto;
  background: rgba(122, 184, 220, 0.48);
  z-index: 2;
  height: 161px;
}

.st_loaded .st_left_shape span:nth-child(1) {
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
}

.st_left_shape span:nth-child(2) {
  right: 80%;
  bottom: auto;
  background: #7AB8DC;
  z-index: 1;
  height: 166px;
}

.st_loaded .st_left_shape span:nth-child(2) {
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}

.st_left_shape span:nth-child(3) {
  right: 77%;
  background: #3A97CC;
  height: 200px;
  margin-top: -32px;
}

.st_loaded .st_left_shape span:nth-child(3) {
  -webkit-animation-delay: .8s;
  animation-delay: .8s;
}

.left-angle-shape::before {
  width: 100%;
  height: 350px;
  position: absolute;
  left: 0;
  top: auto;
  content: "";
  background-color: #FFFFFF;
  background-size: cover;
  bottom: 0;
  -webkit-clip-path: polygon(-85% 100%, 100% 100%, 100% 0%);
  clip-path: polygon(-85% 100%, 100% 100%, 100% 0%);
}

@supports not ((-webkit-clip-path: polygon(-85% 100%, 100% 100%, 100% 0%)) or (clip-path: polygon(-85% 100%, 100% 100%, 100% 0%))) {
  .left-angle-shape::before {
    -webkit-transform: skewY(-6deg);
    -ms-transform: skewY(-6deg);
    -moz-transform: skewY(-6deg);
    -o-transform: skewY(-6deg);
    transform: skewY(-6deg);
    -webkit-transform-origin: top right;
    -ms-transform-origin: top right;
    -moz-transform-origin: top right;
    -o-transform-origin: top right;
    transform-origin: top right;
  }
}

@media only screen and (max-width: 1199.98px) {
  .left-angle-shape::before {
    -webkit-clip-path: polygon(-185% 100%, 100% 100%, 100% 0%);
    clip-path: polygon(-185% 100%, 100% 100%, 100% 0%);
  }
  @supports not ((-webkit-clip-path: polygon(-185% 100%, 100% 100%, 100% 0%)) or (clip-path: polygon(-185% 100%, 100% 100%, 100% 0%))) {
    .left-angle-shape::before {
      -webkit-transform: skewY(-4deg);
      -ms-transform: skewY(-4deg);
      -moz-transform: skewY(-4deg);
      -o-transform: skewY(-4deg);
      transform: skewY(-4deg);
    }
  }
}

@media only screen and (max-width: 991.98px) {
  .left-angle-shape::before {
    -webkit-clip-path: polygon(-220% 100%, 100% 100%, 100% 0%);
    clip-path: polygon(-220% 100%, 100% 100%, 100% 0%);
  }
  @supports not ((-webkit-clip-path: polygon(-220% 100%, 100% 100%, 100% 0%)) or (clip-path: polygon(-220% 100%, 100% 100%, 100% 0%))) {
    .left-angle-shape::before {
      -webkit-transform: skewY(-2deg);
      -ms-transform: skewY(-2deg);
      -moz-transform: skewY(-2deg);
      -o-transform: skewY(-2deg);
      transform: skewY(-2deg);
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .left-angle-shape::before {
    display: none;
  }
}

.st_left_shape.st_left_shape_2 {
  top: 55%;
  z-index: 2;
}

@media only screen and (max-width: 1199.98px) {
  .st_left_shape.st_left_shape_2 {
    z-index: -1;
  }
}

.banner-black-overley {
  position: relative;
}

.banner-black-overley::before {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background-color: rgba(16, 16, 16, 0.65);
}

.st_banner_info_box {
  background: #FFFFFF;
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.01);
  border-radius: 5px;
  padding: 60px 50px;
}

.st_banner_info_box .st_sidebar_single_item {
  display: flex;
  margin-bottom: 44px;
}

.st_banner_info_box .st_sidebar_single_item:last-child {
  margin: 0;
}

.st_banner_info_box .st_sidebar_single_item:last-child .st_banner_sidebar_content p {
  margin-bottom: 0;
}

.st_banner_info_box .st_sidebar_single_item .st_banner_sidebar_icon {
  margin-right: 25px;
  flex: 0 0 auto;
}

.st_banner_info_box .st_sidebar_single_item .st_banner_sidebar_content h3 {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}

.st_banner_info_box .st_sidebar_single_item .st_banner_sidebar_content p {
  line-height: 2;
}

@media only screen and (max-width: 575.98px) {
  .st_banner_info_box .st_sidebar_single_item {
    display: block;
  }
  .st_banner_info_box .st_sidebar_single_item .st_banner_sidebar_icon {
    margin-right: 0;
    margin-bottom: 25px;
  }
}

.st_banner_with_content {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.st_banner_with_content .st_banner_img_01, .st_banner_with_content .st_banner_img_02, .st_banner_with_content .st_banner_img_03 {
  position: absolute;
  top: 20%;
  left: 13%;
}

.st_banner_with_content .st_banner_img_02 {
  top: 17%;
  left: 50%;
  transform: translateX(-50%);
}

.st_banner_with_content .st_banner_img_03 {
  top: 20%;
  right: 13%;
  left: auto;
}

.banner_before_img::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(../images/v20/banner-mask.png);
  background-repeat: no-repeat;
  background-size: 53%;
  z-index: 0;
}

.st_banner_content.st_banner_content_v2 h1 {
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 24px;
}

.st_banner_content.st_banner_content_v2 h1 span {
  font-size: 60px;
  font-weight: bold;
}

.st_banner_content.st_banner_content_v2 p {
  margin-bottom: 40px;
  font-size: 1.125em;
  color: #DEFAFF;
}

.st_banner_sidebar_before::before {
  width: 300px;
  height: 600px;
  position: absolute;
  left: auto;
  top: 35px;
  content: "";
  background-image: url(../images/v13/mobile-bg.png);
  right: -75px;
  z-index: -1;
}

.st_banner_objects {
  max-width: 600px;
}

.st_banner_objects .st_single_obj {
  position: absolute;
  height: auto;
}

.st_banner_objects .st_single_obj:nth-child(1) {
  top: 18%;
  left: 10%;
  width: 85px;
  animation-name: floating;
  transform-origin: center bottom;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 5s;
  animation-fill-mode: both;
  animation-delay: .3s;
  -webkit-animation-name: floating;
  -webkit-transform-origin: center bottom;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -webkit-animation-delay: .3s;
}

.st_banner_objects .st_single_obj:nth-child(2) {
  top: 30%;
  left: 20px;
  width: 120px;
}

.st_banner_objects .st_single_obj:nth-child(3) {
  top: 39%;
  left: 12%;
  width: 60px;
}

.st_banner_objects .st_single_obj:nth-child(4) {
  bottom: 37%;
  left: 12%;
  width: 80px;
}

.st_banner_objects .st_single_obj:nth-child(5) {
  top: 24%;
  left: 34%;
  width: 90px;
}

.st_banner_objects .st_single_obj:nth-child(6) {
  top: 31%;
  left: 34%;
  width: 80px;
  animation-name: floating;
  transform-origin: center bottom;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 5s;
  animation-fill-mode: both;
  animation-delay: .9s;
  -webkit-animation-name: floating;
  -webkit-transform-origin: center bottom;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -webkit-animation-delay: .9s;
}

.st_banner_objects .st_single_obj:nth-child(7) {
  bottom: 45%;
  left: 28%;
  width: 120px;
}

.st_banner_objects .st_single_obj:nth-child(8) {
  bottom: 31%;
  left: 41%;
  width: 110px;
}

.st_banner_objects .st_single_obj:nth-child(9) {
  top: 27%;
  right: 0;
  width: 130px;
}

.st_banner_objects .st_single_obj:nth-child(10) {
  top: 32%;
  right: 0;
  width: 220px;
  animation-name: floating;
  transform-origin: center bottom;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 5s;
  animation-fill-mode: both;
  animation-delay: 1.8s;
  -webkit-animation-name: floating;
  -webkit-transform-origin: center bottom;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -webkit-animation-delay: 1.8s;
}

.st_banner_objects .st_single_obj:nth-child(11) {
  bottom: 32%;
  right: 20%;
  width: 60px;
}

.owl-carousel.st--hero-slider .owl-item .hero-slider-thumb img {
  width: auto;
  display: inline-block;
}

.owl-carousel.st--hero-slider .owl-nav .owl-prev,
.owl-carousel.st--hero-slider .owl-nav .owl-next {
  border-radius: 100%;
  background-color: #FFFFFF;
  color: #0366D6;
  font-size: 1.5em;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  position: absolute;
  opacity: 1;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  -webkit-text-stroke: 1.5px #F5F9FD;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.owl-carousel.st--hero-slider .owl-nav .owl-prev:hover,
.owl-carousel.st--hero-slider .owl-nav .owl-next:hover {
  background: #0366D6;
  color: #FFFFFF;
  -webkit-text-stroke: 1.5px #0366D6;
}

.owl-carousel.st--hero-slider .owl-nav .owl-prev {
  left: -190px;
}

.owl-carousel.st--hero-slider .owl-nav .owl-next {
  left: inherit;
  right: -190px;
}

@media only screen and (max-width: 1499.98px) {
  .owl-carousel.st--hero-slider .owl-nav .owl-prev {
    left: -90px;
  }
  .owl-carousel.st--hero-slider .owl-nav .owl-next {
    right: -90px;
  }
}

@media only screen and (max-width: 1199.98px) {
  .owl-carousel.st--hero-slider .owl-nav .owl-prev {
    left: calc(50% - 60px);
    top: calc(100% + 80px);
  }
  .owl-carousel.st--hero-slider .owl-nav .owl-next {
    right: calc(50% - 60px);
    top: calc(100% + 80px);
  }
}

@media only screen and (max-width: 991.98px) {
  .owl-carousel.st--hero-slider .owl-nav .owl-prev,
  .owl-carousel.st--hero-slider .owl-nav .owl-next {
    display: none;
  }
}

.height-100vh-full-screen .st--hero-slider {
  margin-top: 64px;
}

@media only screen and (max-width: 991.98px) {
  .height-100vh-full-screen .st--hero-slider {
    margin: 150px 0 120px;
  }
}

@media only screen and (max-width: 767.98px) {
  .height-100vh-full-screen .st--hero-slider {
    margin: 100px 0 50px;
  }
}

@media only screen and (max-width: 991.98px) {
  .st--hero-slider {
    margin: 150px 0 120px;
    text-align: center;
  }
  .st--hero-slider .hero-slide-content {
    width: 80%;
    margin: 0 auto;
  }
  .st--hero-slider .hero-slider-thumb {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767.98px) {
  .st--hero-slider {
    margin: 100px 0 50px;
  }
  .st--hero-slider .hero-slide-content {
    width: 96%;
    margin: 0 auto;
  }
}

.st--hero-slider .animate-out {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}

/**
* = 7. Promos Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_single_prom_item {
  display: block;
}

.st_single_prom_item .st_promo_content img {
  width: 80%;
  margin-bottom: 40px;
}

.st_single_prom_item .st_promo_content h3 {
  color: #4A516A;
}

.st_single_prom_item .st_promo_content p {
  color: rgba(74, 81, 106, 0.6);
}

.st_single_prom_item .st_promo_content .show-more {
  display: inline-block;
  color: #2bbcff;
  font-size: 0.875em;
  font-weight: bold;
}

.st_single_prom_item .st_promo_content .show-more .icon-arrow {
  width: 14px;
  height: 14px;
}

.st_promo_icon {
  border-radius: 100%;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #5040a0;
  text-align: center;
  margin-bottom: 25px;
  display: inline-block;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.st_promo_icon.st_promo_icon_bg {
  display: inline-block;
  margin: 0 0 70px;
  position: relative;
  background: none;
}

.st_promo_icon.st_promo_icon_bg::before {
  width: 170px;
  height: 137px;
  position: absolute;
  left: 65%;
  top: 45%;
  content: "";
  transform: translate(-50%, -50%);
  background-image: url(../images/v4/promo-bg.png);
  z-index: 0;
}

.st_promo_icon.st_promo_icon_bg img {
  position: relative;
  z-index: 1;
}

.st-icon-gradient {
  background-size: 200% auto;
}

.st-icon-gradient:hover {
  background-position: center right;
}

.st_single_prom_item:hover .st-icon-gradient {
  background-position: center right;
}

.st_single_prom_item_v2.st_single_prom_item .st_promo_icon {
  display: inline-block;
  margin: 0 auto 30px;
  position: relative;
  background: none;
}

.st_single_prom_item_v2.st_single_prom_item .st_promo_content h3 {
  margin-bottom: 20px;
}

.st_single_prom_item_v2.st_single_prom_item .st_promo_content p {
  color: #4A516A;
}

.st_single_prom_item .st_promo_counter {
  background-color: #FFFFFF;
  position: absolute;
  width: 42px;
  height: 42px;
  line-height: 42px;
  top: -20px;
  right: -25px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
}

.st_promo_center .st_single_prom_item {
  text-align: center;
}

.st_promo_center .st_single_prom_item .st_promo_icon {
  display: inline-block;
  margin: 0 auto 30px;
  position: relative;
  background: none;
}

.st_single_prom_item.st_single_prom_item_v3 .st_promo_icon {
  width: 212px;
  height: 212px;
  overflow: hidden;
  margin-bottom: 50px;
}

.st_single_prom_item.st_single_prom_item_v3 .st_promo_icon img {
  height: 100%;
  width: auto;
  object-fit: cover;
  object-postion: center center;
}

@media only screen and (max-width: 767.98px) {
  .st_single_prom_item.st_single_prom_item_v3 .st_promo_icon {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 575.98px) {
  .st_single_prom_item.st_single_prom_item_v3 .st_promo_icon {
    margin-bottom: 15px;
  }
}

.st_single_prom_item.st_single_prom_item_v4 {
  padding: 55px 30px;
  border-radius: 20px;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.04);
}

.st_single_prom_item.st_single_prom_item_v4 .st_promo_icon {
  width: inherit;
  height: 100px;
}

.st_single_prom_item.st_single_prom_item_v4 .st_promo_icon img {
  width: auto;
  height: 100%;
}

.st_single_prom_item.st_single_prom_item_v4 .st_promo_content h3 {
  margin-bottom: 30px;
  font-weight: bold;
}

.overlep-content-wrap {
  box-shadow: 0 3px 76px rgba(0, 0, 0, 0.05);
  padding: 100px;
  position: relative;
  top: -75px;
  margin-left: -8%;
  margin-right: -8%;
  margin-bottom: 115px;
}

.overlep-content-wrap .st_section_heding_title {
  width: 70%;
  margin: 0 auto 80px;
}

@media only screen and (max-width: 1199.98px) {
  .overlep-content-wrap {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding: 100px 30px;
  }
}

@media only screen and (max-width: 767.98px) {
  .overlep-content-wrap {
    padding-bottom: 50px;
    margin-bottom: 30px;
  }
}

.st_single_prom_item.st_single_prom_item_5 .st_promo_icon {
  width: 198px;
  height: 198px;
  overflow: hidden;
  margin-bottom: 30px;
  background-color: #F5F9FD;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 8px solid #FFFFFF;
  box-shadow: 0 3px 20px #ecf8ff;
}

.st_single_prom_item.st_single_prom_item_5 .st_promo_icon img {
  height: 100%;
  width: auto;
  object-fit: cover;
  object-postion: center center;
}

.st_single_prom_item.st_single_prom_item_5 .st_promo_content p {
  font-size: 1em;
}

.st_single_prom_item_v5 .st_promo_icon {
  margin: 0 auto 50px;
  height: inherit;
  width: inherit;
  line-height: inherit;
}

.promo-after-background::after {
  content: "";
  display: block;
  height: 40%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #F5F9FD;
  z-index: -1;
}

/**
* = 8. Video Elements Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_video_bg {
  border-radius: 10px;
  width: 828px;
  height: 455px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #8066dc;
  background: -webkit-linear-gradient(#8066dc 0%, #5040a0 100%);
  background: -o-linear-gradient(#8066dc 0%, #5040a0 100%);
  background: linear-gradient(#8066dc 0%, #5040a0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$first', endColorstr='$second',GradientType=0 );
  z-index: 0;
}

.st_video_bg::before {
  width: 90%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 30px;
  content: "";
  background-color: rgba(128, 102, 220, 0.77);
  filter: blur(24px);
  z-index: -1;
  transform: translateX(-50%);
}

.st_video_bg::after {
  border-radius: 10px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background: #8066dc;
  background: -webkit-linear-gradient(#8066dc 0%, #5040a0 100%);
  background: -o-linear-gradient(#8066dc 0%, #5040a0 100%);
  background: linear-gradient(#8066dc 0%, #5040a0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$first', endColorstr='$second',GradientType=0 );
  z-index: 0;
  opacity: .6;
}

@media only screen and (max-width: 767.98px) {
  .st_video_bg {
    height: 280px;
  }
}

.st_video_icon a {
  border-radius: 100%;
  background-color: #FFFFFF;
  width: 58px;
  height: 58px;
  line-height: 58px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  text-align: center;
  z-index: 1;
}

.iq-waves {
  height: 14rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 14rem;
  z-index: 0;
}

.iq-waves .waves {
  -webkit-animation: 2s ease-in-out 0s normal none infinite running waves;
  -ms-animation: 2s ease-in-out 0s normal none infinite running waves;
  -moz-animation: 2s ease-in-out 0s normal none infinite running waves;
  -o-animation: 2s ease-in-out 0s normal none infinite running waves;
  animation: 2s ease-in-out 0s normal none infinite running waves;
  border-radius: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
}

.iq-waves .wave-1 {
  background-color: rgba(56, 67, 104, 0.3);
  width: 70%;
  height: 70%;
  left: 15%;
  top: 15%;
}

.iq-waves .wave-2 {
  background-color: rgba(56, 67, 104, 0.09);
  width: 86%;
  height: 86%;
  left: 7%;
  top: 7%;
}

.st_video_bg.orange-gradient-banner::before {
  background-color: rgba(254, 149, 69, 0.77);
  filter: blur(24px);
}

.st_video_bg.orange-gradient-banner::after {
  background: none;
  background-color: #FE9545;
  opacity: .6;
}

.st_video_cloumn {
  padding: 55px 0;
  margin-left: calc((-100vw + 100%) / 2);
  padding-left: calc((100vw - 100%) / 2);
  border-radius: 0 620px 620px 0;
}

@media only screen and (max-width: 991.98px) {
  .st_video_cloumn {
    margin-right: 0;
    margin-left: 0;
    padding-left: 0;
    border-radius: 0;
    text-align: center;
  }
}

.st_video_img {
  position: relative;
  border-radius: 100%;
  width: 236px;
  height: 236px;
  display: block;
  border: 13px solid rgba(255, 255, 255, 0.04);
  overflow: hidden;
}

.st_video_img::before {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background-color: rgba(36, 41, 46, 0.53);
}

.st_video_img .st_popup_youtube {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

span.st-play-icon-border {
  width: 58px;
  height: 58px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-color: transparent;
  border-radius: 100%;
  padding: 40px;
  overflow: hidden;
  border: 1px dashed #FF0000;
}

.st_video_icon svg {
  height: 100px;
  width: 100px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.st_video_icon circle {
  fill: transparent;
  stroke: green;
  stroke-width: 1;
}

.st_video_icon .solid {
  stroke-dasharray: none;
}

.st_video_icon .dashed {
  stroke-dasharray: 8, 8.5;
}

.st_video_area.st_video_3 {
  position: relative;
}

.st_video_area.st_video_3:before {
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  background-color: rgba(29, 16, 104, 0.8);
}

.st_video_3 .st_video_icon a {
  z-index: 2;
}

.st_video_3 .iq-waves {
  z-index: 1;
}

.st_video_3 .iq-waves .wave-1 {
  background-color: rgba(255, 255, 255, 0.09);
}

.st_video_3 .iq-waves .wave-2 {
  background-color: rgba(255, 255, 255, 0.24);
}

.st_watch_video {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 70%;
  z-index: 2;
}

/**
* = 9. Counter Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_counter_right h2 {
  font-size: 3.000em;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  margin: 0 0 18px;
}

.st_counter_right h4 {
  color: #4A516A;
  font-weight: 500;
  font-size: 1.125em;
  font-family: "Poppins", sans-serif;
}

.st_single_counter {
  display: flex;
  border: 1px solid #F3F3F3;
  padding: 30px 20px 30px 35px;
}

@media only screen and (max-width: 1199.98px) {
  .st_single_counter {
    display: block;
    padding: 30px 20px;
  }
}

.st_single_counter .st_counter_left {
  margin-top: 5px;
  margin-right: 20px;
}

@media only screen and (max-width: 1199.98px) {
  .st_single_counter .st_counter_left {
    margin-bottom: 17px;
    margin-right: 0;
    text-align: center;
  }
}

.st_single_counter .st_counter_right h2 {
  font-size: 1.750em;
  font-weight: bold;
  margin: 0 0 5px;
}

.st_single_counter .st_counter_right p {
  margin: 0;
}

@media only screen and (max-width: 1199.98px) {
  .st_single_counter .st_counter_right {
    text-align: center;
  }
}

@media only screen and (max-width: 991.98px) {
  .st_single_counter {
    margin-bottom: 30px;
  }
}

.st_stats_area {
  overflow: hidden;
  text-align: center;
}

.st_stats_area .st_stats_waraper {
  padding: 100px 0;
  margin-right: calc((-100vw + 100%) / 2);
  padding-right: calc((100vw - 100%) / 2);
  border-radius: 620px 0 0 620px;
}

.st_stats_area .st_stats_waraper .st_stats_content {
  margin-left: 74px;
}

.st_stats_area .st_stats_waraper .st_stats_content h2 {
  font-size: 3em;
  color: #fff;
  margin-bottom: 20px;
  line-height: 1;
}

.st_stats_area .st_stats_waraper .st_stats_content p {
  color: #D4D4E9;
  font-size: 18px;
  font-size: 1.125rem;
  letter-spacing: 1px;
}

.st_stats_area .st_stats_waraper .st_stats_content p:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 991.98px) {
  .st_stats_area .st_stats_waraper {
    padding-right: 0;
    margin-right: 0;
    border-radius: 0;
  }
  .st_stats_area .st_stats_waraper .st_stats_content {
    margin-left: 0;
    margin-bottom: 50px;
  }
}

/**
* = 10. Feature Elements Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_feature_item {
  border-radius: 10px;
  width: 100%;
  max-width: 313px;
  padding: 60px 45px;
  position: relative;
  margin-bottom: 60px;
}

.st_feature_item h3 {
  margin: 0 0 29px;
}

.st_feature_item p {
  font-size: 0.875em;
  line-height: 1.9;
  margin-bottom: 0;
}

@media only screen and (max-width: 991.98px) {
  .st_feature_item {
    padding: 40px 30px;
  }
}

@media only screen and (max-width: 1199.98px) {
  .st_feature_item {
    margin: 0 auto 90px;
  }
}

.feature_item_icon {
  border-radius: 100%;
  background-color: #4A516A;
  color: #FFFFFF;
  width: 72px;
  height: 72px;
  line-height: 72px;
  text-align: center;
  position: absolute;
  top: 35px;
  left: -43px;
}

@media only screen and (max-width: 1199.98px) {
  .feature_item_icon {
    left: 25px;
    top: -50px;
  }
}

.feature-box-shadow-black {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.07);
}

.st_inner_feature_item {
  background-color: #FFFFFF;
  display: flex;
  padding: 25px;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  margin-bottom: 40px;
}

.st_inner_feature_item .st_inner_feature_icon {
  margin-right: 14px;
  flex: 0 0 auto;
  font-size: 1.125em;
  background-color: #FFFFFF;
  color: #1E43DD;
  width: 49px;
  height: 49px;
  line-height: 49px;
  text-align: center;
  border-radius: 100%;
}

.st_inner_feature_item .st_inner_feature_content {
  margin-top: 9px;
}

.st_inner_feature_item .st_inner_feature_content h3 {
  font-size: 1.250em;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1199.98px) {
  .st_inner_feature_item .st_inner_feature_content h3 {
    font-size: 16px;
    font-size: 1rem;
  }
}

.st_inner_feature_item .st_inner_feature_content p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.7;
}

.st_inner_feature_item .st_inner_feature_content p:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 991.98px) {
  .st_inner_feature_item {
    margin-bottom: 25px;
  }
}

.st_inner_feature_item.st_trasnparent_item {
  background: transparent;
  box-shadow: none;
  margin-bottom: 25px;
  padding-top: 0;
}

@media only screen and (max-width: 1199.98px) {
  .st_inner_feature_item.st_trasnparent_item {
    padding: 15px;
  }
}

@media only screen and (max-width: 991.98px) {
  .st_inner_feature_item.st_trasnparent_item {
    padding: 10px;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_inner_feature_item.st_trasnparent_item {
    margin-bottom: 35px;
    padding: 0;
  }
}

.st_design_feature_item .st_design_feature_img {
  height: 165px;
  margin-bottom: 25px;
}

.st_design_feature_item .st_design_feature_content h3 {
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 30px;
}

.st_design_feature_item .st_design_feature_content p {
  line-height: 2;
}

.st_feature_item_v2 {
  margin-bottom: 60px;
}

.st_feature_item_v2 i {
  font-size: 1.5em;
  margin-bottom: 30px;
  border-radius: 3px;
  background-color: #EBF8FF;
  color: #0079BF;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
}

.st_feature_item_v2 h3 {
  font-size: 1.250em;
  font-weight: bold;
  margin-bottom: 20px;
}

.st_feature_item_v2 p {
  line-height: 2;
}

img.feature-force-large-thumb {
  max-width: 1600px;
  float: right;
  width: 50vw;
}

@media only screen and (max-width: 991.98px) {
  img.feature-force-large-thumb {
    max-width: 100%;
    float: none;
    width: auto;
  }
}

.st_feature_right_item {
  padding: 50px 0;
  position: relative;
}

@media only screen and (max-width: 991.98px) {
  .st_feature_right_item {
    position: relative;
    padding-left: 75px;
    width: 100%;
    padding-bottom: 0;
  }
}

.st_feature_right_item .st_feature_content {
  position: relative;
  z-index: 0;
  margin-bottom: 50px;
}

.st_feature_right_item .st_feature_content:last-child {
  margin: 0;
}

.st_feature_right_item .st_feature_content::before {
  width: 48px;
  height: 48px;
  position: absolute;
  left: -75px;
  top: 0;
  content: "\f06b";
  background-color: #EBF8FF;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1.5em;
  color: #0079BF;
  text-align: center;
  line-height: 45px;
  border-radius: 5px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
}

.st_feature_right_item .st_feature_content::after {
  width: 2px;
  height: 100%;
  position: absolute;
  left: -50px;
  top: 50px;
  content: "";
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
}

.st_feature_right_item .st_feature_content:last-child::after {
  content: none;
}

.st_feature_right_item .st_feature_content h3 {
  font-size: 1.250em;
  color: #FFFFFF;
  margin-bottom: 25px;
}

.st_feature_right_item .st_feature_content p {
  color: #CDDFE9;
  line-height: 2;
}

.st_feature_left_item {
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.01);
  border-radius: 5px;
  padding: 60px 39px;
}

.st_feature_left_item h2 {
  font-size: 1.5em;
  font-weight: 300;
  line-height: 1.7;
  margin-bottom: 35px;
}

.st_feature_left_item p {
  font-size: 0.875em;
  line-height: 1.9;
  margin-bottom: 40px;
}

.st_feature_left_item .read_more_btn {
  color: #140F30;
  font-size: .813em;
  font-weight: 600;
}

.st_feature_left_item .read_more_btn img {
  margin-left: 20px;
}

.st_feature_single_item {
  margin-bottom: 70px;
}

.st_feature_single_item img {
  margin-bottom: 20px;
}

.st_feature_single_item h3 {
  font-size: 14px;
  font-size: 0.875rem;
  margin-bottom: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}

.st_feature_single_item p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.9;
}

.st_simple_feature_item {
  margin-bottom: 65px;
}

.st_simple_feature_item img {
  margin-bottom: 30px;
}

.st_simple_feature_item p {
  font-size: 1.125em;
}

/**
* = 11. About Block Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_about_area {
  padding: 270px 0;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 1199.98px) {
  .st_about_area {
    padding: 130px 0 150px;
  }
}

@media only screen and (max-width: 1199.98px) {
  .st_about_area {
    padding: 100px 0 100px;
  }
}

@media only screen and (max-width: 575.98px) {
  .st_about_area {
    padding: 80px 0 80px;
  }
}

.st_about_area .st_about_side_img {
  position: absolute;
  left: 50vw;
  width: calc(50vw + 180px);
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 991.98px) {
  .st_about_area .st_about_side_img {
    position: relative;
    left: 0;
    top: inherit;
    display: block;
    transform: translateY(0);
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
}

.st_about_single_full {
  width: 100%;
  margin-bottom: 80px;
}

.st_about_single_full:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 991.98px) {
  .st_about_single_full {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 575.98px) {
  .st_about_single_full {
    margin-bottom: 40px;
  }
}

.st_left_about_shape {
  position: absolute;
  top: 25px;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transform: skewY(-3deg);
  -ms-transform: skewY(-3deg);
  -moz-transform: skewY(-3deg);
  -o-transform: skewY(-3deg);
  transform: skewY(-3deg);
  -webkit-transform-origin: 0;
  -ms-transform-origin: 0;
  -moz-transform-origin: 0;
  -o-transform-origin: 0;
  transform-origin: 0;
  z-index: 0;
}

.st_left_about_shape span {
  position: relative;
  opacity: 0;
  width: 100%;
  margin-bottom: -3px;
  top: 0;
  width: 360px;
}

@media only screen and (max-width: 1199.98px) {
  .st_left_about_shape {
    display: none;
  }
}

.st_loaded .st_left_about_shape span {
  -webkit-animation: stripeSlideInRight 2s forwards;
  -ms-animation: stripeSlideInRight 2s forwards;
  -moz-animation: stripeSlideInRight 2s forwards;
  -o-animation: stripeSlideInRight 2s forwards;
  animation: stripeSlideInRight 2s forwards;
}

.st_left_about_shape span:nth-child(1) {
  right: 0;
  left: inherit;
  background: rgba(122, 184, 220, 0.48);
  z-index: 2;
  height: 161px;
}

.st_loaded .st_left_about_shape span:nth-child(1) {
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
}

.st_left_about_shape span:nth-child(2) {
  right: -154px;
  bottom: auto;
  background: #7AB8DC;
  z-index: 1;
  height: 166px;
  margin-top: -40px;
}

.st_loaded .st_left_about_shape span:nth-child(2) {
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}

.st_left_about_shape span:nth-child(3) {
  left: inherit;
  background: #3A97CC;
  height: 172px;
  right: -230px;
}

.st_loaded .st_left_about_shape span:nth-child(3) {
  -webkit-animation-delay: .8s;
  animation-delay: .8s;
}

.top-bottom-angle {
  position: relative;
  margin-bottom: 0;
  padding: 250px 0 200px;
}

.top-bottom-angle::before, .top-bottom-angle::after {
  width: 100%;
  height: 140px;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background-color: #FFFFFF;
  background-size: cover;
}

.top-bottom-angle::before {
  -webkit-clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
  clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
}

@supports not ((-webkit-clip-path: polygon(0% 100%, 100% 0%, 0% 0%)) or (clip-path: polygon(0% 100%, 100% 0%, 0% 0%))) {
  .top-bottom-angle::before {
    -webkit-transform: skewY(-4deg);
    -ms-transform: skewY(-4deg);
    -moz-transform: skewY(-4deg);
    -o-transform: skewY(-4deg);
    transform: skewY(-4deg);
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -o-transform-origin: bottom left;
    transform-origin: bottom left;
  }
}

.top-bottom-angle::after {
  top: auto;
  bottom: 0;
  -webkit-clip-path: polygon(0% 100%, 100% 101%, 100% 0%);
  clip-path: polygon(0% 100%, 100% 101%, 100% 0%);
}

@supports not ((-webkit-clip-path: polygon(0% 100%, 100% 101%, 100% 0%)) or (clip-path: polygon(0% 100%, 100% 101%, 100% 0%))) {
  .top-bottom-angle::after {
    -webkit-transform: skewY(-4deg);
    -ms-transform: skewY(-4deg);
    -moz-transform: skewY(-4deg);
    -o-transform: skewY(-4deg);
    transform: skewY(-4deg);
    -webkit-transform-origin: top right;
    -ms-transform-origin: top right;
    -moz-transform-origin: top right;
    -o-transform-origin: top right;
    transform-origin: top right;
  }
}

@media only screen and (max-width: 1199.98px) {
  .top-bottom-angle {
    padding: 170px 0 180px;
  }
  .top-bottom-angle::before {
    -webkit-clip-path: polygon(-100% 100%, 100% 0%, 0% 0%);
    clip-path: polygon(-100% 100%, 100% 0%, 0% 0%);
  }
  .top-bottom-angle::after {
    -webkit-clip-path: polygon(-100% 100%, 100% 100%, 100% 0%);
    clip-path: polygon(-100% 100%, 100% 100%, 100% 0%);
  }
}

@media only screen and (max-width: 991.98px) {
  .top-bottom-angle {
    padding: 130px 0 220px;
  }
  .top-bottom-angle::before {
    -webkit-clip-path: polygon(-220% 100%, 100% 0%, 0% 0%);
    clip-path: polygon(-220% 100%, 100% 0%, 0% 0%);
  }
  @supports not ((-webkit-clip-path: polygon(-220% 100%, 100% 0%, 0% 0%)) or (clip-path: polygon(-220% 100%, 100% 0%, 0% 0%))) {
    .top-bottom-angle::before {
      -webkit-transform: skewY(-2deg);
      -ms-transform: skewY(-2deg);
      -moz-transform: skewY(-2deg);
      -o-transform: skewY(-2deg);
      transform: skewY(-2deg);
    }
  }
  .top-bottom-angle::after {
    -webkit-clip-path: polygon(-220% 100%, 100% 100%, 100% 0%);
    clip-path: polygon(-220% 100%, 100% 100%, 100% 0%);
  }
  @supports not ((-webkit-clip-path: polygon(-220% 100%, 100% 100%, 100% 0%)) or (clip-path: polygon(-220% 100%, 100% 100%, 100% 0%))) {
    .top-bottom-angle::after {
      -webkit-transform: skewY(-2deg);
      -ms-transform: skewY(-2deg);
      -moz-transform: skewY(-2deg);
      -o-transform: skewY(-2deg);
      transform: skewY(-2deg);
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .top-bottom-angle {
    padding: 80px 0 80px;
  }
  .top-bottom-angle::before, .top-bottom-angle::after {
    display: none;
  }
}

.top-bottom-angle.top-bottom-angle-index::after {
  z-index: -1;
}

.st_about_bubbles_logo {
  position: absolute;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.07);
  border-radius: 100%;
  background-color: #fff;
  text-align: center;
  animation-name: floating;
  transform-origin: center bottom;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 7s;
  animation-fill-mode: both;
  -webkit-animation-name: floating;
  -webkit-transform-origin: center bottom;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-duration: 7s;
  -webkit-animation-fill-mode: both;
}

.st_about_bubbles_logo:nth-child(odd) {
  animation-delay: 2s;
}

.st_about_bubbles_logo:nth-child(1) {
  width: 100px;
  height: 100px;
  line-height: 100px;
  bottom: -55px;
  left: 0;
}

.st_about_bubbles_logo:nth-child(1) img {
  position: relative;
  left: 8px;
  top: -2px;
}

.st_about_bubbles_logo:nth-child(2) {
  width: 90px;
  height: 90px;
  line-height: 90px;
  bottom: 28%;
  left: 13%;
}

.st_about_bubbles_logo:nth-child(3), .st_about_bubbles_logo:nth-child(4), .st_about_bubbles_logo:nth-child(5), .st_about_bubbles_logo:nth-child(6) {
  width: 60px;
  height: 60px;
  line-height: 60px;
  top: 10%;
  left: 10px;
}

.st_about_bubbles_logo:nth-child(4) {
  top: auto;
  bottom: 10%;
  left: 35%;
}

.st_about_bubbles_logo:nth-child(5) {
  top: 0;
  left: 32%;
}

.st_about_bubbles_logo:nth-child(6) {
  top: 35%;
  left: 50%;
}

.st_about_bubbles_logo:nth-child(7), .st_about_bubbles_logo:nth-child(8), .st_about_bubbles_logo:nth-child(9) {
  top: -26%;
  left: 55%;
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.st_about_bubbles_logo:nth-child(8) {
  top: 34%;
  right: 9%;
  left: auto;
}

.st_about_bubbles_logo:nth-child(9) {
  bottom: -15%;
  right: 16%;
  left: auto;
  top: auto;
}

@media only screen and (max-width: 991.98px) {
  .st_about_bubbles {
    height: 261px;
    margin-bottom: 130px;
    position: relative;
    margin-top: 20px;
  }
}

/**
* = 12. Content Elements Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_default_content p {
  margin-bottom: 20px;
}

.st_default_content p:last-child {
  margin-bottom: 0;
}

.st_default_content p.lead {
  font-size: 1.25em;
  font-weight: 300;
}

.st_default_content i {
  background-color: #B3AADC;
  color: #fff;
  width: 54px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  border-radius: 100%;
  font-size: 1.5em;
  margin: 0 0 22px;
}

.st_default_content .st_default_btn img {
  margin-bottom: 0;
}

.st_default_content ul {
  list-style: none;
  margin: 0;
  padding: 0 0 0 30px;
}

.st_default_content ul li {
  position: relative;
  margin-bottom: 20px;
}

.st_default_content ul li::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -30px;
  width: 20px;
  height: 24px;
  background-image: url("../images/icon-done.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center;
}

.st_default_content ul li:last-child {
  margin-bottom: 0;
}

.st_default_content ul.st-ul-coloum-two {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.st_default_content ul.st-ul-coloum-three {
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
}

@media only screen and (max-width: 1199.98px) {
  .st_default_content h2 {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

@media only screen and (max-width: 991.98px) {
  .st_default_content h2 {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_default_content {
    -ms-word-break: normal;
    word-break: normal;
  }
  .st_default_content h2 {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

.st_about_single_img {
  position: absolute;
  right: 0;
  top: 50%;
  width: 8vw;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: hidden;
  border-radius: 15px;
}

.st_about_single_img.item_01 {
  top: 60px;
  right: 176px;
  width: auto;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.st_about_single_img.item_02 {
  top: 205px;
  right: 53px;
  width: auto;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.st_about_single_img.item_03 {
  bottom: 93px;
  right: 210px;
  width: auto;
  top: inherit;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.st_about_right_box {
  position: relative;
  width: 400px;
  height: auto;
  border-radius: 100%;
  box-shadow: 0 3px 50px rgba(0, 0, 0, 0.41);
  z-index: 0;
}

.st_about_right_box::before {
  background-color: #0079BF;
  width: 400px;
  height: 400px;
  position: absolute;
  left: auto;
  top: -45px;
  content: "";
  right: -40px;
  border-radius: 100%;
  z-index: -1;
}

.st_about_right_box .st_video_icon .st_popup_youtube {
  background-color: rgba(0, 0, 0, 0.44);
  width: 130px;
  height: 130px;
  line-height: 130px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  text-align: center;
}

.st_about_right_box .iq-waves {
  height: 22rem;
  width: 22rem;
}

.st_about_right_box .iq-waves .wave-1 {
  background-color: rgba(56, 67, 104, 0.5);
}

@media only screen and (max-width: 1199.98px) {
  .st_about_right_box::before {
    right: 0;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_about_right_box::before {
    display: none;
  }
}

.st_content_item {
  border-radius: 5px;
  z-index: 0;
  padding: 100px 80px;
  position: relative;
  left: -50px;
}

@media only screen and (max-width: 991.98px) {
  .st_content_item {
    left: 0;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_content_item {
    padding: 50px 35px;
  }
}

.st_content_item:before {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background-color: #FFFFFF;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.06);
  z-index: -1;
}

.st_content_item .st_sontent_wrap .st_content_top img {
  margin-bottom: 18px;
}

.st_content_item .st_sontent_wrap .st_content_top p {
  line-height: 2;
}

.st_content_item .st_content_bottom ul {
  margin: 0;
  padding: 0 60px;
  list-style: none;
}

@media only screen and (max-width: 767.98px) {
  .st_content_item .st_content_bottom ul {
    padding: 0;
  }
}

.st_content_item .st_content_bottom ul li {
  color: #140F30;
  font-size: 0.875em;
  font-weight: 500;
  margin: 24px 0;
  position: relative;
}

.st_content_item .st_content_bottom ul li::before {
  width: 25px;
  height: 25px;
  position: absolute;
  left: -55px;
  top: 0;
  content: "\f06b";
  background-color: #FFEDEC;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1em;
  color: #F46F6C;
  text-align: center;
  line-height: 25px;
  border-radius: 100%;
}

@media only screen and (max-width: 767.98px) {
  .st_content_item .st_content_bottom ul li::before {
    position: relative;
    left: 0;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 7px;
  }
}

.st_content_item .st_content_bottom ul li:first-child {
  margin-top: 0;
}

.st_content_item .st_content_bottom ul li:last-child {
  margin-bottom: 0;
}

.st_solid_bg {
  background-color: #FFFFFF;
  width: 300px;
  height: 600px;
  left: -100px;
  top: 22%;
  position: absolute;
  z-index: -2;
}

.st_content_bg {
  position: absolute;
  z-index: -2;
  top: -65px;
  left: -100px;
}

.st_single_right_img {
  min-width: 65vw;
  position: absolute;
  left: -80px;
}

.st_single_right_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 1600px;
  float: left;
}

@media only screen and (max-width: 767.98px) {
  .st_single_right_img {
    min-width: 100%;
    position: relative;
    left: inherit;
    margin-top: 60px;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_single_right_img {
    position: relative;
    left: inherit;
    margin-top: 60px;
  }
}

.st-right-full-half {
  position: absolute;
  bottom: 0;
  width: 50vw;
  right: 0;
}

@media only screen and (max-width: 1199.98px) {
  .st-right-full-half {
    width: 40vw;
  }
  .st-right-full-half.half-middle-lg {
    width: 50vw;
  }
}

.st_about_right_item,
.st_about_left_item {
  position: relative;
}

.st_about_right_item .st_about_solid_bg,
.st_about_left_item .st_about_solid_bg {
  background-color: rgba(245, 249, 253, 0.6);
  width: 300px;
  height: 497px;
  left: auto;
  top: 30%;
  position: absolute;
  right: -70px;
  z-index: -1;
}

.st_about_right_item .st_about_image_bg,
.st_about_left_item .st_about_image_bg {
  position: absolute;
  top: -55px;
  z-index: -1;
  left: -50px;
}

.st_about_right_item img,
.st_about_left_item img {
  width: 100%;
}

@media only screen and (max-width: 991.98px) {
  .st_about_right_item,
  .st_about_left_item {
    text-align: center;
  }
  .st_about_right_item .st_about_image_bg,
  .st_about_left_item .st_about_image_bg {
    top: 0;
  }
}

@media only screen and (min-width: 992px) {
  .st_about_right_item,
  .st_about_left_item {
    width: 120%;
  }
}

@media only screen and (min-width: 992px) {
  .st_about_right_item {
    margin-right: -20%;
  }
}

@media only screen and (min-width: 992px) {
  .st_about_left_item {
    margin-left: -20%;
  }
}

.st-special-thumbnail {
  width: 80%;
  box-shadow: 0 13px 13px rgba(0, 0, 0, 0.015);
  border-radius: 34px;
}

/**
* = 13. Call To Actions Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_download_area_v2 {
  text-align: center;
  padding: 0 0 100px;
}

@media only screen and (max-width: 767.98px) {
  .st_download_area_v2 {
    padding: 0;
  }
}

.st_download_area_v2 .st_download_content_wrapper {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.st_download_area_v2 .st_download_content_wrapper h2 {
  margin: 0 0 25px;
}

/**
* = 14. Team Elements Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_team_item {
  text-align: center;
}

@media only screen and (max-width: 991.98px) {
  .st_team_item {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_team_item {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }
}

.st_team_item .st_single_team_bg {
  background-image: url(../images/team-mask-2.png);
  background-size: 90% 90%;
  background-repeat: no-repeat;
  background-position: center center;
}

.st_team_item .st_single_team_bg .st_team_single_img {
  mask-image: url(../images/team-mask.png);
  mask-repeat: no-repeat;
  mask-size: 120% 120%;
  mask-position: -20px -30px;
  -webkit-mask-image: url(../images/team-mask.png);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 120% 120%;
  -webkit-mask-position: -20px -30px;
  margin-bottom: 20px;
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 991.98px) {
  .st_team_item .st_single_team_bg .st_team_single_img img {
    display: block;
    width: 100%;
  }
}

.st_team_item .st_team_content h3 {
  margin: 0 0 10px;
}

.st_team_item .st_team_content p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1;
  margin: 0 0 25px;
}

.st_team_item .st_team_social_icon ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.st_team_item .st_team_social_icon ul li {
  display: inline-block;
  margin: 0 8px;
  text-transform: capitalize;
}

.st_team_item .st_team_social_icon ul li a {
  font-size: 18px;
  font-size: 1.125rem;
  color: #4A516A;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.st_team_item .st_team_social_icon ul li a:hover {
  color: #333333;
}

.st_team_item_v2 {
  background-color: #FFFFFF;
  box-shadow: 0 3px 36px rgba(0, 0, 0, 0.07);
  padding: 20px;
  border-bottom: 4px solid #E9ECFF;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.st_team_item_v2:hover {
  border-bottom: 4px solid #0366D6;
}

.st_team_item_v2 .st_team_member {
  text-align: center;
}

@media only screen and (max-width: 575.98px) {
  .st_team_item_v2 .st_team_member img {
    display: block;
    width: 100%;
  }
}

.st_team_item_v2 .st_team_content {
  display: flex;
  justify-content: space-between;
  padding: 31px 0 15px;
}

.st_team_item_v2 .st_team_content .st_team_single_content h3 {
  margin-bottom: 8px;
}

.st_team_item_v2 .st_team_content .st_team_single_content p {
  font-size: 14px;
  font-size: 0.875rem;
  margin-bottom: 0;
}

@media only screen and (max-width: 1199.98px) {
  .st_team_item_v2 .st_team_content {
    display: block;
  }
  .st_team_item_v2 .st_team_content .st_team_single_content {
    text-align: center;
  }
}

.st_team_item_v2 .st_team_social_icon {
  display: flex;
  align-items: end;
}

.st_team_item_v2 .st_team_social_icon ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.st_team_item_v2 .st_team_social_icon ul li {
  display: inline-block;
  margin: 0 2px;
  text-transform: capitalize;
}

.st_team_item_v2 .st_team_social_icon ul li a {
  color: #4A516A;
  font-size: 1.125em;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

@media only screen and (max-width: 1199.98px) {
  .st_team_item_v2 .st_team_social_icon {
    margin-top: 15px;
    display: block;
    text-align: center;
  }
}

.st_team_item_v2:hover .st_team_social_icon ul li a {
  color: #0366D6;
}

.st_team_item.st_team_item_v3 .st_team_item_img {
  width: 172px;
  height: 172px;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 30px;
  border: 10px solid #FFFFFF;
  box-shadow: 0 3px 80px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
}

.st_team_item.st_team_item_v3 .st_team_item_img img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.st_team_item.st_team_item_v3 .st_team_item_img img:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.st_team_item.st_team_item_v3 .st_team_content h3 {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  margin-bottom: 0;
}

.st_team_item.st_team_item_v3 .st_team_content p {
  font-size: 14px;
  font-size: 0.875rem;
  margin-bottom: 10px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .st_team_item {
    -webkit-transform: translate3d(0, 0, 0);
  }
}

.st_team_area {
  padding: 100px 0 150px;
}

@media only screen and (max-width: 991.98px) {
  .st_team_area {
    padding: 100px 0 100px;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_team_area {
    padding: 80px 0 40px;
  }
}

.st_team_4 .st_team_single_content {
  border-left: 4px solid #ECEAF6;
}

.st_team_4 .st_team_single_content h3 {
  font-family: "Lato", sans-serif;
}

.st_team_4 .st_team_hover_content {
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  -webkit-transform: translateX(-25%);
  -ms-transform: translateX(-25%);
  -moz-transform: translateX(-25%);
  -o-transform: translateX(-25%);
  transform: translateX(-25%);
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  padding: 40px 10px 35px 25px;
  background-color: rgba(245, 249, 253, 0.95);
}

.st_team_4 .st_team_hover_content h3 {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.9375rem;
  line-height: 1;
}

.st_team_4 .st_team_hover_content p {
  margin-bottom: 1.5625rem;
  color: #140F30;
  font-size: 14px;
  font-size: 0.875rem;
}

@media only screen and (max-width: 1199.98px) {
  .st_team_4 .st_team_hover_content p {
    margin-bottom: 0.9375rem;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_team_4 .st_team_hover_content p {
    margin-bottom: 0.625rem;
  }
}

.st_team_4 .st_team_member {
  overflow: hidden;
  height: 360px;
}

@media only screen and (max-width: 1199.98px) {
  .st_team_4 .st_team_member {
    height: 300px;
  }
}

@media only screen and (max-width: 991.98px) {
  .st_team_4 .st_team_member {
    height: 330px;
  }
}

.st_team_4 .st_team_member:hover .st_team_hover_content {
  opacity: 1;
  height: 100%;
  transform: translateX(0);
}

/**
* = 15. Testimonial Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_testimonial_item {
  margin: 0 0 90px;
  position: relative;
}

.st_testimonial_item .st_testimonial_content {
  background-color: #5040A0;
  width: 54%;
  margin: 0 auto;
  border-radius: 5px;
  padding: 50px 67px 80px;
  z-index: 0;
}

@media only screen and (max-width: 991.98px) {
  .st_testimonial_item .st_testimonial_content {
    width: 80%;
    padding: 50px 40px 80px;
  }
}

@media only screen and (max-width: 991.98px) {
  .st_testimonial_item .st_testimonial_content {
    width: 100%;
  }
}

.st_testimonial_item .st_testimonial_content::before, .st_testimonial_item .st_testimonial_content::after {
  width: 57%;
  height: 91%;
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  background-color: rgba(0, 0, 0, 0.04);
  transform: translate(-50%, -50%);
  border-radius: 3px;
  z-index: -1;
}

@media only screen and (max-width: 991.98px) {
  .st_testimonial_item .st_testimonial_content::before, .st_testimonial_item .st_testimonial_content::after {
    width: 84%;
  }
}

.st_testimonial_item .st_testimonial_content::after {
  width: 59%;
  height: 82%;
}

@media only screen and (max-width: 991.98px) {
  .st_testimonial_item .st_testimonial_content::after {
    width: 88%;
  }
}

.st_testimonial_item .st_testimonial_content h3 {
  font-size: 1.500em;
  color: #FFE600;
  font-weight: 400;
  margin: 0 0 30px;
}

.st_testimonial_item .st_testimonial_content p {
  color: #D7D2F1;
  font-weight: 300;
  line-height: 2.1;
}

.st_testimonial_item .st_testimonial_client {
  position: absolute;
  bottom: -90px;
  left: 50%;
  transform: translateX(-50%);
}

.st_testimonial_item .st_testimonial_client .client_img {
  width: 70px !important;
  height: 70px;
  border-radius: 100%;
  margin: 0 auto 10px;
  overflow: hidden;
}

.st_testimonial_item .st_testimonial_client .client_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.st_testimonial_item .client_title h4 {
  color: #FFFFFF;
  font-size: 0.875em;
  font-weight: 700;
  margin: 0 0 10px;
  text-transform: capitalize;
}

.st_testimonial_item .client_title p {
  color: #C4C4C4;
  font-size: .75em;
  margin: 0;
  text-transform: uppercase;
}

@media only screen and (max-width: 991.98px) {
  .owl-carousel.owl-border-nav .owl-nav {
    text-align: center;
  }
}

.owl-carousel.owl-border-nav .owl-nav .owl-prev,
.owl-carousel.owl-border-nav .owl-nav .owl-next {
  background-color: transparent;
  color: #FFFFFF;
  font-size: 1em;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid #CCC2FF;
  position: absolute;
  opacity: 0.4;
  left: 120px;
  top: 35%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  border-radius: 100%;
  z-index: 1;
}

.owl-carousel.owl-border-nav .owl-nav .owl-prev:hover,
.owl-carousel.owl-border-nav .owl-nav .owl-next:hover {
  opacity: 1;
}

@media only screen and (max-width: 991.98px) {
  .owl-carousel.owl-border-nav .owl-nav .owl-prev,
  .owl-carousel.owl-border-nav .owl-nav .owl-next {
    left: 0;
  }
}

@media only screen and (max-width: 991.98px) {
  .owl-carousel.owl-border-nav .owl-nav .owl-prev,
  .owl-carousel.owl-border-nav .owl-nav .owl-next {
    left: inherit;
    top: inherit;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    position: relative;
    margin: 50px 15px 0;
    display: inline-block;
  }
}

.owl-carousel.owl-border-nav .owl-nav .owl-next {
  left: auto;
  right: 120px;
}

@media only screen and (max-width: 991.98px) {
  .owl-carousel.owl-border-nav .owl-nav .owl-next {
    right: 0;
  }
}

.st_v2_testimonial_item {
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.05);
  padding: 45px 40px;
  margin: 10px 15px 35px;
}

@media only screen and (max-width: 767.98px) {
  .st_v2_testimonial_item {
    padding: 35px 34px;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
}

.st_v2_testimonial_item .st_testimonial_content {
  margin-bottom: 40px;
  position: relative;
}

.st_v2_testimonial_item .st_testimonial_content:before {
  content: "\f10e";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #F3F2F2;
  font-size: 3em;
  margin-right: 10px;
  position: absolute;
  right: 0;
  bottom: -30px;
  z-index: -1;
}

@media only screen and (max-width: 767.98px) {
  .st_v2_testimonial_item .st_testimonial_content:before {
    display: none;
  }
}

.st_v2_testimonial_item .st_testimonial_content h3 {
  margin: 0 0 20px;
}

.st_v2_testimonial_item .st_testimonial_content p {
  line-height: 2;
}

@media only screen and (max-width: 767.98px) {
  .st_v2_testimonial_item .st_testimonial_content p {
    line-height: 1.8;
    word-break: break-all;
  }
}

.st_v2_testimonial_item .st_testimonial_feedback {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767.98px) {
  .st_v2_testimonial_item .st_testimonial_feedback {
    display: block;
  }
}

.st_v2_testimonial_item .st_testimonial_feedback .st_client_waraper {
  display: flex;
}

.st_v2_testimonial_item .st_testimonial_feedback .st_client_waraper .st_client_img {
  width: 50px;
  border-radius: 100%;
  overflow: hidden;
  height: 50px;
  margin-right: 20px;
}

.st_v2_testimonial_item .st_testimonial_feedback .st_client_waraper .st_client_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.st_v2_testimonial_item .st_testimonial_feedback .st_client_waraper .st_client_name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 767.98px) {
  .st_v2_testimonial_item .st_testimonial_feedback .st_client_waraper .st_client_name {
    display: block;
    justify-content: left;
  }
}

.st_v2_testimonial_item .st_testimonial_feedback .st_client_waraper .st_client_name h4 {
  font-size: 0.875em;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0 0 12px;
}

.st_v2_testimonial_item .st_testimonial_feedback .st_client_waraper .st_client_name p {
  font-size: .75em;
  text-transform: uppercase;
  line-height: 1;
}

.st_v2_testimonial_item .st_testimonial_feedback .st_client_review {
  display: flex;
  align-items: center;
}

.st_v2_testimonial_item .st_testimonial_feedback .st_client_review ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.st_v2_testimonial_item .st_testimonial_feedback .st_client_review ul li {
  display: inline-block;
  margin: 0 3px;
  text-transform: capitalize;
}

.st_v2_testimonial_item .st_testimonial_feedback .st_client_review ul li a {
  color: #FFB300;
  font-size: 0.875em;
}

@media only screen and (max-width: 767.98px) {
  .st_v2_testimonial_item .st_testimonial_feedback .st_client_review ul {
    width: auto;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_v2_testimonial_item .st_testimonial_feedback .st_client_review {
    margin-left: 70px;
  }
  .st_v2_testimonial_item .st_testimonial_feedback .st_client_review ul li {
    margin: 0;
  }
  .st_v2_testimonial_item .st_testimonial_feedback .st_client_review ul li a {
    font-size: 10px;
    font-size: 0.625rem;
  }
}

.st_slider_bottom_dots .owl-dots {
  text-align: center;
}

.st_slider_bottom_dots.owl-carousel .owl-dot {
  border: 1px solid #B7B7B7;
  display: inline-block;
  height: 11px;
  margin-right: 10px;
  width: 11px;
  margin-top: 30px;
  border-radius: 10px;
}

@media only screen and (max-width: 767.98px) {
  .st_slider_bottom_dots.owl-carousel .owl-dot {
    margin-top: 0;
  }
}

.st_slider_bottom_dots.owl-carousel .owl-dot.active {
  background: #00E3F6 none repeat scroll 0 0;
  height: 15px;
  width: 15px;
  border: 2px solid #FFF3F4;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
}

.st_testimonial_slider_v2 .st_testimonial_item .st_testimonial_content {
  text-align: center;
  background-color: #FFFFFF;
}

.st_testimonial_slider_v2 .st_testimonial_item .st_testimonial_content::before, .st_testimonial_slider_v2 .st_testimonial_item .st_testimonial_content::after {
  background-color: rgba(255, 255, 255, 0.04);
}

.st_testimonial_slider_v2 .st_testimonial_item .st_testimonial_content h3 {
  color: #4A516A;
}

.st_testimonial_slider_v2 .st_testimonial_item .st_testimonial_content p {
  color: #4A516A;
}

.st_testimonial_slider_v2 .st_testimonial_item .st_testimonial_client .client_img {
  width: 90px !important;
  height: 90px;
  box-shadow: 0 16px 20px rgba(20, 15, 48, 0.32);
}

.st_testimonial_slider_v2 .st_testimonial_item .st_testimonial_client .client_title h4 {
  color: #4A516A;
}

.st_testimonial_slider_v2.owl-carousel.owl-border-nav .owl-nav .owl-prev,
.st_testimonial_slider_v2.owl-carousel.owl-border-nav .owl-nav .owl-next {
  color: #CCC2FF;
  opacity: .4;
}

.st_testimonial_slider_v2.owl-carousel.owl-border-nav .owl-nav .owl-prev:hover,
.st_testimonial_slider_v2.owl-carousel.owl-border-nav .owl-nav .owl-next:hover {
  opacity: 1;
}

.st_testimonial_slider_v3 .st_testimonial_item {
  margin: 0;
  padding: 80px 0 0;
}

.st_testimonial_slider_v3 .st_testimonial_item .st_testimonial_client {
  position: static;
  transform: none;
  margin-bottom: 40px;
}

.st_testimonial_slider_v3 .st_testimonial_item .st_testimonial_client .client_img {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}

.st_testimonial_slider_v3 .st_testimonial_item .st_testimonial_content {
  background-color: transparent;
  width: 59%;
  padding: 0;
}

.st_testimonial_slider_v3 .st_testimonial_item .st_testimonial_content::before, .st_testimonial_slider_v3 .st_testimonial_item .st_testimonial_content::after {
  background-color: rgba(255, 255, 255, 0.04);
  opacity: 0;
}

.st_testimonial_slider_v3 .st_testimonial_item .st_testimonial_content p {
  color: #4A516A;
  font-size: 1.5em;
  margin-bottom: 55px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

@media only screen and (max-width: 767.98px) {
  .st_testimonial_slider_v3 .st_testimonial_item .st_testimonial_content p {
    font-size: 16px;
    font-size: 1rem;
  }
}

.st_testimonial_slider_v3 .st_testimonial_item .st_testimonial_content h3 {
  color: #4A516A;
  font-size: 1.125em;
  font-weight: bold;
  margin: 0;
}

.st_testimonial_slider_v3 .st_testimonial_item .st_testimonial_content h3 span {
  font-weight: 300;
}

.st_testimonial_slider_v3 .st_testimonial_item .st_quote {
  position: absolute;
  top: 30px;
  z-index: -1;
  left: 70px;
}

@media only screen and (max-width: 991.98px) {
  .st_testimonial_slider_v3 .st_testimonial_item .st_testimonial_content {
    width: 100%;
  }
}

.st_testimonial_slider_v3.owl-carousel .owl-dot {
  background: #AAFFC5;
  border-radius: 10px;
  display: inline-block;
  height: 11px;
  margin-right: 11px;
  width: 11px;
  margin-top: 60px;
}

.st_testimonial_slider_v3.owl-carousel .owl-dot.active {
  background: #38D16A none repeat scroll 0 0;
  height: 14px;
  width: 14px;
  border: 2px solid #fff;
  position: relative;
  top: -1px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
}

.owl-carousel .owl-dots {
  text-align: center;
}

.st_online_community_item .st_single_item {
  animation-name: pulse_animation;
  animation-duration: 5000ms;
  transform-origin: 70% 70%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: absolute;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  overflow: hidden;
  z-index: 1;
  opacity: 0.6;
}

.st_online_community_item .st_single_item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.st_online_community_item .st_single_item.item_1 {
  width: 121px;
  height: 121px;
  left: -25px;
  bottom: 20px;
}

.st_online_community_item .st_single_item.item_2 {
  width: 69px;
  height: 69px;
  left: 23%;
  bottom: 35%;
}

.st_online_community_item .st_single_item.item_3, .st_online_community_item .st_single_item.item_4, .st_online_community_item .st_single_item.item_5 {
  width: 37px;
  height: 37px;
  left: 6%;
  top: 50%;
}

.st_online_community_item .st_single_item.item_4 {
  left: 22%;
  top: 32%;
}

.st_online_community_item .st_single_item.item_5 {
  left: auto;
  top: auto;
  right: 20%;
  bottom: 10%;
}

.st_online_community_item .st_single_item.item_6 {
  width: 57px;
  height: 57px;
  right: 8%;
  bottom: 27%;
}

.st_online_community_item .st_single_item.item_7 {
  width: 71px;
  height: 71px;
  right: 18%;
  top: 40%;
}

.st_online_community_item .st_single_item.item_8, .st_online_community_item .st_single_item.item_9 {
  width: 83px;
  height: 83px;
  right: 28%;
  top: 13%;
}

.st_online_community_item .st_single_item.item_9 {
  right: -30px;
  top: 8%;
}

.st_online_community_item .st_single_item.item_10 {
  width: 71px;
  height: 71px;
  right: 12%;
  top: 30%;
}

.st_online_community_item .st_single_item.item_11 {
  width: 85px;
  height: 85px;
  right: 3%;
  top: 50%;
}

.st_online_community_item .st_single_item.item_12 {
  width: 67px;
  height: 67px;
  right: 13%;
  bottom: 20%;
}

.st_online_community_item.st_online_community_item_v2 .st_single_item.item_1 {
  width: 121px;
  height: 121px;
  left: 0;
  bottom: 27%;
}

.st_online_community_item.st_online_community_item_v2 .st_single_item.item_2 {
  width: 89px;
  height: 89px;
  left: 28%;
  bottom: 23%;
}

.st_online_community_item.st_online_community_item_v2 .st_single_item.item_2 {
  width: 89px;
  height: 89px;
  left: 28%;
  bottom: 23%;
}

@media only screen and (max-width: 1199.98px) {
  .st_online_community_item.st_online_community_item_v2 .st_single_item.item_2 {
    width: 50px;
    height: 50px;
    left: 28%;
    bottom: 17%;
  }
}

.st_online_community_item.st_online_community_item_v2 .st_single_item.item_3 {
  width: 70px;
  height: 70px;
  left: 6%;
  top: 24%;
}

.st_online_community_item.st_online_community_item_v2 .st_single_item.item_4 {
  width: 70px;
  height: 70px;
  left: 20%;
  top: 18%;
}

.st_online_community_item.st_online_community_item_v2 .st_single_item.item_5 {
  width: 89px;
  height: 89px;
  left: 32%;
  top: 25%;
}

@media only screen and (max-width: 1199.98px) {
  .st_online_community_item.st_online_community_item_v2 .st_single_item.item_5 {
    left: 32%;
    top: 5%;
    width: 60px;
    height: 60px;
  }
}

.st_online_community_item.st_online_community_item_v2 .st_single_item.item_6 {
  width: 69px;
  height: 69px;
  left: 17%;
  bottom: 45%;
  right: auto;
}

.st_online_community_item.st_online_community_item_v2 .st_single_item.item_7 {
  width: 101px;
  height: 101px;
  right: 24%;
  top: 50%;
}

@media only screen and (max-width: 1199.98px) {
  .st_online_community_item.st_online_community_item_v2 .st_single_item.item_7 {
    width: 70px;
    height: 70px;
  }
}

.st_online_community_item.st_online_community_item_v2 .st_single_item.item_8 {
  width: 83px;
  height: 83px;
  right: 28%;
  top: 13%;
}

@media only screen and (max-width: 1199.98px) {
  .st_online_community_item.st_online_community_item_v2 .st_single_item.item_8 {
    width: 50px;
    height: 50px;
  }
}

.st_online_community_item.st_online_community_item_v2 .st_single_item.item_9 {
  width: 83px;
  height: 83px;
  right: -30px;
  top: 8%;
}

.st_online_community_item.st_online_community_item_v2 .st_single_item.item_10 {
  width: 71px;
  height: 71px;
  right: 12%;
  top: 30%;
}

.st_online_community_item.st_online_community_item_v2 .st_single_item.item_11 {
  width: 85px;
  height: 85px;
  right: 3%;
  top: 50%;
}

.st_online_community_item.st_online_community_item_v2 .st_single_item.item_12 {
  width: 67px;
  height: 67px;
  right: 13%;
  bottom: 20%;
}

@media only screen and (max-width: 991.98px) {
  .st_online_community_item.st_online_community_item_v2 .st_single_item {
    display: none;
  }
}

.st_online_community_item.st_online_community_item_v3 .st_single_item {
  opacity: .09;
}

.st_online_community_item.st_online_community_item_v3 .st_single_item.item_1 {
  bottom: 115px;
}

.st_online_community_item.st_online_community_item_v3 .st_single_item.item_2, .st_online_community_item.st_online_community_item_v3 .st_single_item.item_3, .st_online_community_item.st_online_community_item_v3 .st_single_item.item_4, .st_online_community_item.st_online_community_item_v3 .st_single_item.item_5 {
  width: 69px;
  height: 69px;
  left: 4%;
  top: 30%;
}

.st_online_community_item.st_online_community_item_v3 .st_single_item.item_3 {
  left: 16%;
  top: 7%;
}

.st_online_community_item.st_online_community_item_v3 .st_single_item.item_4 {
  left: 15%;
  bottom: 27%;
  top: auto;
}

.st_online_community_item.st_online_community_item_v3 .st_single_item.item_5 {
  left: 13%;
  top: auto;
  bottom: 3%;
}

.st_online_community_item.st_online_community_item_v3 .st_single_item.item_6, .st_online_community_item.st_online_community_item_v3 .st_single_item.item_7 {
  width: 89px;
  height: 89px;
  left: 32%;
  top: 13%;
}

.st_online_community_item.st_online_community_item_v3 .st_single_item.item_7 {
  left: 33%;
  top: 62%;
}

.st_online_community_item.st_online_community_item_v3 .st_single_item.item_8, .st_online_community_item.st_online_community_item_v3 .st_single_item.item_9, .st_online_community_item.st_online_community_item_v3 .st_single_item.item_13 {
  right: -20px;
  top: 8%;
  width: 83px;
  height: 83px;
}

.st_online_community_item.st_online_community_item_v3 .st_single_item.item_9 {
  right: -20px;
  bottom: 6%;
  top: auto;
}

.st_online_community_item.st_online_community_item_v3 .st_single_item.item_10 {
  right: 90px;
  top: 45%;
  width: 85px;
  height: 85px;
}

.st_online_community_item.st_online_community_item_v3 .st_single_item.item_11 {
  right: 15%;
  top: 27%;
  width: 71px;
  height: 71px;
}

.st_online_community_item.st_online_community_item_v3 .st_single_item.item_12 {
  right: 15%;
  bottom: 25%;
  width: 67px;
  height: 67px;
}

.st_online_community_item.st_online_community_item_v3 .st_single_item.item_13 {
  right: 30%;
  top: 10%;
}

.st_online_community_item.st_online_community_item_v3 .st_single_item.item_14 {
  right: 32%;
  bottom: 12%;
  width: 101px;
  height: 101px;
}

.st_online_community_item.st_online_community_item_v4 .st_single_item.item_1 {
  width: 121px;
  height: 121px;
  left: -25px;
  bottom: 20px;
}

@media only screen and (max-width: 991.98px) {
  .st_online_community_item.st_online_community_item_v4 .st_single_item.item_1 {
    width: 70px;
    height: 70px;
  }
}

.st_online_community_item.st_online_community_item_v4 .st_single_item.item_2 {
  width: 69px;
  height: 69px;
  left: 23%;
  bottom: 35%;
}

@media only screen and (max-width: 1199.98px) {
  .st_online_community_item.st_online_community_item_v4 .st_single_item.item_2 {
    left: 13%;
  }
}

@media only screen and (max-width: 991.98px) {
  .st_online_community_item.st_online_community_item_v4 .st_single_item.item_2 {
    display: none;
  }
}

.st_online_community_item.st_online_community_item_v4 .st_single_item.item_3, .st_online_community_item.st_online_community_item_v4 .st_single_item.item_4, .st_online_community_item.st_online_community_item_v4 .st_single_item.item_5 {
  width: 37px;
  height: 37px;
  left: 6%;
  top: 50%;
}

.st_online_community_item.st_online_community_item_v4 .st_single_item.item_4 {
  left: 22%;
  top: 32%;
}

.st_online_community_item.st_online_community_item_v4 .st_single_item.item_5 {
  left: auto;
  top: auto;
  right: 20%;
  bottom: 10%;
}

.st_online_community_item.st_online_community_item_v4 .st_single_item.item_6 {
  width: 57px;
  height: 57px;
  right: 8%;
  bottom: 27%;
}

@media only screen and (max-width: 991.98px) {
  .st_online_community_item.st_online_community_item_v4 .st_single_item.item_6 {
    display: none;
  }
}

.st_online_community_item.st_online_community_item_v4 .st_single_item.item_7 {
  width: 71px;
  height: 71px;
  right: 18%;
  top: 40%;
}

.st_online_community_item.st_online_community_item_v4 .st_single_item.item_8 {
  width: 83px;
  height: 83px;
  right: -13px;
  top: 23%;
}

@media only screen and (max-width: 767.98px) {
  .st_online_community_item.st_online_community_item_v4 .st_single_item {
    display: none;
  }
}

.st_v4_testimonial_slider .st_testimonial_waraper {
  display: flex;
  padding: 100px 0 55px;
}

@media only screen and (max-width: 991.98px) {
  .st_v4_testimonial_slider .st_testimonial_waraper {
    display: block;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_v4_testimonial_slider .st_testimonial_waraper {
    padding: 30px 0 0;
  }
}

.st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_left {
  position: relative;
  margin-left: 40px;
  height: auto;
  flex: 0 0 40%;
  margin-right: 100px;
  border-radius: 100%;
  box-shadow: 0 3px 50px rgba(0, 0, 0, 0.41);
  z-index: 0;
}

.st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_left:before {
  background-color: #0079BF;
  width: 456px;
  height: 456px;
  position: absolute;
  left: -40px;
  top: -40px;
  content: "";
  border-radius: 100%;
  z-index: -1;
}

.st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_left:after {
  content: "\f10d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  top: -40px;
  right: -7px;
  font-size: 7.5em;
  color: #0079BF;
  line-height: 1;
}

@media only screen and (max-width: 1199.98px) {
  .st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_left {
    width: 360px;
    height: 360px;
  }
  .st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_left::before {
    width: 100%;
    height: 100%;
    top: -61px;
  }
  .st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_left::after {
    top: -17px;
    font-size: 5.5em;
  }
}

@media only screen and (max-width: 991.98px) {
  .st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_left {
    width: 290px;
    height: 290px;
    margin: 0 auto;
  }
  .st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_left::before {
    top: -32px;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_left::before {
    display: none;
  }
}

.st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_right {
  padding: 60px 0;
}

@media only screen and (max-width: 991.98px) {
  .st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_right {
    text-align: center;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_right {
    padding-bottom: 0;
  }
}

.st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_right .st_testimonial_content {
  position: relative;
  margin-bottom: 50px;
}

.st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_right .st_testimonial_content h3 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_right .st_testimonial_content p {
  line-height: 2;
}

.st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_right .st_testimonial_content:before {
  content: "\f10e";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  bottom: -120px;
  right: 0;
  font-size: 5.5em;
  color: rgba(20, 15, 48, 0.04);
  line-height: 1;
  z-index: -1;
}

@media only screen and (max-width: 1199.98px) {
  .st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_right .st_testimonial_content:before {
    right: 0;
    font-size: 4.5em;
  }
}

.st_v4_testimonial_slider .st_testimonial_waraper .st_testimonial_right .st_testimonial_client h3 {
  color: #0079BF;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.st_v4_testimonial_slider.st_v4_testimonial_slider_v2 .st_testimonial_waraper {
  padding-top: 0;
}

.st_v4_testimonial_slider.st_v4_testimonial_slider_v2 .st_testimonial_waraper .st_testimonial_right {
  padding-bottom: 85px;
}

.st_v4_testimonial_slider.st_v4_testimonial_slider_v2 .st_testimonial_waraper .st_testimonial_right .st_testimonial_content::before {
  background-image: url(../images/v18/quote-object.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 64px;
}

@media only screen and (max-width: 767.98px) {
  .st_v4_testimonial_slider.st_v4_testimonial_slider_v2 .st_testimonial_waraper .st_testimonial_right .st_testimonial_content::before {
    display: none;
  }
}

.st_testimonial_avatar {
  position: absolute;
  bottom: 0;
  left: 60%;
  width: 600px;
}

.owl-carousel.st_v4_testimonial_slider .owl-nav {
  position: absolute;
  left: calc(40% + 140px);
  bottom: 55px;
}

@media only screen and (max-width: 991.98px) {
  .owl-carousel.st_v4_testimonial_slider .owl-nav {
    left: 50%;
    bottom: 45px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 767.98px) {
  .owl-carousel.st_v4_testimonial_slider .owl-nav {
    left: 0;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    width: 100%;
    bottom: inherit;
    text-align: center;
    display: none;
  }
}

.owl-carousel.st_v4_testimonial_slider .owl-nav .owl-prev,
.owl-carousel.st_v4_testimonial_slider .owl-nav .owl-next {
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  opacity: .4;
  width: 110px;
  border-radius: 0 50px 50px 0;
  padding: 16px 0 !important;
  display: inline-block;
  text-align: center;
}

.owl-carousel.st_v4_testimonial_slider .owl-nav .owl-prev:hover,
.owl-carousel.st_v4_testimonial_slider .owl-nav .owl-next:hover {
  opacity: 1;
}

@media only screen and (max-width: 767.98px) {
  .owl-carousel.st_v4_testimonial_slider .owl-nav .owl-prev,
  .owl-carousel.st_v4_testimonial_slider .owl-nav .owl-next {
    display: inline-block;
  }
}

.owl-carousel.st_v4_testimonial_slider .owl-nav .owl-prev {
  border-radius: 50px 0 0 50px;
  background: -moz-linear-gradient(left, rgba(245, 251, 255, 0.89) 0%, rgba(252, 254, 255, 0.89) 100%);
  background: -webkit-linear-gradient(left, rgba(245, 251, 255, 0.89) 0%, rgba(252, 254, 255, 0.89) 100%);
  background: linear-gradient(to right, rgba(245, 251, 255, 0.89) 0%, rgba(252, 254, 255, 0.89) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e3fcfeff', endColorstr='#e3f5fbff',GradientType=1 );
}

.owl-carousel.st_v4_testimonial_slider .owl-nav .owl-next {
  background: -moz-linear-gradient(left, rgba(252, 254, 255, 0.89) 0%, rgba(245, 251, 255, 0.89) 100%);
  background: -webkit-linear-gradient(left, rgba(252, 254, 255, 0.89) 0%, rgba(245, 251, 255, 0.89) 100%);
  background: linear-gradient(to right, rgba(252, 254, 255, 0.89) 0%, rgba(245, 251, 255, 0.89) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e3fcfeff', endColorstr='#e3f5fbff',GradientType=1 );
}

.owl-carousel.st_v4_testimonial_slider.st_v4_testimonial_slider_v2 .owl-nav {
  position: absolute;
  left: 0;
  bottom: 55px;
}

@media only screen and (max-width: 991.98px) {
  .owl-carousel.st_v4_testimonial_slider.st_v4_testimonial_slider_v2 .owl-nav {
    left: 50%;
    bottom: 20px;
  }
}

@media only screen and (max-width: 767.98px) {
  .owl-carousel.st_v4_testimonial_slider.st_v4_testimonial_slider_v2 .owl-nav {
    bottom: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    display: block;
  }
}

.owl-carousel.st_v4_testimonial_slider.st_v4_testimonial_slider_v2 .owl-nav .owl-next {
  background: #1d1068;
  background: -moz-linear-gradient(left, #1d1068 0%, #1d1068 10%, #301f8e 100%);
  background: -webkit-linear-gradient(left, #1d1068 0%, #1d1068 10%, #301f8e 100%);
  background: linear-gradient(to right, #1d1068 0%, #1d1068 10%, #301f8e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d1068', endColorstr='#301f8e',GradientType=1 );
}

.owl-carousel.st_v4_testimonial_slider.st_v4_testimonial_slider_v2 .owl-nav .owl-prev {
  background: #301f8e;
  background: -moz-linear-gradient(left, #301f8e 0%, #1d1068 90%, #1d1068 100%);
  background: -webkit-linear-gradient(left, #301f8e 0%, #1d1068 90%, #1d1068 100%);
  background: linear-gradient(to right, #301f8e 0%, #1d1068 90%, #1d1068 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#301f8e', endColorstr='#1d1068',GradientType=1 );
}

.slider-control-gigas.owl-carousel .owl-dot {
  background: rgba(80, 64, 160, 0.5) !important;
}

.slider-control-gigas.owl-carousel .owl-dot.active {
  background: #5040A0 !important;
}

.slider-control-perison-blue.owl-carousel .owl-dot {
  background: rgba(30, 67, 221, 0.5) !important;
}

.slider-control-perison-blue.owl-carousel .owl-dot.active {
  background: #1E43DD !important;
}

.slider-control-st-aqua.owl-carousel .owl-dot {
  background: rgba(0, 227, 246, 0.5) !important;
}

.slider-control-st-aqua.owl-carousel .owl-dot.active {
  background: #00E3F6 !important;
}

.slider-control-shamrock.owl-carousel .owl-dot {
  background: rgba(58, 217, 132, 0.5) !important;
}

.slider-control-shamrock.owl-carousel .owl-dot.active {
  background: #3AD984 !important;
}

.slider-control-yellow-orange.owl-carousel .owl-dot {
  background: rgba(255, 170, 59, 0.5) !important;
}

.slider-control-yellow-orange.owl-carousel .owl-dot.active {
  background: #FFAA3B !important;
}

.slider-control-st-pink.owl-carousel .owl-dot {
  background: rgba(242, 63, 121, 0.5) !important;
}

.slider-control-st-pink.owl-carousel .owl-dot.active {
  background: #F23F79 !important;
}

.slider-control-st-orange.owl-carousel .owl-dot {
  background: rgba(255, 93, 28, 0.5) !important;
}

.slider-control-st-orange.owl-carousel .owl-dot.active {
  background: #FF5D1C !important;
}

.slider-control-science-blue.owl-carousel .owl-dot {
  background: rgba(3, 102, 214, 0.5) !important;
}

.slider-control-science-blue.owl-carousel .owl-dot.active {
  background: #0366D6 !important;
}

.slider-control-emerald.owl-carousel .owl-dot {
  background: rgba(56, 209, 106, 0.5) !important;
}

.slider-control-emerald.owl-carousel .owl-dot.active {
  background: #38D16A !important;
}

.slider-control-chambray.owl-carousel .owl-dot {
  background: rgba(59, 62, 153, 0.5) !important;
}

.slider-control-chambray.owl-carousel .owl-dot.active {
  background: #3B3E99 !important;
}

.slider-control-medium-purple.owl-carousel .owl-dot {
  background: rgba(128, 102, 220, 0.5) !important;
}

.slider-control-medium-purple.owl-carousel .owl-dot.active {
  background: #8066DC !important;
}

.slider-control-lochmara.owl-carousel .owl-dot {
  background: rgba(0, 121, 191, 0.5) !important;
}

.slider-control-lochmara.owl-carousel .owl-dot.active {
  background: #0079BF !important;
}

.slider-control-froly.owl-carousel .owl-dot {
  background: rgba(244, 111, 108, 0.5) !important;
}

.slider-control-froly.owl-carousel .owl-dot.active {
  background: #F46F6C !important;
}

.slider-control-alpha-froly.owl-carousel .owl-dot {
  background: rgba(244, 111, 108, 0.5) !important;
}

.slider-control-alpha-froly.owl-carousel .owl-dot.active {
  background: rgba(244, 111, 108, 0.1) !important;
}

.slider-control-sweet-pink.owl-carousel .owl-dot {
  background: rgba(255, 163, 161, 0.5) !important;
}

.slider-control-sweet-pink.owl-carousel .owl-dot.active {
  background: #FFA3A1 !important;
}

.slider-control-koamaru.owl-carousel .owl-dot {
  background: rgba(29, 16, 104, 0.5) !important;
}

.slider-control-koamaru.owl-carousel .owl-dot.active {
  background: #1D1068 !important;
}

.slider-control-haiti.owl-carousel .owl-dot {
  background: rgba(20, 15, 48, 0.5) !important;
}

.slider-control-haiti.owl-carousel .owl-dot.active {
  background: #140F30 !important;
}

.slider-control-magnolia.owl-carousel .owl-dot {
  background: rgba(249, 248, 255, 0.5) !important;
}

.slider-control-magnolia.owl-carousel .owl-dot.active {
  background: #F9F8FF !important;
}

.slider-control-alice-blue.owl-carousel .owl-dot {
  background: rgba(245, 251, 255, 0.5) !important;
}

.slider-control-alice-blue.owl-carousel .owl-dot.active {
  background: #F5FBFF !important;
}

.slider-control-polar.owl-carousel .owl-dot {
  background: rgba(245, 249, 253, 0.5) !important;
}

.slider-control-polar.owl-carousel .owl-dot.active {
  background: #F5F9FD !important;
}

.slider-control-st-primary.owl-carousel .owl-dot {
  background: rgba(74, 81, 106, 0.5) !important;
}

.slider-control-st-primary.owl-carousel .owl-dot.active {
  background: #4A516A !important;
}

.slider-control-st-white.owl-carousel .owl-dot {
  background: rgba(255, 255, 255, 0.5) !important;
}

.slider-control-st-white.owl-carousel .owl-dot.active {
  background: #FFFFFF !important;
}

.slider-control-st-black.owl-carousel .owl-dot {
  background: rgba(0, 0, 0, 0.5) !important;
}

.slider-control-st-black.owl-carousel .owl-dot.active {
  background: #000000 !important;
}

.slider-control-st-fog.owl-carousel .owl-dot {
  background: rgba(214, 204, 255, 0.5) !important;
}

.slider-control-st-fog.owl-carousel .owl-dot.active {
  background: #D6CCFF !important;
}

.slider-control-st-gray.owl-carousel .owl-dot {
  background: rgba(153, 153, 153, 0.5) !important;
}

.slider-control-st-gray.owl-carousel .owl-dot.active {
  background: #999999 !important;
}

.slider-control-st-concrete.owl-carousel .owl-dot {
  background: rgba(243, 243, 243, 0.5) !important;
}

.slider-control-st-concrete.owl-carousel .owl-dot.active {
  background: #F3F3F3 !important;
}

.slider-control-st-violet.owl-carousel .owl-dot {
  background: rgba(213, 46, 239, 0.5) !important;
}

.slider-control-st-violet.owl-carousel .owl-dot.active {
  background: #D52EEF !important;
}

.slider-control-sun-glow.owl-carousel .owl-dot {
  background: rgba(252, 204, 44, 0.5) !important;
}

.slider-control-sun-glow.owl-carousel .owl-dot.active {
  background: #FCCC2C !important;
}

.slider-control-st-cabaret.owl-carousel .owl-dot {
  background: rgba(209, 56, 108, 0.5) !important;
}

.slider-control-st-cabaret.owl-carousel .owl-dot.active {
  background: #D1386C !important;
}

.slider-control-st-zircon.owl-carousel .owl-dot {
  background: rgba(248, 250, 255, 0.5) !important;
}

.slider-control-st-zircon.owl-carousel .owl-dot.active {
  background: #F8FAFF !important;
}

.slider-control-clear-day.owl-carousel .owl-dot {
  background: rgba(249, 255, 253, 0.5) !important;
}

.slider-control-clear-day.owl-carousel .owl-dot.active {
  background: #F9FFFD !important;
}

.slider-control-st-shamrock.owl-carousel .owl-dot {
  background: rgba(58, 217, 132, 0.5) !important;
}

.slider-control-st-shamrock.owl-carousel .owl-dot.active {
  background: #3AD984 !important;
}

.slider-control-blue-bell.owl-carousel .owl-dot {
  background: rgba(157, 158, 206, 0.5) !important;
}

.slider-control-blue-bell.owl-carousel .owl-dot.active {
  background: #9D9ECE !important;
}

.slider-control-snuff.owl-carousel .owl-dot {
  background: rgba(219, 215, 233, 0.5) !important;
}

.slider-control-snuff.owl-carousel .owl-dot.active {
  background: #DBD7E9 !important;
}

.slider-control-french-grey.owl-carousel .owl-dot {
  background: rgba(195, 195, 199, 0.5) !important;
}

.slider-control-french-grey.owl-carousel .owl-dot.active {
  background: #C3C3C7 !important;
}

.slider-control-ebony-clay.owl-carousel .owl-dot {
  background: rgba(31, 40, 51, 0.5) !important;
}

.slider-control-ebony-clay.owl-carousel .owl-dot.active {
  background: #1F2833 !important;
}

.slider-control-black-rock.owl-carousel .owl-dot {
  background: rgba(8, 3, 39, 0.5) !important;
}

.slider-control-black-rock.owl-carousel .owl-dot.active {
  background: #080327 !important;
}

.slider-control-st-lavender.owl-carousel .owl-dot {
  background: rgba(180, 181, 213, 0.5) !important;
}

.slider-control-st-lavender.owl-carousel .owl-dot.active {
  background: #B4B5D5 !important;
}

.slider-control-titan-white.owl-carousel .owl-dot {
  background: rgba(245, 245, 255, 0.5) !important;
}

.slider-control-titan-white.owl-carousel .owl-dot.active {
  background: #F5F5FF !important;
}

.slider-control-regent.owl-carousel .owl-dot {
  background: rgba(141, 155, 164, 0.5) !important;
}

.slider-control-regent.owl-carousel .owl-dot.active {
  background: #8D9BA4 !important;
}

.slider-control-shhamrock.owl-carousel .owl-dot {
  background: rgba(64, 209, 167, 0.5) !important;
}

.slider-control-shhamrock.owl-carousel .owl-dot.active {
  background: #40D1A7 !important;
}

.slider-control-gray-light.owl-carousel .owl-dot {
  background: rgba(240, 242, 248, 0.5) !important;
}

.slider-control-gray-light.owl-carousel .owl-dot.active {
  background: #f0f2f8 !important;
}

.slider-control-gray.owl-carousel .owl-dot {
  background: rgba(182, 190, 218, 0.5) !important;
}

.slider-control-gray.owl-carousel .owl-dot.active {
  background: #b6beda !important;
}

.slider-control-gray-dark.owl-carousel .owl-dot {
  background: rgba(47, 52, 71, 0.5) !important;
}

.slider-control-gray-dark.owl-carousel .owl-dot.active {
  background: #2f3447 !important;
}

.slider-control-blue-darker.owl-carousel .owl-dot {
  background: rgba(63, 69, 92, 0.5) !important;
}

.slider-control-blue-darker.owl-carousel .owl-dot.active {
  background: #3f455c !important;
}

.slider-control-blue-dark.owl-carousel .owl-dot {
  background: rgba(74, 81, 106, 0.5) !important;
}

.slider-control-blue-dark.owl-carousel .owl-dot.active {
  background: #4a516a !important;
}

.slider-control-blue.owl-carousel .owl-dot {
  background: rgba(43, 188, 255, 0.5) !important;
}

.slider-control-blue.owl-carousel .owl-dot.active {
  background: #2bbcff !important;
}

.slider-control-green.owl-carousel .owl-dot {
  background: rgba(42, 217, 211, 0.5) !important;
}

.slider-control-green.owl-carousel .owl-dot.active {
  background: #2ad9d3 !important;
}

.st_v2_testimonial_slider .owl-stage-outer {
  padding: 30px 30px;
  margin-left: -30px;
  width: calc(100% + 60px);
}

@media only screen and (max-width: 767.98px) {
  .st_v2_testimonial_slider .owl-stage-outer {
    padding: 0;
    margin-left: 0;
    width: 100%;
  }
}

.owl-item {
  opacity: 0;
  -webkit-transition: opacity 500ms;
  -ms-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  -o-transition: opacity 500ms;
  transition: opacity 500ms;
}

.owl-item.active {
  opacity: 1;
}

/**
* = 16. Screenshot Slider Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_swiper_slider {
  position: relative;
}

.st_swiper_slider .st_screenshot {
  overflow: hidden;
  padding: 40px 0;
}

.st_swiper_slider .st_screenshot .swiper-slide.swiper-slide-prev {
  filter: blur(2px);
}

.st_swiper_slider .st_screenshot .swiper-slide.swiper-slide-next {
  filter: blur(3px);
  opacity: 0.4;
}

.st_swiper_slider .st_screenshot .swiper-slide.swiper-slide-active {
  box-shadow: 0 10px 60px rgba(39, 39, 39, 0.05);
}

.st_swiper_slider .st_screenshot .swiper-slide img {
  width: 100%;
}

.st_swiper_slider .st_screenshot .swiper-slide .swiper-slide-shadow-right, .st_swiper_slider .st_screenshot .swiper-slide .swiper-slide-shadow-left {
  background-image: none;
}

.st_swiper_slider .swiper-slide img {
  width: 100%;
}

.st_swiper_slider .swiper-slide .swiper-slide-shadow-right,
.st_swiper_slider .swiper-slide .swiper-slide-shadow-left {
  background-image: none;
}

@media only screen and (max-width: 991.98px) {
  .st_swiper_slider {
    padding-top: 0;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_swiper_slider {
    padding: 0;
  }
}

.swiper-button-prev,
.swiper-button-next {
  background-image: none;
  border-radius: 100%;
  background-color: #F5F9FD;
  color: #8066DC;
  font-size: 1.5em;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  position: absolute;
  opacity: 1;
  left: -90px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  outline: none;
  -webkit-text-stroke: 1.5px #F5F9FD;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

@media only screen and (max-width: 991.98px) {
  .swiper-button-prev,
  .swiper-button-next {
    background: #5040A0;
    color: #FFFFFF;
    box-shadow: 0 10px 30px rgba(80, 64, 160, 0.3);
    -webkit-text-stroke: 1.5px #5040A0;
    display: none !important;
  }
}

.swiper-button-next {
  left: auto;
  right: -90px;
}

@media only screen and (max-width: 1199.98px) {
  div.swiper-button-prev {
    left: 0;
  }
  div.swiper-button-next {
    right: 0;
  }
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background: #5040A0;
  color: #FFFFFF;
  box-shadow: 0 10px 30px rgba(80, 64, 160, 0.3);
  -webkit-text-stroke: 1.5px #5040A0;
}

.st_screenshot_wrap {
  position: relative;
}

.st_screenshot_v2 {
  overflow: hidden;
  border-left: 15px solid rgba(242, 243, 245, 0.85);
  border-right: 15px solid rgba(242, 243, 245, 0.85);
  width: calc(100% - 140px);
  margin: auto;
}

.st_screenshot_v2 .swiper-slide.swiper-slide-prev {
  transform: translateX(500px) !important;
  opacity: .85;
}

.st_screenshot_v2 .swiper-slide.swiper-slide-next {
  transform: translateX(-500px) !important;
  opacity: .85;
}

.st_screenshot_v2 .swiper-slide {
  box-shadow: 0 0 50px rgba(39, 39, 39, 0.08);
}

.st_screenshot_v2 .swiper-slide img {
  width: 100%;
}

.st_screenshot_v2 .swiper-slide .swiper-slide-shadow-right, .st_screenshot_v2 .swiper-slide .swiper-slide-shadow-left {
  background-image: none;
}

@media only screen and (max-width: 991.98px) {
  .st_screenshot_v2 {
    width: calc(100% - 60px);
  }
}

@media only screen and (max-width: 767.98px) {
  .st_screenshot_v2 {
    width: 100%;
  }
}

.st_screenshot_v2 + .swiper-button-prev,
.st_screenshot_v2 + .swiper-button-prev + .swiper-button-next {
  background-color: #fff;
  color: #00E3F6;
  font-size: 1.5em;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  position: absolute;
  opacity: 1;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  z-index: 1;
  -webkit-text-stroke: 1.5px #FFFFFF;
}

.st_screenshot_v2 + .swiper-button-prev:hover,
.st_screenshot_v2 + .swiper-button-prev + .swiper-button-next:hover {
  background-color: #00E3F6;
  color: #FFFFFF;
  box-shadow: 0 10px 30px rgba(0, 227, 246, 0.3);
  -webkit-text-stroke: 1.5px #00E3F6;
}

.st_screenshot_v2 + .swiper-button-prev + .swiper-button-next {
  left: auto;
  right: 0;
}

@media only screen and (max-width: 991.98px) {
  .st_screenshot_area {
    overflow-x: hidden;
  }
}

.st_screenshot_area .st_screenshot_warap.st_screenshot_warap_v3 .st_screenshot_tab {
  position: relative;
  margin-bottom: 75px;
}

.st_screenshot_area .st_screenshot_warap.st_screenshot_warap_v3 .st_screenshot_tab:before {
  width: 100%;
  height: 6px;
  position: absolute;
  left: 0;
  top: 45px;
  content: "";
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(255, 247, 246, 0.8) 49%, rgba(255, 247, 246, 0.8) 50%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(255, 247, 246, 0.8) 49%, rgba(255, 247, 246, 0.8) 50%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(255, 247, 246, 0.8) 49%, rgba(255, 247, 246, 0.8) 50%, rgba(255, 255, 255, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#ccffffff',GradientType=1 );
}

.st_screenshot_area .st_screenshot_warap.st_screenshot_warap_v3 .st_screenshot_tab .nav-pills > li {
  display: inline-block;
  margin: 0 25px;
  text-transform: capitalize;
}

@media only screen and (max-width: 991.98px) {
  .st_screenshot_area .st_screenshot_warap.st_screenshot_warap_v3 .st_screenshot_tab .nav-pills > li {
    margin: 0 15px;
  }
}

.st_screenshot_area .st_screenshot_warap.st_screenshot_warap_v3 .st_screenshot_tab .nav-pills > li a {
  padding: 15px 26px;
}

.st_screenshot_area .st_screenshot_warap.st_screenshot_warap_v3 .st_screenshot_tab .nav-pills > li a.active {
  color: #F46F6C;
}

.st_screenshot_area .st_screenshot_warap.st_screenshot_warap_v3 .st_screenshot_tab .nav-pills > li a.active:before {
  content: none;
}

@media only screen and (max-width: 991.98px) {
  .st_screenshot_area .st_screenshot_warap.st_screenshot_warap_v3 .st_screenshot_tab .nav-pills > li a {
    padding: 0;
    background: none;
  }
}

.st_swiper_slider_center_frame {
  position: relative;
  padding: 75px 0;
}

@media only screen and (max-width: 767.98px) {
  .st_swiper_slider_center_frame {
    width: 80%;
    margin: auto;
    padding: 0;
  }
}

.st_swiper_slider_center_frame .st_swiper_frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 385px;
  text-align: center;
}

@media only screen and (max-width: 991.98px) {
  .st_swiper_slider_center_frame .st_swiper_frame {
    display: none;
  }
}

.st_swiper_slider_center_frame .st_screenshot_center_frame {
  overflow: hidden;
}

.st_swiper_slider_center_frame .st_screenshot_center_frame .swiper-slide {
  box-shadow: 10px 0 80px rgba(0, 0, 0, 0.05);
}

.st_swiper_slider_center_frame .st_screenshot_center_frame .swiper-slide img {
  width: 100%;
}

.st_swiper_slider_center_frame .st_screenshot_center_frame .swiper-slide .swiper-slide-shadow-right, .st_swiper_slider_center_frame .st_screenshot_center_frame .swiper-slide .swiper-slide-shadow-left {
  background-image: none;
}

.st_swiper_slider_center_frame .st_screenshot_center_frame .swiper-slide-nth-prev-2 {
  transform: translate3d(245px, 0px, 0px) !important;
}

.st_swiper_slider_center_frame .st_screenshot_center_frame .swiper-slide-nth-prev-3 {
  transform: translate3d(416px, 0px, 0px) !important;
}

.st_swiper_slider_center_frame .st_screenshot_center_frame .swiper-slide-nth-next-2 {
  transform: translate3d(-245px, 0px, 0px) !important;
}

.st_swiper_slider_center_frame .st_screenshot_center_frame .swiper-slide-nth-next-3 {
  transform: translate3d(-416px, 0px, 0px) !important;
}

.st_swiper_slider_center_frame .swiper-button-prev,
.st_swiper_slider_center_frame .swiper-button-next {
  border-radius: 100%;
  background-color: #FFFFFF;
  color: #00E3F6;
  font-size: 1.5em;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  position: absolute;
  opacity: 1;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  -webkit-text-stroke: 1.5px #F5F9FD;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.st_swiper_slider_center_frame .swiper-button-prev:hover,
.st_swiper_slider_center_frame .swiper-button-next:hover {
  background: #00E3F6;
  color: #FFFFFF;
  box-shadow: 0 10px 30px rgba(0, 227, 246, 0.3);
  -webkit-text-stroke: 1.5px #00E3F6;
}

.st_swiper_slider_center_frame .swiper-button-prev {
  left: -90px;
}

.st_swiper_slider_center_frame .swiper-button-prev.swiper-buton-style-v2,
.st_swiper_slider_center_frame .swiper-button-next.swiper-buton-style-v2 {
  background-color: #FFFFFF;
  color: #FF5D1C;
  -webkit-text-stroke: 1.5px #FFFFFF;
}

.st_swiper_slider_center_frame .swiper-button-prev.swiper-buton-style-v2:hover, .st_swiper_slider_center_frame .swiper-button-prev.swiper-buton-style-v2:active, .st_swiper_slider_center_frame .swiper-button-prev.swiper-buton-style-v2:focus,
.st_swiper_slider_center_frame .swiper-button-next.swiper-buton-style-v2:hover,
.st_swiper_slider_center_frame .swiper-button-next.swiper-buton-style-v2:active,
.st_swiper_slider_center_frame .swiper-button-next.swiper-buton-style-v2:focus {
  background-color: #FF5D1C;
  color: #FFFFFF;
  -webkit-text-stroke: 1.5px #FF5D1C;
}

.st_swiper_slider_center_frame .swiper-button-next {
  right: -90px;
}

@media only screen and (max-width: 1199.98px) {
  .st_swiper_slider_center_frame div.swiper-button-prev {
    left: 0;
  }
  .st_swiper_slider_center_frame div.swiper-button-next {
    right: 0;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_swiper_slider_center_frame div.swiper-button-prev {
    display: none;
  }
  .st_swiper_slider_center_frame div.swiper-button-next {
    display: none;
  }
}

@media only screen and (max-width: 767.98px) {
  .swiper-button-prev,
  .swiper-button-next {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    margin-top: -26.5px;
    width: 45px;
    height: 45px;
  }
}

/**
* = 17. Subscriptions Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_news_letter_area {
  text-align: center;
  position: relative;
}

.st_news_letter_area .st_subscribe_waraper {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 140px 0;
}

.st_news_letter_area .st_subscribe_waraper h2 {
  margin: 0 0 40px;
}

.st_subscribe {
  position: relative;
  width: 554px;
  max-width: 100%;
  margin: 0 auto;
}

.st_subscribe i {
  font-size: 1.125em;
  left: 43px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 575.98px) {
  .st_subscribe i {
    left: 30px;
    top: 35px;
  }
}

.st_subscribe input[type="email"] {
  width: 100%;
  height: 70px;
  padding: 0 80px;
  border: 1px solid #F2EFFF;
  border-radius: 50px;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

@media only screen and (max-width: 575.98px) {
  .st_subscribe input[type="email"] {
    padding: 0 30px 0 70px;
  }
}

.st_subscribe input[type="submit"] {
  border-radius: 50px;
  position: absolute;
  border: none;
  height: 54px;
  text-transform: uppercase;
  font-weight: 600px;
  color: #FFFFFF;
  padding: 0 40px;
  right: 8px;
  top: 8px;
  cursor: pointer;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.16);
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

@media only screen and (max-width: 575.98px) {
  .st_subscribe input[type="submit"] {
    position: relative;
    width: 100%;
    right: inherit;
    top: inherit;
  }
}

.st_subscription_info {
  margin-top: 11px;
  position: absolute;
  bottom: -36px;
  width: 100%;
  display: none;
  text-align: center;
}

.st_news_left_item h2 {
  color: #F5F9FD;
  font-weight: 300;
  margin: 0 0 20px;
  font-size: 2.125em;
}

@media only screen and (max-width: 991.98px) {
  .st_news_left_item h2 {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_news_left_item h2 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

@media only screen and (max-width: 991.98px) {
  .st_news_left_item {
    width: 100%;
  }
}

.st_subscribe .st-subscription-form .st_subscribe_email.st_subscribe_email_transparent[type="email"] {
  background-color: transparent;
  width: 100%;
  height: 60px;
  padding: 0 40px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  color: #FFFFFF;
}

.st_subscribe .st-subscription-form input[type="submit"] {
  top: 0;
  right: 0;
  height: 60px;
}

@media only screen and (max-width: 991.98px) {
  .st_subscribe {
    width: 80%;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_subscribe {
    width: 100%;
  }
}

.st_subscribe_info_icon {
  font-size: 0.875em;
  margin-top: 20px;
}

.st_subscribe_info_icon i {
  margin-right: 10px;
}

.st_subscribe_info_icon p {
  display: inline-block;
}

.st_subscription_wrapper > h2 {
  font-size: 2.750em;
  margin-bottom: 75px;
}

@media only screen and (max-width: 1199.98px) {
  .st_subscription_wrapper > h2 {
    font-size: 38px;
    font-size: 2.375rem;
  }
}

@media only screen and (max-width: 991.98px) {
  .st_subscription_wrapper > h2 {
    font-size: 34px;
    font-size: 2.125rem;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_subscription_wrapper > h2 {
    font-size: 28px;
    font-size: 1.75rem;
    margin-bottom: 50px;
  }
}

.st_subscribe.st_subscribe_flat {
  width: 470px;
  max-width: 100%;
  margin: 0 auto;
  z-index: 0;
}

.st_subscribe.st_subscribe_flat::before {
  border-radius: 5px;
  z-index: -1;
  width: calc(100% + 10px);
  height: 70px;
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.25);
}

@media only screen and (max-width: 767.98px) {
  .st_subscribe.st_subscribe_flat::before {
    display: none;
  }
}

.st_subscribe.st_subscribe_flat input[type="email"] {
  background-color: #FFFFFF;
  width: 100%;
  height: 60px;
  padding: 0 40px;
  border: 2px solid #FFFFFF;
  border-radius: 5px;
}

.st_subscribe.st_subscribe_flat input[type="submit"] {
  border-radius: 5px;
  background: #5040A0;
  color: #FFFFFF;
  box-shadow: 0 5px 35px rgba(128, 102, 220, 0.5);
  top: 3px;
  right: 3px;
  padding: 0 25px;
  font-weight: 600;
}

@media only screen and (max-width: 575.98px) {
  .st_subscribe.st_subscribe_flat input[type="submit"] {
    right: 0;
  }
}

.st_subscribe.st_subscribe_flat.st_about_subscription:before {
  background-color: rgba(255, 255, 255, 0.25);
}

.st_subscribe.st_subscribe_flat.st_about_subscription input[type="submit"] {
  box-shadow: 0 5px 35px rgba(0, 227, 246, 0.5);
  padding: 0 30px;
}

.st_subscribe.st_subscribe_flat.st_coming_soon:before {
  background-color: rgba(0, 0, 0, 0.05);
}

.st_subscribe.st_subscribe_flat.st_coming_soon input[type="submit"] {
  box-shadow: 0 5px 35px rgba(30, 67, 221, 0.3);
  padding: 0 30px;
}

/**
* = 18. Pricing Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.pricing-wrapper {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 10px 20px 0 rgba(182, 190, 218, 0.5);
  text-align: center;
}

@media only screen and (max-width: 767.98px) {
  .pricing-wrapper {
    max-width: 300px;
    margin: 0 auto;
  }
}

.pricing-header {
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #4a516a;
  color: #FFFFFF;
  font-size: 0.875em;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
}

.pricing-wrapper.highlighted .pricing-header {
  background-color: #2bbcff;
}

.pricing-price {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  padding: 0 20px;
  font-weight: bold;
}

.pricing-price .price {
  font-size: 50px;
}

.pricing-price .text {
  font-size: 30px;
}

.pricing-wrapper.highlighted .pricing-price {
  color: #2bbcff;
}

.pricing-body {
  flex-grow: 1;
  padding: 0 20px 15px;
}

.pricing-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pricing-body ul > li {
  margin-bottom: 15px;
}

.pricing-body ul > li.plus {
  font-weight: bold;
}

.pricing-body ul > li.plus > img {
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
}

/**
* = 19. Client Slider Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_client_slider.owl-carousel .owl-item img {
  width: auto;
  margin: 0 auto;
}

/**
* = 20. Tab Features Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_custom-tab {
  background-size: 200% auto;
  box-shadow: 0 11px 30px rgba(28, 76, 223, 0.36);
  border-radius: 3px;
  position: relative;
}

.st_custom-tab ul.nav.nav-pills {
  margin: 0;
  padding: 0;
  list-style: none;
  flex-wrap: inherit;
  overflow: hidden;
}

.st_custom-tab ul.nav.nav-pills li {
  width: 100%;
}

.st_custom-tab ul.nav.nav-pills li a {
  color: #FFFFFF;
  font-size: 1.125em;
  height: 100%;
  display: block;
  text-align: center;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  padding: 50px 30px;
  border-radius: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.st_custom-tab ul.nav.nav-pills li a span {
  margin-left: 16px;
}

@media only screen and (max-width: 1199.98px) {
  .st_custom-tab ul.nav.nav-pills li a span {
    display: block;
    text-align: center;
    margin-top: 18px;
  }
}

@media only screen and (max-width: 991.98px) {
  .st_custom-tab ul.nav.nav-pills li a span {
    display: none;
  }
}

@media only screen and (max-width: 1199.98px) {
  .st_custom-tab ul.nav.nav-pills li a img {
    display: inline-block;
  }
}

@media only screen and (max-width: 1199.98px) {
  .st_custom-tab ul.nav.nav-pills li a {
    padding: 35px 20px;
  }
}

@media only screen and (max-width: 1199.98px) {
  .st_custom-tab ul.nav.nav-pills li a {
    padding: 35px 5px;
  }
}

.st_tab_box {
  background-color: #FFFFFF;
  padding: 75px;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.02);
}

@media only screen and (max-width: 991.98px) {
  .st_tab_box {
    padding: 55px 50px;
    display: block;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_tab_box {
    padding: 35px 30px;
    display: block;
  }
}

.st_tab_box .st_tab_single_img {
  flex: 0 0 50%;
  margin-right: 56px;
}

@media only screen and (max-width: 991.98px) {
  .st_tab_box .st_tab_single_img {
    margin-right: 0;
    margin-bottom: 50px;
    text-align: center;
  }
}

.st_tab_box .st_tab_content h3 {
  font-size: 1.5em;
  margin: 0 0 33px;
}

.st_tab_box .st_tab_content p {
  line-height: 2;
}

/**
* = 21. Download Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_download_area {
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.st_download_content_waraper {
  padding: 150px 0 90px;
}

.st_download_content_waraper h2 {
  font-weight: 300;
  margin: 0 0 50px;
  line-height: 1.7;
}

@media only screen and (max-width: 991.98px) {
  .st_download_content_waraper {
    padding-top: 100px;
    padding-bottom: 55px;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_download_content_waraper {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.st_download_single_img {
  position: relative;
  z-index: 1;
}

.st_download_area .st_single_shape_01 {
  background: -moz-linear-gradient(left, rgba(91, 135, 255, 0.66) 0%, rgba(30, 105, 230, 0.66) 90%, rgba(23, 102, 227, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(91, 135, 255, 0.66) 0%, rgba(30, 105, 230, 0.66) 90%, rgba(23, 102, 227, 0) 100%);
  background: linear-gradient(to right, rgba(91, 135, 255, 0.66) 0%, rgba(30, 105, 230, 0.66) 90%, rgba(23, 102, 227, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a85b87ff', endColorstr='#001766e3',GradientType=1 );
  width: 700px;
  height: 110px;
  position: absolute;
  top: 35%;
  transform: rotate(42deg);
  left: -145px;
  z-index: -1;
}

.st_download_area .st_single_shape_02 {
  background: -moz-linear-gradient(left, rgba(5, 197, 241, 0.25) 0%, rgba(255, 255, 255, 0.25) 100%);
  background: -webkit-linear-gradient(left, rgba(5, 197, 241, 0.25) 0%, rgba(255, 255, 255, 0.25) 100%);
  background: linear-gradient(to right, rgba(5, 197, 241, 0.25) 0%, rgba(255, 255, 255, 0.25) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4005c5f1', endColorstr='#40ffffff',GradientType=1 );
  width: 700px;
  height: 110px;
  position: absolute;
  top: 35%;
  transform: rotate(-47deg);
  right: -162px;
  z-index: -1;
}

.st_app_download_warap {
  padding: 215px 0;
}

.st_app_download_warap .st_app_download_title {
  margin-bottom: 55px;
}

.st_app_download_warap .st_app_download_title p {
  font-size: 1.5em;
  margin-top: 20px;
}

.st_app_download_warap .st_app_download_subscription {
  margin-bottom: 32px;
}

.st_app_download_warap .st_app_download_subscription .st_subscribe.st_subscribe_flat::before {
  opacity: 0;
}

.st_app_download_warap .st_app_download_subscription .st_subscribe.st_subscribe_flat input[type="email"] {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.07);
}

.st_app_download_warap .st_app_download_subscription .st_subscribe.st_subscribe_flat input[type="submit"] {
  box-shadow: 0 5px 35px rgba(56, 209, 106, 0.5);
}

.st_app_download_warap .st_app_download_subscription .st_subscribe_info p {
  color: #C3C3C7;
  font-size: .75em;
  margin-top: 20px;
}

.st_app_download_warap .st_or {
  font-weight: bold;
  font-size: 1.125em;
  margin-bottom: 30px;
}

.st_app_download_warap .st_app_download_btn .st_default_btn {
  min-width: 200px;
}

.st_app_download_warap .st_app_download_btn .st_default_btn:hover, .st_app_download_warap .st_app_download_btn .st_default_btn:focus {
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.22);
}

.download_area_v2::before {
  background-image: url(../images/v6/feature-bg.png);
  content: "";
  position: absolute;
  width: 100%;
  height: 300px;
  top: -293px;
  left: 0;
  background-repeat: repeat;
  background-size: auto;
  background-position: bottom center;
}

.st_select_warap {
  display: inline-block;
  position: relative;
  margin-right: 25px;
  z-index: 0;
}

.st_select_warap::after {
  width: 7px;
  height: 7px;
  position: absolute;
  left: inherit;
  top: 50%;
  content: "";
  border-bottom: 2px solid #F23F79;
  border-right: 2px solid #F23F79;
  display: block;
  right: 30px;
  transform: rotate(45deg);
  margin-top: -4px;
}

.st_select_warap select {
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #4A516A;
  font-size: 0.9em;
  padding: 12px 60px 12px 25px;
  border: none;
  background-color: #FFFFFF;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.15), 0 5px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  border-radius: 50px;
  min-height: 45px;
}

@media only screen and (max-width: 575.98px) {
  .st_select_warap {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }
  .st_select_warap select {
    width: 100%;
  }
}

/**
* = 22. Contact Block Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_contact_area .st_contact_hedding_title {
  padding: 0 0 40px;
}

.st_contact_area .st_contact_hedding_title h2 {
  font-size: 1.5em;
  margin: 0 0 10px;
}

.st_contact_area .st_contact_hedding_title p {
  font-size: 0.875em;
}

.st_accordion_item .card {
  position: relative;
  border: none;
}

.st_accordion_item .card .card-header {
  padding: 25px 30px;
  margin-bottom: 0;
  background-color: #FFFFFF;
  border-bottom: 1px solid rgba(30, 67, 221, 0.09);
  transition: all 0.4s;
}

@media only screen and (max-width: 1199.98px) {
  .st_accordion_item .card .card-header {
    padding: 20px 20px;
  }
}

.st_accordion_item .card .card-header h3 {
  font-size: 1.125em;
  color: #140F30;
  margin: 0;
  font-weight: bold;
}

@media only screen and (max-width: 1199.98px) {
  .st_accordion_item .card .card-header h3 {
    font-size: 15px;
    font-size: 0.9375rem;
    float: left;
    width: calc(100% - (44px + 18px + 20px + 10px));
    white-space: normal;
    text-align: left;
    font-weight: normal;
    margin-right: 10px;
    margin-top: 7px;
  }
}

.st_accordion_item .card .card-header .btn-link {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1199.98px) {
  .st_accordion_item .card .card-header .btn-link {
    display: block;
    overflow: hidden;
  }
}

.st_accordion_item .card .card-header .btn {
  padding: 0;
}

.st_accordion_item .st_accordion_icon_1 {
  margin-right: 18px;
  background-color: #FFFFFF;
  color: #1E43DD;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  box-shadow: 0 6px 20px rgba(30, 67, 221, 0.13);
}

@media only screen and (max-width: 1199.98px) {
  .st_accordion_item .st_accordion_icon_1 {
    float: left;
    box-shadow: none;
    border: 1px solid;
  }
}

.st_accordion_item .st_st_accordion_icon_2 {
  font-size: .75em;
  color: #00E3F6;
  border: 2px solid #00E3F6;
  width: 20px;
  height: 20px;
  line-height: 18px;
  margin-left: auto;
  justify-self: flex-end;
  position: relative;
  border-radius: 100%;
}

@media only screen and (max-width: 1199.98px) {
  .st_accordion_item .st_st_accordion_icon_2 {
    float: right;
    margin-top: 11px;
  }
}

.st_accordion_item .card.card__active .card-header {
  border: none;
  background-color: transparent !important;
}

.st_accordion_item .card.card__active .card-header h3 {
  color: #fff;
}

.st_accordion_item .card.card__active .st_st_accordion_icon_2 {
  color: #fff;
  border: 2px solid #fff;
}

.st_accordion_item .card.card__active .st_st_accordion_icon_2:before {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  top: 0px;
  left: 3px;
}

.st_accordion_item .card.card__active .st_st_accordion_icon_2 i {
  display: none;
}

.st_accordion_item .card-body {
  padding: 0px 25px 25px 92px;
  font-size: .875em;
  line-height: 1.7;
  color: #FFFFFF;
}

@media only screen and (max-width: 1199.98px) {
  .st_accordion_item .card-body {
    padding: 0px 25px 25px 25px;
  }
}

.st_contact_waraper {
  background-color: #fff;
  padding: 70px 56px;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.02);
}

@media only screen and (max-width: 767.98px) {
  .st_contact_waraper {
    height: inherit !important;
    padding: 36px 30px;
  }
}

.st_contact_waraper .st_contact_form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767.98px) {
  .st_contact_waraper .st_contact_form {
    display: block;
    margin-bottom: 35px;
  }
}

.st_contact_waraper .st_input_item {
  flex: 0 0 48%;
  margin: 0 0 35px;
}

.st_contact_waraper .st_input_item:last-child {
  margin: 0;
}

.st_contact_waraper .st_input_item input, .st_contact_waraper .st_input_item textarea {
  background-color: #F5F9FD;
  width: 100%;
  border: none;
  border: 1px solid rgba(30, 67, 221, 0.09);
  padding: 14px 25px;
  font-size: 14px;
  font-size: 0.875rem;
  border-radius: 3px;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.02);
}

.st_contact_waraper .st_input_item textarea {
  height: 220px;
  resize: none;
}

.st_contact_waraper .st_input_item input[type="submit"] {
  background-color: #1ED9F9;
  width: 100%;
  color: #fff;
  font-weight: 600;
  border: none;
  font-size: .813em;
  padding: 14px 25px;
  margin: 0;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.16);
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.st_contact_waraper .st_input_item input[type="submit"]:hover {
  background-color: #333;
  color: #fff;
}

/**
* = 23. Simple Content Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_cta_content h2 {
  font-size: 2.750em;
  margin: 0 0 20px;
  font-weight: 400;
}

@media only screen and (max-width: 767.98px) {
  .st_cta_content h2 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.st_cta_content p {
  line-height: 2;
  margin: 0 0 60px;
}

.st_cta_content p:last-child {
  margin-bottom: 0;
}

.st_cta_content.st_cta_content_v2 h2 {
  font-size: 2.125em;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767.98px) {
  .st_cta_content.st_cta_content_v2 h2 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.st_cta_single_img {
  position: relative;
  left: -80px;
  width: calc(100% + 40px);
}

@media only screen and (max-width: 991.98px) {
  .st_cta_single_img {
    position: relative;
    left: inherit;
    width: auto;
    max-width: 100%;
    text-align: center;
  }
}

.st_online_community_item.st_simple_call_2_action .st_single_item.item_1 {
  width: 121px;
  height: 121px;
  left: 0;
  bottom: 27%;
}

.st_online_community_item.st_simple_call_2_action .st_single_item.item_2 {
  width: 89px;
  height: 89px;
  left: 28%;
  bottom: 23%;
}

.st_online_community_item.st_simple_call_2_action .st_single_item.item_3 {
  left: 6%;
  top: 24%;
}

.st_online_community_item.st_simple_call_2_action .st_single_item.item_4 {
  width: 70px;
  height: 70px;
  left: 20%;
  top: 18%;
}

.st_online_community_item.st_simple_call_2_action .st_single_item.item_5 {
  width: 89px;
  height: 89px;
  left: 32%;
  top: 25%;
}

.st_online_community_item.st_simple_call_2_action .st_single_item.item_6 {
  width: 69px;
  height: 69px;
  left: 17%;
  bottom: 45%;
  right: auto;
}

@media only screen and (max-width: 1199.98px) {
  .st_online_community_item.st_simple_call_2_action .st_single_item.item_6 {
    display: none;
  }
}

.st_online_community_item.st_simple_call_2_action .st_single_item.item_7 {
  width: 101px;
  height: 101px;
  right: 24%;
  top: 50%;
}

@media only screen and (max-width: 1199.98px) {
  .st_online_community_item.st_simple_call_2_action .st_single_item.item_7 {
    width: 70px;
    height: 70px;
    top: 58%;
  }
}

.st_online_community_item.st_simple_call_2_action .st_single_item.item_8 {
  width: 83px;
  height: 83px;
  right: 28%;
  top: 13%;
}

.st_online_community_item.st_simple_call_2_action .st_single_item.item_9 {
  width: 83px;
  height: 83px;
  right: -30px;
  top: 8%;
}

.st_online_community_item.st_simple_call_2_action .st_single_item.item_10 {
  width: 71px;
  height: 71px;
  right: 12%;
  top: 30%;
}

@media only screen and (max-width: 1199.98px) {
  .st_online_community_item.st_simple_call_2_action .st_single_item.item_10 {
    display: none;
  }
}

.st_online_community_item.st_simple_call_2_action .st_single_item.item_11 {
  width: 85px;
  height: 85px;
  right: 3%;
  top: 50%;
}

@media only screen and (max-width: 1199.98px) {
  .st_online_community_item.st_simple_call_2_action .st_single_item.item_11 {
    display: none;
  }
}

.st_online_community_item.st_simple_call_2_action .st_single_item.item_12 {
  width: 67px;
  height: 67px;
  right: 13%;
  bottom: 20%;
}

@media only screen and (max-width: 1199.98px) {
  .st_online_community_item.st_simple_call_2_action .st_single_item {
    display: none;
  }
}

.short-width-content {
  width: 80%;
  margin: 0 auto;
}

.very-short-width-content {
  width: 66%;
  margin: 0 auto;
}

@media only screen and (max-width: 991.98px) {
  .very-short-width-content {
    width: 100%;
  }
}

.st-over-content {
  position: relative;
  z-index: 3;
}

.st-white-overley {
  position: relative;
}

.st-white-overley::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2;
  top: 0;
  left: 0;
}

.st-black-overley {
  position: relative;
}

.st-black-overley::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 2;
  top: 0;
  left: 0;
}

.st_cta_wrap {
  text-align: center;
  padding: 75px 0;
  background-size: cover;
  background-position: center center;
  position: relative;
  border-radius: 10px;
  z-index: 0;
}

.st_cta_wrap .st_box_shadow {
  width: 85%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 30px 70px rgba(244, 111, 108, 0.51);
  z-index: -1;
}

.st_cta_wrap:before {
  width: 170px;
  height: 100%;
  position: absolute;
  left: 5px;
  top: 0;
  content: "";
  background-image: url(../images/v14/cta-01.png);
  background-size: auto 100%;
}

.st_cta_wrap:after {
  width: 190px;
  height: 100%;
  position: absolute;
  left: auto;
  top: 0;
  content: "";
  background-image: url(../images/v14/cta-02.png);
  right: 0;
  background-size: auto 100%;
}

@media only screen and (max-width: 991.98px) {
  .st_cta_wrap::before, .st_cta_wrap::after {
    display: none;
  }
}

.st_full_height_content {
  margin-top: -12px;
}

.wave-curved-bg.st-wave-expend {
  padding-top: 140px;
  margin-top: -150px;
}

@media only screen and (max-width: 991.98px) {
  .wave-curved-bg.st-wave-expend {
    padding-top: 0;
    margin-top: 0;
  }
}

.st_bg_after {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

/**
* = 24. Showcase Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_screenshot_warap .st_screenshot_tab {
  position: relative;
  margin-bottom: 75px;
}

@media only screen and (max-width: 767.98px) {
  .st_screenshot_warap .st_screenshot_tab {
    margin-bottom: 40px;
  }
}

.st_screenshot_warap .st_screenshot_tab:before {
  width: 100%;
  height: 5px;
  position: absolute;
  left: 0;
  top: 35px;
  content: "";
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(255, 238, 225, 0.8) 50%, rgba(255, 255, 254, 0.8) 99%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.8) 0%, rgba(255, 238, 225, 0.8) 50%, rgba(255, 255, 254, 0.8) 99%, rgba(255, 255, 255, 0.8) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(255, 238, 225, 0.8) 50%, rgba(255, 255, 254, 0.8) 99%, rgba(255, 255, 255, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#ccffffff',GradientType=1 );
}

@media only screen and (max-width: 767.98px) {
  .st_screenshot_warap .st_screenshot_tab:before {
    display: none;
  }
}

.st_screenshot_warap .st_screenshot_tab .nav {
  display: block;
}

.st_screenshot_warap .st_screenshot_tab .nav-pills > li a.active {
  color: #FF5D1C !important;
  position: relative;
  background-color: transparent;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.st_screenshot_warap .st_screenshot_tab .nav-pills > li a.active:before {
  width: 300px;
  height: 3px;
  position: absolute;
  left: 50%;
  top: 35px;
  content: "";
  transform: translateX(-50%);
  background: #FFFFFF;
  background: -moz-linear-gradient(left, #FFFFFF 0%, #FF5D1C 50%, #FFFFFF 100%);
  background: -webkit-linear-gradient(left, #FFFFFF 0%, #FF5D1C 50%, #FFFFFF 100%);
  background: linear-gradient(to right, #FFFFFF 0%, #FF5D1C 50%, #FFFFFF 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-white', endColorstr='$color-white',GradientType=1 );
}

@media only screen and (max-width: 767.98px) {
  .st_screenshot_warap .st_screenshot_tab .nav-pills > li a.active:before {
    display: none;
  }
}

.st_screenshot_warap .st_screenshot_tab ul.nav.nav-pills {
  margin: 0;
  padding: 0;
  list-style: none;
}

.st_screenshot_warap .st_screenshot_tab ul.nav.nav-pills li {
  display: inline-block;
  margin: 0 45px;
  text-transform: capitalize;
}

@media only screen and (max-width: 991.98px) {
  .st_screenshot_warap .st_screenshot_tab ul.nav.nav-pills li {
    margin: 0 15px;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_screenshot_warap .st_screenshot_tab ul.nav.nav-pills li {
    margin: 6px 15px;
  }
}

.st_screenshot_warap .st_screenshot_tab ul.nav.nav-pills li a {
  color: #140F30;
  font-size: 14px;
  font-size: 0.875rem;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  display: block;
  text-transform: uppercase;
  padding: 0;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.st_screenshot_warap .st_tab_single_img {
  display: inline-block;
  position: relative;
  background-color: #FFFFFF;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.19);
}

.st_screenshot_warap .st_tab_single_img img {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.08);
  padding: 0 30px;
  position: relative;
  z-index: 2;
  width: calc(100% - 60px);
}

@media only screen and (max-width: 991.98px) {
  .st_screenshot_warap .st_tab_single_img img {
    padding: 0;
    box-shadow: none;
    width: 100%;
  }
  .st_screenshot_warap .st_tab_single_img::before {
    display: none;
  }
}

/**
* = 25. Map Block Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
div.st_map {
  width: 100%;
  height: 485px;
  text-align: center;
}

@media only screen and (max-width: 991.98px) {
  div.st_map {
    height: 350px;
  }
}

@media only screen and (max-width: 767.98px) {
  div.st_map {
    height: 300px;
  }
}

.st_marker {
  background-color: #FF5D1C;
  border-radius: 100%;
  border: 7px solid #ffffff;
}

.st_marker:before {
  width: 90px;
  height: 90px;
  position: absolute;
  left: -39px;
  top: -39px;
  content: "";
  background: rgba(255, 93, 28, 0.4);
  -webkit-animation: 2s ease-in-out 0s normal none infinite running waves;
  -ms-animation: 2s ease-in-out 0s normal none infinite running waves;
  -moz-animation: 2s ease-in-out 0s normal none infinite running waves;
  -o-animation: 2s ease-in-out 0s normal none infinite running waves;
  animation: 2s ease-in-out 0s normal none infinite running waves;
  border-radius: 100%;
  z-index: -1;
}

.st_contact_page .st_marker {
  background-color: #FFFFFF;
  border: 7px solid #1E43DD;
}

.st_contact_page .st_marker:before {
  background: rgba(30, 67, 221, 0.1);
}

/**
* = 26. List Elements Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_faq_item {
  margin-bottom: 50px;
  padding-right: 50px;
}

.st_faq_item .st_faq_single_item {
  margin-bottom: 20px;
  overflow: hidden;
}

.st_faq_item .st_faq_single_item i {
  font-size: 1.125em;
  margin-right: 15px;
}

.st_faq_item .st_faq_single_item h3 {
  display: inline-block;
  margin-bottom: 0;
}

.st_faq_item .st-faq-content {
  padding-left: 38px;
}

.st_faq_item .st-faq-content p {
  font-size: 0.9em;
  line-height: 1.85;
}

.st_faq_item .st-faq-content p:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 767.98px) {
  .st_faq_item {
    padding-right: 0;
  }
  .st_faq_item .st_faq_single_item i {
    width: 20px;
    float: left;
    margin-top: 7px;
  }
  .st_faq_item .st_faq_single_item h3 {
    display: block;
    float: left;
    width: calc(100% - (20px + 15px));
  }
}

.st_faq_area.st_section_padding {
  padding-bottom: 100px;
}

.st-shortcode-wrapper {
  position: relative;
}

.st-shortcode-wrapper ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.st-shortcode-wrapper ul li {
  width: 11.1111111111%;
  float: left;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 0.8;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.st-shortcode-wrapper ul li:hover, .st-shortcode-wrapper ul li:focus, .st-shortcode-wrapper ul li:active {
  opacity: 1;
}

.st-shortcode-wrapper ul li a {
  display: block;
  text-align: center;
  padding: 25px 10px;
}

.st-shortcode-wrapper ul li a:hover, .st-shortcode-wrapper ul li a:focus, .st-shortcode-wrapper ul li a:active {
  color: #0366D6;
}

.st-shortcode-wrapper ul li a:hover i,
.st-shortcode-wrapper ul li a:hover h2, .st-shortcode-wrapper ul li a:focus i,
.st-shortcode-wrapper ul li a:focus h2, .st-shortcode-wrapper ul li a:active i,
.st-shortcode-wrapper ul li a:active h2 {
  color: currentColor;
}

.st-shortcode-wrapper ul li a img {
  display: inline-block;
  width: 32px;
  margin-bottom: 8px;
}

.st-shortcode-wrapper ul li a i {
  font-size: 34px;
  font-size: 2.125rem;
  line-height: 1;
  color: currentColor;
}

.st-shortcode-wrapper ul li a h2 {
  font-size: 12px;
  font-size: 0.75rem;
  margin-bottom: 0;
  color: currentColor;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 991.98px) {
  .st-shortcode-wrapper ul li {
    width: 16.6666666667%;
  }
}

@media only screen and (max-width: 575.98px) {
  .st-shortcode-wrapper ul li {
    width: 33.3333333333%;
  }
}

.st-shortcode-wrapper ul.ls-column-8 li {
  width: 12.5%;
}

.st-shortcode-wrapper ul.ls-column-7 li {
  width: 14.2857142857%;
}

.st-shortcode-wrapper ul.ls-column-6 li {
  width: 16.6666666667%;
}

.st-shortcode-wrapper ul.ls-column-5 li {
  width: 20%;
}

@media only screen and (max-width: 575.98px) {
  .st-shortcode-wrapper ul.ls-column-5 li {
    width: 33.3333333333%;
  }
}

.st-shortcode-wrapper ul.ls-column-4 li {
  width: 25%;
}

@media only screen and (max-width: 575.98px) {
  .st-shortcode-wrapper ul.ls-column-4 li {
    width: 33.3333333333%;
  }
}

.st-shortcode-wrapper ul.ls-column-3 li {
  width: 33.3333333333%;
}

.st-shortcode-wrapper ul.ls-column-2 li {
  width: 50%;
}

.st-shortcode-wrapper ul.ls-column-1 li {
  width: 100%;
}

.st-shortcode-wrapper::before,
.st-shortcode-wrapper::after,
.st-shortcode-wrapper ul::before,
.st-shortcode-wrapper ul::after {
  content: "";
  display: table;
  clear: both;
}

/**
* = 27. Special Elements Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_dotted_bg {
  width: 415px;
  height: 415px;
  background-image: url(../images/v6/dotted-2.png);
  background-size: cover;
  padding: 65px;
  position: relative;
}

.st_dotted_bg .st_dotted_border {
  border: 2px dashed #BBD3F3;
  width: 100%;
  height: 100%;
  padding: 60px;
  border-radius: 100%;
}

.st_dotted_bg .st_dotted_border .st_solid_bg {
  background-color: #3A3D98;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: relative;
  left: 0;
  top: 0;
}

.st_dotted_bg .st_dotted_border .st_solid_bg:before {
  width: 181px;
  height: 181px;
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  transform: translate(-50%, -50%);
  background-image: url(../images/v6/dotted.png);
  border-radius: 100%;
}

.st_dotted_bg .st_dotted_border .st_solid_bg .st_s {
  color: #3A3D98;
  height: 100%;
  font-size: 3.5em;
  font-weight: bold;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lato", sans-serif;
  z-index: 0;
  top: -8px;
}

.st_dotted_bg .st_dotted_border .st_solid_bg .st_s:before, .st_dotted_bg .st_dotted_border .st_solid_bg .st_s:after {
  width: 75px;
  height: 75px;
  position: absolute;
  left: 50%;
  top: 55%;
  content: "";
  transform: translate(-50%, -50%) rotate(-30deg);
  background-color: #fff;
  border-radius: 15px;
  z-index: -1;
}

@media only screen and (max-width: 767.98px) {
  .st_dotted_bg .st_dotted_border .st_solid_bg .st_s:before, .st_dotted_bg .st_dotted_border .st_solid_bg .st_s:after {
    width: 55px;
    height: 55px;
    border-radius: 6px;
  }
}

.st_dotted_bg .st_dotted_border .st_solid_bg .st_s:after {
  background-color: rgba(255, 255, 255, 0.33);
  transform: translate(-50%, -50%) rotate(30deg);
}

.st_dotted_bg .st_icon_item .st_about_icon {
  position: absolute;
  font-size: 1.750em;
  background-color: #F23F79;
  color: #fff;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  box-shadow: 0 5px 20px rgba(242, 63, 121, 0.45);
  border-radius: 100%;
}

@media only screen and (max-width: 767.98px) {
  .st_dotted_bg .st_icon_item .st_about_icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 1em;
  }
}

.st_dotted_bg .st_icon_item .st_about_icon.icon_01 {
  left: 110px;
  top: 0;
}

.st_dotted_bg .st_icon_item .st_about_icon.icon_02 {
  left: 70px;
  bottom: 30px;
}

.st_dotted_bg .st_icon_item .st_about_icon.icon_03 {
  right: 7px;
  bottom: 165px;
}

@media only screen and (max-width: 1199.98px) {
  .st_dotted_bg {
    width: 365px;
    height: 365px;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_dotted_bg {
    width: 320px;
    height: 320px;
    padding: 40px;
  }
}

.st_banner_sidebar_item {
  position: relative;
  width: 408px;
  height: 408px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 35px;
  border-radius: 30px;
}

.st_banner_sidebar_item .st_banner_single_item_01 {
  width: 100%;
  height: 100%;
  padding: 28px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 30px;
}

.st_banner_sidebar_item .st_banner_single_item_01 .st_banner_single_item_02 {
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: #fff;
  border-radius: 20px;
}

.st_banner_sidebar_item .st_banner_single_item_01 .st_banner_single_item_02 .st_banner_single_item_03 {
  width: 100%;
  height: 100%;
  background-color: #8066DC;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10em;
  text-transform: uppercase;
  box-shadow: 0 0 30px rgba(128, 102, 220, 0.65);
  border-radius: 20px;
}

.st_banner_sidebar_item.st_banner_sidebar_item_v2 {
  width: 306px;
  height: 306px;
  background-color: #8066DC;
  padding: 26px;
  margin: 0 auto;
}

.st_banner_sidebar_item.st_banner_sidebar_item_v2 .st_banner_single_item_01 {
  padding: 21px;
}

.st_banner_sidebar_item.st_banner_sidebar_item_v2 .st_banner_single_item_01 .st_banner_single_item_02 {
  padding: 25px;
}

.st_banner_sidebar_item.st_banner_sidebar_item_v2 .st_banner_single_item_01 .st_banner_single_item_02 .st_banner_single_item_03 {
  font-size: 8.125em;
  color: #FFFFFF;
}

/**
* = 28. Clients Elements Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_client_service_left_item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
}

@media only screen and (max-width: 575.98px) {
  .st_client_service_left_item {
    display: block;
  }
}

.st_client_service_left_item .st_client_service_single_item {
  background: #F5FBFF;
  border-radius: 15px;
  margin-bottom: 24px;
  flex: 0 0 48%;
  padding: 60px 38px;
}

.st_client_service_left_item .st_client_service_single_item:nth-child(1) {
  position: relative;
  top: 90px;
}

.st_client_service_left_item .st_client_service_single_item:nth-child(3) {
  position: relative;
  top: 90px;
  z-index: 3;
}

.st_client_service_left_item .st_client_service_single_item .st_client_service_img {
  height: 60px;
  width: auto;
  margin-bottom: 25px;
}

.st_client_service_left_item .st_client_service_single_item h3 {
  font-size: 1.250em;
  color: #0079BF;
  margin: 0 0 14px;
}

.st_client_service_left_item .st_client_service_single_item p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.8;
  margin-bottom: 0;
}

@media only screen and (max-width: 575.98px) {
  .st_client_service_left_item .st_client_service_single_item {
    width: 100%;
  }
  .st_client_service_left_item .st_client_service_single_item:nth-child(1) {
    position: relative;
    top: inherit;
    width: 100%;
    margin-top: 50px;
  }
  .st_client_service_left_item .st_client_service_single_item:nth-child(3) {
    top: inherit;
  }
}

.st_client_waraper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.st_client_waraper .st_client_item {
  flex: 0 0 25%;
  min-height: 110px;
}

@media only screen and (max-width: 991.98px) {
  .st_client_waraper .st_client_item {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_client_waraper {
    justify-content: left;
    text-align: left;
  }
  .st_client_waraper .st_client_item {
    flex: 0 0 33%;
  }
}

@media only screen and (max-width: 575.98px) {
  .st_client_waraper {
    display: block;
  }
}

/**
* = 29. Work process Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_work_single_item {
  position: relative;
  text-align: center;
}

.st_work_single_item::after {
  width: 63px;
  height: 36px;
  position: absolute;
  left: auto;
  top: 50px;
  content: "";
  background-image: url(../images/v9/arrow.png);
  right: -40px;
  background-repeat: no-repeat;
}

.st_work_single_item img {
  margin-bottom: 40px;
}

@media only screen and (max-width: 991.98px) {
  .st_work_single_item::after {
    position: relative;
    left: auto;
    top: inherit;
    right: inherit;
    display: block;
    margin: 50px auto;
    transform: rotate(90deg);
  }
}

.last_child_none .st_work_single_item::after {
  display: none;
}

/**
* = 30. Faqs Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_faq_tab_waraper .st_faqs_tab {
  position: relative;
  margin-bottom: 75px;
  text-align: center;
}

.st_faq_tab_waraper .st_faqs_tab .nav {
  display: block;
}

.st_faq_tab_waraper .st_faqs_tab .nav-pills {
  margin: 0;
  padding: 0;
  list-style: none;
}

.st_faq_tab_waraper .st_faqs_tab .nav-pills > li {
  display: inline-block;
  margin: 0;
  text-transform: capitalize;
}

@media only screen and (max-width: 991.98px) {
  .st_faq_tab_waraper .st_faqs_tab .nav-pills > li {
    margin: 10px 0;
  }
}

.st_faq_tab_waraper .st_faqs_tab .nav-pills > li a {
  color: #4A516A;
  font-weight: 600;
  display: block;
  text-transform: uppercase;
  padding: 5px 20px;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.st_faq_tab_waraper .st_faqs_tab .nav-pills > li a.active {
  color: #0079BF;
  position: relative;
  background-color: rgba(0, 121, 191, 0.05);
  border-radius: 50px;
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.st_faq_item .st_faq_single_item i {
  color: #0079BF;
}

.nav-pills.nav-pills-bg-gigas > li a.active {
  color: #5040A0 !important;
  background-color: rgba(80, 64, 160, 0.05) !important;
}

.nav-pills.nav-pills-bg-perison-blue > li a.active {
  color: #1E43DD !important;
  background-color: rgba(30, 67, 221, 0.05) !important;
}

.nav-pills.nav-pills-bg-st-aqua > li a.active {
  color: #00E3F6 !important;
  background-color: rgba(0, 227, 246, 0.05) !important;
}

.nav-pills.nav-pills-bg-shamrock > li a.active {
  color: #3AD984 !important;
  background-color: rgba(58, 217, 132, 0.05) !important;
}

.nav-pills.nav-pills-bg-yellow-orange > li a.active {
  color: #FFAA3B !important;
  background-color: rgba(255, 170, 59, 0.05) !important;
}

.nav-pills.nav-pills-bg-st-pink > li a.active {
  color: #F23F79 !important;
  background-color: rgba(242, 63, 121, 0.05) !important;
}

.nav-pills.nav-pills-bg-st-orange > li a.active {
  color: #FF5D1C !important;
  background-color: rgba(255, 93, 28, 0.05) !important;
}

.nav-pills.nav-pills-bg-science-blue > li a.active {
  color: #0366D6 !important;
  background-color: rgba(3, 102, 214, 0.05) !important;
}

.nav-pills.nav-pills-bg-emerald > li a.active {
  color: #38D16A !important;
  background-color: rgba(56, 209, 106, 0.05) !important;
}

.nav-pills.nav-pills-bg-chambray > li a.active {
  color: #3B3E99 !important;
  background-color: rgba(59, 62, 153, 0.05) !important;
}

.nav-pills.nav-pills-bg-medium-purple > li a.active {
  color: #8066DC !important;
  background-color: rgba(128, 102, 220, 0.05) !important;
}

.nav-pills.nav-pills-bg-lochmara > li a.active {
  color: #0079BF !important;
  background-color: rgba(0, 121, 191, 0.05) !important;
}

.nav-pills.nav-pills-bg-froly > li a.active {
  color: #F46F6C !important;
  background-color: rgba(244, 111, 108, 0.05) !important;
}

.nav-pills.nav-pills-bg-alpha-froly > li a.active {
  color: rgba(244, 111, 108, 0.1) !important;
  background-color: rgba(244, 111, 108, 0.05) !important;
}

.nav-pills.nav-pills-bg-sweet-pink > li a.active {
  color: #FFA3A1 !important;
  background-color: rgba(255, 163, 161, 0.05) !important;
}

.nav-pills.nav-pills-bg-koamaru > li a.active {
  color: #1D1068 !important;
  background-color: rgba(29, 16, 104, 0.05) !important;
}

.nav-pills.nav-pills-bg-haiti > li a.active {
  color: #140F30 !important;
  background-color: rgba(20, 15, 48, 0.05) !important;
}

.nav-pills.nav-pills-bg-magnolia > li a.active {
  color: #F9F8FF !important;
  background-color: rgba(249, 248, 255, 0.05) !important;
}

.nav-pills.nav-pills-bg-alice-blue > li a.active {
  color: #F5FBFF !important;
  background-color: rgba(245, 251, 255, 0.05) !important;
}

.nav-pills.nav-pills-bg-polar > li a.active {
  color: #F5F9FD !important;
  background-color: rgba(245, 249, 253, 0.05) !important;
}

.nav-pills.nav-pills-bg-st-primary > li a.active {
  color: #4A516A !important;
  background-color: rgba(74, 81, 106, 0.05) !important;
}

.nav-pills.nav-pills-bg-st-white > li a.active {
  color: #FFFFFF !important;
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.nav-pills.nav-pills-bg-st-black > li a.active {
  color: #000000 !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.nav-pills.nav-pills-bg-st-fog > li a.active {
  color: #D6CCFF !important;
  background-color: rgba(214, 204, 255, 0.05) !important;
}

.nav-pills.nav-pills-bg-st-gray > li a.active {
  color: #999999 !important;
  background-color: rgba(153, 153, 153, 0.05) !important;
}

.nav-pills.nav-pills-bg-st-concrete > li a.active {
  color: #F3F3F3 !important;
  background-color: rgba(243, 243, 243, 0.05) !important;
}

.nav-pills.nav-pills-bg-st-violet > li a.active {
  color: #D52EEF !important;
  background-color: rgba(213, 46, 239, 0.05) !important;
}

.nav-pills.nav-pills-bg-sun-glow > li a.active {
  color: #FCCC2C !important;
  background-color: rgba(252, 204, 44, 0.05) !important;
}

.nav-pills.nav-pills-bg-st-cabaret > li a.active {
  color: #D1386C !important;
  background-color: rgba(209, 56, 108, 0.05) !important;
}

.nav-pills.nav-pills-bg-st-zircon > li a.active {
  color: #F8FAFF !important;
  background-color: rgba(248, 250, 255, 0.05) !important;
}

.nav-pills.nav-pills-bg-clear-day > li a.active {
  color: #F9FFFD !important;
  background-color: rgba(249, 255, 253, 0.05) !important;
}

.nav-pills.nav-pills-bg-st-shamrock > li a.active {
  color: #3AD984 !important;
  background-color: rgba(58, 217, 132, 0.05) !important;
}

.nav-pills.nav-pills-bg-blue-bell > li a.active {
  color: #9D9ECE !important;
  background-color: rgba(157, 158, 206, 0.05) !important;
}

.nav-pills.nav-pills-bg-snuff > li a.active {
  color: #DBD7E9 !important;
  background-color: rgba(219, 215, 233, 0.05) !important;
}

.nav-pills.nav-pills-bg-french-grey > li a.active {
  color: #C3C3C7 !important;
  background-color: rgba(195, 195, 199, 0.05) !important;
}

.nav-pills.nav-pills-bg-ebony-clay > li a.active {
  color: #1F2833 !important;
  background-color: rgba(31, 40, 51, 0.05) !important;
}

.nav-pills.nav-pills-bg-black-rock > li a.active {
  color: #080327 !important;
  background-color: rgba(8, 3, 39, 0.05) !important;
}

.nav-pills.nav-pills-bg-st-lavender > li a.active {
  color: #B4B5D5 !important;
  background-color: rgba(180, 181, 213, 0.05) !important;
}

.nav-pills.nav-pills-bg-titan-white > li a.active {
  color: #F5F5FF !important;
  background-color: rgba(245, 245, 255, 0.05) !important;
}

.nav-pills.nav-pills-bg-regent > li a.active {
  color: #8D9BA4 !important;
  background-color: rgba(141, 155, 164, 0.05) !important;
}

.nav-pills.nav-pills-bg-shhamrock > li a.active {
  color: #40D1A7 !important;
  background-color: rgba(64, 209, 167, 0.05) !important;
}

.nav-pills.nav-pills-bg-gray-light > li a.active {
  color: #f0f2f8 !important;
  background-color: rgba(240, 242, 248, 0.05) !important;
}

.nav-pills.nav-pills-bg-gray > li a.active {
  color: #b6beda !important;
  background-color: rgba(182, 190, 218, 0.05) !important;
}

.nav-pills.nav-pills-bg-gray-dark > li a.active {
  color: #2f3447 !important;
  background-color: rgba(47, 52, 71, 0.05) !important;
}

.nav-pills.nav-pills-bg-blue-darker > li a.active {
  color: #3f455c !important;
  background-color: rgba(63, 69, 92, 0.05) !important;
}

.nav-pills.nav-pills-bg-blue-dark > li a.active {
  color: #4a516a !important;
  background-color: rgba(74, 81, 106, 0.05) !important;
}

.nav-pills.nav-pills-bg-blue > li a.active {
  color: #2bbcff !important;
  background-color: rgba(43, 188, 255, 0.05) !important;
}

.nav-pills.nav-pills-bg-green > li a.active {
  color: #2ad9d3 !important;
  background-color: rgba(42, 217, 211, 0.05) !important;
}

/**
* = 31. Login Block Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_user_login_area {
  background-size: 90% 100%;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.st_user_login_area .st_user_login {
  width: 35%;
  margin: 0 auto;
}

.st_user_login_area .st_user_login .st_input_item {
  background-color: #F5F9FD;
  padding: 20px 24px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.st_user_login_area .st_user_login .st_input_item label {
  display: block;
  color: #333333;
  font-size: .75em;
}

.st_user_login_area .st_user_login .st_input_item input,
.st_user_login_area .st_user_login .st_input_item textarea {
  padding: 0;
  border: none;
  color: #BEC3CC;
  width: 100%;
  background-color: transparent;
  box-shadow: none;
}

.st_user_login_area .st_user_login .st_input_submit input[type="submit"] {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.12);
}

@media only screen and (max-width: 991.98px) {
  .st_user_login_area .st_user_login {
    width: 90%;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_user_login_area .st_user_login {
    width: 100%;
  }
}

.st_user_login_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.st_user_login_wrap .st_input_column {
  flex: 0 0 46%;
}

@media only screen and (max-width: 767.98px) {
  .st_user_login_wrap {
    -webkit-display: block;
    display: block;
  }
}

.st_user_login.st_user_login_v2 {
  width: 75%;
}

@media only screen and (max-width: 991.98px) {
  .st_user_login.st_user_login_v2 {
    width: 100%;
  }
}

/**
* = 32. Info Box Elements Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_info_box {
  display: flex;
  padding: 60px 40px;
  border-radius: 5px;
}

.st_info_box .st_info_box_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  margin-right: 40px;
}

.st_info_box .st_info_box_content h3 {
  font-size: 1.5em;
  margin-bottom: 25px;
  font-weight: 400;
}

.st_info_box .st_info_box_content p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 2;
}

@media only screen and (max-width: 767.98px) {
  .st_info_box {
    display: block;
    padding: 50px 15px 40px;
    text-align: center;
  }
  .st_info_box .st_info_box_icon {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

/**
* = 33. Image Mapping Styles
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_image_mapping_wrap {
  position: relative;
}

.st_image_mapping_wrap > img {
  box-shadow: 0 40px 66px rgba(0, 0, 0, 0.03);
}

.st_image_mapping_wrap.st_image_mapping_wrap_v2 {
  width: 80%;
  margin: 0 auto;
}

.st_image_mapping_wrap.st_image_mapping_wrap_v2 > img {
  box-shadow: none;
}

@media only screen and (max-width: 991.98px) {
  .st_image_mapping_wrap.st_image_mapping_wrap_v2 {
    width: 100%;
  }
}

@media only screen and (max-width: 767.98px) {
  .st_image_mapping_wrap .st_tooltip {
    display: none;
  }
}

.st_tooltip {
  position: absolute;
  width: 50px;
  height: 50px;
  border: 9px solid #F3F0FB;
  background-color: #8066DC;
  border-radius: 50%;
  cursor: help;
}

.st_tooltip span {
  position: absolute;
  display: inline-block;
  width: auto;
  background-color: #ECEAF6;
  white-space: nowrap;
  color: #140F30;
  line-height: 1;
  padding: 16px 24px;
  font-weight: bold;
  top: -45px;
  left: 50%;
  opacity: 0;
  -webkit-transform: translateX(-50%) scale3d(0, 0, 0);
  -ms-transform: translateX(-50%) scale3d(0, 0, 0);
  -moz-transform: translateX(-50%) scale3d(0, 0, 0);
  -o-transform: translateX(-50%) scale3d(0, 0, 0);
  transform: translateX(-50%) scale3d(0, 0, 0);
  -webkit-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.st_tooltip span::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 14px solid #ECEAF6;
  position: absolute;
  bottom: -13px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.st_tooltip::before, .st_tooltip::after {
  content: "";
  display: block;
  background-color: #fff;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.st_tooltip::before {
  width: 1px;
  height: 50%;
}

.st_tooltip::after {
  height: 1px;
  width: 50%;
}

.st_tooltip:hover span {
  opacity: 1;
  -webkit-transform: translateX(-50%) scale3d(1, 1, 1);
  -ms-transform: translateX(-50%) scale3d(1, 1, 1);
  -moz-transform: translateX(-50%) scale3d(1, 1, 1);
  -o-transform: translateX(-50%) scale3d(1, 1, 1);
  transform: translateX(-50%) scale3d(1, 1, 1);
}

.st_tooltip.st_tooltip_post_1 {
  top: 115px;
  right: 100px;
}

.st_tooltip.st_tooltip_post_2 {
  bottom: 245px;
  left: 165px;
}

.st_tooltip.st_tooltip_post_3 {
  bottom: 67px;
  left: 500px;
}

.st_tooltip.st_tooltip_post_4 {
  right: 23px;
  bottom: 50px;
}

.st_image_mapping_wrap_v2 .st_tooltip.st_tooltip_post_4 {
  right: 128px;
  bottom: 81px;
}

@media only screen and (max-width: 991.98px) {
  .st_image_mapping_wrap_v2 .st_tooltip.st_tooltip_post_3 {
    left: 250px;
  }
}

/**
* = 34. logo
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.saastrend-logo {
  width: 220px;
  height: 220px;
  margin: 0 auto 80px;
  position: relative;
}

.saastrend-logo h1 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 9rem;
  line-height: 220px;
  display: inline-block;
  margin: 0;
  position: relative;
  z-index: 5;
}

.saastrend-logo span {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.saastrend-logo span.st-logo-eff1 {
  background-color: #00E3F6;
}

.saastrend-logo span.st-logo-eff2 {
  opacity: 0.2;
  background-color: #FFFFFF;
  -webkit-animation: rotation 9s infinite linear;
  -ms-animation: rotation 9s infinite linear;
  -moz-animation: rotation 9s infinite linear;
  -o-animation: rotation 9s infinite linear;
  animation: rotation 9s infinite linear;
}

@media only screen and (max-width: 767.98px) {
  .saastrend-logo {
    width: 150px;
    height: 150px;
  }
  .saastrend-logo h1 {
    font-size: 5rem;
    line-height: 150px;
  }
}

/**
* = 35. BreadCrumb
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.st_breadcrumb_content ul li {
  line-height: 1;
  display: inline-block;
  position: relative;
}

.st_breadcrumb_content ul li.breadcrumb-seperator {
  display: inline-block;
  padding: 7.5px 7px 7.5px 2px;
  top: 3.2px;
  text-align: center;
}

.st_breadcrumb_content ul li.breadcrumb-seperator::after {
  content: "/";
  display: block;
  color: currentColor;
  position: absolute;
  top: 2.5px;
  transform: rotate(6deg);
  font-size: 0.8em;
}

.st_breadcrumb_content ul li a {
  font-size: 14px;
  font-size: 0.875rem;
}

.st_breadcrumb_wrapper.st_breadcrumb_2 {
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 0;
}

.st_breadcrumb_wrapper.st_breadcrumb_2:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: -moz-linear-gradient(left, rgba(28, 72, 222, 0.8) 0%, rgba(0, 227, 246, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(28, 72, 222, 0.8) 0%, rgba(0, 227, 246, 0.8) 100%);
  background: linear-gradient(to right, rgba(28, 72, 222, 0.8) 0%, rgba(0, 227, 246, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc1c48de', endColorstr='#cc00e3f6',GradientType=1 );
}

.st_breadcrumb_wrapper.st_breadcrumb_3 {
  background-repeat: no-repeat;
  background-position: center bottom;
}

/**
* = 36. Info Elements
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.single-content-info {
  border-bottom: 1px solid rgba(30, 67, 221, 0.09);
  position: relative;
  padding: 20px 0;
}

.single-content-info .single-info-icon {
  width: 60px;
  height: 60px;
  line-height: 65px;
  border-radius: 100%;
  margin-right: 15px;
  text-align: center;
  float: left;
}

.single-content-info .single-info-icon i {
  font-size: 22px;
  font-size: 1.375rem;
}

.single-content-info .single-info-content {
  float: left;
  width: calc(100% - 75px);
  margin-top: 15px;
}

.single-content-info .single-info-content p {
  font-size: 18px;
  font-size: 1.125rem;
  color: #140F30;
}

.single-content-info .single-info-content p:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 991.98px) {
  .single-content-info .single-info-content br {
    display: none;
  }
}

.single-content-info::before, .single-content-info::after {
  content: "";
  display: table;
  clear: both;
}

.single-content-info:first-child {
  padding-top: 0;
}

.single-content-info.single-content-info-v2 {
  border: 1px solid #F5FAFF;
  padding: 30px;
}

.single-content-info.single-content-info-v2:first-child {
  padding-top: 30px;
}

.single-content-info.single-content-info-v2 .single-info-icon {
  float: none;
  margin: 0 auto;
}

.single-content-info.single-content-info-v2 .single-info-content {
  float: none;
  width: 100%;
}

.st_box_shadow {
  box-shadow: 0 6px 20px rgba(30, 67, 221, 0.13);
}

/**
* = 37. Inner Pages
*
* @package: SassTrend
* @version: 1.0
*
* ------------------------------------------------------------------------- */
.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 100%;
  vertical-align: middle;
}

.icon.icon-arrow {
  background-image: url("../images/icon-arrow.svg");
}

.icon.icon-done {
  background-image: url("../images/icon-done.svg");
}

.icon.icon-plus {
  background-image: url("../images/icon-plus.svg");
}

.contact-form-label {
  margin-left: 10px;
  margin-bottom: 5px;
  color: rgba(74, 81, 106, 0.6);
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
}

.contact-form-group {
  position: relative;
}

.contact-form-group > input {
  height: 60px;
  padding: 0 138px 0 18px;
  border: 2px solid rgba(182, 190, 218, 0.8);
  border-radius: 10px;
  color: rgba(74, 81, 106, 0.6);
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
}

.contact-form-group > input::placeholder {
  color: rgba(74, 81, 106, 0.3);
  font-weight: bold;
  opacity: 1;
}

.contact-form-group > input:focus {
  border-color: #2bbcff;
  color: #2bbcff;
}

.contact-form-group > button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 120px;
}

.contact-form-msg {
  margin-left: 10px;
  margin-bottom: -18px;
  font-size: 12px;
}

.contact-form-msg.msg-success {
  color: rgba(74, 81, 106, 0.6);
}

.contact-form-msg.msg-error {
  color: #f47f7f;
}

.badge {
  display: inline-block;
  position: relative;
  padding: 10px 20px;
  margin-top: -10px;
  margin-bottom: -10px;
  border-radius: 17px;
  background-color: #2bbcff;
  box-shadow: 0 5px 10px 0 rgba(43, 188, 255, 0.5);
  color: #FFFFFF;
  font-size: 0.875em;
  font-weight: bold;
  line-height: 1;
}

.badge::before {
  content: '';
  display: block;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border: 1px solid #FFFFFF;
  border-radius: 17px;
  pointer-events: none;
}

.career-box-wrapper {
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 767.98px) {
  .career-box-wrapper {
    max-width: 300px;
    margin: 0 auto;
  }
}

.career-box-wrapper::before {
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 100%;
}

.career-box {
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #FFFFFF;
  box-shadow: 0 10px 20px 0 rgba(182, 190, 218, 0.5);
  text-align: center;
}

.career-box-body {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.career-box-body .job-desc {
  color: #4A516A;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.2;
}

.career-box-footer .show-more {
  display: inline-block;
  color: #2bbcff;
  font-size: 0.875em;
  font-weight: bold;
}

.career-box-footer .show-more .icon-arrow {
  width: 14px;
  height: 14px;
}

.article h1 {
  font-weight: bold;
  text-align: center;
}

.article h3 {
  margin-bottom: 50px;
  text-align: center;
}

.article h4 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.article p, .article ul, .article ol {
  margin-bottom: 30px;
  font-size: 20px;
}

.article .desc {
  color: rgba(74, 81, 106, 0.6);
}

.article hr {
  margin: 30px -15px;
  border-top-color: rgba(182, 190, 218, 0.4);
}

@media only screen and (min-width: 768px) {
  .article hr {
    margin-left: -30px;
    margin-right: -30px;
  }
}

.st_contact_area .contact-form {
  max-width: 450px;
}

@media only screen and (max-width: 991.98px) {
  .st_contact_area .contact-form {
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }
}

.st_contact_area .contact-form-label {
  color: rgba(182, 190, 218, 0.6);
}

.st_contact_area .contact-form-msg.msg-success {
  color: rgba(182, 190, 218, 0.6);
}

@media only screen and (max-width: 991.98px) {
  .st_contact_area_img {
    text-align: center;
  }
}

@media only screen and (min-width: 992px) {
  .st_contact_area_img {
    position: relative;
    z-index: 1;
    margin-bottom: -118px;
    text-align: right;
  }
}

.page-index .st_banner_area {
  background-image: url("../images/paca-01.svg");
  background-repeat: no-repeat;
  background-size: auto 70%;
  background-position: right bottom;
}

@media only screen and (min-width: 992px) {
  .page-index .st_banner_area {
    background-size: auto 100%;
  }
}

.page-index .st_banner_area .contact-form {
  max-width: 450px;
  margin-bottom: 60px;
}

@media only screen and (max-width: 991.98px) {
  .page-index .st_banner_area .contact-form {
    margin-left: auto;
    margin-right: auto;
  }
}

.page-index .st_banner_area .share-button {
  display: inline-flex;
  align-items: center;
  color: #b6beda;
  font-size: 14px;
  line-height: 1;
}

.page-index .st_banner_area .share-button:hover {
  color: #4A516A;
}

.page-index .st_banner_area .share-button img {
  margin-left: 10px;
}

.page-index .st_banner_area_img {
  width: 100%;
}

@media only screen and (min-width: 992px) {
  .page-index .st_banner_area_img {
    width: 140%;
    margin-right: -40%;
  }
}

.page-index .st_banner_area_img img {
  width: 100%;
}

.page-index .st_laptop_area img {
  width: 100%;
  margin-top: -30px;
  margin-bottom: -35.85586%;
  pointer-events: none;
}

.page-index .st_pricing_area {
  background-image: url("../images/paca-02.svg");
  background-repeat: no-repeat;
  background-size: auto 70%;
  background-position: right top;
}

@media only screen and (min-width: 992px) {
  .page-index .st_pricing_area {
    background-size: auto 100%;
  }
}

.page-index .st_tabletmobile_area {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media only screen and (min-width: 992px) {
  .page-index .st_tabletmobile_area {
    height: 660px;
    margin-top: -110px;
    margin-bottom: -50px;
    padding-top: 110px;
    padding-bottom: 50px;
    overflow: hidden;
  }
  .page-index .st_tabletmobile_area > .container {
    height: 100%;
  }
  .page-index .st_tabletmobile_area > .container > .row {
    height: 100%;
    align-items: center;
  }
}

@media only screen and (min-width: 992px) {
  .page-index .st_tabletmobile_area_img {
    height: 660px;
    margin-top: -110px;
    margin-bottom: -50px;
    pointer-events: none;
  }
}

.page-index .st_tabletmobile_area_img > img {
  width: 100%;
}

@media only screen and (min-width: 992px) {
  .page-index .st_tabletmobile_area_img > img {
    width: auto;
    max-width: initial;
    height: 692px;
  }
}

/*# sourceMappingURL=saastrend.css.map */
